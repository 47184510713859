import { CRM_API, RequestHelper } from "utils";

export const CompaniaService = (() => {
  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`compania/${Id}`);
    });
  };
  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).postWithFiles("compania", data);
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get("compania", params);
    });
  };
  const crear = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post("compania/CrearCompania", data);
    });
  };

  const editar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post("compania/EditarCompania", data);
    });
  };

  const listarDatosCertificado = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).get("compania/ListarDatos", data);
    });
  };

  return {
    actualizar,
    buscarPorId,
    listado,
    editar,
    crear,
    listarDatosCertificado
  };
})();
