import { SvgComponent } from "components";
import { URL_DOCUMENTACION } from "./constants";
export const getMenuArray = () => {
  let menuArray = [];

  let menuAdministracion = {
    Nombre: "Administración",
    URL: "/CompaniaList",
    PermisoNombre: "ECF.Admin.Compania.LISTAR",
    Orden: 0,
    Icono: (
      <SvgComponent
        fill="white"
        height="35"
        width="35"
        iconName="fal fa-address-card"
      />
    ),
  };

  let menuTransacciones = {
    Nombre: "Transacciones E-CF",
    URL: "/TransaccionesList",
    PermisoNombre: "ECF.ComprobanteElectronico.LISTAR",
    Orden: 1,
    Icono: (
      <SvgComponent
        fill="white"
        height="35"
        width="35"
        iconName="transaction"
      />
    ),
  };

  let menuAnulacion = {
    Nombre: "Anulaciones de E-CF",
    URL: "/AnulacionesList",
    PermisoNombre: "ECF.AnulacionSecuencia.LISTAR",
    Orden: 2,
    Icono: (
      <SvgComponent
        fill="white"
        height="35"
        width="35"
        iconName="bill-cancel"
      />
    ),
  };

  let menuDirectorio = {
    Nombre: "Directorio de Servicios",
    URL: "/ConsultaDirectorioList",
    PermisoNombre: "ECF.ConsultarDirectorios.LISTAR",
    Orden: 3,
    Icono: (
      <SvgComponent
        fill="white"
        height="35"
        width="35"
        iconName="directory-list"
      />
    ),
  };

  let menuAprobacion = {
    Nombre: "Aprobación Comercial",
    URL: "/AprobacionComercialList",
    PermisoNombre: "ECF.AprobacionComercial.LISTAR",
    Orden: 4,
    Icono: (
      <SvgComponent
        fill="white"
        height="35"
        width="35"
        iconName="bill-approbation"
      />
    ),
  };

  let menuECFRecibidos = {
    Nombre: "E-CF Recibidos",
    URL: "/ComprobantesRecibidosList",
    PermisoNombre: "ECF.ComprobantesRecibidos.LISTAR",
    Orden: 5,
    Icono: (
      <SvgComponent fill="white" height="35" width="35" iconName="recibidos" />
    ),
  };

  let menuFirmar = {
    Nombre: "Firmar Archivo XML",
    URL: "/FirmarArchivoXML",
    PermisoNombre: "ECF.ComprobanteElectronico.FIRMAR",
    Orden: 6,
    Icono: (
      <SvgComponent
        fill="white"
        height="35"
        width="35"
        iconName="file-signed"
      />
    ),
  };

  const menuDocumentacion = {
    Nombre: "Documentación",
    PermisoNombre: "ECF.Documentacion.Ver",
    URL: URL_DOCUMENTACION,
    Orden: 7,
    Icono: (
      <SvgComponent fill="white" height="35" width="35" iconName="cotization" />
    ),
    isURL: true,
  };

  let menuArchivos = {
    Nombre: "Archivo",
    URL: "/ArchivoList",
    PermisoNombre: "ECF.Archivo.LISTAR",
    Orden: 8,
    Icono: (
      <SvgComponent fill="white" height="35" width="35" iconName="cotization" />
    ),
  };

  let menuCargaArchivos = {
    Nombre: "Carga Archivos",
    URL: "/CargaArchivo",
    PermisoNombre: "ECF.Archivo.LISTAR",
    Orden: 8,
    Icono: (
      <SvgComponent fill="white" height="35" width="35" iconName="cotization" />
    ),
  };

  let menuReporte = {
    Nombre: "Reporte",
    URL: "/Reporte",
    PermisoNombre: "ECF.Reporte.Imprimir",
    Orden: 9,
    Icono: (
      <SvgComponent fill="white" height="35" width="35" iconName="print" />
    ),
  };

  let menuSeriesFiscales = {
    Nombre: "Series Fiscales",
    URL: "/SerieFiscalList",
    PermisoNombre: "ECF.SerieFiscal.LISTAR",
    Orden: 9,
    Icono: (
      <SvgComponent
        fill="white"
        height="35"
        width="35"
        iconName="file-signed"
      />
    ),
  };

  menuArray.push(menuAdministracion);
  menuArray.push(menuTransacciones);
  menuArray.push(menuAnulacion);
  menuArray.push(menuDirectorio);
  menuArray.push(menuAprobacion);
  menuArray.push(menuECFRecibidos);
  menuArray.push(menuFirmar);
  menuArray.push(menuDocumentacion);
  menuArray.push(menuArchivos);
  menuArray.push(menuCargaArchivos);
  menuArray.push(menuReporte);
  menuArray.push(menuSeriesFiscales);

  menuArray.sort(function (a, b) {
    var textA = a.Nombre.toUpperCase();
    var textB = b.Nombre.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return menuArray;
};
