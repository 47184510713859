import { ResponseHeadersHelper } from "./response.header.helper";
import {
  SESSION_KEY,
  BAD_GATEWAY_ERROR_HTTP_CODE,
  BAD_GATEWAY_ERROR_MESSAGE,
  NO_INTERNET_CONNECTION_MESSAGE,
} from "utils";
import {} from "./constants";
import { DateHelper } from "./dates.helper";

export const RequestHelper = (rootUrl) =>
  ((rootUrl) => {
    const getUserToken = () => {
      return localStorage.getItem(SESSION_KEY);
    };

    const setUserToken = (token) => {
      if (token) {
        localStorage.setItem(SESSION_KEY, token);
      }
    };

    const getDefaultHeaders = () => {
      return {
        SessionId: getUserToken(),
        "Content-Type": "application/json",
      };
    };

    const getEndpoint = (apiPath, params) => {
      let url = rootUrl + apiPath;
      if (params) {
        url += "?" + serializeParams(params);
      }
      return url;
    };

    const serializeParams = (obj) => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };

    const handleResponse = (response, isBlob = false) => {
      const headersHelper = ResponseHeadersHelper(response.headers);
      setUserToken(headersHelper.getSessionToken());
      if (isBlob) {
        if (!response.ok) {
          return response.json().then(
            (data) =>
              new Promise((res) =>
                res({
                  Success: response.ok,
                  StatusCode: response.status,
                  Data: data,
                  Headers: headersHelper.getPaginationHeaders(),
                })
              )
          );
        }

        return {
          Success: true,
          blob: response.blob(),
        };
      }
      return response.json().then(
        (data) =>
          new Promise((res) =>
            res({
              Success: response.ok,
              StatusCode: response.status,
              Data: data,
              Headers: headersHelper.getPaginationHeaders(),
            })
          )
      );
    };

    const handleError = (err) => {
      var msg = BAD_GATEWAY_ERROR_MESSAGE;
      if (!window.navigator.onLine) msg = NO_INTERNET_CONNECTION_MESSAGE;

      return {
        Success: false,
        StatusCode: BAD_GATEWAY_ERROR_HTTP_CODE,
        Data: { Message: msg, Error: err },
        Headers: null,
      };
    };

    const get = (apiPath, params, isBlob = false) => {
      stringifyDates(params);
      return fetch(getEndpoint(apiPath, params), {
        headers: new Headers({
          ...getDefaultHeaders(),
        }),

        method: "get",
        mode: "cors",
      })
        .then((res) => handleResponse(res, isBlob))
        .catch((err) => handleError(err));
    };

    const post = (apiPath, data, isBlob = false) => {
      stringifyDates(data);
      return fetch(getEndpoint(apiPath), {
        headers: new Headers({
          ...getDefaultHeaders(),
        }),
        credentials: "include",
        body: JSON.stringify(data),
        method: "post",
        mode: "cors",
      })
        .then((res) => handleResponse(res, isBlob))
        .catch((err) => handleError(err));
    };

    const put = (apiPath, data) => {
      stringifyDates(data);
      return fetch(getEndpoint(apiPath), {
        headers: new Headers({
          ...getDefaultHeaders(),
        }),
        credentials: "include",
        body: JSON.stringify(data),
        method: "put",
        mode: "cors",
      })
        .then((res) => handleResponse(res))
        .catch((err) => handleError(err));
    };

    const postWithFiles = (apiPath, data) => {
      const formData = new FormData();
      formData.append("json", JSON.stringify(data));
      data.Files.map((file, i) => {
        formData.append(`File-${i}`, file);
      });
      return fetch(getEndpoint(apiPath), {
        body: formData,
        method: "post",
        mode: "cors",
        headers: new Headers({
          SessionId: getUserToken(),
        }),
      })
        .then((res) => handleResponse(res))
        .catch((err) => handleError(err));
    };

    const stringifyDates = (data) => {
      for (let key in data) {
        if (DateHelper.isValidDate(data[key])) {
          data[key] = DateHelper.formatDate(data[key]);
        }
      }
    };

    return {
      post,
      get,
      put,
      postWithFiles,
    };
  })(rootUrl);
