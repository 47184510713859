export class DetallesItems {
  static get PROPIEDADES_EXPORTAR() {
    return [
      {
        value: "IdSec",
        label: "Id",
      },
      {
        value: "TransaccionId",
        label: "Transaccion Id",
      },
      {
        value: "NumeroLinea",
        label: "Número Linea",
      },
      {
        value: "IndicadorFacturacion",
        label: "Indicador Facturación",
      },
      {
        value: "IndicadorAgenteRetencionoPercepcion",
        label: "Indicador Agente Retencion o Percepción",
      },
      {
        value: "MontoITBISRetenido",
        label: "Monto ITBIS Retenido",
      },
      {
        value: "MontoISRRetenido",
        label: "Monto ISR Retenido",
      },
      {
        value: "NombreItem",
        label: "Nombre Item",
      },
      {
        value: "IndicadorBienoServicio",
        label: "Indicador Bien o Servicio",
      },
      {
        value: "DescripcionItem",
        label: "Descripción Item",
      },
      {
        value: "CantidadItem",
        label: "Cantidad Item",
      },
      {
        value: "UnidadMedida",
        label: "Unidad Medida",
      },
      {
        value: "CantidadReferencia",
        label: "Cantidad Referencia",
      },
      {
        value: "UnidadReferencia",
        label: "Unidad Referencia",
      },
      {
        value: "GradosAlcohol",
        label: "Grados Alcohol",
      },
      {
        value: "PrecioUnitarioReferencia",
        label: "Precio Unitario Referencia",
      },
      {
        value: "FechaElaboracion",
        label: "Fecha Elaboracion",
      },
      {
        value: "FechaVencimientoItem",
        label: "Fecha Vencimiento Item",
      },
      {
        value: "PesoNetoKilogramo",
        label: "Peso Neto Kilogramo",
      },
      {
        value: "PesoNetoMineria",
        label: "Peso Neto Mineria",
      },
      {
        value: "TipoAfiliacion",
        label: "Tipo Afiliación",
      },
      {
        value: "Liquidacion",
        label: "Liquidación",
      },
      {
        value: "PrecioUnitarioItem",
        label: "Precio Unitario Item",
      },
      {
        value: "DescuentoMonto",
        label: "Descuento Monto",
      },
      {
        value: "RecargoMonto",
        label: "Recargo Monto",
      },
      {
        value: "PrecioOtraMoneda",
        label: "Precio Otra Moneda",
      },
      {
        value: "DescuentoOtraMoneda",
        label: "Descuento Otra Moneda",
      },
      {
        value: "RecargoOtraMoneda",
        label: "Recargo Otra Moneda",
      },
      {
        value: "MontoItemOtraMoneda",
        label: "Monto Item Otra Moneda",
      },
      {
        value: "MontoItem",
        label: "Monto Item",
      },
      {
        value: "MontoImpuestoSelectivoConsumoEspecifico",
        label: "Monto Impuesto Selectivo Consumo Especifico",
      },
      {
        value: "MontoImpuestoSelectivoConsumoAdvalorem",
        label: "Monto Impuesto Selectivo Consumo Advalorem",
      },
    ];
  }
}
