export * from "./UsuarioLookup/UsuarioLookup";
export * from "./ComprobanteRecibidoLookup/ComprobanteRecibidoLookup";

export const initialWhere = {
  EsPaginable: true,
  Estatus: "A",
  CantidadPorPagina: 5,
  EsLookup: true,
};
export const initialWhereModal = {
  Estatus: "A",
  OrderBy: "IdSec",
};
export const controlsProportion = {
  input: "93%",
  button: "5%",
  inputGroup: "62%",
  buttonGroup: "28%",
};
export const buttonStyle = {
  borderRadius: "0% 0.2rem 0.2rem 0%",
  paddingLeft: "0px",
  marginLeft: "0px",
  paddingTop: "0px",
  paddingRight: "0px",
};
