import React from "react";
import { Button, Modal } from "react-bootstrap";
import IconButton from "components/IconButton/IconButton";

export default function ModalContainer({
  cerrar,
  showModal,
  title,
  subtitle,
  children,
  grabar,
  size = "xl",
  btnGrabar = { label: "Grabar", show: true },
  btnCerrar = { label: "Cerrar", show: true },
}) {
  return (
    <Modal
      className="modal modal-primary "
      size={size}
      onHide={cerrar}
      show={showModal}
    >
      <Modal.Header className="justify-content-between">
        <div>
          <h3>{title}</h3>
        </div>

        {subtitle && (
          <div>
            <h5>
              <b>{subtitle}</b>
            </h5>
          </div>
        )}
        <IconButton onClick={cerrar}>
          <i className="fa-solid fa-xmark "></i>
        </IconButton>
      </Modal.Header>

      <Modal.Body className="text-left">{children}</Modal.Body>
      <Modal.Footer>
        {btnGrabar.show && (
          <Button className="btn-simple" onClick={grabar} variant="link">
            {btnGrabar.label}
          </Button>
        )}
        {btnCerrar.show && (
          <Button className="btn-simple" onClick={cerrar} variant="link">
            {btnCerrar.label}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
