import { Card } from "react-bootstrap";
import uuid from "react-uuid";
import "./graficos.styles.scss";
import Select from "react-select";
import { SvgComponent } from "components";
import { useState } from "react";
import IconButton from "components/IconButton/IconButton";

export const ChartWrapper = ({
  height,
  width,
  Chart,
  title,
  data,
  noFilter,
}) => {
  const [showCanvas, setShowCanvas] = useState(false);
  return (
    <Card
      style={{
        width,
        height,
      }}
    >
      <Card.Header>
        <Card.Title
          className="d-flex"
          style={{ justifyContent: "space-between" }}
        >
          {title}
          {!noFilter && (
            <IconButton onClick={() => setShowCanvas(true)}>
              <SvgComponent iconName="filter" fill="#77777a" />
            </IconButton>
          )}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Chart
          data={data}
          showCanvas={showCanvas}
          setShowCanvas={setShowCanvas}
        />
      </Card.Body>
    </Card>
  );
};
