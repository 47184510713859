import React from "react";
import { Card } from "react-bootstrap";
import { useTheme } from "hooks";
function EditContainer({ children, disabled = false, esView }) {
  const { theme } = useTheme();

  return (
    <Card.Body
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: esView
          ? `calc((100vh - ${theme.MAX_HEIGHT_HEADER}) - 160px)`
          : `calc((100vh - ${theme.MAX_HEIGHT_HEADER}) - 60px)`,
        minHeight: esView
          ? `calc((100vh - ${theme.MAX_HEIGHT_HEADER}) - 150px)`
          : `calc((100vh - ${theme.MAX_HEIGHT_HEADER}) - 100px)`,
        paddingBottom: `40px`,
      }}
    >
      <fieldset disabled={disabled}>{children}</fieldset>
    </Card.Body>
  );
}

export default EditContainer;
