import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Select from "react-select";

import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";
import { AprobacionComercialManager as manager } from "managers";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import { ComprobanteRecibidoLookup } from "components";
import useQueryParams from "hooks/useQueryParams";

export default function AprobacionComercialEdit({ showLoading, hideLoading }) {
  const [aprobacion, setAprobacion] = useState({});
  const [saved, setSaved] = useState(false);

  const URLParameters = useQueryParams();
  const { handleException } = useExceptionHandler();
  const { Message, cerrarAlertas, Types } = useMessage();

  const ComprobanteRecibidoId = URLParameters.get("ComprobanteRecibidoId");
  const ESTADO_LIST = [
    { value: 1, label: "Aprobado" },
    { value: 2, label: "Rechazado" },
  ];

  useEffect(() => {
    if (ComprobanteRecibidoId) {
      setAprobacion({
        ...aprobacion,
        ComprobanteElectronicoRecibidoId: ComprobanteRecibidoId,
      });
    }

    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, []);

  function grabar() {
    if (!saved) {
      showLoading();
      manager
        .crear(aprobacion)
        .then((res) => {
          Message({
            message:
              res.RespuestaAprobacionComercial?.Mensaje?.string ??
              `Grabado Exitosamente`,
          });
          setSaved(true);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    } else {
      Message({
        message: `La entidad ya fue grabada.`,
        type: Types.TYPE_WARNING,
      });
    }
  }

  return (
    <Form action="" className="form-horizontal" method="">
      <Card>
        <EditHeader
          titulo="Aprobación Comercial"
          grabar={grabar}
          volver={true}
        />
        <EditContainer>
          <SectionRow>
            <InputContainer label="Documento">
              <ComprobanteRecibidoLookup
                initialValue={{
                  value: aprobacion.ComprobanteElectronicoRecibidoId,
                }}
                onChange={(e) => {
                  setAprobacion({
                    ...aprobacion,
                    TipoeCF: e.tipoeCF,
                    eNCF: e.eNCF,
                    MontoTotal: e.monto,
                    FechaEmision: e.fechaEmision,
                    RNCEmisor: e.rncEmisor,
                    ComprobanteElectronicoRecibidoId: e.value,
                  });
                }}
              />
            </InputContainer>
            <InputContainer label="Estado">
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="selectTipoComprobante"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Tipo Comprobante"
                options={ESTADO_LIST}
                value={ESTADO_LIST.filter(
                  (opt) => opt.value === aprobacion.Estado
                )}
                onChange={(e) => {
                  setAprobacion({
                    ...aprobacion,
                    Estado: e.value,
                  });
                }}
              />
            </InputContainer>
          </SectionRow>
          <SectionRow>
            <InputContainer label="Motivo Rechazo">
              <Form.Control
                name="DetalleMotivoRechazo"
                type="text"
                as={"textarea"}
                rows={2}
                value={aprobacion.DetalleMotivoRechazo}
                onChange={(e) => {
                  setAprobacion({
                    ...aprobacion,
                    DetalleMotivoRechazo: e.target.value,
                  });
                }}
              />
            </InputContainer>
          </SectionRow>
        </EditContainer>
      </Card>
    </Form>
  );
}
