import { useState } from "react";
import { Form } from "react-bootstrap";
import { StyleHelper } from "utils";
import "./AutoComplete.styles.scss";

export const AutoComplete = ({
  getList,
  parentValue,
  setParentValue,
  placeholder = "",
  auxOnChange = () => {},
  noBorderRadius = false,
  required = false,
  isDisabled = false,
}) => {
  var validSelection = false;
  const [state, setState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: "",
  });

  const onChange = async (e) => {
    let suggestions = [];
    const userInput = e.currentTarget.value;

    if (userInput) {
      setParentValue({ value: null, label: userInput });
      auxOnChange({ value: null });
      suggestions = await getList(userInput);

      const filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: userInput,
      });
    } else {
      setParentValue({ value: undefined, label: "" });
      auxOnChange({ value: undefined });
    }
  };

  const onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = state;
    const containerDiv = document.getElementById("suggestionsList");
    let scrollAmount = 0;

    if (e.keyCode === 13) {
      //enter
      let selected = filteredSuggestions[activeSuggestion];
      setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: selected.label,
      });
      setParentValue({
        label: selected.label,
        value: parseInt(selected.value),
        ...selected,
      });
      auxOnChange({ label: selected.label, value: parseInt(selected.value) });
      e.target.focus();
      validSelection = true;
    } else if (e.keyCode === 38) {
      //up
      if (activeSuggestion === 0) {
        return;
      }
      setState({
        ...state,
        activeSuggestion: activeSuggestion - 1,
      });
      scrollAmount = -30;
    } else if (e.keyCode === 40) {
      //down
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setState({
        ...state,
        activeSuggestion: activeSuggestion + 1,
      });
      scrollAmount = 30;
    }

    if (activeSuggestion < filteredSuggestions.length) {
      if (containerDiv != undefined && containerDiv != null)
        containerDiv.scrollBy({ top: scrollAmount, behavior: "smooth" });
    }
  };

  const onClick = (e) => {
    let selectedValue = parseInt(e.currentTarget.id);
    let selected = state.filteredSuggestions.filter(
      (opt) => opt.value === selectedValue
    )[0];
    setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: selected.label,
    });
    setParentValue(selected);
    auxOnChange(selected);
    validSelection = true;
  };

  let SuggestionsListComponent;
  if (state.showSuggestions && state.userInput) {
    if (state.filteredSuggestions.length) {
      SuggestionsListComponent = (
        <ul id="suggestionsList" className="suggestions">
          {state.filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === state.activeSuggestion)
              className = "suggestion-active";

            return (
              <li
                className={className}
                key={suggestion.value}
                id={suggestion.value}
                onClick={onClick}
              >
                {suggestion.label}
              </li>
            );
          })}
        </ul>
      );
    } else {
      SuggestionsListComponent = (
        <div className="no-suggestions">
          <em>No hay opciones</em>
        </div>
      );
    }
  }

  return (
    <div className="autoComplete-wrapper">
      <div className="autoComplete-parent">
        <Form.Control
          type="search"
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={parentValue.label}
          style={
            noBorderRadius
              ? required
                ? { ...StyleHelper.InputRequired, borderRadius: 0 }
                : { borderRadius: 0 }
              : required
              ? StyleHelper.InputRequired
              : {}
          }
          onBlur={() => {
            setTimeout(() => {
              setState({
                ...state,
                showSuggestions: false,
              });
              if (!validSelection && parentValue.value === null)
                setParentValue({ label: "", value: null });
            }, 200);
          }}
        />
        {SuggestionsListComponent}
      </div>
    </div>
  );
};
