import { CRM_API, RequestHelper } from "utils";

const RUTA = "impuestos-adicionales";
export const ImpuestosAdicionalesService = (() => {
  const listadoImpuestosAdicionales = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/impuestos`, params);
    });
  };

  const descargarExcel = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/impuestos/excel`,
        params,
        true
      );
    });
  };

  return {
    listadoImpuestosAdicionales,
    descargarExcel,
  };
})();
