const ESTATUS_ACTIVO = "A";
const ESTATUS_ACTIVO_LABEL = "Activo";
const ESTATUS_INACTIVO = "I";
const ESTATUS_INACTIVO_LABEL = "Inactivo";

export class Perfil {
  static get ESTATUS_DESC() {
    switch (this.Estatus) {
      case ESTATUS_ACTIVO:
        return ESTATUS_ACTIVO_LABEL;
      case ESTATUS_INACTIVO:
        return ESTATUS_INACTIVO_LABEL;
    }
  }
  static get ESTATUS_LIST() {
    return [
      {
        value: ESTATUS_ACTIVO,
        label: ESTATUS_ACTIVO_LABEL,
      },
      {
        value: ESTATUS_INACTIVO,
        label: ESTATUS_INACTIVO_LABEL,
      },
    ];
  }

  static get DEFAULT_ESTATUS_VALUE() {
    return ESTATUS_ACTIVO;
  }
}
