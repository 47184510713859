import { CRM_API, RequestHelper } from "utils";

const RUTA = "exportar";
export const ExportarService = (() => {
  const exportar = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).post(`${RUTA}`, params, true);
    });
  };

  return {
    exportar,
  };
})();
