import React, { useState, useEffect, useCallback } from "react";
import { AuthenticationManager } from "managers";
import { AuthenticationContext } from "contexts";

export function AuthenticationProvider({ children }) {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      AuthenticationManager.refreshUsuarioLogin()
        .then((data) => {
          setCurrentSession(data);
        })
        .catch((error) => {
          setCurrentSession(null);
        });
    }, 500);
  }, []);

  const setCurrentSession = useCallback(
    (data) => {
      setUser(data?.usuarioLogin || null);
    },
    [user]
  );

  const login = (authModel) => {
    return AuthenticationManager.login(authModel).then((data) => {
      setCurrentSession(data);
    });
  };

  function logout() {
    return AuthenticationManager.logout().then(() => {
      setCurrentSession(null);
    });
  }

  const cambiarCompania = (model) => {
    return AuthenticationManager.cambiarCompania(model).then((data) => {
      setCurrentSession(data);
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{ user, login, logout, cambiarCompania }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
