import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import UsuarioList from "./UsuarioList";
import PerfilList from "./PerfilList";
import { SvgComponent } from "components";

const UsuariosEquipos = ({ hideLoading, showLoading, isActive, option }) => {
  const [currentTab, setCurrentTab] = useState();
  const iconStyle = { marginRight: "15px" };
  const iconColor = "#3f3f3f";
  const navStyle = { width: "100%", justifyContent: "center" };
  const paneStyle = { padding: "0px" };

  useEffect(() => {
    if (isActive) {
      if (option) setCurrentTab(option);
      else setCurrentTab("info-icons");
    }
  }, [isActive, option]);

  const handleSelect = (eventKey) => {
    setCurrentTab(eventKey);
  };

  return (
    <>
      <Tab.Container
        id="icons-tabs"
        defaultActiveKey="info-icons"
        activeKey={currentTab}
        onSelect={handleSelect}
      >
        <Nav className="ml-0" role="tablist" variant="tabs" fill>
          <Nav.Item>
            <Nav.Link eventKey="info-icons" style={navStyle}>
              <SvgComponent
                iconName="user"
                fill={iconColor}
                style={iconStyle}
              />
              Usuarios
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="style-icons" style={navStyle}>
              <SvgComponent
                iconName="roles"
                height="24"
                width="24"
                fill={iconColor}
                style={iconStyle}
              />
              Perfiles
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="info-icons" style={paneStyle}>
            <UsuarioList
              hideLoading={hideLoading}
              showLoading={showLoading}
              isActive={currentTab === "info-icons"}
              hideTitle={true}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="style-icons" style={paneStyle}>
            <PerfilList
              hideLoading={hideLoading}
              showLoading={showLoading}
              isActive={currentTab === "style-icons"}
              hideTitle={true}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
export default UsuariosEquipos;
