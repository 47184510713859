import React from "react";
import { Card } from "react-bootstrap";

function EditSection({
  children,
  title,
  mt,
  ml,
  mb,
  childrenStyles,
  style = { marginLeft: "10px" },
  hasButton = false,
  button = <></>,
}) {
  return (
    <>
      <div className={`mb-${mb ? mb : "4"} ${mt ? mt : "mt-2"}`} style={style}>
        <Card.Title className="d-inline text-muted font-weight-bold">
          {title}
        </Card.Title>
        <div className="float-right">{hasButton && button}</div>
      </div>

      <div
        className={`ml-${ml ? ml : "ml-5"} mb-${mb ? mb : "mb-4"}`}
        style={childrenStyles}
      >
        {children}
      </div>
    </>
  );
}

export default EditSection;
