import { CRM_API, RequestHelper } from "utils";

const RUTA = "configuracion-correo-electronico";
export const ConfiguracionCorreoElectronicoService = (() => {
  const actualizarMailConfig = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(
        `${RUTA}/actualizar-mail-config`,
        data
      );
    });
  };

  const vincularCuentaGmail = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}/vincular-cuenta-gmail`, data);
    });
  };

  const vincularCuentaOutlook = (args) => {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(
        `${RUTA}/vincular-cuenta-outlook`,
        data
      );
    });
  };

  const desvincularCuentaGmail = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}/desvincular-cuenta-gmail`);
    });
  };

  const desvincularCuentaOutlook = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}/desvincular-cuenta-outlook`);
    });
  };

  const obtenerUrlOutlook = function (args) {
    return new Promise((res) => res(args)).then(() => {
      return RequestHelper(CRM_API).get(`${RUTA}/obtener-url-outlook`, {
        CodeVerifier: args.codeVerifier,
        CodeChallenge: args.codeChallenge,
        Fuente: args.fuente,
        Referencia: args.referencia,
      });
    });
  };

  const obtenerUrlOutlookAutomatizacion = function (args) {
    return new Promise((res) => res(args)).then(() => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/obtener-url-outlook-automatizacion`,
        {
          CodeVerifier: args.codeVerifier,
          CodeChallenge: args.codeChallenge,
          Fuente: args.fuente,
          Referencia: args.referencia,
        }
      );
    });
  };

  const buscarMailConfig = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).get(`${RUTA}/mail-config`, {
        Fuente: data.fuente,
        Referencia: data.referencia,
      });
    });
  };

  return {
    buscarMailConfig,
    actualizarMailConfig,
    vincularCuentaGmail,
    desvincularCuentaGmail,
    obtenerUrlOutlook,
    vincularCuentaOutlook,
    desvincularCuentaOutlook,
    obtenerUrlOutlookAutomatizacion,
  };
})();
