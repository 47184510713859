import React, { useState, useRef } from "react";
import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";
import { useEffect } from "react";
import { GridView } from "components";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import IconButton from "components/IconButton/IconButton";
import { Card } from "react-bootstrap";
import { GraficosManager } from "managers";
import { Simplebar } from "components/Simplebar/Simplebar";
import { InputSidebarContainer } from "components/PantallasEdit/InputContainer/InputSideBarContainer";
import Select from "react-select";
import { useLocalStorage } from "hooks";
import {
  PERIODO_TABLA_LIST,
  PERIODICIDAD_TABLA_ACTUAL_LIST,
  PERIODICIDAD_TABLA_PASADO_LIST,
  PERIODICIDAD_TABLA_OTRO_LIST,
  PERIODO_TABLA_ACTUAL,
  PERIODO_TABLA_PASADO,
  TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL,
  TIPO_COMPROBANTE_FACTURA_CONSUMO,
  TIPO_COMPROBANTE_NOTA_DEBITO,
  TIPO_COMPROBANTE_NOTA_CREDITO,
  TIPO_COMPROBANTE_REGIMEN_ESPECIAL,
  TIPO_COMPROBANTE_GUBERNAMENTAL,
  PERIODICIDAD_TABLA_ACTUAL_HOY,
  PERIODICIDAD_TABLA_OTRO_RANGO,
  PERIODO_TABLA_OTRO,
} from "utils";
import Porcentaje from "components/Porcentaje/Porcentaje";
import { TRANSACCION_ESTATUS_TERMINADA } from "utils";

import GridFooterTotalCard from "components/GridFooterTotalCard/GridFooterTotalCard";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";

import { DateHelper } from "utils";

function TablaClientes({ showCanvas, setShowCanvas, title }) {
  const [list, setList] = useState([]);

  const [sucursales, setSucursales] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [codigosVendedores, setCodigosVendedores] = useState([]);

  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const ref = useRef(null);

  const DEFAULT_VALUES = {
    TipoEcfList: [
      TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL,
      TIPO_COMPROBANTE_FACTURA_CONSUMO,
      TIPO_COMPROBANTE_NOTA_DEBITO,
      TIPO_COMPROBANTE_NOTA_CREDITO,
      TIPO_COMPROBANTE_REGIMEN_ESPECIAL,
      TIPO_COMPROBANTE_GUBERNAMENTAL,
    ],
    Estatus: TRANSACCION_ESTATUS_TERMINADA,
    Periodo: PERIODO_TABLA_ACTUAL,
    Periodicidad: PERIODICIDAD_TABLA_ACTUAL_HOY,
    ComprobantesCategoria: "V",
  };

  const [storedWhere, setStoredWhere] = useLocalStorage(
    "Comprobantes-Tabla-Ventas",
    DEFAULT_VALUES
  );

  const [where, setWhere] = useState(
    storedWhere ? storedWhere : DEFAULT_VALUES
  );
  useEffect(() => {
    buscar();
  }, []);

  function buscar() {
    GraficosManager.obtenerDatosTablaComprobantesVentas(where)
      .then((res) => {
        setList(res.Data);
      })
      .catch((err) => handleException(err));

    GraficosManager.obtenerDatosFiltros()
      .then((res) => {
        const sucursales = res.Data?.Sucursales?.map((sucursal) => {
          return {
            label: sucursal,
            value: sucursal,
          };
        });
        sucursales.unshift({
          label: "Todas",
          value: "",
        });
        setSucursales(sucursales);

        const monedas = res.Data?.Monedas?.map((moneda) => {
          return {
            label: moneda,
            value: moneda,
          };
        });
        monedas.unshift({
          label: "Todas",
          value: "",
        });
        setMonedas(monedas);

        const codigosVendedores = res.Data?.CodigosVendedores?.map(
          (vendedor) => {
            return {
              label: vendedor,
              value: vendedor,
            };
          }
        );
        codigosVendedores.unshift({
          label: "Todos",
          value: "",
        });
        setCodigosVendedores(codigosVendedores);
      })
      .catch((err) => handleException(err));
  }

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setWhere);
    FormHelper.handleChange(e, setStoredWhere);
  };

  const FilterSimplebar = () => {
    return (
      <Simplebar
        ref={ref}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvas}
        setShow={(e) => {
          setShowCanvas(e);
        }}
        useLoading={true}
        title="Filtros Tabla Comprobantes Ventas"
        btnLeft={{
          show: true,
          message: "Limpiar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "clean",
          func: () => {
            setWhere({});
            setStoredWhere({});
            setTimeout(() => {
              buscar();
            }, 100);
          },
        }}
        btnRight={{
          show: true,
          message: "Filtrar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "check",
          func: () => {
            buscar();
            setShowCanvas(false);
          },
        }}
      >
        <InputSidebarContainer label="Periodo">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Periodo"
            onChange={(e) => {
              setWhere({
                ...where,
                Periodo: e.value,
              });
              setStoredWhere({
                ...storedWhere,
                Periodo: e.value,
              });
            }}
            options={PERIODO_TABLA_LIST}
            value={PERIODO_TABLA_LIST.filter(function (option) {
              return (
                option.value ===
                (where.Periodo ? where.Periodo : PERIODO_TABLA_LIST[0].value)
              );
            })}
          />

          {where.Periodo === PERIODO_TABLA_ACTUAL && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setWhere({
                  ...where,
                  Periodicidad: e.value,
                });
                setStoredWhere({
                  ...storedWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_ACTUAL_LIST}
              value={PERIODICIDAD_TABLA_ACTUAL_LIST.filter(function (option) {
                return (
                  option.value ===
                  (where.Periodicidad
                    ? where.Periodicidad
                    : PERIODICIDAD_TABLA_ACTUAL_LIST[0].value)
                );
              })}
            />
          )}

          {where.Periodo === PERIODO_TABLA_PASADO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setWhere({
                  ...where,
                  Periodicidad: e.value,
                });
                setStoredWhere({
                  ...storedWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_PASADO_LIST}
              value={PERIODICIDAD_TABLA_PASADO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (where.Periodicidad
                    ? where.Periodicidad
                    : PERIODICIDAD_TABLA_PASADO_LIST[0].value)
                );
              })}
            />
          )}

          {where.Periodo === PERIODO_TABLA_OTRO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setWhere({
                  ...where,
                  Periodicidad: e.value,
                });
                setStoredWhere({
                  ...storedWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_OTRO_LIST}
              value={PERIODICIDAD_TABLA_OTRO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (where.Periodicidad
                    ? where.Periodicidad
                    : PERIODICIDAD_TABLA_OTRO_LIST[0].value)
                );
              })}
            />
          )}

          {where.Periodo === PERIODO_TABLA_OTRO &&
            where.Periodicidad === PERIODICIDAD_TABLA_OTRO_RANGO && (
              <InputSidebarContainer label="Rango de Fechas">
                <DateTimePicker
                  usarLabel={false}
                  esRangoFechas={true}
                  labelFechaInicio="Desde"
                  labelFechaFin="Hasta"
                  fechaInicioOriginal={where.FechaInicio}
                  fechaFinOriginal={where.FechaFin}
                  onChange={(e) => {
                    if (
                      (where.FechaInicio !==
                        DateHelper.formatDate(e.fechaInicio) ||
                        where.FechaFin !== DateHelper.formatDate(e.fechaFin)) &&
                      (e.fechaFin || e.fechaInicio)
                    ) {
                      setWhere({
                        ...where,
                        FechaInicio: DateHelper.formatDate(e.fechaInicio),
                        FechaFin: DateHelper.formatDate(e.fechaFin),
                      });
                      setStoredWhere({
                        ...where,
                        FechaInicio: DateHelper.formatDate(e.fechaInicio),
                        FechaFin: DateHelper.formatDate(e.fechaFin),
                      });
                    }
                  }}
                />
              </InputSidebarContainer>
            )}
        </InputSidebarContainer>

        <InputSidebarContainer label="Sucursal">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Sucursal"
            onChange={(e) => {
              setWhere({
                ...where,
                Sucursal: e.value,
              });
              setStoredWhere({
                ...storedWhere,
                Sucursal: e.value,
              });
            }}
            options={sucursales}
            value={sucursales.filter(function (option) {
              return (
                option.value ===
                (where.Sucursal ? where.Sucursal : sucursales[0].value)
              );
            })}
          />
        </InputSidebarContainer>
        <InputSidebarContainer label="Moneda">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Sucursal"
            onChange={(e) => {
              setWhere({
                ...where,
                Moneda: e.value,
              });
              setStoredWhere({
                ...storedWhere,
                Moneda: e.value,
              });
            }}
            options={monedas}
            value={monedas.filter(function (option) {
              return (
                option.value ===
                (where.Moneda ? where.Moneda : monedas[0].value)
              );
            })}
          />
        </InputSidebarContainer>
        <InputSidebarContainer label="Codigo Vendedor">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="CodigoVendedor"
            onChange={(e) => {
              setWhere({
                ...where,
                CodigoVendedor: e.value,
              });
              setStoredWhere({
                ...storedWhere,
                CodigoVendedor: e.value,
              });
            }}
            options={codigosVendedores}
            value={codigosVendedores.filter(function (option) {
              return (
                option.value ===
                (where.CodigoVendedor
                  ? where.CodigoVendedor
                  : codigosVendedores[0].value)
              );
            })}
          />
        </InputSidebarContainer>
      </Simplebar>
    );
  };

  return (
    <div>
      <FilterSimplebar />
      <Card
        style={{
          width: "92.5vw",
        }}
      >
        <Card.Header>
          <Card.Title
            className="d-flex"
            style={{ justifyContent: "space-between" }}
          >
            {title}
            <IconButton onClick={() => setShowCanvas(true)}>
              <SvgComponent iconName="filter" fill="#77777a" />
            </IconButton>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <GridView
            allowPaging={false}
            dataSource={list}
            where={where}
            setWhere={setWhere}
            height="55vh"
            hideFilters={true}
            borderless={false}
            showFooter={true}
          >
            <GridView.Column
              headerText="Nombre"
              dataField="Nombre"
              sortExpression="Nombre"
            />
            <GridView.Column
              headerText="Total"
              dataField="MontoTotal"
              sortExpression="MontoTotal"
              number={true}
              extraComponentdataField={"MontoTotalComparacionPorcentaje"}
              extraComponent={Porcentaje}
              sumarizar={true}
              footerRenderer={GridFooterTotalCard}
            />
            <GridView.Column
              headerText="Total ITBIS1"
              dataField="TotalITBIS1"
              sortExpression="TotalITBIS1"
              number={true}
              extraComponentdataField={"TotalITBIS1ComparacionPorcentaje"}
              extraComponent={Porcentaje}
              sumarizar={true}
              footerRenderer={GridFooterTotalCard}
            />
            <GridView.Column
              headerText="Total ITBIS2"
              dataField="TotalITBIS2"
              sortExpression="TotalITBIS2"
              number={true}
              extraComponentdataField={"TotalITBIS2ComparacionPorcentaje"}
              extraComponent={Porcentaje}
              sumarizar={true}
              footerRenderer={GridFooterTotalCard}
            />
            <GridView.Column
              headerText="Total ITBIS3"
              dataField="TotalITBIS3"
              sortExpression="TotalITBIS3"
              number={true}
              extraComponentdataField={"TotalITBIS3ComparacionPorcentaje"}
              extraComponent={Porcentaje}
              sumarizar={true}
              footerRenderer={GridFooterTotalCard}
            />
            <GridView.Column
              headerText="Monto Exento"
              dataField="MontoExento"
              sortExpression="MontoExento"
              number={true}
              extraComponentdataField={"MontoExentoComparacionPorcentaje"}
              extraComponent={Porcentaje}
              sumarizar={true}
              footerRenderer={GridFooterTotalCard}
            />
            <GridView.Column
              headerText="Total Retención ISR"
              dataField="TotalISRRetencion"
              sortExpression="TotalISRRetencion"
              number={true}
              extraComponentdataField={"TotalISRRetencionComparacionPorcentaje"}
              extraComponent={Porcentaje}
              sumarizar={true}
              footerRenderer={GridFooterTotalCard}
            />
            <GridView.Column
              headerText="Total Retención ITBIS"
              dataField="TotalITBISRetencion"
              sortExpression="TotalITBISRetencion"
              number={true}
              extraComponentdataField={
                "TotalITBISRetencionComparacionPorcentaje"
              }
              extraComponent={Porcentaje}
              sumarizar={true}
              footerRenderer={GridFooterTotalCard}
            />
          </GridView>
        </Card.Body>
      </Card>
    </div>
  );
}
export default TablaClientes;
