export class WarningException extends Error {
  constructor(message, exceptionType, exceptionMessage) {
    super(message);
    this.exceptionType = exceptionType;
    this.exceptionMessage = exceptionMessage;
    this.warning = true;
    this.name = "WarningException";
  }
}

export class ConfirmException extends Error {
  constructor(message, exceptionType, exceptionMessage) {
    super(message);
    this.exceptionType = exceptionType;
    this.exceptionMessage = exceptionMessage;
    this.confirm = true;
    this.name = "ConfirmException";
  }
}

export class ValidationException extends Error {
  constructor(message, exceptionType, exceptionMessage) {
    super(message);
    this.exceptionType = exceptionType;
    this.exceptionMessage = exceptionMessage;
    this.warning = true;
    this.name = "ValidationException";
  }
}

export class ErrorException extends Error {
  constructor(message, exceptionType, exceptionMessage, stackTrace) {
    super(message);
    this.exceptionType = exceptionType;
    this.exceptionMessage = exceptionMessage;
    this.stackTrace = stackTrace;
    this.name = "ErrorException";
  }
}

export class UnauthorizeException extends Error {
  constructor(unauthorizedModel) {
    super("Unauthorized");
    this.unauthorized = true;
    this.unauthorizedModel = unauthorizedModel;
  }
}

export class NoConnectionException extends Error {
  constructor(message, exceptionType, exceptionMessage) {
    super(message);
    this.exceptionType = exceptionType;
    this.exceptionMessage = exceptionMessage;
    this.noConnection = true;
    this.name = "NoConnectionException";
  }
}