import { useContext } from "react";
import { AuthenticationContext } from "contexts";

export function useAuthentication() {
  const { user, login, logout, cambiarCompania } = useContext(
    AuthenticationContext
  );

  const tienePermiso = (permiso) => {
    return user.Funciones.includes(permiso);
  };

  return {
    usuario: user,
    login,
    logout,
    cambiarCompania,
    tienePermiso,
  };
}
