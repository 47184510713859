import React, { useState, useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import Select from "react-select";

import { FormHelper } from "utils";
import useMessage from "hooks/useMessage";
import { CompaniaManager } from "managers";
import { useAuthentication, useApp } from "hooks";
import { InputSidebarContainer } from "components";
import DropZone from "components/DropZone/Dropzone";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import { TAMANIOS_REPRESENTION_IMPRESA_LIST } from "utils";

function NuevaCompania({ showLoading, hideLoading, isActive }) {
  const [compania, setCompania] = useState({
    Color1: "#f16101",
    Color2: "#FFFFFF",
    Color3: "#FFFFFF",
  });

  const { configuration } = useApp();
  const { usuario } = useAuthentication();
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();

  const AMBIENTE_LIST = [
    { value: "T", label: "Prueba" },
    { value: "C", label: "Certificación" },
    { value: "S", label: "Simulación" },
  ];

  useEffect(() => {
    showLoading();
    hideLoading();
    return () => {
      hideLoading();
    };
  }, []);

  const grabar = () => {
    showLoading();
    CompaniaManager.actualizar(compania)
      .then(() => {
        Message({ message: `Actualizado Exitosamente` });
        cargarCompania();
      })
      .catch((e) => handleException(e))
      .finally(() => hideLoading());
  };

  const cargarCompania = () => {
    CompaniaManager.buscarPorId(usuario.CompaniaId)
      .then((data) => {
        data.Color1 = "#" + data.Color1;
        data.Color2 = "#" + data.Color2;
        data.Color3 = "#" + data.Color3;

        setCompania(data);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onFileChange = (propFile, propName) => {
    setCompania({ ...compania, [propName]: propFile });
  };

  const setCheckedProp = (e) => {
    const { name, checked } = e.target;
    setCompania({
      ...compania,
      [name]: checked,
    });
  };

  return (
    <Form
      action=""
      className="form-horizontal"
      id="TypeValidation"
      method=""
      autoComplete="off"
    >
      <Card className="p-0 m-0">
        <EditHeader
          titulo="Nueva Compañía de Prueba"
          grabar={grabar}
          showLoading={showLoading}
          hideLoading={hideLoading}
          isInSimpleBar={true}
        />
        <EditContainer>
          <EditSection title="General">
            <SectionRow>
              <InputContainer label="Nombre">
                <Form.Control
                  name="Nombre"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.Nombre}
                />
              </InputContainer>
              <InputContainer label="Razón Social">
                <Form.Control
                  name="RazonSocial"
                  type="text"
                  placeholder="Razón Social"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.RazonSocial}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Codigo">
                <Form.Control
                  name="Codigo"
                  type="text"
                  value={compania.Codigo}
                  readOnly
                />
              </InputContainer>
              <InputContainer label="RNC">
                <Form.Control
                  name="RNC"
                  type="number"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.RNC}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Correo Electrónico">
                <Form.Control
                  name="Email"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.Email}
                />
              </InputContainer>
              <InputContainer label="Dirección">
                <Form.Control
                  name="DireccionLinea1"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.DireccionLinea1}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Télefono">
                <Form.Control
                  name="TelefonoPrincipal"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.TelefonoPrincipal}
                />
              </InputContainer>
            </SectionRow>

            <SectionRow colSpan={2}>
              <InputSidebarContainer label="Logo">
                <DropZone
                  label="Dé click o arrastre Imagenes para continuar"
                  updateFilesCb={(f) => onFileChange(f, "Logo")}
                  multiple={false}
                  type="image"
                />
              </InputSidebarContainer>
            </SectionRow>
            <SectionRow triple={true}>
              <InputContainer label="Color Primario">
                <Form.Control
                  name="Color1"
                  type="color"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.Color1}
                />
              </InputContainer>
              <InputContainer
                label="Color Secundario"
                labelClassName="pl-2 d-flex"
              >
                <Form.Control
                  name="Color2"
                  type="color"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.Color2}
                />
              </InputContainer>
              <InputContainer label="Color Logo">
                <Form.Control
                  name="Color3"
                  type="color"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.Color3}
                />
              </InputContainer>
            </SectionRow>
          </EditSection>
        </EditContainer>
      </Card>
    </Form>
  );
}

export default NuevaCompania;
