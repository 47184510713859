import React from "react";

function Porcentaje({ amount }) {
  const POSITIVE_COLOR = "#009400";
  const NEUTRAL_COLOR = "gray";
  const NEGATIVE_COLOR = "red";

  return (
    <div className="float-left ">
      <h5
        style={{
          color:
            amount == 0
              ? NEUTRAL_COLOR
              : amount < 0
              ? NEGATIVE_COLOR
              : POSITIVE_COLOR,
          fontSize: "9px",
          width: "100%",
          margin: "2%",
        }}
      >
        <i
          className={`fa-sharp fa-solid ${
            amount == 0 ? "" : amount < 0 ? `fa-caret-down` : "fa-caret-up"
          }`}
        ></i>{" "}
        {amount}%
      </h5>
    </div>
  );
}

export default Porcentaje;
