import { CRM_API, RequestHelper } from "utils";

const RUTA = "graficos";
export const GraficosService = (() => {
  const obtenerDatosGraficoComprobantesVenta = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/comprobantesVenta`, params);
    });
  };

  const obtenerDatosGraficoTransacciones = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/graficoTransacciones`, params);
    });
  };

  const obtenerDatosGraficoComprobantesCompras = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/comprobantesCompras`, params);
    });
  };
  const obtenerDatosTablaComprobantesCompras = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/tablaComprobantesCompras`,
        params
      );
    });
  };

  const obtenerDatosTablaComprobantesVentas = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/tablaComprobantesVentas`,
        params
      );
    });
  };

  const obtenerDatosTablaTop = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/tablaTop`, params);
    });
  };

  const obtenerDatosTablaBottom = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/tablaTop`, {
        ...params,
        esBottom: true,
      });
    });
  };

  const obtenerDatosFiltros = (args) => {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/filtros`, {
        ...params,
        esBottom: true,
      });
    });
  };

  return {
    obtenerDatosGraficoComprobantesVenta,
    obtenerDatosGraficoComprobantesCompras,
    obtenerDatosGraficoTransacciones,
    obtenerDatosTablaComprobantesCompras,
    obtenerDatosTablaComprobantesVentas,
    obtenerDatosTablaTop,
    obtenerDatosTablaBottom,
    obtenerDatosFiltros,
  };
})();
