import React from "react";
import * as BS from "react-bootstrap";
import Select from "react-select";
import uuid from "react-uuid";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Row, Col } from "react-bootstrap";
import { useTheme } from "hooks";
import { GridViewProvider } from "providers";
import { useGridView, useGridViewRow } from "hooks";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import "./GridView.styles.scss";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import IconButton from "components/IconButton/IconButton";
import ToolTip from "components/ToolTip/ToolTip";

const defaultWidth = "auto";
const defaultMinWidth = "90px";
const defaultMaxWidth = "400px";
const defaultVerticalAlign = "baseline";
const dollarUSLocale = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const BodyRow = ({ empty }) => {
  const { state: gridState } = useGridView();
  const { state: rowState, dispatch: rowDispatch } = useGridViewRow();

  const getData = (data, number) => {
    if (number) {
      return dollarUSLocale.format(data);
    } else {
      return data;
    }
  };

  const stickyField = {
    position: "sticky",
    backgroundColor: "white",
    left: "0px",
    width: "90px",
    maxWidth: "90px",
    minWidth: "90px",
  };
  if (empty) {
    return (
      <tr
        style={{
          borderRight: "none",
          borderLeft: "none",
        }}
      >
        <td
          colSpan={gridState.columns.length}
          className="text-center"
          style={{ display: "table-cell" }}
        >
          <strong>
            <span className="text-muted">No Hay Registros.</span>
          </strong>
        </td>
      </tr>
    );
  }

  return (
    <tr style={{ borderRight: "none", borderLeft: "none" }}>
      {gridState.columns.map((c, i) =>
        c.props.bodyRenderer && c.props.renderText ? (
          <td
            key={c.props.key || c.props.dataField || i.toString()}
            style={{
              width: c.props.width ? c.props.width : defaultWidth,
              maxWidth: c.props.width ? c.props.width : defaultMaxWidth,
              minWidth: c.props.width ? c.props.width : defaultMinWidth,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span>
              <div
                className="row align-content-center"
                style={{ marginLeft: "1%" }}
              >
                {c.props.bodyRenderer(rowState.row, rowDispatch)}
                {c.props.dataField
                  ? getData(rowState.row[c.props.dataField], c.props.number)
                  : ""}
              </div>
            </span>
          </td>
        ) : c.props.bodyRenderer ? (
          <td
            key={c.props.key || c.props.dataField || i.toString()}
            className="pl-0"
            style={
              c.props.isSticky
                ? stickyField
                : {
                    width: c.props.width ? c.props.width : defaultWidth,
                    maxWidth: c.props.width ? c.props.width : defaultMaxWidth,
                    minWidth: c.props.width ? c.props.width : defaultMinWidth,

                    textAlign:
                      c.props.textAlign ??
                      (getData(rowState.row[c.props.dataField], c.props.number)
                        ? c.props.date
                          ? "right"
                          : ""
                        : "center"),
                  }
            }
          >
            {c.props.bodyRenderer(rowState.row, rowDispatch)}
          </td>
        ) : (
          <td
            key={c.props.dataField}
            style={{
              width: c.props.width ? c.props.width : defaultWidth,
              maxWidth: c.props.width ? c.props.width : defaultMaxWidth,
              minWidth: c.props.width ? c.props.width : defaultMinWidth,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              border: gridState.borderless
                ? "1px, 0px solid #dee2e6 "
                : "1px solid lightgray",
              textAlign:
                c.props.textAlign ??
                (getData(rowState.row[c.props.dataField], c.props.number)
                  ? c.props.date
                    ? "right"
                    : ""
                  : "center"),
            }}
          >
            {c.props.extraComponent ? (
              <Row>
                <Col
                  className="d-flex justify-content-end "
                  style={{ padding: "0px", marginLeft: "6px" }}
                >
                  <span
                    style={{
                      wordBreak: "break-all",
                      float: `${c.props.number ? "right" : ""}`,
                    }}
                  >
                    {c.props.dataField
                      ? getData(rowState.row[c.props.dataField], c.props.number)
                      : ""}
                  </span>
                </Col>
                <Col
                  md={5}
                  className="d-flex justify-content-end"
                  style={{ padding: "0px", paddingRight: "15px" }}
                >
                  {c.props.extraComponent &&
                    c.props.extraComponent({
                      amount: rowState.row[c.props.extraComponentdataField],
                    })}
                </Col>
              </Row>
            ) : (
              <span
                style={{
                  wordBreak: "break-all",
                  float: `${c.props.number ? "right" : ""}`,
                }}
              >
                {c.props.dataField
                  ? getData(rowState.row[c.props.dataField], c.props.number)
                  : ""}

                {c.props.extraComponent &&
                  c.props.extraComponent({
                    amount: rowState.row[c.props.extraComponentdataField],
                  })}
              </span>
            )}
          </td>
        )
      )}
    </tr>
  );
};

const BodyRowSkeleton = () => {
  const { theme } = useTheme();
  const { state: gridState } = useGridView();
  return (
    <tr>
      {gridState.columns.map((c, i) => {
        return (
          <td
            key={`skeleton-row-${i}`}
            style={{
              width: c.props.width
                ? c.props.width
                : i == 0
                ? "90px"
                : defaultWidth,
              maxWidth: c.props.width
                ? c.props.width
                : i == 0
                ? "90px"
                : defaultMaxWidth,
              minWidth: c.props.width
                ? c.props.width
                : i == 0
                ? "90px"
                : defaultMinWidth,
            }}
          >
            <Skeleton baseColor={theme.skeletonColor} style={{ zIndex: "0" }} />
          </td>
        );
      })}
    </tr>
  );
};

const TableHeader = () => {
  const { state } = useGridView();
  const sticky = {
    padding: "0px 0px 0px 0px",
    verticalAlign: "top",
    width: "90px",
    minWidth: "90px",
    maxWidth: "90px",
    position: "sticky",
    left: "0px",
    backgroundColor: "white",
  };

  function onInputChange(e) {
    const { name, value } = e.target;
    state.setWhere({
      ...state.where,
      [name]: value,
    });
    state.setPagination({
      ...state.pagination,
      IsLoading: state.allowPaging,
      IndiceDePagina: 0,
    });
  }

  function onHeaderLabelClick(orderBy) {
    state.setWhere({
      ...state.where,
    });
    state.setPagination({
      ...state.pagination,
      IsLoading: state.allowPaging,
      IndiceDePagina: 0,
      EsOrdernable: true,
      OrderBy: orderBy,
      EsAscendente: !state.pagination.EsAscendente,
    });
  }

  function onResetFilters() {
    state.setWhere({ isDone: true });
    state.setPagination({
      ...state.pagination,
      IsLoading: state.allowPaging,
      IndiceDePagina: 0,
    });
    if (state.onReset) {
      state.onReset();
    }
  }

  function onReload() {
    state.setWhere({ ...state.where });
    state.setPagination({
      ...state.pagination,
      IsLoading: state.allowPaging,
      CantidadPorPagina: 15,
      isReload: true,
    });
  }

  return (
    <thead
      style={{
        position: "sticky",
        top: "0px",
        backgroundColor: "white",
        zIndex: 1,
        overflowX: "auto",
        borderRight: "none",
        borderLeft: "none",
      }}
    >
      <tr>
        {state.columns.map((h, i) => {
          return h.props.dataField ? (
            <th
              key={h.props.key || h.props.dataField || i.toString()}
              style={{
                width: h.props.width ? h.props.width : defaultWidth,
                maxWidth: h.props.width ? h.props.width : defaultMaxWidth,
                minWidth: h.props.width ? h.props.width : defaultMinWidth,
                verticalAlign: h.props.verticalAlign
                  ? h.props.verticalAlign
                  : defaultVerticalAlign,
              }}
            >
              <h1
                className={
                  h.props.number
                    ? "grid-header-label text-right"
                    : h.props.date
                    ? "grid-header-label text-center"
                    : "grid-header-label"
                }
                style={{
                  cursor: h.props.sortExpression ? "pointer" : "default",
                }}
                onClick={(e) => {
                  if (h.props.sortExpression) {
                    onHeaderLabelClick(h.props.sortExpression);
                  }
                }}
              >
                {h.props.headerText}
                {h.props.sortExpression && <i className="fa-solid fa-sort"></i>}
              </h1>

              <>
                {!state.hideFilters ? (
                  h.props.filterRenderer ? (
                    h.props.filterRenderer(onInputChange)
                  ) : (
                    <BS.Form.Control
                      className={`grid-header-input ${
                        !h.props.hideFilter ? "" : "invisible"
                      }`}
                      type="text"
                      placeholder={
                        h.props.placeholder ? h.props.placeholder : "Buscar..."
                      }
                      disabled={h.props.isDisabled}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      autoComplete="false"
                      name={h.props.searchField ?? h.props.dataField}
                      value={
                        state.where[h.props.searchField ?? h.props.dataField] ||
                        ""
                      }
                    />
                  )
                ) : (
                  <div></div>
                )}
              </>
            </th>
          ) : (
            <th
              key={h.props.key || h.props.dataField || i.toString()}
              className={`${h.props.isSticky ? "p-0" : ""}`}
              style={
                h.props.isSticky
                  ? {
                      ...sticky,
                      width: h.props.width ? h.props.width : sticky.width,
                      maxWidth: h.props.width ? h.props.width : sticky.maxWidth,
                      minWidth: h.props.width ? h.props.width : sticky.minWidth,
                    }
                  : {
                      width: h.props.width ? h.props.width : defaultWidth,
                      maxWidth: h.props.width ? h.props.width : defaultMaxWidth,
                      minWidth: h.props.width ? h.props.width : defaultMinWidth,
                      verticalAlign: h.props.verticalAlign
                        ? h.props.verticalAlign
                        : defaultVerticalAlign,
                    }
              }
            >
              {h.props.headerText ? (
                !h.props.isSticky ? (
                  <h1 className="grid-header-label float-center">
                    {h.props.headerText}
                  </h1>
                ) : (
                  <h1 className="grid-header-label-actions">
                    {h.props.headerText}
                  </h1>
                )
              ) : h.props.forceRender ? (
                h.props.filterRenderer(onInputChange)
              ) : state.hideFilters ? (
                <> </>
              ) : (
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "flex-end",
                    paddingTop: "40%",
                    paddingRight: "6%",
                    alignContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <IconButton onClick={onReload}>
                    <ToolTip message="Recargar">
                      <div>
                        <SvgComponent
                          iconName="reload"
                          height="25px"
                          width="25px"
                          fill="black"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                  <IconButton onClick={onResetFilters}>
                    <ToolTip message="Limpiar Filtros">
                      <div>
                        <SvgComponent
                          iconName="clean"
                          height="25px"
                          width="25px"
                          fill="black"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
const TableFooter = () => {
  const { state: gridState } = useGridView();

  return gridState.showFooter ? (
    <tfoot
      style={{
        position: "sticky",
        top: "0px",
        backgroundColor: "white",
        zIndex: "1",
        overflowX: "hidden",
        borderRight: "none",
        borderLeft: "none",
      }}
    >
      <tr>
        {gridState.columns.map((h, i) => {
          let total = 0.0;
          let totalComparar = 0.0;
          return (
            <td
              key={"footer" + uuid() || i.toString()}
              className="pl-0"
              style={{
                border: gridState.borderless ? "none" : "1px solid lightgray",
                width: h.props.width ? h.props.width : defaultWidth,
                maxWidth: h.props.width ? h.props.width : defaultMaxWidth,
                minWidth: h.props.width ? h.props.width : defaultMinWidth,
                verticalAlign: h.props.verticalAlign
                  ? h.props.verticalAlign
                  : defaultVerticalAlign,
              }}
            >
              {h.props.sumarizar &&
                gridState.dataSource.length > 0 &&
                gridState.dataSource.map((column) => {
                  total += column[h.props.dataField];
                  totalComparar += column[`${h.props.dataField}Comparar`];
                })}

              {h.props.footerRenderer ? (
                h.props.footerRenderer({
                  totalColumnaGrid: total,
                  totalCompararColumnaGrid: totalComparar,
                })
              ) : h.props.sumarizar && gridState.dataSource.length > 0 ? (
                <span
                  style={{
                    wordBreak: "break-all",
                    float: `right`,
                    fontWeight: "bold",
                  }}
                >
                  {total}
                </span>
              ) : null}
            </td>
          );
        })}
      </tr>
    </tfoot>
  ) : null;
};

const TableBody = () => {
  const { state } = useGridView();
  return (
    <tbody>
      {state.dataSource.length > 0 && !state.pagination?.IsLoading ? (
        state.dataSource.map((v) => {
          return (
            <GridViewProvider.Row
              key={v[state.primaryKey] ? v[state.primaryKey] : v.IdSec}
              row={v}
            >
              <BodyRow />
            </GridViewProvider.Row>
          );
        })
      ) : (state.allowPaging && state.pagination?.IsLoading === undefined) ||
        state.pagination?.IsLoading ? (
        [...Array(state.pagination?.CantidadPorPagina ?? 0)?.keys()]?.map(
          (k, i) => <BodyRowSkeleton key={`$skeleton-${k}-${i}`} />
        )
      ) : (
        <>
          <GridViewProvider.Row key={uuid()} row={{}}>
            <BodyRow empty={true} />
          </GridViewProvider.Row>
        </>
      )}
    </tbody>
  );
};

const GridTable = () => {
  const { state } = useGridView();
  return (
    <div
      className="panel-body"
      style={{
        overflowX: "auto",
        height: state.height ? state.height : "78vh",
        border: "1px solid #e3e3e3",
      }}
    >
      <table className="table-hover table gridView">
        {<TableHeader />}
        {<TableBody />}
        {<TableFooter></TableFooter>}
      </table>
    </div>
  );
};

const GridPagination = () => {
  const { state, dispatch } = useGridView();

  function onInputChange(e) {
    state.setPagination({
      ...state.pagination,
      CantidadPorPagina: e.value,
      IndiceDePagina: 0,
      IsLoading: state.allowPaging,
    });

    state.setWhere({
      ...state.where,
      CantidadPorPagina: e.value,
    });
  }
  const opcionesPaginacion = [
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
  ];

  return (
    <>
      {state.allowPaging ? (
        <nav
          aria-label="Page navigation example"
          style={{ marginTop: "10px", maxHeight: "38px" }}
        >
          <ul className="pagination justify-content-center">
            <li>
              <div style={{ minWidth: "160px" }}>
                <Select
                  className="react-select-pagination primary"
                  classNamePrefix="react-select-pagination"
                  options={opcionesPaginacion}
                  name="CantidadPorPagina"
                  menuPlacement="auto"
                  styles={{
                    zIndex: 2,
                  }}
                  value={opcionesPaginacion.filter(
                    (option) =>
                      option.value === state.pagination?.CantidadPorPagina
                  )}
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                ></Select>
              </div>
            </li>
            <li
              className={` page-item ${
                state.currentPage == 1 ? "disabled" : ""
              }`}
            >
              <a
                className="page-link gridView-pagination-link"
                GridFooter
                href="#"
                onClick={() => {
                  state.setPagination({
                    ...state.pagination,
                    IsLoading: state.allowPaging,
                  });
                  dispatch({ type: "previous" });
                }}
                aria-label="Previous"
              >
                <span aria-hidden="true">
                  <ChevronLeft size={25} /> Anterior
                </span>

                <span className="sr-only">Previous</span>
              </a>
            </li>
            <li className="page-item active">
              <a
                className="page-link gridView-pagination"
                href="#"
                style={{ zIndex: 0 }}
              >
                {state.currentPage ? state.currentPage : 1} /
                {state.lastPageNumber ? state.lastPageNumber : 1}
              </a>
            </li>
            <li
              className={`page-item ${
                state.currentPage == state.lastPageNumber ? "disabled" : ""
              }`}
            >
              <a
                className="page-link gridView-pagination-link"
                href="#"
                onClick={() => {
                  state.setPagination({
                    ...state.pagination,
                    IsLoading: state.allowPaging,
                  });
                  dispatch({ type: "next" });
                }}
                aria-label="Next"
              >
                <span aria-hidden="true">
                  Siguiente <ChevronRight size={25} />
                </span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

export const GridView = (props) => {
  return (
    <GridViewProvider.Main columns={props.children} {...props}>
      <GridTable />
      <GridPagination />
    </GridViewProvider.Main>
  );
};

export const CardWrapped = (props) => {
  return (
    <BS.Row>
      <BS.Col md="12">
        <BS.Card className="strpied-tabled-with-hover">
          <BS.Card.Header></BS.Card.Header>
          <BS.Card.Body className="table-responsive p-0">
            <GridView {...props} />
          </BS.Card.Body>
        </BS.Card>
      </BS.Col>
    </BS.Row>
  );
};

GridView.CardWrapped = CardWrapped;
GridView.Column = () => <></>;
