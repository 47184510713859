import {
  HEADER_WHERE_INDICE_PAGINA,
  HEADER_WHERE_CANTIDAD_PAGINA,
  HEADER_WHERE_RECORDS_TOTAL,
} from "../utils/constants";

export default function usePaginationValuesWhere() {
  const setPaginationValuesWhere = (headers, where, setWhere) => {
    setWhere({
      ...where,
      IndiceDePagina: parseInt(headers[HEADER_WHERE_INDICE_PAGINA]),
      CantidadPorPagina: parseInt(headers[HEADER_WHERE_CANTIDAD_PAGINA]),
      PaginasTotal: Math.ceil(
        parseInt(headers[HEADER_WHERE_RECORDS_TOTAL]) /
          parseInt(headers[HEADER_WHERE_CANTIDAD_PAGINA])
      ),
      IsLoading: false,
    });
  };

  return {
    setPaginationValuesWhere,
  };
}
