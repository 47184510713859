import { useEffect, useState } from "react";

import { GridView } from "components";
import useMessage from "hooks/useMessage";
import useExceptionHandler from "hooks/useExceptionHandler";
import { DEFAULT_PAGINATION_VALUES, StringHelper } from "utils";
import { DirectorioServiciosManager as manager } from "managers";
import ListHeader from "components/PantallaList/Header/ListHeader";

export default function DirectorioServiciosList({ hideLoading }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);

  const { cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  function buscar() {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
    };
    manager
      .listado(whereWithPaginationValues)
      .then((res) => {
        for (let directorio of res.Data) {
          directorio["Rnc"] = StringHelper.formatRNC(directorio["Rnc"]);
        }
        setList(res.Data);
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());
  }

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  return (
    <>
      <ListHeader titulo="Directorio de Servicios" />
      <GridView
        pagination={pagination}
        setPagination={setPagination}
        allowPaging={pagination.EsPaginable}
        hideFilters={true}
        pageSize={pagination.CantidadPorPagina}
        pageIndex={pagination.IndiceDePagina}
        lastPageNumber={pagination.PaginasTotal}
        dataSource={list}
        onPageIndexChanging={onPageIndexChanging}
        where={where}
        setWhere={setWhere}
        height="78vh"
      >
        <GridView.Column
          headerText="RNC"
          dataField="Rnc"
          sortExpression="Rnc"
        />
        <GridView.Column
          headerText="Nombre"
          dataField="Nombre"
          sortExpression="Nombre"
        />
        <GridView.Column
          headerText="Url Recepción"
          dataField="UrlRecepcion"
          sortExpression="UrlRecepcion"
        />
        <GridView.Column
          headerText="Url Aceptación"
          dataField="UrlAceptacion"
          sortExpression="UrlAceptacion"
        />
        <GridView.Column
          headerText="Url Opcional"
          dataField="UrlOpcional"
          sortExpression="UrlOpcional"
        />
      </GridView>
    </>
  );
}
