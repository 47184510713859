import { ArchivosService } from "services/ECF/archivos.service";
import { ResponseExceptionHandlerHelper } from "utils";

export const ArchivosManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listado = (where) => {
    return ArchivosService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const exportar = (where) => {
    return ArchivosService.exportar(where).then((res) => {
      return res;
    });
  };

  const cargarArchivo = (data) => {
    return ArchivosService.cargarArchivo({
      ...data,
      Files: data.File,
    }).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const descargarArchivo = (IdSec) => {
    return ArchivosService.descargarArchivo(IdSec).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.blob;
    });
  };

  const descargarPlantilla = (tipoPlantilla) => {
    return ArchivosService.descargarPlantilla(tipoPlantilla).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.blob;
    });
  };

  return {
    listado,
    exportar,
    cargarArchivo,
    descargarArchivo,
    descargarPlantilla,
  };
})();
