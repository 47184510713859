export const StringHelper = (() => {
  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
    else return null;
  }

  function formatRNC(rnc) {
    var cleaned = ("" + rnc).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{5})(\d{1})$/);

    if (match) return match[1] + "-" + match[2] + "-" + match[3];
    else return cleaned;
  }

  function formatCurrency(amount = 0) {
    var formatter = new Intl.NumberFormat("DOP", {
      style: "currency",
      currency: "DOP",
    });

    return formatter.format(amount);
  }

  function formatNumber(n = 0) {
    return (Math.round(n * 100) / 100).toFixed(2);
  }

  return {
    formatPhoneNumber,
    formatRNC,
    formatCurrency,
    formatNumber,
  };
})();
