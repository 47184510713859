import React, { useState, useEffect } from "react";
import { Button, Card, Form, Container, Col } from "react-bootstrap";
import { FormHelper } from "utils";
import { useAuthentication } from "hooks";
import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";

export function LoadingPage({ showLoading, hideLoading }) {
  const { cerrarAlertas } = useMessage();

  useEffect(() => {
    showLoading();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  });
  return (
    <>
      
    </>
  );
}
