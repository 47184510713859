import React, { useState, useEffect, useRef } from "react";
import { Form, Card } from "react-bootstrap";
import useMessage from "hooks/useMessage";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import { Simplebar, GridView } from "components";
import { DateTimePicker, UsuarioLookup } from "components";
import { DateHelper, DEFAULT_PAGINATION_VALUES } from "utils";
import { TokenManager } from "managers";
import { useAuthentication } from "hooks";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import Select from "react-select";
import ToolTip from "components/ToolTip/ToolTip";
import IconButton from "components/IconButton/IconButton";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import uuid from "react-uuid";

import { FormHelper } from "utils";

function AuthorizationToken({ showLoading, hideLoading, isActive }) {
  const [showCanvas, setShowCanvas] = useState(false);
  const { usuario } = useAuthentication();
  const { handleException } = useExceptionHandler();
  const { Message, cerrarAlertas } = useMessage();

  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [tokens, setTokens] = useState([]);
  const [fechaExpedicionKey, setFechaExpedicionKey] = useState(uuid());
  const [fechaExpiracionKey, setFechaExpiracionKey] = useState(uuid());

  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    { value: "A", label: "Activo" },
    { value: "I", label: "Inactivo" },
  ];

  const ref = useRef(null);

  useEffect(() => {
    buscar();
  }, [where]);

  const copiarToken = (IdSec) => {
    showLoading();
    TokenManager.buscarToken(IdSec)
      .then((data) => {
        navigator.clipboard.writeText(data.Token);
        Message({
          message: `Token con Id ${IdSec} Copiado al Portapapeles`,
          autoDismiss: 1.5,
        });
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());
  };

  const inactivarToken = (IdSec) => {
    showLoading();
    TokenManager.inactivarToken(IdSec)
      .then((data) => {
        navigator.clipboard.writeText(data.Token);
        setWhere({ ...where });
        Message({
          message: `Token con Id ${IdSec} Inactivado correctamente.`,
          autoDismiss: 1.5,
        });
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());
  };

  function buscar() {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
      FechaExpiracionInicio: where.RangoFechas?.fechaInicio,
      FechaExpiracionFin: where.RangoFechas?.fechaFin,

      FechaExpedicionInicio: where.RangoFechasExpedicion?.fechaInicio,
      FechaExpedicionFin: where.RangoFechasExpedicion?.fechaFin,
    };

    TokenManager.listarToken(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        try {
          for (let archivo of res.Data) {
            archivo["FechaExpedicion"] = DateHelper.formatStringDate(
              archivo["FechaExpedicion"],
              true
            );
            archivo["FechaExpiracion"] = DateHelper.formatStringDate(
              archivo["FechaExpiracion"],
              true
            );
          }
        } catch (err) {}
        setTokens(res.Data);
      })
      .catch((err) => {
        handleException(err);
      })
      .finally(() => hideLoading());
  }

  const abrirSimpleBarToken = () => {
    setShowCanvas(true);
  };

  const onReset = () => {
    setFechaExpedicionKey(uuid());
    setFechaExpiracionKey(uuid());
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const SimplebarToken = () => {
    const [tokenOptions, setTokenOptions] = useState({
      expira: false,
      companiaId: usuario.CompaniaId,
    });

    const setCheckedProp = (e) => {
      const { name, checked } = e.target;
      setTokenOptions({
        ...tokenOptions,
        [name]: checked,
      });
    };

    const generarToken = () => {
      showLoading();
      TokenManager.generarToken(tokenOptions)
        .then((res) => {
          Message({ message: `Token Generado Exitosamente` });
          setTokenOptions(res);
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => hideLoading());
    };

    return (
      <Simplebar
        size="md"
        ref={ref}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvas}
        setShow={(e) => {
          setShowCanvas(e);
        }}
        useLoading={true}
        title="Generar Token de Autorización"
        btnLeft={{
          show: false,
        }}
        btnRight={{
          show: true,
          message: "Generar Token   ",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "check",
          func: () => {
            generarToken();
            setShowCanvas(false);
          },
        }}
      >
        <InputContainer label="Nombre">
          <Form.Control
            name="Nombre"
            type="text"
            placeholder="Nombre"
            onChange={(e) => FormHelper.handleChange(e, setTokenOptions)}
            value={tokenOptions.Nombre}
          />
        </InputContainer>

        <InputContainer label="Usuario">
          <Form.Control
            name="UsuarioAsociado"
            type="text"
            placeholder="Usuario"
            onChange={(e) => FormHelper.handleChange(e, setTokenOptions)}
            value={tokenOptions.UsuarioAsociado}
          />
        </InputContainer>
        <InputContainer label="Expira">
          <Form.Check
            className="divContainer"
            type="checkbox"
            aria-label="expira"
            id="chkExpira"
            name="expira"
            checked={tokenOptions.expira}
            onChange={setCheckedProp}
          />
        </InputContainer>

        {tokenOptions.expira && (
          <InputContainer label="Fecha de Expiración">
            <DateTimePicker
              soloFecha={true}
              usarLabel={false}
              fechaInicioOriginal={tokenOptions.fechaExpiracion}
              onChange={(e) => {
                if (e.fechaInicio != null)
                  setTokenOptions({
                    ...tokenOptions,
                    fechaExpiracion: DateHelper.formatDate(e.fechaInicio),
                  });
              }}
            />
          </InputContainer>
        )}
      </Simplebar>
    );
  };

  return isActive ? (
    <>
      {showCanvas && <SimplebarToken></SimplebarToken>}
      <Form
        action=""
        className="form-horizontal p-3"
        id="TypeValidation"
        method=""
        autoComplete="off"
      >
        <Card className="p-0 m-0">
          <EditHeader
            titulo="Token de Autorización API"
            generarToken={abrirSimpleBarToken}
            showLoading={showLoading}
            hideLoading={hideLoading}
            isInSimpleBar={true}
          />
          <GridView
            pagination={pagination}
            setPagination={setPagination}
            allowPaging={pagination.EsPaginable}
            pageSize={pagination.CantidadPorPagina}
            pageIndex={pagination.IndiceDePagina}
            lastPageNumber={pagination.PaginasTotal}
            dataSource={tokens}
            onPageIndexChanging={onPageIndexChanging}
            where={where}
            setWhere={setWhere}
            height="78vh"
            onReset={onReset}
          >
            <GridView.Column
              width="90px"
              showClearFilter={true}
              bodyRenderer={(row) => {
                return (
                  <>
                    <div className="float-right">
                      <IconButton onClick={() => inactivarToken(row.IdSec)}>
                        <ToolTip message="Inactivar Token">
                          <div>
                            <SvgComponent
                              iconName="cancel"
                              height="25px"
                              width="25px"
                            />
                          </div>
                        </ToolTip>
                      </IconButton>
                    </div>
                    <div className="float-right">
                      <IconButton onClick={() => copiarToken(row.IdSec)}>
                        <ToolTip message="Copiar Token">
                          <div>
                            <SvgComponent
                              iconName="copy"
                              height="25px"
                              width="25px"
                            />
                          </div>
                        </ToolTip>
                      </IconButton>
                    </div>
                  </>
                );
              }}
            />

            <GridView.Column
              headerText="Id"
              dataField="IdSec"
              sortExpression="IdSec"
              width="90px"
            />
            <GridView.Column
              headerText="Nombre"
              dataField="Nombre"
              sortExpression="Nombre"
              width="210px"
            />

            <GridView.Column
              date
              hideFilter
              headerText="Fecha Expedición"
              dataField="FechaExpedicion"
              sortExpression="FechaExpedicion"
              width="250px"
              filterRenderer={(onInputChange) => {
                return (
                  <DateTimePicker
                    key={fechaExpedicionKey}
                    soloFecha
                    esRangoFechas
                    esGrid
                    usarLabel={false}
                    fechaInicioOriginal={
                      where?.RangoFechasExpedicion?.fechaInicio || ""
                    }
                    fechaFinOriginal={
                      where?.RangoFechasExpedicion?.fechaFin || ""
                    }
                    onChange={(e) =>
                      onInputChange({
                        target: { name: "RangoFechasExpedicion", value: e },
                      })
                    }
                  />
                );
              }}
            />

            <GridView.Column
              headerText="Expira"
              dataField="_ExpiraDesc"
              sortExpression="Expira"
              isDisabled
            />

            <GridView.Column
              date
              hideFilter
              headerText="Fecha Expiración"
              dataField="FechaExpiracion"
              sortExpression="FechaExpiracion"
              width="250px"
              filterRenderer={(onInputChange) => {
                return (
                  <DateTimePicker
                    key={fechaExpiracionKey}
                    soloFecha
                    esRangoFechas
                    esGrid
                    usarLabel={false}
                    fechaInicioOriginal={where?.RangoFechas?.fechaInicio || ""}
                    fechaFinOriginal={where?.RangoFechas?.fechaFin || ""}
                    onChange={(e) =>
                      onInputChange({
                        target: { name: "RangoFechas", value: e },
                      })
                    }
                  />
                );
              }}
            />

            <GridView.Column
              headerText="Usuario"
              dataField="UsuarioAsociado"
              sortExpression="UsuarioAsociado"
              width="120px"
            />

            <GridView.Column
              headerText="Estatus"
              dataField="Estatus"
              sortExpression="Estatus"
              width="120px"
              filterRenderer={() => {
                return (
                  <Select
                    className="react-select primary"
                    options={ESTATUS_LIST}
                    value={ESTATUS_LIST.filter(function (option) {
                      return (
                        option.value ===
                        (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                      );
                    })}
                    name="Estatus"
                    onChange={(e) =>
                      setWhere({
                        ...where,
                        Estatus: e.value,
                      })
                    }
                  />
                );
              }}
            />
            <GridView.Column
              headerText="Token"
              dataField="Token"
              sortExpression="Token"
              width="400px"
            />
          </GridView>
        </Card>
      </Form>
    </>
  ) : (
    <> </>
  );
}

export default AuthorizationToken;
