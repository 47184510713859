import React, { useState, useEffect } from "react";
import { Button, Card, Form, Container, Col } from "react-bootstrap";
import { FormHelper } from "utils";
import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";
import { useTheme } from "hooks";
import { AuthenticationManager } from "managers";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";

export const ResetPasswoodPage = ({
  showLoading,
  hideLoading,
  setIsResetPassword,
}) => {
  const { theme } = useTheme();
  const [cardClasses, setCardClasses] = useState("card-hidden");
  const [form, setForm] = useState({
    Contrasena: "",
    ConfirmContrasena: "",
  });
  const { handleException } = useExceptionHandler();
  const { Message, Warning, cerrarAlertas } = useMessage();
  const urlParams = new URLSearchParams(window.location.search);
  const em = urlParams.get("em");
  const cc = urlParams.get("cc");

  useEffect(() => {
    if (em && cc) {
      setForm({ ...form, em: em, cc: cc });
    }
    return () => {
      cerrarAlertas();
    };
  }, []);

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setForm);
  };

  const onSubmit = (e) => {
    showLoading();
    FormHelper.handleSubmit(e);
    AuthenticationManager.resetPassword(form)
      .then((res) => {
        Message({ message: res });
        setTimeout(() => {
          setIsResetPassword(false);
        }, 1000);
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        hideLoading();
      });
  };

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 500);
  }, []);

  return (
    <>
      <div className="full-page section-image" data-color="black">
        <div className="content d-flex align-items-center p-0">
          <Container style={{ maxWidth: "65%", minWidth: "65%" }}>
            <Col className="mx-auto" xs="4">
              <Form className="form" onSubmit={onSubmit}>
                <Card
                  className={"card-login " + cardClasses}
                  style={{ minWidth: "300px" }}
                >
                  <Card.Header className="text-center" style={{}}>
                    {theme.logo ? (
                      <img
                        alt="logo"
                        src={theme.logo}
                        height={theme.title == "Citrus CRM" ? "80px" : "72px"}
                        width={theme.title == "Citrus CRM" ? "110px" : "200px"}
                      />
                    ) : (
                      theme.appName
                    )}
                  </Card.Header>
                  <Card.Body
                    className="d-flex"
                    style={{
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Form.Group>
                      <label>Contraseña</label>
                      <Form.Control
                        required
                        name="Contrasena"
                        type="password"
                        value={form.Contrasena}
                        onChange={onInputChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Confirmar Contraseña</label>
                      <Form.Control
                        required
                        name="ConfirmContrasena"
                        type="password"
                        value={form.ConfirmContrasena}
                        onChange={onInputChange}
                      />
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer
                    className="d-flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    <AnimatedIconButton
                      size="60px"
                      height="35px"
                      width="35px"
                      iconName="thumb"
                      message="Confirmar"
                      style={{ padding: "0px" }}
                      onClick={(e) => onSubmit(e)}
                      useVendorTheme={true}
                    />
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
};
