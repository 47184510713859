import { SerieFiscalService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const SerieFiscalManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const buscarPorId = (Id, esLookup) => {
    return SerieFiscalService.buscarPorId(Id, esLookup).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const inactivar = (Id) => {
    return SerieFiscalService.inactivar(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    return SerieFiscalService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const crear = (usuario) => {
    return SerieFiscalService.crear(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (usuario) => {
    return SerieFiscalService.actualizar(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    buscarPorId,
    listado,
    crear,
    actualizar,
    inactivar,
  };
})();
