import { UsuarioService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const UsuarioManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const buscarPorId = (Id, esLookup, esView) => {
    return UsuarioService.buscarPorId(Id, esLookup, esView).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    return UsuarioService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const crear = (usuario) => {
    return UsuarioService.crear(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (usuario) => {
    return UsuarioService.actualizar({
      ...usuario,
      Files: usuario.Files ? usuario.Files : [],
    }).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const cambiarContrasenia = (usuario) => {
    return UsuarioService.cambiarContrasenia(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizarContrasenia = (usuario) => {
    return UsuarioService.actualizarContrasenia(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    buscarPorId,
    listado,
    crear,
    actualizar,
    cambiarContrasenia,
    actualizarContrasenia,
  };
})();
