import React, { useState, useEffect } from "react";
import {} from "managers";
import { GridViewContext, GridViewRowContext } from "contexts";
import useDebounce from "hooks/useDebounce";
import { useLocalStorage } from "hooks";

const GridViewProvider = () => {};

GridViewProvider.Main = (props) => {
  const [innerWhere, setInnerWhere] = useState(props.where);
  const debouncedWhere = useDebounce(innerWhere, 500);
  const [storedWhere, setStoredWhere] = useLocalStorage(props.whereKey, {});

  useEffect(() => {
    if (props.whereKey && storedWhere !== null) {
      setInnerWhere({ ...storedWhere, isDone: true });
    } else {
      setInnerWhere({ ...innerWhere, isDone: true });
    }
  }, []);

  useEffect(() => {
    if (props.whereKey) {
      setStoredWhere(innerWhere);
    }
    if (props.setWhere) {
      props.setWhere(innerWhere);
    }
    if (innerWhere?.CantidadPorPagina) {
      props.setPagination({
        ...props.pagination,
        CantidadPorPagina: innerWhere.CantidadPorPagina ?? 15,
      });
    }
  }, [debouncedWhere]);

  const state = {
    allowPaging: props.allowPaging ?? true,
    allowSorting: props.allowSorting ?? true,
    dataSource: props.dataSource ?? [],
    primaryKey: props.primaryKey ?? "IdSec",
    pageSize: props.pageSize ?? 10,
    columns: (props.columns ?? []).filter((c) => c && c.props),
    currentPage: (props.pageIndex ?? 0) + 1,
    lastPageNumber: props.lastPageNumber ?? 1,
    where: innerWhere,
    setWhere: setInnerWhere,
    pagination: props.pagination,
    setPagination: props.setPagination,
    hideFilters: props.hideFilters,
    height: props.height,
    onReset: props.onReset,
    showFooter: props.showFooter ?? false,
    borderless: props.borderless ?? true,
  };

  const dispatch = (action) => {
    switch (action.type) {
      case "previous":
        if (state.currentPage > 1) {
          props.onPageIndexChanging(state.currentPage - 1);
        }
        break;
      case "next":
        if (state.currentPage < state.lastPageNumber) {
          props.onPageIndexChanging(state.currentPage + 1);
        }

        break;
    }
  };

  const value = {
    state,
    dispatch,
  };

  return (
    <GridViewContext.Provider value={value}>
      {props.children}
    </GridViewContext.Provider>
  );
};

GridViewProvider.Row = (props) => {
  const state = {
    row: props.row,
  };

  const dispatch = (action) => {
    switch (action.type) {
      case "update":
        break;
    }
  };

  return (
    <GridViewRowContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GridViewRowContext.Provider>
  );
};

export { GridViewProvider };
