import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";

import useMessage from "hooks/useMessage";
import { InputSidebarContainer } from "components";
import DropZone from "components/DropZone/Dropzone";
import { FirmarArchivoManager as manager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";

export default function FirmarArchivoXML({ showLoading, hideLoading }) {
  const [archivo, setArchivo] = useState({});
  const [firmado, setFirmado] = useState(false);

  const { Message, cerrarAlertas, Types } = useMessage();
  const { handleException } = useExceptionHandler();

  useEffect(() => {
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, []);

  function grabar() {
    if (
      archivo.File === null ||
      archivo.File === undefined ||
      archivo.File.length === 0
    ) {
      Message({
        message: `Debe subir un archivo .xml.`,
        type: Types.TYPE_WARNING,
      });
      return;
    }
    manager
      .firmar(archivo)
      .then((res) => {
        if (res.Ok) {
          Message({ message: `Firmado Exitosamente` });
          downloadXMLfromJSON(res);
          setFirmado(true);
        } else {
          Message({
            message: `El archivo no pudo ser firmado correctamente, intente de nuevo.`,
            type: Types.TYPE_WARNING,
          });
        }
      })
      .catch((e) => handleException(e))
      .finally(() => hideLoading());
  }

  const onFileChange = (propFile, propName) => {
    setArchivo({ ...archivo, [propName]: propFile });
  };

  const downloadXMLfromJSON = (str) => {
    try {
      let fileName = str.FileName;
      var xmlBlob = new Blob([str.Xml], { type: "application/xml" });

      let a = document.createElement("a");
      a.download = fileName;
      a.target = "blank";
      a.href = URL.createObjectURL(xmlBlob);
      a.click();
    } catch (err) {
      console.error(err);
    }
  };

  const setCheckedProp = (e) => {
    const { name, checked } = e.target;
    setArchivo({ ...archivo, [name]: checked });
  };

  return (
    <Form action="" className="form-horizontal" method="">
      <Card>
        <EditHeader titulo="Firmar Archivo" volver={true} grabar={grabar} />
        <EditContainer>
          <SectionRow colSpan={2}>
            <InputSidebarContainer label="Archivo">
              <DropZone
                label="Dé click o arrastre un archivo .xml para continuar"
                updateFilesCb={(f) => onFileChange(f, "File")}
                multiple={false}
                type="xml"
              />
            </InputSidebarContainer>
            <div className="d-flex">
              <Form.Check
                className="divContainer ml-2 mt-2"
                type="checkbox"
                aria-label="Incluir Fecha y Hora Firma"
                onChange={setCheckedProp}
                id="chkIncluirFechaHoraFirma"
                name="IncluirFechaHora"
                checked={archivo.IncluirFechaHora}
              />
              <label
                htmlFor="chkIncluirFechaHoraFirma"
                style={{ width: "450px" }}
                className="mt-2"
              >
                Incluir Fecha y Hora Firma
              </label>
            </div>
          </SectionRow>
        </EditContainer>
      </Card>
    </Form>
  );
}
