import { ConfiguracionCorreoElectronicoService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const ConfiguracionCorreoElectronicoManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const buscarMailConfig = (data) => {
    return ConfiguracionCorreoElectronicoService.buscarMailConfig(data).then(
      (res) => {
        if (!res.Success) {
          handleException(res);
        }
        return res.Data;
      }
    );
  };

  const actualizarMailConfig = (mailConfig) => {
    return ConfiguracionCorreoElectronicoService.actualizarMailConfig(
      mailConfig
    ).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const obtenerUrlOutlook = (args) => {
    return ConfiguracionCorreoElectronicoService.obtenerUrlOutlook(args).then(
      (res) => {
        if (!res.Success) {
          handleException(res);
        }
        return res.Data;
      }
    );
  };

  const obtenerUrlOutlookAutomatizacion = (args) => {
    return ConfiguracionCorreoElectronicoService.obtenerUrlOutlookAutomatizacion(
      args
    ).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const vincularCuentaGmail = (mailConfig) => {
    return ConfiguracionCorreoElectronicoService.vincularCuentaGmail(
      mailConfig
    ).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const vincularCuentaOutlook = (mailConfig) => {
    return ConfiguracionCorreoElectronicoService.vincularCuentaOutlook(
      mailConfig
    ).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const desvincularCuentaOutlook = () => {
    return ConfiguracionCorreoElectronicoService.desvincularCuentaOutlook().then(
      (res) => {
        if (!res.Success) {
          handleException(res);
        }
        return res.Data;
      }
    );
  };

  const desvincularCuentaGmail = (data) => {
    return ConfiguracionCorreoElectronicoService.desvincularCuentaGmail(
      data
    ).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    buscarMailConfig,
    actualizarMailConfig,
    vincularCuentaGmail,
    desvincularCuentaGmail,
    obtenerUrlOutlook,
    vincularCuentaOutlook,
    desvincularCuentaOutlook,
    obtenerUrlOutlookAutomatizacion,
  };
})();
