const ESTATUS_ACTIVO = "A";
const ESTATUS_ACTIVO_LABEL = "Activo";
const ESTATUS_INACTIVO = "I";
const ESTATUS_INACTIVO_LABEL = "Inactivo";
const ESTATUS_BLOQUEADO = "B";
const ESTATUS_BLOQUEADO_LABEL = "Bloqueado";

export class Usuario {
  static get ESTATUS_LIST() {
    return [
      {
        value: ESTATUS_ACTIVO,
        label: ESTATUS_ACTIVO_LABEL,
      },
      {
        value: ESTATUS_INACTIVO,
        label: ESTATUS_INACTIVO_LABEL,
      },
      {
        value: ESTATUS_BLOQUEADO,
        label: ESTATUS_BLOQUEADO_LABEL,
      },
    ];
  }

  static get DEFAULT_ESTATUS_VALUE() {
    return ESTATUS_ACTIVO;
  }
}
