import { CRM_API, RequestHelper } from "utils";

const RUTA = "usuario";
export const UsuarioService = (() => {
  const buscarPorId = function (args, esLookup, esView) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/${Id}`, {
        esLookup,
      });
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}`, params);
    });
  };

  const crear = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}`, data);
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).put(`${RUTA}`, data);
    });
  };

  const cambiarContrasenia = function (args) {
    return new Promise((res) => res(args)).then((usuario) => {
      return RequestHelper(CRM_API).post(`${RUTA}/cambiarContrasenia`, usuario);
    });
  };

  const actualizarContrasenia = function (args) {
    return new Promise((res) => res(args)).then((usuario) => {
      return RequestHelper(CRM_API).post(
        `${RUTA}/actualizarContrasenia`,
        usuario
      );
    });
  };

  return {
    listado,
    crear,
    actualizar,
    buscarPorId,
    cambiarContrasenia,
    actualizarContrasenia,
  };
})();
