export const useConfigurationPage = () => {
  const setConfigurationState = (isOpen) => {
    localStorage.setItem("ConfigurationState", isOpen);
  };
  const getConfigurationState = () => {
    return localStorage.getItem("ConfigurationState");
  };
  const setConfigurationLocation = (location, option) => {
    localStorage.setItem(
      "ConfigurationLocation",
      JSON.stringify({ location: location, option: option })
    );
  };
  const getConfigurationLocation = () => {
    return JSON.parse(localStorage.getItem("ConfigurationLocation"));
  };

  return {
    setConfigurationState,
    getConfigurationState,
    setConfigurationLocation,
    getConfigurationLocation,
  };
};
