import React, { useState, useRef } from "react";
import { Col, Dropdown, Form, Nav } from "react-bootstrap";
import html2canvas from "html2canvas";

import { AppManager } from "managers";
import useMessage from "hooks/useMessage";
import ToolTip from "components/ToolTip/ToolTip";
import useExceptionHandler from "hooks/useExceptionHandler";
import { Simplebar, InputSidebarContainer } from "components";

export const SupportButton = () => {
  const [showCanvas, setShowCanvas] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [reporte, setReporte] = useState({
    titulo: "",
    cc: "",
    descripcion: "",
    ss: null,
  });

  const canvasRef = useRef();
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();

  const validar = () => {
    let isValid = true;
    if (reporte.titulo == "") {
      isValid = false;
      setValidationMsg("Debe proporcionar un título");
    } else setValidationMsg("");

    return isValid;
  };

  function enviarCorreo() {
    canvasRef.current.showLoading(true);
    const _reporte = {
      Titulo: reporte.titulo,
      CorreoCopia: reporte.cc,
      Descripcion: reporte.descripcion,
      ScreenShot: reporte.ss,
    };
    AppManager.reportarError(_reporte)
      .then((res) => {
        if (res.Data.ok) {
          Message({ message: res.Data.msg });
          setTimeout(() => {
            setShowCanvas(false);
            setReporte({});
            cerrarAlertas();
          }, 2000);
        }
      })
      .catch((e) => handleException(e))
      .finally(() => canvasRef.current.showLoading(false));
  }

  return (
    <>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle
          as={Nav.Link}
          id="dropdown-support"
          className="buttonHover"
          onClick={() => {
            html2canvas(document.body).then((canvas) => {
              setShowCanvas(true);
              const base64 = canvas.toDataURL();
              var image = new Image(500, 300);
              image.src = base64;
              image.className = "border border-dark rounded";
              const container = document.getElementById("canvas-container");
              container.innerHTML = null;
              container.append(image);
              setReporte({
                ...reporte,
                ss: base64,
              });
            });
          }}
        >
          <ToolTip placement="left" message="Incidencias">
            <svg
              width="24"
              height="23"
              xmlns="http://www.w3.org/2000/svg"
              fill="#77777A"
            >
              <path
                d="M24 17.98h-13l-7 5.02v-5.02h-4v-16.981h24v16.981zm-22-14.981v12.981h4v3.125l4.357-3.125h11.643v-12.981h-20zm10 8.501c.69
               0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25zm1-6.5v5h-2v-5h2z"
              />
            </svg>
          </ToolTip>
        </Dropdown.Toggle>
      </Dropdown>
      <Simplebar
        ref={canvasRef}
        size="md"
        useLoading={true}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvas}
        setShow={(e) => {
          setShowCanvas(e);
          cerrarAlertas();
        }}
        title={"Reportar Incidente"}
        btnLeft={{
          show: true,
          message: "Enviar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "send",
          isCenter: true,
          func: () => {
            if (validar()) enviarCorreo();
          },
        }}
        btnRight={{
          show: false,
          message: "Desestimar",
          isComponent: false,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "x-mark",
          func: () => {
            setShowCanvas(false);
            setReporte({});
            cerrarAlertas();
          },
        }}
      >
        <>
          <InputSidebarContainer label="Título">
            <Form.Control
              name="titulo"
              type="text"
              value={reporte.titulo}
              onChange={(e) => {
                setReporte({
                  ...reporte,
                  titulo: e.target.value,
                });
              }}
            />
            {validationMsg != "" && (
              <Form.Text className="text-danger" id="validationMsg" muted>
                {validationMsg}
              </Form.Text>
            )}
          </InputSidebarContainer>
          <InputSidebarContainer label="Correo Copia">
            <Form.Control
              name="cc"
              type="text"
              value={reporte.cc}
              onChange={(e) => {
                setReporte({
                  ...reporte,
                  cc: e.target.value,
                });
              }}
            />
          </InputSidebarContainer>
          <InputSidebarContainer label="Descripción">
            <Form.Control
              name="descripcion"
              type="text"
              as={"textarea"}
              rows={2}
              value={reporte.descripcion}
              onChange={(e) => {
                setReporte({
                  ...reporte,
                  descripcion: e.target.value,
                });
              }}
            />
          </InputSidebarContainer>

          <Col>
            <div id="canvas-container" className="text-center p-0 m-0"></div>
          </Col>
        </>
      </Simplebar>
    </>
  );
};
