import React, { useState, useEffect } from "react";
import { DEFAULT_PAGINATION_VALUES } from "utils";
import { GridView } from "components";
import useExceptionHandler from "hooks/useExceptionHandler";
import usePaginationValuesWhere from "../hooks/usePaginationValuesWhere";
import useMessage from "hooks/useMessage";
import { ReferenciasManager } from "managers";
import useQueryParams from "hooks/useQueryParams";
import ListHeader from "components/PantallaList/Header/ListHeader";

function Referencias(showLoading, hideLoading) {
  const [pagination, setPagination] = useState({
    ...DEFAULT_PAGINATION_VALUES,
    OrderBy: "Provincia.Nombre",
    EsAscendente: true,
    CantidadPorPagina: 50,
  });
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const [where, setWhere] = useState({});
  const [list, setList] = useState([]);
  const URLParameters = useQueryParams();
  const tabla = URLParameters.get("tabla");

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };
  useEffect(() => {
    buscar();
  }, [where]);

  const buscar = () => {
    const whereWithPaginationValues = { ...where, ...pagination };
    ReferenciasManager.listadoMunicipios(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setList(res.Data);
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {});
  };

  const descargarProvinciasExcel = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Provincias.xlsx";
    ReferenciasManager.descargarProvinciasExcel()
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      });
  };

  const descargarMunicipiosExcel = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Municipios.xlsx";
    ReferenciasManager.descargarMunicipiosExcel()
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      });
  };

  return (
    <div>
      <div style={{ paddingRight: "6px" }}>
        <ListHeader
          titulo={"Provincias y Municipios"}
          mensajeDescarga="Descargar Provincias Excel"
          mensajeArchivoComplementario="Descargar Municipios Excel"
          descargar={() => {
            descargarProvinciasExcel();
          }}
          archivoComplementario={() => {
            descargarMunicipiosExcel();
          }}
        ></ListHeader>
      </div>
      <>
        <GridView
          pagination={pagination}
          setPagination={setPagination}
          allowPaging={pagination.EsPaginable}
          pageSize={pagination.CantidadPorPagina}
          pageIndex={pagination.IndiceDePagina}
          lastPageNumber={pagination.PaginasTotal}
          dataSource={list}
          onPageIndexChanging={onPageIndexChanging}
          where={where}
          setWhere={setWhere}
          height="80vh"
        >
          <GridView.Column
            headerText="Nombre Municipio"
            dataField="Nombre"
            sortExpression="Nombre"
            isfield={true}
          />
          <GridView.Column
            headerText="Código Municipio"
            dataField="CodigoEncf"
            sortExpression="CodigoEncf"
            isfield={true}
          />

          <GridView.Column
            headerText="Nombre Provincia"
            dataField="ProvinciaNombre"
            sortExpression="ProvinciaNombre"
            isfield={true}
          />
          <GridView.Column
            headerText="Código Provincia"
            dataField="ProvinciaCodigoEncf"
            sortExpression="ProvinciaCodigoEncf"
            isfield={true}
          />
        </GridView>
      </>
    </div>
  );
}

export default Referencias;
