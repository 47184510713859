import { useContext } from 'react';
import { GridViewContext, GridViewRowContext } from 'contexts';

export function useGridView() {
    const context = useContext(GridViewContext);
    return context;
}

export function useGridViewRow() {
    const context = useContext(GridViewRowContext);
    return context;
}