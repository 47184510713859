import { FuncionService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const FuncionManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const buscarPorPerfilId = (Id) => {
    return FuncionService.buscarPorPerfilId(Id, true).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const buscarNoEnPerfilId = (Id) => {
    return FuncionService.buscarNoEnPerfilId(Id, false).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const buscarPorNombreUsuario = (NombreUsuario) => {
    return FuncionService.buscarPorNombreUsuario(NombreUsuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    buscarPorPerfilId,
    buscarPorNombreUsuario,
    buscarNoEnPerfilId,
  };
})();
