import { TokenService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const TokenManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const generarToken = (tokenOptions) => {
    return service.generarToken(tokenOptions).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const buscarToken = (Id) => {
    return service.buscarToken(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const inactivarToken = (Id) => {
    return service.inactivarToken(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listarToken = (where) => {
    return service.listarToken(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    generarToken,
    buscarToken,
    listarToken,
    inactivarToken,
  };
})();
