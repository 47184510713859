import React, { useState, useEffect, useRef } from "react";
import { Card, Nav, Row, Tab } from "react-bootstrap";

import { SvgComponent } from "components";
import ConfigurarCompania from "views/ConfigurarCompania";
import UsuariosEquipos from "views/UsuariosEquipos";
import { Simplebar } from "components";
import { useConfigurationPage } from "hooks";
import AuthorizationToken from "views/AuthorizationToken";
import { useAuthentication } from "hooks";
import { Permisos } from "utils/permisos";
import "./EntidadesList.scss";
let Entidad = "compania";
let Opcion = "";
const EntidadesList = ({ setShowCanvas, showCanvas }) => {
  const [currentTab, setCurrentTab] = useState("");
  const { tienePermiso } = useAuthentication();
  const ref = useRef(null);
  const {
    setConfigurationState,
    setConfigurationLocation,
    getConfigurationLocation,
  } = useConfigurationPage();
  const iconColor = "#3f3f3f";
  const iconStyle = { marginRight: "15px" };
  const navLinkStyle = {
    width: "100%",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const { location, option } = getConfigurationLocation();
        if (location && Entidad && Entidad !== location) {
          Entidad = location;
          setCurrentTab(location);
        }
        if (Opcion !== "" && option !== Opcion) {
          Opcion = option;
        }
      } catch {}
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (Entidad) {
      setCurrentTab(Entidad);
    } else setCurrentTab("compania");
  }, [Entidad]);

  const handleSelect = (eventKey) => {
    setConfigurationLocation(eventKey);
  };

  const hideLoading = () => {
    ref?.current?.showLoading(false);
  };
  const showLoading = () => {
    ref?.current?.showLoading(true);
  };

  return (
    <Simplebar
      ref={ref}
      size="xxxl"
      backdrop={true}
      placement={"end"}
      portal={false}
      visible={showCanvas}
      setShow={(e) => {
        setConfigurationState(e);
      }}
      useLoading={true}
      title="Configuración"
      btnLeft={{
        show: false,
        label: "Grabar",
        func: () => {},
      }}
      btnRight={{
        show: false,
        label: "Grabar",
        func: () => {},
      }}
    >
      <Row
        style={{
          minHeight: "78vh",
          maxHeight: "78vh",
          padding: "0px 0px 0px 15px",
          marginRight: 0,
          marginTop: "0px",
          width: "96vw",
        }}
      >
        <Card style={{ width: "15%", overflowY: "auto", paddingTop: "0px" }}>
          <Card.Body
            style={{
              paddingTop: "0px",
              paddingLeft: "0px",
              marginTop: "0px",
            }}
          >
            <Nav
              variant="tabs"
              defaultActiveKey="compania"
              activeKey={currentTab}
              className="flex-column"
              onSelect={handleSelect}
              style={{
                borderBottom: 0,
              }}
            >
              <Nav.Item>
                <Nav.Link eventKey="compania" style={navLinkStyle}>
                  <SvgComponent
                    fill={iconColor}
                    iconName="company"
                    style={iconStyle}
                  />
                  Compañía
                </Nav.Link>
              </Nav.Item>
              {tienePermiso(Permisos.Usuario.LISTAR) && (
                <Nav.Item>
                  <Nav.Link eventKey="usuarios" style={navLinkStyle}>
                    <SvgComponent
                      fill={iconColor}
                      iconName="user"
                      style={iconStyle}
                    />
                    Usuarios
                  </Nav.Link>
                </Nav.Item>
              )}
              {tienePermiso(Permisos.Seguridad.CREAR_TOKEN) && (
                <Nav.Item>
                  <Nav.Link eventKey="Autorización" style={navLinkStyle}>
                    <SvgComponent
                      fill={iconColor}
                      iconName="key"
                      style={iconStyle}
                    />
                    Autorización
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Card.Body>
        </Card>
        <Card style={{ width: "80%" }}>
          <Card.Body className="p-0 m-0">
            <Tab.Container
              id="icons-tabs"
              defaultActiveKey="compania"
              activeKey={currentTab}
            >
              <Tab.Content>
                <Tab.Pane eventKey="compania" className="p-0 m-0">
                  <ConfigurarCompania
                    hideLoading={hideLoading}
                    showLoading={showLoading}
                    isActive={currentTab === "compania"}
                  />
                </Tab.Pane>

                {tienePermiso(Permisos.Usuario.LISTAR) && (
                  <Tab.Pane eventKey="usuarios" className="p-0 m-0">
                    <UsuariosEquipos
                      hideLoading={hideLoading}
                      showLoading={showLoading}
                      isActive={currentTab === "usuarios"}
                      option={Opcion}
                    />
                  </Tab.Pane>
                )}
                {tienePermiso(Permisos.Seguridad.CREAR_TOKEN) && (
                  <Tab.Pane eventKey="Autorización" className="p-0 m-0">
                    <AuthorizationToken
                      hideLoading={hideLoading}
                      showLoading={showLoading}
                      isActive={currentTab === "Autorización"}
                      option={Opcion}
                    />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Row>
    </Simplebar>
  );
};

export default EntidadesList;
