import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import usePaginationValuesWhere from "../../hooks/usePaginationValuesWhere";
import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";
import { useEffect } from "react";
import { CompaniaManager } from "managers";
import { GridView } from "components";
import Select from "react-select";
import IconButton from "components/IconButton/IconButton";
import ToolTip from "components/ToolTip/ToolTip";
import ListHeader from "components/PantallaList/Header/ListHeader";
import { Compania } from "models/Compania";
import { SvgComponent } from "components";
import { DEFAULT_PAGINATION_VALUES } from "utils";
import { useAuthentication } from "hooks";

const ESTATUS_LIST = [{ value: "", label: "Todos" }, ...Compania.ESTATUS_LIST];
const AMBIENTES_LIST = [
  { value: "", label: "Todos" },
  ...Compania.AMBIENTE_LIST,
];

export default function CompaniaList({ showLoading, hideLoading }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState({
    EsPaginable: true,
    CantidadPorPagina: 15,
    EsOrdernable: true,
    OrderBy: "Id",
  });
  const [list, setList] = useState([]);
  const history = useHistory();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const { cambiarCompania } = useAuthentication();

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  const buscar = () => {
    const whereWithPaginationValues = { ...where, ...pagination };
    CompaniaManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setList(res.Data);
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const ingresarCompania = (id) => {
    if (id) {
      showLoading();
      cambiarCompania({ Id: id })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => {
          hideLoading();
        });
    } else {
      Warning({ message: "Compañía inválida." });
    }
  };

  const handleClick = (params) => {
    const { Id } = params;
    let urlBase = `/CompaniaEdit?`;
    if (Id) {
      urlBase += `Id=${Id}`;
    }
    history.push(urlBase);
  };

  return (
    <div>
      <div style={{ paddingRight: "6px" }}>
        <ListHeader titulo={"Compania"} crearNuevo={handleClick}></ListHeader>
      </div>
      <>
        <GridView
          pagination={pagination}
          setPagination={setPagination}
          allowPaging={pagination.EsPaginable}
          pageSize={pagination.CantidadPorPagina}
          pageIndex={pagination.IndiceDePagina}
          lastPageNumber={pagination.PaginasTotal}
          dataSource={list}
          onPageIndexChanging={onPageIndexChanging}
          where={where}
          setWhere={setWhere}
          height="70vh"
        >
          <GridView.Column
            bodyRenderer={(row) => {
              return (
                <>
                  <>
                    <div className="float-right">
                      <IconButton onClick={() => ingresarCompania(row.Id)}>
                        <ToolTip message="Ingresar">
                          <div>
                            <SvgComponent
                              iconName="user"
                              height="25"
                              width="25"
                            />
                          </div>
                        </ToolTip>
                      </IconButton>
                    </div>
                  </>
                  <div className="float-right">
                    <IconButton onClick={() => handleClick({ Id: row.Id })}>
                      <ToolTip message="Editar">
                        <div>
                          <SvgComponent
                            iconName="pencil"
                            height="25px"
                            width="25px"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                  </div>
                </>
              );
            }}
          />
          <GridView.Column headerText="Id" dataField="Id" sortExpression="Id" />
          <GridView.Column
            headerText="Nombre"
            dataField="Nombre"
            sortExpression="Nombre"
          />
          <GridView.Column
            headerText="Código"
            dataField="Codigo"
            sortExpression="Codigo"
          />
          <GridView.Column
            headerText="RNC"
            dataField="RNC"
            sortExpression="RNC"
          />
          <GridView.Column
            headerText="Correo"
            dataField="Email"
            sortExpression="Email"
          />
          <GridView.Column
            width="160px"
            headerText="Dirección"
            dataField="DireccionLinea1"
            sortExpression="DireccionLinea1"
          />
          <GridView.Column
            width="150px"
            headerText="Tel. Principal"
            dataField="TelefonoPrincipal"
            sortExpression="TelefonoPrincipal"
          />
          <GridView.Column
            width="150px"
            headerText="Tel. Secundario"
            dataField="TelefonoSecundario"
            sortExpression="TelefonoSecundario"
          />
          <GridView.Column
            width="150px"
            headerText="Página Web"
            dataField="PaginaWeb"
            sortExpression="PaginaWeb"
          />
          <GridView.Column
            headerText="Ext. 1"
            dataField="Extension1"
            sortExpression="Extension1"
          />
          <GridView.Column
            headerText="Ext. 2"
            dataField="Extension2"
            sortExpression="Extension2"
          />
          <GridView.Column
            width="150px"
            headerText="Ambiente"
            dataField="Ambiente"
            sortExpression="Ambiente"
            style={{ color: "#824141" }}
            filterRenderer={() => {
              return (
                <Select
                  className="react-select primary"
                  options={AMBIENTES_LIST}
                  value={AMBIENTES_LIST.filter(function (option) {
                    return (
                      option.value ===
                      (where.Ambiente
                        ? where.Ambiente
                        : AMBIENTES_LIST[0].value)
                    );
                  })}
                  name="Ambiente"
                  onChange={(e) =>
                    setWhere({
                      ...where,
                      Ambiente: e.value,
                    })
                  }
                ></Select>
              );
            }}
          />
          <GridView.Column
            width="150px"
            headerText="Estatus"
            dataField="Estatus"
            sortExpression="Estatus"
            filterRenderer={() => {
              return (
                <Select
                  className="react-select primary"
                  options={ESTATUS_LIST}
                  value={ESTATUS_LIST.filter(function (option) {
                    return (
                      option.value ===
                      (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                    );
                  })}
                  name="Estatus"
                  onChange={(e) =>
                    setWhere({
                      ...where,
                      Estatus: e.value,
                    })
                  }
                ></Select>
              );
            }}
          />
        </GridView>
      </>
    </div>
  );
}
