export const FormHelper = (() => {
  const handleChange = (event, stateSetter) => {
    const { name, value } = event.target;
    stateSetter((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeNoEspace = (event, stateSetter) => {
    const { name, value } = event.target;
    stateSetter((prevState) => {
      return {
        ...prevState,
        [name]: value.replace(/\s/g, ""),
      };
    });
  };

  const handleChangeSoloNumero = (event, stateSetter) => {
    const { name, value } = event.target;
    stateSetter((prevState) => {
      return {
        ...prevState,
        [name]: value.replace(/\D/g, ""),
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return {
    handleChange,
    handleSubmit,
    handleChangeNoEspace,
    handleChangeSoloNumero,
  };
})();
