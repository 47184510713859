import { MenuService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const MenuManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const buscarPorId = (Id) => {
    return MenuService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };
  const listado = (where) => {
    return MenuService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };
  const crear = (menu) => {
    return MenuService.crear(menu).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };
  const actualizar = (menu) => {
    return MenuService.actualizar(menu).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    buscarPorId,
    listado,
    crear,
    actualizar,
  };
})();
