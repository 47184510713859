import React, { useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useConfirmContext } from "providers/confirm.provider";
import "./confirm.styles.scss";

function Confirm({ data }) {
  const [alert, setAlert] = React.useState(null);
  const { confirmDispatch } = useConfirmContext();

  const execute = (process) => {
    process();
    setAlert(null);
  };

  useEffect(() => {
    if (data.some((x) => x)) {
      var {
        alertType,
        title,
        textBody,
        onConfirm,
        onCancel,
        confirmBtnBsStyle = "success",
        cancelBtnBsStyle = "danger",
        confirmBtnText = "Confirmar",
        cancelBtnText = "Cancelar",
        showCancel = true,
        style = { display: "block", marginTop: "-100px" },
      } = data[0].content;
      setAlert(
        <SweetAlert
          customClass="customRowStyle"
          type={alertType}
          style={style}
          title={title}
          onConfirm={() => execute(onConfirm)}
          onCancel={() => execute(onCancel)}
          confirmBtnBsStyle={confirmBtnBsStyle}
          cancelBtnBsStyle={cancelBtnBsStyle}
          confirmBtnText={confirmBtnText}
          cancelBtnText={cancelBtnText}
          showCancel={showCancel}
          confirmBtnCssClass="btn btn-sm customBtnConfirm"
          cancelBtnCssClass="btn btn-sm customBtnCancel"
        >
          {textBody}
        </SweetAlert>
      );
      return confirmDispatch({ type: "REMOVE_ALL" });
    }
  });

  return <>{alert}</>;
}

export default Confirm;
