import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Usuario } from "models/usuario";
import { UsuarioManager } from "managers";
import useMessage from "hooks/useMessage";
import ToolTip from "components/ToolTip/ToolTip";
import { DEFAULT_PAGINATION_VALUES } from "utils";
import { GridView, SvgComponent } from "components";
import IconButton from "components/IconButton/IconButton";
import useExceptionHandler from "hooks/useExceptionHandler";
import ListHeader from "components/PantallaList/Header/ListHeader";
import usePaginationValuesWhere from "../hooks/usePaginationValuesWhere";
import { useConfigurationPage } from "hooks";
export default function UsuarioList({
  showLoading,
  hideLoading,
  hideTitle = false,
  isActive,
}) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const history = useHistory();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const { setConfigurationState } = useConfigurationPage();
  const ESTATUS_LIST = [{ value: "", label: "Todos" }, ...Usuario.ESTATUS_LIST];

  useEffect(() => {
    if (isActive) {
      buscar();
    }
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where, isActive]);

  const buscar = () => {
    const whereWithPaginationValues = { ...where, ...pagination };
    UsuarioManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setList(res.Data);
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (params) => {
    let urlBase = `/UsuarioEdit?`;
    let parametros = "";
    if (params) {
      const { Id, Clonar } = params;
      if (Id) {
        urlBase = `/UsuarioEdit?`;
        parametros += `Id=${Id}`;
      }
      if (Clonar) {
        urlBase = `/UsuarioEdit?`;
        parametros += `&Clonar=${Clonar}`;
      }
      if (isActive) {
        setConfigurationState(false);
      }
    }
    history.push(urlBase + parametros);
  };

  return isActive ? (
    <>
      <div style={{ paddingRight: isActive ? "15px" : "6px" }}>
        <ListHeader
          titulo={hideTitle ? "" : "Usuario"}
          crearNuevo={handleClick}
        ></ListHeader>
      </div>
      <GridView
        pagination={pagination}
        setPagination={setPagination}
        allowPaging={pagination.EsPaginable}
        pageSize={pagination.CantidadPorPagina}
        pageIndex={pagination.IndiceDePagina}
        lastPageNumber={pagination.PaginasTotal}
        dataSource={list}
        onPageIndexChanging={onPageIndexChanging}
        where={where}
        setWhere={setWhere}
        height="70vh"
      >
        <GridView.Column
          bodyRenderer={(row) => {
            return (
              <>
                <div className="float-right">
                  <IconButton
                    onClick={() =>
                      handleClick({ Id: row.IdSec, Clonar: false })
                    }
                  >
                    <ToolTip message="Ver">
                      <div>
                        <SvgComponent
                          iconName="pencil"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                  <IconButton
                    onClick={() => handleClick({ Id: row.IdSec, Clonar: true })}
                  >
                    <ToolTip message="Clonar">
                      <div>
                        <SvgComponent
                          iconName="copy"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
              </>
            );
          }}
        />
        <GridView.Column
          headerText="Id"
          dataField="IdSec"
          sortExpression="IdSec"
          width="8%"
        />
        <GridView.Column
          headerText="Nombre de Usuario"
          dataField="NombreUsuario"
          sortExpression="NombreUsuario"
        />
        <GridView.Column
          headerText="Nombre Completo"
          dataField="Descripcion"
          sortExpression="Descripcion"
        />
        <GridView.Column
          headerText="Correo"
          dataField="Email"
          sortExpression="Email"
        />
        <GridView.Column
          headerText="Estatus"
          dataField="Estatus"
          sortExpression="Estatus"
          filterRenderer={() => {
            return (
              <Select
                className="react-select primary"
                options={ESTATUS_LIST}
                value={ESTATUS_LIST.filter(function (option) {
                  return (
                    option.value ===
                    (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                  );
                })}
                name="Estatus"
                onChange={(e) =>
                  setWhere({
                    ...where,
                    Estatus: e.value,
                  })
                }
              />
            );
          }}
        />
      </GridView>
    </>
  ) : (
    <></>
  );
}
