import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";
import { useEffect } from "react";
import { useState } from "react";
import { Button, InputGroup, Form } from "react-bootstrap";

export function EmailInput({
  inputValue = "",
  parentCallback,
  placeholder,
  noMultiple,
}) {
  const [emails, setEmails] = useState([{ key: 0, value: "" }]);
  const [validationMsg, setValidationMsg] = useState([]);
  const btnStyle = {
    margin: 0,
    borderRadius: "0rem 0.25rem 0.25rem 0rem",
  };

  useEffect(() => {
    if (inputValue) {
      let initialValues = inputValue.split(",");
      const emailArray = initialValues.map((e, i) => {
        return {
          key: i,
          value: e,
        };
      });
      setEmails(emailArray);
    }
  }, []);

  function addNewTextBox() {
    let lastEmail = emails.at(-1);
    setEmails([...emails, { key: lastEmail.key + 1, value: "" }]);
  }

  function removeEmail(key) {
    let filteredEmails = emails.filter((email) => {
      return email.key !== key;
    });
    if (filteredEmails.length === 0) {
      filteredEmails = [
        {
          key: 0,
          value: "",
        },
      ];
    }
    setEmails(filteredEmails);
    let emailsString = filteredEmails.map((e) => e.value).join(",");
    parentCallback(emailsString);
  }

  function updateEmail(email) {
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newEmails = emails.map((e) => {
      return e.key === email.key ? email : e;
    });
    setEmails(newEmails);

    if (regExp.test(email.value)) {
      const filteredVal = validationMsg.filter((val) => {
        return val.key !== email.key;
      });
      setValidationMsg(filteredVal);
      let emailsString = emails.map((e) => e.value).join(",");
      parentCallback(emailsString);
    } else {
      const previousMsg = [...validationMsg];
      let msg = { key: email.key, msg: "Correo Electrónico no válido." };
      setValidationMsg([...previousMsg, msg]);
    }
  }

  return (
    <>
      {emails.map((email, idx) => {
        return (
          <div
            className="mb-1"
            key={email.key}
            style={{
              width: "102%",
            }}
          >
            <InputGroup>
              <Form.Control
                name="Email"
                type="email"
                placeholder={placeholder ? placeholder : ""}
                value={email.value}
                onChange={(e) =>
                  updateEmail({ key: email.key, value: e.target.value })
                }
                onBlur={(e) =>
                  updateEmail({ key: email.key, value: e.target.value })
                }
              />

              {!noMultiple &&
                (idx >= 1 ? (
                  <AnimatedIconButton
                    size="40px"
                    height="18"
                    width="18"
                    style={{
                      borderRadius: "0%",
                      paddingLeft: "0px",
                      marginLeft: "0px",
                      paddingTop: "0px",
                      paddingRight: "0px",
                      marginRight: "-1px",
                    }}
                    iconName="minus"
                    onClick={() => removeEmail(email.key)}
                  />
                ) : null)}
              {!noMultiple && (
                <AnimatedIconButton
                  size="40px"
                  height="18"
                  width="18"
                  style={{
                    borderRadius: "0% 0.3rem 0.3rem 0%",
                    paddingLeft: "0px",
                    marginLeft: "0px",
                    paddingTop: "0px",
                  }}
                  iconName="plus"
                  onClick={() => addNewTextBox()}
                />
              )}
            </InputGroup>
            {validationMsg !== [] &&
            validationMsg.find((val) => val.key === email.key) !== undefined ? (
              <Form.Text className="text-danger" id="validationMsg" muted>
                {validationMsg.find((val) => val.key === email.key).msg}
              </Form.Text>
            ) : null}
          </div>
        );
      })}
    </>
  );
}
