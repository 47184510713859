import { AprobacionComercialService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const AprobacionComercialManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listado = (where) => {
    return service.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const buscarPorId = (Id) => {
    return service.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const crear = (aprobacion) => {
    return service.crear(aprobacion).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    listado,
    buscarPorId,
    crear,
  };
})();
