import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  COffcanvasBody,
} from "@coreui/react";

import { useTheme } from "hooks";
import IconButton from "components/IconButton/IconButton";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";
import "./Simplebar.styles.scss";

export const Simplebar = forwardRef(
  (
    {
      backdrop,
      placement = "end",
      portal = true,
      visible,
      title,
      setShow,
      children,
      size = "md",
      btnLeft = {
        show: true,
        label: "Aceptar",
        func: (e) => {},
        isComponent: false,
        btnStyle: {},
      },
      btnRight = {
        show: true,
        label: "Aplicar",
        func: (e) => {},
        isComponent: false,
        btnStyle: {},
      },
      useLoading = false,
    },
    ref
  ) => {
    const [displayMode, setDisplayMode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { theme } = useTheme();
    const styleVars = {
      "--cui-offcanvas-width": getSize(size),
    };
    const footerStyle = {
      position: "relative",
      padding: "0.3rem",
      left: 0,
      bottom: 0,
      width: "100%",
      backgroundColor: "lightgrey",
      color: "white",
    };

    useEffect(() => {
      setDisplayMode("");
    }, [visible]);

    useImperativeHandle(ref, () => ({
      showLoading: (v) => {
        setIsLoading(v);
      },
    }));

    function getSize(s) {
      switch (s) {
        case "xxxl":
          return "95vw";
        case "xxl":
          return "80vw";
        case "xl":
          return "70vw";
        case "lg":
          return "50vw";
        case "md":
          return "30vw";
        default:
          return "20vw";
      }
    }

    return (
      <>
        <COffcanvas
          backdrop={backdrop}
          placement={placement}
          portal={portal}
          visible={visible}
          onHide={() => {
            setShow(false);
            setDisplayMode("none");
            if (useLoading) setIsLoading(false);
          }}
          style={styleVars}
        >
          <COffcanvasHeader style={{ backgroundColor: theme.color1 }}>
            <COffcanvasTitle className="text-white">{title}</COffcanvasTitle>
            <IconButton
              onClick={() => {
                setShow(false);
                setDisplayMode("none");
                if (useLoading) setIsLoading(false);
              }}
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </IconButton>
          </COffcanvasHeader>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "9999999",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                style={{
                  width: "4rem",
                  height: "4rem",
                  color: theme.color1,
                }}
                animation="border"
              />
            </div>
          )}
          <COffcanvasBody
            style={
              isLoading === true
                ? {
                    WebkitFilter: "blur(2px)",
                    filter: "blur(2px)",
                    overflowX: "hidden",
                  }
                : {
                    overflowX: "hidden",
                  }
            }
          >
            <div style={{ display: displayMode }}>{children}</div>
          </COffcanvasBody>
          <div
            id="canvasFooter"
            style={btnLeft.show || btnRight.show ? footerStyle : {}}
          >
            <div
              className={`d-flex ${
                btnLeft.isCenter
                  ? "justify-content-center"
                  : "justify-content-center"
              }`}
            >
              {btnLeft.show && !btnLeft.isComponent ? (
                <Button
                  onClick={btnLeft.func}
                  style={{
                    backgroundColor: theme.color1,
                    borderColor: theme.color1,
                  }}
                  disabled={isLoading}
                >
                  {btnLeft.label}
                </Button>
              ) : (
                btnLeft.isComponent && (
                  <AnimatedIconButton
                    onClick={btnLeft.func}
                    size={btnLeft.size}
                    height={btnLeft.height}
                    width={btnLeft.width}
                    message={btnLeft.message}
                    disabled={isLoading}
                    iconName={btnLeft.iconName}
                  />
                )
              )}
              {btnRight.show && !btnRight.isComponent ? (
                <Button
                  onClick={btnRight.func}
                  variant={"outline-secondary"}
                  className="text-white"
                  disabled={isLoading}
                >
                  {btnRight.label}
                </Button>
              ) : (
                btnRight.isComponent && (
                  <AnimatedIconButton
                    onClick={btnRight.func}
                    size={btnRight.size}
                    height={btnRight.height}
                    width={btnRight.width}
                    message={btnRight.message}
                    disabled={isLoading}
                    iconName={btnRight.iconName}
                  />
                )
              )}
            </div>
          </div>
        </COffcanvas>
      </>
    );
  }
);
