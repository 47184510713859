import { ComprobanteElectronicoService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const ComprobanteElectronicoManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listado = (where) => {
    return service.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const buscarPorId = (Id, esLookup = false) => {
    return service.buscarPorId(Id, esLookup).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    listado,
    buscarPorId,
  };
})();
