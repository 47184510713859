import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import Select from "react-select";
import { useLocation } from "react-router-dom";

import { FormHelper } from "utils";
import { GridView } from "components";
import { Perfil } from "models/Perfil";
import useMessage from "hooks/useMessage";
import useQueryParams from "hooks/useQueryParams";
import { PerfilManager, FuncionManager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { TIPO_SERIE_FISCAL_LIST } from "utils";
import { DateHelper, DEFAULT_PAGINATION_VALUES } from "utils";
import { SerieFiscalManager } from "managers";

export default function SerieFiscalEdit({ showLoading, hideLoading }) {
  const [serieFiscal, setSerieFiscal] = useState({});
  const [esNuevo, setEsNuevo] = useState(false);
  const [tituloHeader, setTituloHeader] = useState("Crear Serie Fiscal");
  const [funcionesPorAgregarList, setFuncionesPorAgregarList] = useState([]);
  const URLParameters = useQueryParams();
  const { Warning, Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();

  const Id = URLParameters.get("Id");
  const esClonar = URLParameters.get("clonar") == "true";
  const ESTATUS_LIST = Perfil.ESTATUS_LIST;
  const location = useLocation();

  useEffect(() => {
    setEsNuevo(!Id || esClonar);
    if (Id) {
      showLoading();
      cargarSerieFiscal(Id);
      esClonar
        ? setTituloHeader("Clonar Serie Fiscal")
        : setTituloHeader("Editar  Serie Fiscal");
    } else {
      setDefaultValues();
    }

    return () => {
      hideLoading();
      cerrarAlertas();
    };
  }, [location.search]);

  const setDefaultValues = () => {
    setSerieFiscal({
      ...serieFiscal,
      Estatus: Perfil.DEFAULT_ESTATUS_VALUE,
      LlamadaCondicion: Perfil.CONDICION_LLAMADA_EN_PROCESO,
    });
  };

  const cargarSerieFiscal = (Id) => {
    SerieFiscalManager.buscarPorId(Id)
      .then((data) => {
        setSerieFiscal(data);
      })
      .catch((e) => handleException(e))
      .finally(() => hideLoading());
  };

  const validar = () => {
    const validaciones = [];
    if (!serieFiscal.Tipo) {
      validaciones.push("Debe seleccionar el tipo de la Serie Fiscal.");
    }

    if (!serieFiscal.FechaExpiracion) {
      validaciones.push(
        "Debe seleccionar la Fecha de Expiración de la Serie Fiscal."
      );
    }

    if (!serieFiscal.RangoDesde) {
      validaciones.push(
        "Debe seleccionar el incio del rango de las secuencias."
      );
    }

    if (serieFiscal.RangoDesde <= 0) {
      validaciones.push("El incio del rango de las secuencias es invalido.");
    }

    if (!serieFiscal.RangoHasta) {
      validaciones.push("Debe seleccionar el fin del rango de las secuencias.");
    }

    if (serieFiscal.RangoHasta <= 0) {
      validaciones.push("El fin del rango de las secuencias es invalido.");
    }

    if (parseInt(serieFiscal.RangoHasta) <= parseInt(serieFiscal.RangoDesde)) {
      validaciones.push("El rango seleccionado es invalido.");
    }

    if (validaciones.length >= 1) {
      Warning({ message: validaciones[0] });
      return false;
    }
    return true;
  };

  const grabar = () => {
    showLoading();
    if (!validar()) {
      hideLoading();
      return;
    }
    if (esNuevo) {
      SerieFiscalManager.crear(serieFiscal)
        .then((res) => {
          setEsNuevo(false);
          Message({ message: `Grabado Exitosamente` });
          cargarSerieFiscal(res.IdSec);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    } else {
      SerieFiscalManager.actualizar(serieFiscal)
        .then(() => {
          Message({ message: `Actualizado Exitosamente` });
          cargarSerieFiscal(res.IdSec);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    }
  };

  function addToList(Id) {
    const index = funcionesPorAgregarList.findIndex((funcion) => {
      return funcion.IdSec === Id;
    });
    serieFiscal.Funciones.push(funcionesPorAgregarList[index]);
  }

  function removeFromList(Id) {
    const index = serieFiscal.Funciones.findIndex((perfil) => {
      return perfil.IdSec === Id;
    });
    setFuncionesPorAgregarList([
      ...funcionesPorAgregarList,
      serieFiscal.Funciones[index],
    ]);
    serieFiscal.Funciones.splice(index, 1);
  }

  function handleFuncionSwitch(params) {
    const { Id } = params;
    const index = funcionesPorAgregarList.findIndex((funcion) => {
      return funcion.IdSec === Id;
    });
    let list = [...funcionesPorAgregarList];
    if (list[index].EsAnadirPerfil) {
      removeFromList(Id);
    } else {
      addToList(Id);
    }
    list[index].EsAnadirPerfil = !funcionesPorAgregarList[index].EsAnadirPerfil;
    setFuncionesPorAgregarList(list);
  }

  const inactivar = () => {
    showLoading();

    SerieFiscalManager.inactivar(serieFiscal.IdSec)
      .then(() => {
        Message({ message: `Secuencia Fiscal Inactivada Exitosamente` });
        cargarSerieFiscal(res.IdSec);
      })
      .catch((e) => handleException(e))
      .finally(() => hideLoading());
  };

  return (
    <Form action="" className="form-horizontal" id="TypeValidation" method="">
      <Card>
        <EditHeader
          titulo={`${tituloHeader}`}
          volver={true}
          grabar={grabar}
          inactivar={!esNuevo && inactivar}
        />
        <EditContainer>
          <EditSection title="">
            <SectionRow>
              <InputContainer labelSize={3} label="Tipo">
                <Select
                  className="react-select primary mb-2"
                  classNamePrefix="react-select"
                  isDisabled={!esNuevo}
                  name="Tipo"
                  onChange={(e) => {
                    setSerieFiscal({
                      ...serieFiscal,
                      Tipo: e.value,
                    });
                  }}
                  options={TIPO_SERIE_FISCAL_LIST}
                  value={TIPO_SERIE_FISCAL_LIST.filter(function (option) {
                    return (
                      option.value ===
                      (serieFiscal.Tipo
                        ? serieFiscal.Tipo
                        : TIPO_SERIE_FISCAL_LIST[0].value)
                    );
                  })}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer labelSize={3} label="Fecha Expiración">
                <DateTimePicker
                  usarLabel={false}
                  esRangoFechas={false}
                  labelFechaInicio=""
                  soloFecha={true}
                  fechaInicioOriginal={serieFiscal.FechaExpiracion}
                  onChange={(e) => {
                    if (
                      serieFiscal.FechaExpiracion !==
                        DateHelper.formatDate(e.fechaInicio) &&
                      e.fechaInicio
                    ) {
                      setSerieFiscal({
                        ...serieFiscal,
                        FechaExpiracion: DateHelper.formatDate(e.fechaInicio),
                      });
                    }
                  }}
                />
              </InputContainer>
            </SectionRow>

            <SectionRow>
              <InputContainer labelSize={3} label="Serie Fija">
                <Form.Control
                  name="SerieFija"
                  type="text"
                  disabled={true}
                  onChange={(e) => FormHelper.handleChange(e, setSerieFiscal)}
                  value={serieFiscal.Tipo ? "E" + serieFiscal.Tipo : ""}
                />
              </InputContainer>
            </SectionRow>

            <SectionRow>
              <InputContainer labelSize={3} label="Rango Desde">
                <Form.Control
                  name="RangoDesde"
                  type="number"
                  onChange={(e) => FormHelper.handleChange(e, setSerieFiscal)}
                  value={serieFiscal.RangoDesde}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer labelSize={3} label="Rango Hasta">
                <Form.Control
                  name="RangoHasta"
                  type="number"
                  onChange={(e) => FormHelper.handleChange(e, setSerieFiscal)}
                  value={serieFiscal.RangoHasta}
                />
              </InputContainer>
            </SectionRow>

            <SectionRow>
              <InputContainer labelSize={3} label="Última Serie Usada">
                <Form.Control
                  disabled={esNuevo}
                  name="UltimaSerieUsada"
                  type="number"
                  onChange={(e) => FormHelper.handleChange(e, setSerieFiscal)}
                  value={serieFiscal.UltimaSerieUsada}
                />
              </InputContainer>
            </SectionRow>

            <SectionRow>
              <InputContainer labelSize={3} label="Estatus">
                <Select
                  isDisabled={true}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="Estatus"
                  onChange={(e) =>
                    setSerieFiscal({
                      ...serieFiscal,
                      Estatus: e.value,
                    })
                  }
                  options={ESTATUS_LIST}
                  value={
                    esNuevo
                      ? ESTATUS_LIST.filter(function (option) {
                          return option.value === Perfil.DEFAULT_ESTATUS_VALUE;
                        })
                      : ESTATUS_LIST.filter(function (option) {
                          return option.value === serieFiscal.Estatus;
                        })
                  }
                />
              </InputContainer>
            </SectionRow>
          </EditSection>
        </EditContainer>
      </Card>
    </Form>
  );
}
