import { AnulacionService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const AnulacionManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listado = (where) => {
    return service.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const buscarPorId = (Id) => {
    return service.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const crear = (anulacion) => {
    const data = {
      DetalleAnulacion: [
        {
          TipoeCF: anulacion.TipoComprobante,
          CantidadeNCFAnulados: anulacion.CantidadeNCFAnulados,
          SecuenciasAnuladas: [
            {
              Desde: anulacion.SecuenciaDesde,
              Hasta: anulacion.SecuenciaHasta,
            },
          ],
        },
      ],
    };
    return service.crear(data).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    listado,
    buscarPorId,
    crear,
  };
})();
