import React, { useState, useEffect } from "react";
import { Form, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";

import { FormHelper } from "utils";
import useMessage from "hooks/useMessage";
import { CompaniaManager } from "managers";
import { useAuthentication, useApp } from "hooks";
import { InputSidebarContainer } from "components";
import DropZone from "components/DropZone/Dropzone";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import { TAMANIOS_REPRESENTION_IMPRESA_LIST } from "utils";
import { EmailInput } from "components";
import { ConfiguracionEmailHelper } from "utils";
import VincularCuentaGoogle from "components/Email/VincularCuentaGoogle";

import { ConfiguracionCorreoElectronicoManager } from "managers";
import VincularCuentaMicrosoft from "components/Email/VincularCuentaMicrosoft";

export default function ConfigurarCompania({
  showLoading,
  hideLoading,
  isActive,
}) {
  const [compania, setCompania] = useState({
    IncluirITBISDetalles: false,
    MostrarValorPagar: false,
    IncluirSubTotalesInformativos: false,
  });
  const [tipoActual, setTipoActual] = useState();
  const [configuracionEmail, setConfiguracionEmail] = useState({});
  const { configuration } = useApp();
  const { usuario } = useAuthentication();
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();

  const AMBIENTE_LIST = [
    { value: "T", label: "Prueba" },
    { value: "C", label: "Certificación" },
    { value: "S", label: "Simulación" },
  ];

  useEffect(() => {
    if (isActive) {
      showLoading();
      cargarCompania();
      cargarConfiguracionEmail();
    }
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [isActive]);

  const grabar = () => {
    showLoading();
    CompaniaManager.actualizar(compania)
      .then(() => {
        Message({ message: `Actualizado Exitosamente` });
        cargarCompania();
      })
      .catch((e) => handleException(e))
      .finally(() => hideLoading());
  };

  const setDefaultTipo = () => {
    setTipoActual(ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DEFAULT);
    setConfiguracionEmail({
      ...configuracionEmail,
      Tipo: ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DEFAULT,
    });
  };

  const cargarCompania = () => {
    CompaniaManager.buscarPorId(usuario.CompaniaId)
      .then((data) => {
        data.Color1 = "#" + data.Color1;
        data.Color2 = "#" + data.Color2;
        data.Color3 = "#" + data.Color3;

        setCompania(data);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onFileChange = (propFile, propName) => {
    setCompania({ ...compania, [propName]: propFile });
  };

  const setCheckedProp = (e) => {
    const { name, checked } = e.target;
    setCompania({
      ...compania,
      [name]: checked,
    });
  };

  const cargarConfiguracionEmail = () => {
    showLoading();
    const code = ConfiguracionEmailHelper.obtenerCodigoAutorizacion();
    ConfiguracionCorreoElectronicoManager.buscarMailConfig({
      fuente: ConfiguracionEmailHelper.FUENTE_ENTIDAD.COMPANIA,
      referencia: usuario.CompaniaId,
    })
      .then((data) => {
        if (code) {
          data = {
            ...data,
            Tipo: ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.OUTLOOK,
          };
        } else if (!data.Tipo) {
          data = {
            ...data,
            Tipo: ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DEFAULT,
          };
        }
        setConfiguracionEmail(data);
        setTipoActual(data.Tipo);
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        hideLoading();
      });
  };

  return isActive ? (
    <Form
      action=""
      className="form-horizontal p-3"
      id="TypeValidation"
      method=""
      autoComplete="off"
    >
      <Card className="p-0 m-0">
        <EditHeader
          titulo="Configuración Compañía"
          grabar={grabar}
          showLoading={showLoading}
          hideLoading={hideLoading}
          isInSimpleBar={true}
        />
        <EditContainer>
          <EditSection title="General">
            <SectionRow>
              <InputContainer label="Nombre">
                <Form.Control
                  name="Nombre"
                  type="text"
                  placeholder="Nombre"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.Nombre}
                />
              </InputContainer>
              <InputContainer labelSize={3} label="Razón Social">
                <Form.Control
                  name="RazonSocial"
                  type="text"
                  placeholder="Razón Social"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.RazonSocial}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Codigo">
                <Form.Control
                  name="Codigo"
                  type="text"
                  value={compania.Codigo}
                  readOnly
                />
              </InputContainer>
              <InputContainer labelSize={3} label="RNC">
                <Form.Control
                  name="RNC"
                  type="number"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.RNC}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Email">
                <Form.Control
                  name="Email"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.Email}
                />
              </InputContainer>
              <InputContainer labelSize={3} label="Dirección">
                <Form.Control
                  name="DireccionLinea1"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.DireccionLinea1}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Télefono">
                <Form.Control
                  name="TelefonoPrincipal"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.TelefonoPrincipal}
                />
              </InputContainer>

              <InputContainer labelSize={3} label="Sitio Web">
                <Form.Control
                  name="PaginaWeb"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.PaginaWeb}
                />
              </InputContainer>
            </SectionRow>

            <SectionRow>
              <InputContainer label="Logo">
                <DropZone
                  label="Seleccionar Imagen"
                  updateFilesCb={(f) => onFileChange(f, "Logo")}
                  multiple={false}
                  type="image"
                />
              </InputContainer>
            </SectionRow>
            <EditSection title="Color" mt={"mt-5"}>
              <SectionRow triple={true}>
                <InputContainer label="Primario" labelSize={3}>
                  <Form.Control
                    name="Color1"
                    type="color"
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                    value={compania.Color1}
                  />
                </InputContainer>
                <InputContainer
                  label="Secundario"
                  labelSize={3}
                  labelClassName="pl-2 d-flex"
                >
                  <Form.Control
                    name="Color2"
                    type="color"
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                    value={compania.Color2}
                  />
                </InputContainer>
                <InputContainer label="Logo">
                  <Form.Control
                    name="Color3"
                    type="color"
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                    value={compania.Color3}
                  />
                </InputContainer>
              </SectionRow>
            </EditSection>
          </EditSection>
          <EditSection title="Representación Impresa" mt={"mt-5"}>
            <InputContainer labelSize={3} label="Tamaño Representación Impresa">
              <SectionRow>
                <Select
                  name="TamanoRepresentacionImpresa"
                  className="react-select primary"
                  options={TAMANIOS_REPRESENTION_IMPRESA_LIST}
                  value={TAMANIOS_REPRESENTION_IMPRESA_LIST.filter(function (
                    option
                  ) {
                    return (
                      option.value ===
                      (compania.TamanoRepresentacionImpresa
                        ? compania.TamanoRepresentacionImpresa
                        : TAMANIOS_REPRESENTION_IMPRESA_LIST[0].value)
                    );
                  })}
                  onChange={(e) =>
                    setCompania({
                      ...compania,
                      TamanoRepresentacionImpresa: e.value,
                    })
                  }
                />
                <div className="d-flex">
                  <Form.Check
                    className="divContainer ml-2 mt-2"
                    type="checkbox"
                    aria-label="Enviar Representacion Impresa Por Email"
                    onChange={setCheckedProp}
                    id="chkEnviarRepresentacionImpresaAutomatica"
                    name="EnviarRepresentacionImpresaAutomatica"
                    checked={compania.EnviarRepresentacionImpresaAutomatica}
                  />
                  <label
                    htmlFor="chkEnviarRepresentacionImpresaAutomatica"
                    style={{ width: "450px" }}
                    className="mt-2"
                  >
                    Enviar Automaticamente vía Email
                  </label>
                </div>
              </SectionRow>
            </InputContainer>
            <InputContainer labelSize={3} label="">
              <div className="d-flex">
                <Form.Check
                  className="divContainer ml-2 mt-2"
                  type="checkbox"
                  aria-label="Utilizar Descripcion Representacion Impresa"
                  onChange={setCheckedProp}
                  id="chkUtilizarDescripcion"
                  name="UtilizarDescripcionRepresentacionImpresa"
                  checked={compania.UtilizarDescripcionRepresentacionImpresa}
                />
                <label
                  htmlFor="chkUtilizarDescripcion"
                  style={{ width: "450px" }}
                  className="mt-2"
                >
                  Utilizar Item Descripción
                </label>
              </div>
            </InputContainer>
            <InputContainer labelSize={3} label="">
              <div className="d-flex">
                <Form.Check
                  className="divContainer ml-2 mt-2"
                  type="checkbox"
                  aria-label="Mostrar Columna Descuento en Detalles"
                  onChange={setCheckedProp}
                  id="chkMostrarColumnaDescuentoDetalles"
                  name="MostrarColumnaDescuentoDetalles"
                  checked={compania.MostrarColumnaDescuentoDetalles}
                />
                <label
                  htmlFor="chkMostrarColumnaDescuentoDetalles"
                  style={{ width: "450px" }}
                  className="mt-2"
                >
                  Mostrar Columna Descuento en Detalles
                </label>
              </div>
            </InputContainer>
            <InputContainer labelSize={3} label="">
              <div className="d-flex">
                <Form.Check
                  className="divContainer ml-2 mt-2"
                  type="checkbox"
                  aria-label="Mostrar Columna Recargo en Detalles"
                  onChange={setCheckedProp}
                  id="chkMostrarColumnaRecargoDetalles"
                  name="MostrarColumnaRecargoDetalles"
                  checked={compania.MostrarColumnaRecargoDetalles}
                />
                <label
                  htmlFor="chkMostrarColumnaRecargoDetalles"
                  style={{ width: "450px" }}
                  className="mt-2"
                >
                  Mostrar Columna Recargo en Detalles
                </label>
              </div>
            </InputContainer>
            <InputContainer labelSize={3} label="">
              <div className="d-flex">
                <Form.Check
                  className="divContainer ml-2 mt-2"
                  type="checkbox"
                  aria-label="Mostrar Retenciones en Totales"
                  onChange={setCheckedProp}
                  id="chkMostrarRetencionesEnTotales"
                  name="MostrarRetencionesEnTotales"
                  checked={compania.MostrarRetencionesEnTotales}
                />
                <label
                  htmlFor="chkMostrarRetencionesEnTotales"
                  style={{ width: "450px" }}
                  className="mt-2"
                >
                  Mostrar Retenciones en Totales
                </label>
              </div>
            </InputContainer>
          </EditSection>

          <EditSection title="Comprobantes Electronicos" mt={"mt-5"}>
            <SectionRow>
              <InputContainer label="URL">
                <Form.Control
                  name="URLPublica"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.URLPublica}
                />
              </InputContainer>

              {!configuration.EsProduccion && (
                <InputContainer label="Ambiente" labelSize={3}>
                  <Select
                    name="Ambiente"
                    className="react-select primary"
                    options={AMBIENTE_LIST}
                    value={AMBIENTE_LIST.filter(function (option) {
                      return (
                        option.value ===
                        (compania.Ambiente
                          ? compania.Ambiente
                          : AMBIENTE_LIST[0].value)
                      );
                    })}
                    onChange={(e) =>
                      setCompania({
                        ...compania,
                        Ambiente: e.value,
                      })
                    }
                  />
                </InputContainer>
              )}
            </SectionRow>
            <SectionRow>
              <InputContainer label="Certificado">
                <DropZone
                  label="Seleccione un archivo .p12"
                  updateFilesCb={(f) => onFileChange(f, "Certificado")}
                  multiple={false}
                  type="p12"
                />
              </InputContainer>
              <InputContainer label="Key Certificado" labelSize={3}>
                <Form.Control
                  name="KeyCertificado"
                  type="password"
                  onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  value={compania.KeyCertificado}
                />
              </InputContainer>
            </SectionRow>
          </EditSection>
          <EditSection title="Opciones de Comprobantes" mt={"mt-5"}>
            <SectionRow triple={true}>
              <div className="d-flex">
                <Form.Check
                  className="divContainer"
                  type="checkbox"
                  aria-label="Incluir ITBIS en Detalles"
                  onChange={setCheckedProp}
                  id="chkIncluirITBISDetalles"
                  name="IncluirITBISDetalles"
                  checked={compania.IncluirITBISDetalles}
                />
                <label
                  htmlFor="chkIncluirITBISDetalles"
                  style={{ marginRight: "15px", marginTop: "3px" }}
                >
                  Incluir ITBIS en Detalles
                </label>
              </div>
              <div className="d-flex">
                <Form.Check
                  className="divContainer"
                  type="checkbox"
                  aria-label="Mostrar Valor Pagado"
                  name="MostrarValorPagar"
                  onChange={setCheckedProp}
                  id="ckMostrarValorPagado"
                  checked={compania.MostrarValorPagar}
                />
                <label
                  htmlFor="ckMostrarValorPagado"
                  style={{ marginRight: "15px", marginTop: "3px" }}
                >
                  Mostrar Valor Pagado
                </label>
              </div>
              <div className="d-flex">
                <Form.Check
                  className="divContainer"
                  type="checkbox"
                  aria-label="Incluir SubTotales Informativos"
                  onChange={setCheckedProp}
                  id="ckSubTotalesInformativos"
                  name="IncluirSubTotalesInformativos"
                  checked={compania.IncluirSubTotalesInformativos}
                />
                <label
                  htmlFor="ckSubTotalesInformativos"
                  style={{ marginRight: "15px", marginTop: "3px" }}
                >
                  Incluir SubTotales Informativos
                </label>
              </div>
            </SectionRow>
          </EditSection>

          <EditSection title="Servidor de Correos" mt={"mt-5"}>
            <div className="d-flex">
              <Form.Check
                className="divContainer"
                type="checkbox"
                aria-label="Configurar Servidor Correo Electrónico"
                onChange={setCheckedProp}
                id="ckServidorCorreo"
                name="UsaServidorCorreo"
                checked={compania.UsaServidorCorreo}
              />
              <label
                htmlFor="ckServidorCorreo"
                style={{ marginRight: "15px", marginTop: "3px" }}
              >
                Configurar Servidor Correo Electrónico
              </label>
            </div>
          </EditSection>
          {compania.UsaServidorCorreo && (
            <>
              <SectionRow>
                <Form.Label column sm="2">
                  Tipo
                </Form.Label>
                <Col sm="7">
                  <Form.Group>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="Tipo"
                      onChange={(e) => {
                        setConfiguracionEmail({
                          ...configuracionEmail,
                          Tipo: e.value,
                        });
                      }}
                      options={
                        ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DDL
                      }
                      value={ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DDL.filter(
                        function (option) {
                          return (
                            option.value ===
                            (configuracionEmail.Tipo ||
                              ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO
                                .SMTP)
                          );
                        }
                      )}
                    />
                  </Form.Group>
                </Col>
              </SectionRow>

              {configuracionEmail.Tipo ===
                ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.SMTP && (
                <>
                  <SectionRow>
                    <InputContainer label="Servidor SMTP" labelSize={3}>
                      <Form.Control
                        name="ServidorSMTP"
                        type="text"
                        placeholder="Servidor SMTP"
                        onChange={(e) =>
                          FormHelper.handleChange(e, setCompania)
                        }
                        value={compania.ServidorSMTP}
                      />
                    </InputContainer>
                    <InputContainer label="Puerto" labelSize={3}>
                      <Form.Control
                        name="Puerto"
                        type="number"
                        placeholder="Puerto"
                        onChange={(e) =>
                          FormHelper.handleChange(e, setCompania)
                        }
                        value={compania.Puerto}
                      />
                    </InputContainer>
                  </SectionRow>
                  <SectionRow>
                    <div className="d-flex">
                      <Form.Check
                        className="divContainer"
                        type="checkbox"
                        aria-label="Usar Autenticacion"
                        onChange={setCheckedProp}
                        id="ckServidorCorreo"
                        name="UsarAutenticacion"
                        checked={compania.UsarAutenticacion}
                      />
                      <label
                        htmlFor="ckUsaAutenticacion"
                        style={{ marginRight: "15px", marginTop: "3px" }}
                      >
                        Usar Autenticación
                      </label>
                    </div>
                    <div className="d-flex">
                      <Form.Check
                        className="divContainer"
                        type="checkbox"
                        aria-label="Usar TLS/SSL"
                        onChange={setCheckedProp}
                        id="ckServidorCorreo"
                        name="UsarTLSSSL"
                        checked={compania.UsarTLSSSL}
                      />
                      <label
                        htmlFor="ckUsarTLSSSL"
                        style={{ marginRight: "15px", marginTop: "3px" }}
                      >
                        Usar TLS/SSL
                      </label>
                    </div>
                  </SectionRow>
                  <SectionRow>
                    <InputContainer label="Usuario" labelSize={3}>
                      <Form.Control
                        name="Usuario"
                        type="text"
                        placeholder="Usuario"
                        onChange={(e) =>
                          FormHelper.handleChange(e, setCompania)
                        }
                        value={compania.Usuario}
                      />
                    </InputContainer>
                    <InputContainer label="Contraseña" labelSize={3}>
                      <Form.Control
                        name="Contraseña"
                        type="password"
                        placeholder="Contraseña"
                        onChange={(e) =>
                          FormHelper.handleChange(e, setCompania)
                        }
                        value={compania.Contraseña}
                      />
                    </InputContainer>
                  </SectionRow>
                  <SectionRow>
                    <InputContainer label="Correo Emisor" labelSize={3}>
                      <EmailInput
                        key={"correoEmisor" + compania.Id}
                        inputValue={compania.CorreoEmisor}
                        noMultiple={true}
                        placeholder="Correo Emisor"
                        parentCallback={(e) => {
                          setCompania({ ...compania, CorreoEmisor: e });
                        }}
                      />
                    </InputContainer>
                    <InputContainer label="Nombre Emisor" labelSize={3}>
                      <Form.Control
                        name="NombreEmisor"
                        type="text"
                        placeholder="Nombre Emisor"
                        onChange={(e) =>
                          FormHelper.handleChange(e, setCompania)
                        }
                        value={compania.NombreEmisor}
                      />
                    </InputContainer>
                  </SectionRow>
                </>
              )}
              {configuracionEmail.Tipo ===
                ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.GMAIL &&
                usuario.CompaniaId && (
                  <VincularCuentaGoogle
                    showLoading={showLoading}
                    hideLoading={hideLoading}
                    configuracionEmail={configuracionEmail || {}}
                    setConfiguracionEmail={setConfiguracionEmail}
                    setDefaultTipo={setDefaultTipo}
                    tipoActual={tipoActual}
                    setTipoActual={setTipoActual}
                    fuente={ConfiguracionEmailHelper.FUENTE_ENTIDAD.COMPANIA}
                    referencia={usuario.CompaniaId}
                  ></VincularCuentaGoogle>
                )}
              {configuracionEmail.Tipo ===
                ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.OUTLOOK && (
                <VincularCuentaMicrosoft
                  showLoading={showLoading}
                  hideLoading={hideLoading}
                  configuracionEmail={configuracionEmail || {}}
                  setConfiguracionEmail={setConfiguracionEmail}
                  setDefaultTipo={setDefaultTipo}
                  tipoActual={tipoActual}
                  setTipoActual={setTipoActual}
                ></VincularCuentaMicrosoft>
              )}
            </>
          )}
        </EditContainer>
      </Card>
    </Form>
  ) : (
    <></>
  );
}
