import { CRM_API, RequestHelper } from "utils";

const RUTA = "anulacion-secuencia";
export const AnulacionService = (() => {
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}`, params);
    });
  };

  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/${Id}`);
    });
  };

  const crear = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}`, data);
    });
  };

  return {
    listado,
    buscarPorId,
    crear,
  };
})();
