import { useState, useEffect } from "react";
import { IMaskInput } from "react-imask";
import { Form, InputGroup } from "react-bootstrap";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";

export const PhoneInput = ({
  initialValue = [],
  onChange,
  maxCount,
  hideType,
}) => {
  const defaultPhone = { key: 0, Telefono: "", Extension: "", Tipo: "F" };
  const [validationMsgs, setValidationMsgs] = useState([]);
  const [phones, setPhones] = useState([defaultPhone]);

  useEffect(() => {
    if (
      initialValue.length >= 1 &&
      initialValue[0].Telefono &&
      initialValue[0].Telefono !== "" &&
      (phones[0].Telefono === "" || phones.length != initialValue.length)
    ) {
      let arr = [];
      initialValue.map((val, idx) => {
        const { Telefono, Extension, Tipo } = val;
        arr.push({
          key: idx,
          Telefono,
          Extension,
          Tipo,
        });
      });
      setPhones(arr);
    }
  }, [initialValue]);

  useEffect(() => {
    if (validate()) {
      let arr = phones.map((phone) => {
        return {
          Telefono: phone.Telefono,
          Extension: phone.Extension,
          Tipo: phone.Tipo,
        };
      });
      onChange(arr);
    }
  }, [phones]);

  const validate = () => {
    let isValid = false;
    const regex = /^\d{10}$/;
    phones.map((phone) => {
      if (phone.Telefono !== "") {
        if (regex.test(phone.Telefono)) {
          const filteredValidations = validationMsgs.filter(
            (val) => val.key !== phone.key
          );
          setValidationMsgs(filteredValidations);
          isValid = true;
        } else {
          let validationMsg = { key: phone.key, msg: "Teléfono no válido" };
          setValidationMsgs([...validationMsgs, validationMsg]);
        }
      } else {
        if (phone.Extension !== "") {
          let validationMsg = {
            key: phone.key,
            msg: "No debe haber una extension sin teléfono",
          };
          setValidationMsgs([...validationMsgs, validationMsg]);
        }
      }
    });

    return isValid;
  };

  const addPhone = () => {
    const lastPhone = phones.at(-1);
    setPhones([
      ...phones,
      { key: lastPhone.key + 1, Telefono: "", Extension: "", Tipo: "O" },
    ]);
  };

  const removePhone = (key) => {
    const filteredPhones = phones.filter((phone) => phone.key !== key);

    setPhones(filteredPhones);
  };

  const updatePhone = (key, propName, propValue) => {
    const newPhones = [...phones];
    const selectedPhone = phones.find((phone) => phone.key === key);
    selectedPhone[propName] = propValue;
    newPhones[key] = selectedPhone;
    setPhones(newPhones);
  };

  return (
    <>
      {phones.map((phone, idx) => (
        <div
          key={`phoneContainer-${idx}`}
          style={{
            width: "100%",
          }}
        >
          <InputGroup
            className="mb-2"
            key={`phoneInput-${phone.key}`}
            id={`phoneInput-${phone.key}`}
          >
            {!hideType && (
              <Form.Control
                as="select"
                key={`phoneType-${phone.key}`}
                name="Tipo"
                value={phone.Tipo}
                style={{
                  width: "25%",
                }}
                onChange={(e) =>
                  updatePhone(phone.key, e.target.name, e.target.value)
                }
              >
                <option value="F">Oficina</option>
                <option value="C">Celular</option>
                <option value="O">Otro</option>
              </Form.Control>
            )}
            <IMaskInput
              key={`phoneTxt1-${phone.key}`}
              mask={"(000) 000-0000"}
              unmask={false}
              placeholder="Telefono"
              className="form-control"
              value={phone.Telefono}
              onAccept={(_, mask) => {
                updatePhone(phone.key, "Telefono", mask.unmaskedValue);
              }}
              style={{
                width: "43%",
              }}
            />
            <IMaskInput
              style={{ width: "12%" }}
              mask={"00000"}
              unmask={false}
              className="form-control"
              placeholder="Ext."
              value={phone.Extension}
              onAccept={(_, mask) => {
                updatePhone(phone.key, "Extension", mask.unmaskedValue);
              }}
              key={`phoneTxt2-${phone.key}`}
            />
            {idx >= 1 && (
              <AnimatedIconButton
                size="40px"
                height="18"
                width="18"
                style={{
                  borderRadius: "0%",
                  paddingLeft: "0px",
                  marginLeft: "0px",
                  paddingTop: "0px",
                  paddingRight: "0px",
                  marginRight: "-1px",
                }}
                iconName="minus"
                onClick={() => removePhone(phone.key)}
              />
            )}
            {(maxCount > idx + 1 || !maxCount) && (
              <AnimatedIconButton
                size="40px"
                height="18"
                width="18"
                style={{
                  borderRadius: "0% 0.3rem 0.3rem 0%",
                  paddingLeft: "0px",
                  marginLeft: "0px",
                  paddingTop: "0px",
                  paddingRight: "0px",
                }}
                iconName="plus"
                onClick={() => addPhone()}
              />
            )}
          </InputGroup>
          {validationMsgs !== [] &&
          validationMsgs.find((val) => val.key === phone.key) !== undefined ? (
            <Form.Text
              key={`validation-msg-${phone.key}`}
              className="text-danger"
              id="validationMsg"
              muted
            >
              {validationMsgs.find((val) => val.key === phone.key).msg}
            </Form.Text>
          ) : null}
        </div>
      ))}
    </>
  );
};
