//Estatus
const ESTATUS_ACTIVO = "A";
const ESTATUS_INACTIVO = "I";
const ESTATUS_BLOQUEADO = "B";
const ESTATUS_ACTIVO_LABEL = "Activa";
const ESTATUS_BLOQUEADO_LABEL = "Bloqueada";
const ESTATUS_INACTIVO_LABEL = "Inactiva";

//Ambiente
const AMBIENTE_PRUEBAS = "T";
const AMBIENTE_PRUEBAS_LABEL = "Prueba";
const AMBIENTE_SIMULACION = "S";
const AMBIENTE_SIMULACION_LABEL = "Simulación";
const AMBIENTE_CERTIFICACION = "C";
const AMBIENTE_CERTIFICACION_LABEL = "Certificación";
const AMBIENTE_PRODUCCION = "P";
const AMBIENTE_PRODUCCION_LABEL = "Producción";

export class Compania {
  static get AMBIENTE_DESC() {
    switch (this.Estatus) {
      case AMBIENTE_PRUEBAS:
        return AMBIENTE_PRUEBAS_LABEL;
      case AMBIENTE_SIMULACION:
        return AMBIENTE_SIMULACION_LABEL;
      case AMBIENTE_CERTIFICACION:
        return AMBIENTE_CERTIFICACION_LABEL;
      case AMBIENTE_PRODUCCION:
        return AMBIENTE_PRODUCCION_LABEL;
    }
  }

  static get AMBIENTE_LIST() {
    return [
      {
        value: AMBIENTE_PRUEBAS,
        label: AMBIENTE_PRUEBAS_LABEL,
      },
      {
        value: AMBIENTE_SIMULACION,
        label: AMBIENTE_SIMULACION_LABEL,
      },
      {
        value: AMBIENTE_CERTIFICACION,
        label: AMBIENTE_CERTIFICACION_LABEL,
      },
      {
        value: AMBIENTE_PRODUCCION,
        label: AMBIENTE_PRODUCCION_LABEL,
      },
    ];
  }

  static get ESTATUS_DESC() {
    switch (this.Estatus) {
      case ESTATUS_ACTIVO:
        return ESTATUS_ACTIVO_LABEL;
      case ESTATUS_INACTIVO:
        return ESTATUS_INACTIVO_LABEL;
      case ESTATUS_BLOQUEADO:
        return ESTATUS_BLOQUEADO_LABEL;
    }
  }
  static get ESTATUS_LIST() {
    return [
      {
        value: ESTATUS_ACTIVO,
        label: ESTATUS_ACTIVO_LABEL,
      },
      {
        value: ESTATUS_INACTIVO,
        label: ESTATUS_INACTIVO_LABEL,
      },
      {
        value: ESTATUS_BLOQUEADO,
        label: ESTATUS_BLOQUEADO_LABEL,
      },
    ];
  }

  static get DEFAULT_ESTATUS_VALUE() {
    return ESTATUS_ACTIVO;
  }
  static get DEFAULT_AMBIENTE_VALUE() {
    return AMBIENTE_PRUEBAS;
  }
}
