import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useMessage from "hooks/useMessage";
import { AnulacionManager } from "managers";
import ToolTip from "components/ToolTip/ToolTip";
import { GridView, SvgComponent } from "components";
import IconButton from "components/IconButton/IconButton";
import useExceptionHandler from "hooks/useExceptionHandler";
import { DEFAULT_PAGINATION_VALUES, URLHelper } from "utils";
import ListHeader from "components/PantallaList/Header/ListHeader";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import { DateHelper } from "utils";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { Simplebar } from "components";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import { InputSidebarContainer } from "components";
import { Row } from "react-bootstrap";

export default function AnulacionesList({ showLoading, hideLoading }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const [showCanvas, setShowCanvas] = useState(false);
  const [currentAnulacion, setCurrentAnulacion] = useState({});

  const history = useHistory();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  const buscar = () => {
    const whereWithPaginationValues = { ...where, ...pagination };
    AnulacionManager.listado(whereWithPaginationValues)
      .then((res) => {
        for (let anulacion of res.Data) {
          anulacion["FechaCreacion"] = DateHelper.formatStringDate(
            anulacion["FechaCreacion"]
          );
        }
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setList(res.Data);
      })
      .catch((error) => handleException(error))
      .finally(() => hideLoading());
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (params) => {
    const { Id } = params;
    const anulacion = list.find((a) => a.IdSec == Id);
    if (anulacion !== undefined) {
      setCurrentAnulacion(anulacion);
      setShowCanvas(true);
    }
  };

  return (
    <>
      <ViewAnulacion
        anulacion={currentAnulacion}
        showCanvas={showCanvas}
        setShowCanvas={setShowCanvas}
      />
      <ListHeader
        titulo="Anulación de e-NCF"
        crearNuevo={() => history.push("/AnulacionesEdit")}
      />
      <GridView
        pagination={pagination}
        setPagination={setPagination}
        allowPaging={pagination.EsPaginable}
        pageSize={pagination.CantidadPorPagina}
        pageIndex={pagination.IndiceDePagina}
        lastPageNumber={pagination.PaginasTotal}
        dataSource={list}
        onPageIndexChanging={onPageIndexChanging}
        where={where}
        setWhere={setWhere}
        height="78vh"
      >
        <GridView.Column
          width="80px"
          showClearFilter={false}
          bodyRenderer={(row) => {
            return (
              <>
                <div className="float-right">
                  <IconButton
                    onClick={() =>
                      handleClick({
                        Id: row.IdSec,
                      })
                    }
                  >
                    <ToolTip message="Ver">
                      <div>
                        <SvgComponent
                          iconName="view"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
              </>
            );
          }}
        />
        <GridView.Column
          headerText="Id"
          dataField="IdSec"
          sortExpression="IdSec"
          width="180px"
        />
        <GridView.Column
          headerText="Fecha"
          dataField="FechaCreacion"
          sortExpression="FechaCreacion"
          width="180px"
        />
        <GridView.Column
          headerText="Cantidad NCF Anulados"
          dataField="CantidadENCFAnulados"
          sortExpression="CantidadENCFAnulados"
          width="180px"
        />
      </GridView>
    </>
  );
}

function ViewAnulacion({ showCanvas, setShowCanvas, anulacion }) {
  const [showModal, setShowModal] = useState(false);
  const [currentDetalle, setCurrentDetalle] = useState({});

  const modal = (
    <ModalContainer
      showModal={showModal}
      size="lg"
      title="Detalles Anulación"
      btnGrabar={{
        show: false,
      }}
      cerrar={() => {
        setShowModal(false);
        setCurrentDetalle({});
      }}
    >
      <EditSection>
        <Row>
          <InputSidebarContainer label="Tipo Comprobante">
            <span>{currentDetalle.TipoComprobanteDesc}</span>
          </InputSidebarContainer>
        </Row>
        {currentDetalle.Secuencias?.map((d) => {
          return (
            <SectionRow>
              <span>
                <strong>Desde</strong>: {d.SecuenciaDesde}
              </span>{" "}
              <span>
                <strong>Hasta</strong>: {d.SecuenciaHasta}
              </span>
            </SectionRow>
          );
        })}
      </EditSection>
    </ModalContainer>
  );

  const handleClick = (params) => {
    const { Id } = params;
    const detalle = anulacion.Detalles?.find((t) => t.IdSec == Id);
    if (detalle !== undefined) {
      setCurrentDetalle(detalle);
      setShowModal(true);
    }
  };

  return (
    <Simplebar
      backdrop={true}
      size="lg"
      placement={"end"}
      portal={false}
      visible={showCanvas}
      setShow={(e) => {
        setShowCanvas(e);
      }}
      useLoading={false}
      title="Anulación de e-NCF"
      btnRight={{
        show: false,
        label: "Grabar",
        func: () => {},
      }}
      btnLeft={{
        show: false,
        label: "Cerrar",
      }}
    >
      {showModal && modal}
      <EditSection title="General">
        <SectionRow>
          <InputSidebarContainer label="Id">
            <span>{anulacion.IdSec}</span>
          </InputSidebarContainer>
          <InputSidebarContainer label="Rnc Emisor">
            <span>{anulacion.RNCEmisor}</span>
          </InputSidebarContainer>
        </SectionRow>
        <SectionRow>
          <InputSidebarContainer label="Fecha">
            <span>{anulacion.FechaCreacion}</span>
          </InputSidebarContainer>
          <InputSidebarContainer label="Cantidad e-NCF Anulados">
            <span>{anulacion.CantidadENCFAnulados}</span>
          </InputSidebarContainer>
        </SectionRow>
      </EditSection>
      <EditSection title="Detalles">
        <GridView
          dataSource={anulacion.Detalles}
          allowPaging={false}
          hideFilters={true}
          height="auto"
        >
          <GridView.Column
            width="80px"
            bodyRenderer={(row) => {
              return (
                <>
                  <div className="float-right">
                    <IconButton
                      onClick={() =>
                        handleClick({
                          Id: row.IdSec,
                        })
                      }
                    >
                      <ToolTip message="Ver">
                        <div>
                          <SvgComponent
                            iconName="view"
                            height="25px"
                            width="25px"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                  </div>
                </>
              );
            }}
          />
          <GridView.Column
            headerText="Tipo Comprobante"
            dataField="TipoComprobanteDesc"
            sortExpression="TipoComprobanteDesc"
            width="auto"
          />
          <GridView.Column
            headerText="Cantidad Secuencias"
            dataField="CantSecuencias"
            sortExpression="CantSecuencias"
            width="auto"
          />
        </GridView>
      </EditSection>
    </Simplebar>
  );
}
