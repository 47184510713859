import { CRM_API, RequestHelper } from "utils";

const RUTA = "firmar-archivo";
export const FirmarArchivoService = (() => {
  const firmar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).postWithFiles(RUTA, data);
    });
  };

  return {
    firmar,
  };
})();
