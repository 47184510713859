import { func } from "prop-types";
import { CRM_API, RequestHelper } from "utils";

const RUTA = "transacciones";
export const TransaccionesService = (() => {
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}`, params);
    });
  };

  const reporte = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/reporte`, params);
    });
  };

  const representacionImpresa = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/${params}/reporte`, params);
    });
  };

  const consultarEstado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/${params}/consultarEstado`,
        params
      );
    });
  };

  const descargarArchivoXML = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/${params}/archivoxml`,
        params,
        true
      );
    });
  };
  const enviarEmail = function (args, correoElectronico) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        reemplazarCaracteres(
          `${RUTA}/${params}/${correoElectronico}/representacionImpresaEmail`
        ),
        params
      );
    });
  };

  const cancelarECF = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}/cancelarECF`, data);
    });
  };

  const descargarArchivoRespuestaXML = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/${params}/archivoxmlrespuesta`,
        params,
        true
      );
    });
  };
  const exportar = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).post(`${RUTA}/excel`, params, true);
    });
  };

  const exportarDetalles = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).post(
        `${RUTA}/excel/detalles`,
        params,
        true
      );
    });
  };

  const GenerarReportesExcel = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/excel/reportes`, params, true);
    });
  };

  const GenerarReportesCSV = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/CSV/reportes`, params, true);
    });
  };

  const reemplazarCaracteres = (str) => {
    const reemplazos = {
      " ": "%20",
      "!": "%21",
      "#": "%23",
      $: "%24",
      "&": "%26",
      "'": "%27",
      "(": "%28",
      ")": "%29",
      "*": "%2A",
      "+": "%2B",
      ",": "%2C",
      "/": "%2F",
      ":": "%3A",
      ";": "%3B",
      "=": "%3D",
      "?": "%3F",
      "@": "%40",
      "[": "%5B",
      "]": "%5D",
      '"': "%22",
      "-": "%2D",
      ".": "%2E",
      "<": "%3C",
      ">": "%3E",
      "\\": "%5C",
      "^": "%5E",
      _: "%5F",
      "`": "%60",
    };

    return str.replace(
      /[ !"#$&'()*+,-./:;=?@[\\\]^_`]/g,
      (match) => reemplazos[match]
    );
  };

  return {
    listado,
    reporte,
    representacionImpresa,
    exportar,
    exportarDetalles,
    descargarArchivoXML,
    descargarArchivoRespuestaXML,
    enviarEmail,
    GenerarReportesExcel,
    GenerarReportesCSV,
    consultarEstado,
    cancelarECF,
  };
})();
