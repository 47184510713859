import { CRM_API, RequestHelper } from "utils";

const RUTA = "app";
export const AppService = (() => {
  const refreshAppConfiguration = () => {
    return RequestHelper(CRM_API).get(`${RUTA}`);
  };

  const reportarError = (reporte) => {
    return RequestHelper(CRM_API).post(`${RUTA}`, reporte);
  };

  return {
    refreshAppConfiguration,
    reportarError,
  };
})();
