import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
import { SvgComponent } from "components";
import useExceptionHandler from "hooks/useExceptionHandler";
import { useAuthentication } from "hooks";
import { Permisos } from "utils/permisos";
import {
  ChartWrapper,
  ComprobantesVentasChart,
  ComprobantesComprasChart,
  ComprobantesVentasBarsChart,
  ComprobantesComprasBarsChart,
} from "components/Graficos";
import { ComprobantesGaugeChart } from "components/Graficos/Charts/ComprobantesGaugeChart";
import TablaClientes from "components/Tablas/Clientes/TablaClientes";
import TablaCompras from "components/Tablas/Ventas/TablaCompras";
import TablaTop from "components/Tablas/TablaTop";
import TablaTopProveedores from "components/Tablas/TablaTopProveedores";
import TablaBottomProveedores from "components/Tablas/TablaButtonProveedores";
import TablaBottom from "components/Tablas/TablaBottom";
import { CompaniaManager } from "managers";
import { DateHelper } from "utils";
import { StringHelper } from "utils";

export function Dashboard() {
  const [showCanvasVentas, setShowCanvasVentas] = useState(false);
  const [showCanvasCompras, setShowCanvasCompras] = useState(false);

  const [showCanvasVentasTop, setShowCanvasVentasTop] = useState(false);
  const [showCanvasComprasTop, setShowCanvasComprasTop] = useState(false);
  const [showCanvasVentasBottom, setShowCanvasVentasBottom] = useState(false);
  const [showCanvasComprasBottom, setShowCanvasComprasBottom] = useState(false);
  const [informacionCompania, setInformacionCompania] = useState({});
  const { usuario, tienePermiso } = useAuthentication();
  const { handleException } = useExceptionHandler();

  const componentSizes = {
    width: "45vw",
    height: "50vh",
    iconWidth: "4rem",
    iconHeight: "3rem",
  };

  useEffect(() => {
    if (tienePermiso(Permisos.Graficos.VER_GRAFICOS)) {
      buscar();
    }
  }, []);

  function buscar() {
    CompaniaManager.listadoDatosCertificado()
      .then((res) => {
        setInformacionCompania(res.Data);
      })
      .catch((err) => handleException(err));
  }

  let estilo = "";

  const unMesAntes = new Date();
  unMesAntes.setMonth(unMesAntes.getMonth() + 1);
  const unaSemanaAntes = new Date();
  unaSemanaAntes.setDate(unaSemanaAntes.getDate() + 7);
  if (informacionCompania?.FechaExpiracionCertificado <= unMesAntes) {
    estilo = "rgb(249 101 2 / 73%)";
  } else if (
    informacionCompania?.FechaExpiracionCertificado <= unaSemanaAntes
  ) {
    estilo = "red";
  } else {
    estilo = "green";
  }

  return (
    <Container
      id="section-container"
      className="pt-3 pb-5"
      fluid
      style={{
        overflowY: "scroll",
        maxHeight: "100vh",
      }}
    >
      <Row id="dashboard-row0" key="dashboard-row0">
        <Col lg="1"></Col>
        {tienePermiso(Permisos.Graficos.CARD_VENTAS_MES) && (
          <Col lg="2" md="8" sm="8">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <SvgComponent
                        iconName="cotization"
                        height={componentSizes.iconHeight}
                        width={componentSizes.iconWidth}
                        fill="rgb(6, 103, 166)"
                      />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Ventas</p>
                      <Card.Title as="h5">
                        {informacionCompania?.CantidadDocumentoVenta}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Documentos de este mes
                </div>
              </Card.Footer>
            </Card>
          </Col>
        )}

        {tienePermiso(Permisos.Graficos.CARD_TOTAL_VENTAS_MES) && (
          <Col lg="2" md="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <SvgComponent
                        iconName="coin"
                        height={componentSizes.iconHeight}
                        width={componentSizes.iconWidth}
                        fill="rgb(241, 188, 9)"
                      />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Ventas</p>
                      <Card.Title as="h6" className="font-weight-bold">
                        {StringHelper.formatCurrency(
                          informacionCompania?.MontoTotalVentas
                        )}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Monto Total de este mes
                </div>
              </Card.Footer>
            </Card>
          </Col>
        )}
        {tienePermiso(Permisos.Graficos.CARD_COMPRAS_MES) && (
          <Col lg="2" md="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <SvgComponent
                        iconName="cotization"
                        height={componentSizes.iconHeight}
                        width={componentSizes.iconWidth}
                        fill="rgb(6, 103, 166)"
                      />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Compras</p>
                      <Card.Title as="h5">
                        {informacionCompania?.CantidadDocumentoCompra}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Documentos de este mes
                </div>
              </Card.Footer>
            </Card>
          </Col>
        )}
        {tienePermiso(Permisos.Graficos.CARD_TOTAL_COMPRAS_MES) && (
          <Col lg="2" md="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning text-warning">
                      <SvgComponent
                        iconName="coin"
                        height={componentSizes.iconHeight}
                        width={componentSizes.iconWidth}
                        fill="rgb(241, 188, 9)"
                      />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Compras</p>
                      <Card.Title as="h6" className="font-weight-bold">
                        {StringHelper.formatCurrency(
                          informacionCompania?.MontoTotalCompras
                        )}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Monto Total de este mes
                </div>
              </Card.Footer>
            </Card>
          </Col>
        )}
        {tienePermiso(Permisos.Graficos.CARD_INFORMACION_CERTIFICADO) && (
          <Col lg="2" md="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning text-warning">
                      <SvgComponent
                        style={{ fill: estilo }}
                        iconName="timerunningout"
                        height={componentSizes.iconHeight}
                        width={componentSizes.iconWidth}
                      />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Fecha Expiración</p>
                      <Card.Title as="h5">
                        {DateHelper.formatStringDate(
                          informacionCompania?.FechaExpiracionCertificado,
                          false,
                          false
                        )}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fa-regular fa-clock mr-1"></i>
                  Certificado Digital
                </div>
              </Card.Footer>
            </Card>
          </Col>
        )}
        {tienePermiso(Permisos.Graficos.TABLA_VENTAS) && (
          <Col>
            <TablaClientes
              title="Ventas"
              showCanvas={showCanvasVentas}
              setShowCanvas={setShowCanvasVentas}
            ></TablaClientes>
          </Col>
        )}
        {tienePermiso(Permisos.Graficos.TABLA_COMPRAS) && (
          <Col>
            <TablaCompras
              title="Compras"
              showCanvas={showCanvasCompras}
              setShowCanvas={setShowCanvasCompras}
            ></TablaCompras>
          </Col>
        )}
      </Row>
      <Row id="dashboard-row1" key="dashboard-row1" className="dragRow">
        {tienePermiso(Permisos.Graficos.GRAFICO_PIE_VENTAS) && (
          <Col>
            <ChartWrapper
              width={componentSizes.width}
              height={componentSizes.height}
              title="Comprobantes de Ventas"
              Chart={ComprobantesVentasChart}
            />
          </Col>
        )}
        {tienePermiso(Permisos.Graficos.GRAFICO_PIE_COMPRAS) && (
          <Col>
            <ChartWrapper
              width={componentSizes.width}
              height={componentSizes.height}
              title="Comprobantes de Compras"
              Chart={ComprobantesComprasChart}
            />
          </Col>
        )}
      </Row>
      <Row id="dashboard-row2" key="dashboard-row2" className="dragRow">
        {tienePermiso(Permisos.Graficos.GRAFICO_VENTAS) && (
          <Col>
            <ChartWrapper
              width={componentSizes.width}
              height={componentSizes.height}
              title="Comprobantes de Ventas"
              Chart={ComprobantesVentasBarsChart}
            />
          </Col>
        )}

        {tienePermiso(Permisos.Graficos.GRAFICO_COMPRAS) && (
          <Col>
            <ChartWrapper
              width={componentSizes.width}
              height={componentSizes.height}
              title="Comprobantes de Compras"
              Chart={ComprobantesComprasBarsChart}
            />
          </Col>
        )}
      </Row>
      <Row id="dashboard-row3" key="dashboard-row3" className="dragRow">
        {tienePermiso(Permisos.Graficos.GRAFICO_TRANSACCIONES_REALIZADAS) && (
          <Col>
            <ChartWrapper
              width="45vw"
              height="60vh"
              title="Transacciones Realizadas"
              Chart={ComprobantesGaugeChart}
              noFilter={true}
            />
          </Col>
        )}
      </Row>

      <Row id="dashboard-row4" key="dashboard-row4" className="dragRow">
        {tienePermiso(Permisos.Graficos.GRAFICO_TOP_10_CLIENTES) && (
          <Col>
            <TablaTop
              title="Top 10 Clientes"
              showCanvas={showCanvasVentasTop}
              setShowCanvas={setShowCanvasVentasTop}
            ></TablaTop>
          </Col>
        )}

        {tienePermiso(Permisos.Graficos.GRAFICO_BOTTOM_10_CLIENTES) && (
          <Col>
            <TablaBottom
              title="Bottom 10 Clientes"
              showCanvas={showCanvasVentasBottom}
              setShowCanvas={setShowCanvasVentasBottom}
            ></TablaBottom>
          </Col>
        )}

        {tienePermiso(Permisos.Graficos.GRAFICO_TOP_10_PROVEDORES) && (
          <Col>
            <TablaTopProveedores
              title="Top 10 Proveedores"
              showCanvas={showCanvasComprasTop}
              setShowCanvas={setShowCanvasComprasTop}
            ></TablaTopProveedores>
          </Col>
        )}
        {tienePermiso(Permisos.Graficos.GRAFICO_BOTTOM_10_PROVEDORES) && (
          <Col>
            <TablaBottomProveedores
              title="Bottom 10 Proveedores"
              showCanvas={showCanvasComprasBottom}
              setShowCanvas={setShowCanvasComprasBottom}
            ></TablaBottomProveedores>
          </Col>
        )}
      </Row>
    </Container>
  );
}
