import React, { useReducer } from "react";
import { MessageContext } from "contexts";
import { createPortal } from "react-dom";
import Notifications from "components/Notificactions/Notification";
import { useContext } from "react";
import uuid from "react-uuid";

export const ADD = "ADD";
export const REMOVE = "REMOVE";
export const REMOVE_ALL = "REMOVE_ALL";
export const CLOSE = "CLOSE";
const initialState = [];

export const messageReducer = (state, action) => {
  switch (action.type) {
    case ADD:
      return [
        ...state,
        {
          id: uuid(),
          content: action.payload.content,
          type: action.payload.type,
        },
      ];
    case REMOVE:
      return state.filter((t) => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    case CLOSE:
      return [
        ...state,
        {
          id: uuid(),
          type: "CLOSE",
        },
      ];
    default:
      return state;
  }
};

export function MessageProvider({ children }) {
  const [message, messageDispatch] = useReducer(messageReducer, initialState);
  const messageData = { message, messageDispatch };

  return (
    <MessageContext.Provider value={messageData}>
      {children}
      {createPortal(<Notifications messageOptions={message} />, document.body)}
    </MessageContext.Provider>
  );
}

export const useMessageContext = () => {
  return useContext(MessageContext);
};
