import React, { Children } from "react";

import { Row, Col } from "react-bootstrap";

function SectionRow({
  children,
  colspan,
  multilinea = false,
  triple = false,
  style = {},
}) {
  return (
    <Row
      style={{
        ...style,
        flexDirection: multilinea ? "column" : "row",
        alignItems: "center",
      }}
    >
      {Children.map(children, (child, i) => {
        return (
          <Col key={i} md={multilinea ? 12 : (triple ? 4 : 6) * (colspan || 1)}>
            {child}
          </Col>
        );
      })}
    </Row>
  );
}

export default SectionRow;
