import { ExportarService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const ExportarManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const exportar = (where) => {
    return service.exportar(where).then((res) => {
      return res;
    });
  };
  const exportarDetalles = (where) => {
    return service.exportarDetalles(where).then((res) => {
      return res;
    });
  };

  return {
    exportar,
    exportarDetalles,
  };
})();
