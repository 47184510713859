import React, { useState, useEffect } from "react";
import "./SettingsButton.styles.scss";
import { Dropdown, Nav } from "react-bootstrap";
import ToolTip from "components/ToolTip/ToolTip";
import { useAuthentication } from "hooks";

import CambiarContraseniaModal from "components/Model/CambiarContraseniaModal";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import EntidadesList from "views/Entidades/EntidadesList";
import { useConfigurationPage } from "hooks";
import { Permisos } from "utils/permisos";

const SettingsButton = () => {
  const { logout, usuario: user, tienePermiso } = useAuthentication();
  const { setConfigurationState, getConfigurationState } =
    useConfigurationPage();
  const [showCanvas, setShowCanvas] = useState(false);
  const [mostrarModalCambiarContrasenia, setMostrarModalCambiarContrasenia] =
    useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCanvas(getConfigurationState() === "true");
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const openInNewTab = (url) => {
    const win = window.open(url, "_blank", "noopener,noreferrer");
    win.focus();
  };

  const cerrarSesion = () => {
    logout();
  };

  const cambiarContrasenia = () => {
    setMostrarModalCambiarContrasenia(true);
  };
  return (
    <>
      {mostrarModalCambiarContrasenia && (
        <CambiarContraseniaModal
          setMostrarModalCambiarContrasenia={setMostrarModalCambiarContrasenia}
          mostrarModalCambiarContrasenia={mostrarModalCambiarContrasenia}
          user={user}
        ></CambiarContraseniaModal>
      )}
      {showCanvas && (
        <EntidadesList showCanvas={showCanvas} setShowCanvas={setShowCanvas} />
      )}
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle
          as={Nav.Link}
          id="dropdown-41471887333"
          style={{ fontSize: "20px" }}
          className="buttonHover"
        >
          <ToolTip placement="left" message="Configuración">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 24 24"
              fill="#77777A"
            >
              <path
                d="M24 14.187v-4.374c-2.148-.766-2.726-.802-3.027-1.529-.303-.729.083-1.169 1.059-3.223l-3.093-3.093c-2.026.963-2.488
               1.364-3.224 1.059-.727-.302-.768-.889-1.527-3.027h-4.375c-.764 2.144-.8 2.725-1.529 3.027-.752.313-1.203-.1-3.223-1.059l-3.093
                3.093c.977 2.055 1.362 2.493 1.059 3.224-.302.727-.881.764-3.027 1.528v4.375c2.139.76 2.725.8 3.027 1.528.304.734-.081 1.167-1.059
                 3.223l3.093 3.093c1.999-.95 2.47-1.373 3.223-1.059.728.302.764.88 1.529 3.027h4.374c.758-2.131.799-2.723 1.537-3.031.745-.308
                  1.186.099 3.215 1.062l3.093-3.093c-.975-2.05-1.362-2.492-1.059-3.223.3-.726.88-.763 3.027-1.528zm-4.875.764c-.577 1.394-.068
                   2.458.488 3.578l-1.084 1.084c-1.093-.543-2.161-1.076-3.573-.49-1.396.581-1.79
                    1.693-2.188 2.877h-1.534c-.398-1.185-.791-2.297-2.183-2.875-1.419-.588-2.507-.045-3.579.488l-1.083-1.084c.557-1.118
                     1.066-2.18.487-3.58-.579-1.391-1.691-1.784-2.876-2.182v-1.533c1.185-.398 2.297-.791 2.875-2.184.578-1.394.068-2.459-.488-3.579l1.084-1.084c1.082.538
                      2.162 1.077 3.58.488 1.392-.577 1.785-1.69 2.183-2.875h1.534c.398 1.185.792 2.297 2.184 2.875 1.419.588 2.506.045 3.579-.488l1.084 1.084c-.556
                       1.121-1.065 2.187-.488 3.58.577 1.391 1.689 1.784 2.875 2.183v1.534c-1.188.398-2.302.791-2.877 2.183zm-7.125-5.951c1.654 0 3 1.346 3 3s-1.346
                        3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.762 0-5 2.238-5 5s2.238 5 5 5 5-2.238 5-5-2.238-5-5-5z"
              />
            </svg>
          </ToolTip>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight aria-labelledby="navbarDropdownMenuLink">
          {tienePermiso(Permisos.Seguridad.CONFIGURACION_LISTAR) && (
            <Dropdown.Item
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setConfigurationState(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                style={{ marginRight: "8px" }}
              >
                <path
                  d="M24 14.187v-4.374c-2.148-.766-2.726-.802-3.027-1.529-.303-.729.083-1.169 1.059-3.223l-3.093-3.093c-2.026.963-2.488
               1.364-3.224 1.059-.727-.302-.768-.889-1.527-3.027h-4.375c-.764 2.144-.8 2.725-1.529 3.027-.752.313-1.203-.1-3.223-1.059l-3.093
                3.093c.977 2.055 1.362 2.493 1.059 3.224-.302.727-.881.764-3.027 1.528v4.375c2.139.76 2.725.8 3.027 1.528.304.734-.081 1.167-1.059
                 3.223l3.093 3.093c1.999-.95 2.47-1.373 3.223-1.059.728.302.764.88 1.529 3.027h4.374c.758-2.131.799-2.723 1.537-3.031.745-.308
                  1.186.099 3.215 1.062l3.093-3.093c-.975-2.05-1.362-2.492-1.059-3.223.3-.726.88-.763 3.027-1.528zm-4.875.764c-.577 1.394-.068
                   2.458.488 3.578l-1.084 1.084c-1.093-.543-2.161-1.076-3.573-.49-1.396.581-1.79
                    1.693-2.188 2.877h-1.534c-.398-1.185-.791-2.297-2.183-2.875-1.419-.588-2.507-.045-3.579.488l-1.083-1.084c.557-1.118
                     1.066-2.18.487-3.58-.579-1.391-1.691-1.784-2.876-2.182v-1.533c1.185-.398 2.297-.791 2.875-2.184.578-1.394.068-2.459-.488-3.579l1.084-1.084c1.082.538
                      2.162 1.077 3.58.488 1.392-.577 1.785-1.69 2.183-2.875h1.534c.398 1.185.792 2.297 2.184 2.875 1.419.588 2.506.045 3.579-.488l1.084 1.084c-.556
                       1.121-1.065 2.187-.488 3.58.577 1.391 1.689 1.784 2.875 2.183v1.534c-1.188.398-2.302.791-2.877 2.183zm-7.125-5.951c1.654 0 3 1.346 3 3s-1.346
                        3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.762 0-5 2.238-5 5s2.238 5 5 5 5-2.238 5-5-2.238-5-5-5z"
                />
              </svg>
              Configuración
            </Dropdown.Item>
          )}

          <Dropdown.Item
            href="#"
            onClick={(e) => {
              e.preventDefault();
              cambiarContrasenia();
            }}
          >
            <SvgComponent
              height="20"
              width="20"
              iconName="lock"
              fill="#2f2f2f"
              style={{ marginRight: "8px" }}
            />
            Cambiar Contraseña
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openInNewTab("https://api.whatsapp.com/send?phone=18095488274");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style={{ marginRight: "8px" }}
            >
              <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
            </svg>
            Soporte WhatsApp
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openInNewTab("http://test.ecf.citrus.com.do/docs.html");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style={{ marginRight: "8px" }}
            >
              <path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" />
            </svg>
            Documentación
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => {
              cerrarSesion();
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px" }}
            >
              <path d="M11 21h8v-2l1-1v4h-9v2l-10-3v-18l10-3v2h9v5l-1-1v-3h-8v18zm10.053-9l-3.293-3.293.707-.707 4.5 4.5-4.5 4.5-.707-.707 3.293-3.293h-9.053v-1h9.053z" />
            </svg>
            Cerrar Sesión
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SettingsButton;
