import React, { useEffect } from "react";
import { Card, Dropdown, Nav } from "react-bootstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "hooks";
import ToolTip from "components/ToolTip/ToolTip";
import IconButton from "components/IconButton/IconButton";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";
import { SvgComponent } from "components/SvgComponent/SvgComponent";

function ListHeader(props) {
  const { theme } = useTheme();

  useEffect(() => {
    window.addEventListener("keydown", onEvent);
    return () => {
      window.removeEventListener("keydown", onEvent);
    };
  });

  function onEvent(e) {
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyS") {
      if (props.grabar) {
        e.preventDefault();
        props.grabar();
      }
    }
  }
  return (
    <div
      style={{
        position: `sticky`,
        top: props.top ? props.top : theme.MAX_HEIGHT_HEADER,
        maxHeight: "100px",
        zIndex: 200,
        display: props.esModal ? "none" : "",
      }}
    >
      <Card.Header
        style={{
          background: "white",
          padding: "0.3em 1em 0.3em 1em",
        }}
      >
        <div className="d-flex justify-content-between">
          <div
            className="w-50"
            style={{
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            {props.volver && (
              <IconButton onClick={props.volver}>
                <ToolTip message="Volver">
                  <ChevronLeft color={theme.color1} size={30} />
                </ToolTip>
              </IconButton>
            )}
            <Card.Title as="h4" className="d-inline font-weight-bold">
              {props.titulo}
            </Card.Title>
          </div>
          <div className="d-flex w-25 justify-content-end">
            {props.cambiarVista && (
              <>
                <Dropdown
                  as={Nav.Link}
                  style={{
                    display: "inline-block",
                    padding: 0,
                    padding: "1px 6px",
                  }}
                >
                  <Dropdown.Toggle
                    as={Nav.Link}
                    id="dd-changeView"
                    style={{
                      color: theme.color1,
                      display: "inline-block",
                      padding: 0,
                    }}
                  >
                    <AnimatedIconButton
                      size="38px"
                      height="20px"
                      width="20px"
                      iconName="see-views"
                      message="Cambiar Vista"
                      onClick={props.filtrar}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    alignRight
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {props.cambiarVista.links.map((link) => {
                      return (
                        <Dropdown.Item onClick={link.onClick} key={uuidv4()}>
                          <SvgComponent
                            height="20"
                            width="20"
                            iconName="kanban"
                            fill="black"
                            style={{ marginRight: "10px" }}
                          />

                          {link.title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
            {props.filtrar && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="filter"
                message="Filtrar"
                onClick={props.filtrar}
              />
            )}
            {props.exportar && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="export"
                message="Exportar a Excel"
                onClick={props.exportar}
              />
            )}
            {props.openExportarDetalles && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="taxes"
                message="Exportar Detalles a Excel"
                onClick={props.openExportarDetalles}
              />
            )}
            {props.importar && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="import"
                message="Importar"
                onClick={props.importar}
              />
            )}
            {props.crearNuevo && (
              <AnimatedIconButton
                onClick={props.crearNuevo}
                iconName="create"
                message="Crear"
                size="38px"
                height="20px"
                width="20px"
              />
            )}
            {props.descargar && (
              <AnimatedIconButton
                onClick={props.descargar}
                iconName="download"
                message={props.mensajeDescarga}
                size="38px"
                height="20px"
                width="20px"
                placement={"left"}
              />
            )}
            {props.archivoComplementario && (
              <AnimatedIconButton
                onClick={props.archivoComplementario}
                iconName="download"
                message={props.mensajeArchivoComplementario}
                size="38px"
                height="20px"
                width="20pxs"
                placement={"left"}
              />
            )}
            {props.reporte && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="print"
                message="Reporte"
                onClick={props.reporte}
              />
            )}
          </div>
        </div>
      </Card.Header>
    </div>
  );
}

export default ListHeader;
