import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Select from "react-select";

import useMessage from "hooks/useMessage";
import ToolTip from "components/ToolTip/ToolTip";
import { GridView, SvgComponent } from "components";
import IconButton from "components/IconButton/IconButton";
import useExceptionHandler from "hooks/useExceptionHandler";
import { AprobacionComercialManager as manager } from "managers";
import ListHeader from "components/PantallaList/Header/ListHeader";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import { StringHelper, DateHelper, DEFAULT_PAGINATION_VALUES } from "utils";

export default function AprobacionComercialList({ showLoading, hideLoading }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);

  const history = useHistory();
  const { cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();

  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    { value: "N", label: "Nuevo" },
    { value: "A", label: "Aprobado por la DGII" },
    { value: "R", label: "Rechazado por la DGII" },
    { value: "D", label: "Documento Generado" },
  ];

  const ESTADO_LIST = [
    { value: "", label: "Todos" },
    { value: "A", label: "Aprobado" },
    { value: "R", label: "Rechazado" },
  ];

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  function buscar() {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
    };

    manager
      .listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        try {
          for (let aprobacion of res.Data) {
            aprobacion["FechaEmision"] = DateHelper.formatStringDate(
              aprobacion["FechaEmision"]
            );
            aprobacion["RNCEmisor"] = StringHelper.formatRNC(
              aprobacion["RNCEmisor"]
            );
            aprobacion["MontoTotal"] = StringHelper.formatCurrency(
              aprobacion["MontoTotal"]
            );
          }
        } catch (err) {}
        setList(res.Data);
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());
  }

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (params) => {
    const { Id } = params;
    history.push(`/AprobacionComercialEdit?Id=${Id}`);
  };

  return (
    <>
      <ListHeader
        titulo="Aprobación Comercial"
        crearNuevo={() => history.push("/AprobacionComercialEdit")}
      />
      <GridView
        pagination={pagination}
        setPagination={setPagination}
        allowPaging={pagination.EsPaginable}
        pageSize={pagination.CantidadPorPagina}
        pageIndex={pagination.IndiceDePagina}
        lastPageNumber={pagination.PaginasTotal}
        dataSource={list}
        onPageIndexChanging={onPageIndexChanging}
        where={where}
        setWhere={setWhere}
        height="78vh"
      >
        <GridView.Column
          width="80px"
          showClearFilter={true}
          bodyRenderer={(row) => {
            return (
              <>
                <div className="float-right">
                  <IconButton
                    onClick={() =>
                      handleClick({
                        Id: row.IdSec,
                      })
                    }
                  >
                    <ToolTip message="Ver">
                      <div>
                        <SvgComponent
                          iconName="view"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
              </>
            );
          }}
        />
        <GridView.Column
          headerText="Id"
          dataField="IdSec"
          sortExpression="IdSec"
          width="75px"
        />
        <GridView.Column
          headerText="Emisor"
          dataField="NombreComercial"
          sortExpression="NombreComercial"
        />
        <GridView.Column
          headerText="RNC Emisor"
          dataField="RNCEmisor"
          sortExpression="RNCEmisor"
        />
        <GridView.Column
          headerText="Tipo Comprobante"
          dataField="TipoeCFDesc"
          sortExpression="TipoeCF"
        />
        <GridView.Column
          headerText="E-CF"
          dataField="eNCF"
          sortExpression="eNCF"
        />
        <GridView.Column
          headerText="Fecha Emisión"
          dataField="FechaEmision"
          sortExpression="FechaEmision"
          hideFilters
          isDisabled
        />
        <GridView.Column
          headerText="Monto Total"
          dataField="MontoTotal"
          sortExpression="MontoTotal"
        />
        <GridView.Column
          headerText="Estado"
          dataField="EstadoDesc"
          sortExpression="Estado"
          width="150px"
          filterRenderer={() => {
            return (
              <Select
                className="react-select primary"
                options={ESTADO_LIST}
                value={ESTADO_LIST.filter(function (option) {
                  return (
                    option.value ===
                    (where.Estado ? where.Estado : ESTATUS_LIST[0].value)
                  );
                })}
                name="Estado"
                onChange={(e) =>
                  setWhere({
                    ...where,
                    Estado: e.value,
                  })
                }
              />
            );
          }}
        />
        <GridView.Column
          headerText="Estatus"
          dataField="EstatusDesc"
          sortExpression="Estatus"
          width="150px"
          filterRenderer={() => {
            return (
              <Select
                className="react-select primary"
                options={ESTATUS_LIST}
                value={ESTATUS_LIST.filter(function (option) {
                  return (
                    option.value ===
                    (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                  );
                })}
                name="Estatus"
                onChange={(e) =>
                  setWhere({
                    ...where,
                    Estatus: e.value,
                  })
                }
              />
            );
          }}
        />
      </GridView>
    </>
  );
}
