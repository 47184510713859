import { PerfilService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const PerfilManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const buscarPorId = (Id, esLookup) => {
    return PerfilService.buscarPorId(Id, esLookup).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    return PerfilService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const crear = (usuario) => {
    return PerfilService.crear(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (usuario) => {
    return PerfilService.actualizar(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    buscarPorId,
    listado,
    crear,
    actualizar,
  };
})();
