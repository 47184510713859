import { FirmarArchivoService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const FirmarArchivoManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const firmar = (data) => {
    return service
      .firmar({
        ...data,
        Files: data.File,
      })
      .then((res) => {
        if (!res.Success) {
          handleException(res);
        }
        return res.Data;
      });
  };

  return {
    firmar,
  };
})();
