import { CRM_API, RequestHelper } from "utils";

const RUTA = "archivo";
export const ArchivosService = (() => {
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}`, params);
    });
  };

  const exportar = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).post(`${RUTA}/excel`, params, true);
    });
  };

  const cargarArchivo = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).postWithFiles(RUTA, data);
    });
  };

  const descargarArchivo = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/${params}/descargarArchivo`,
        params,
        true
      );
    });
  };
  const descargarPlantilla = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/${params}/descargarPlantilla`,
        params,
        true
      );
    });
  };
  return {
    listado,
    exportar,
    cargarArchivo,
    descargarArchivo,
    descargarPlantilla,
  };
})();
