import { useTheme } from "hooks";
import React, { useEffect } from "react";

// react-bootstrap components
import { Navbar, Container } from "react-bootstrap";
import SettingsButton from "./SettingsButton";
import { SupportButton } from "./SupportButton";
import { SwitchCompanyButton } from "components/SwitchCompanyButton/SwitchCompanyButton";
import { useAuthentication } from "hooks";
import { useState } from "react";

function AdminNavbar() {
  const { theme } = useTheme();
  const { usuario } = useAuthentication();

  useEffect(() => {
    document.body.classList.add("sidebar-mini");
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        style={{
          position: `sticky`,
          top: `0`,
          zIndex: 201,
          height: `${theme.MAX_HEIGHT_HEADER}`,
          maxHeight: `${theme.MAX_HEIGHT_HEADER}`,
          minHeight: `${theme.MAX_HEIGHT_HEADER}`,
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <Container
          fluid
          className="d-flex justify-content-end"
          style={{ paddingLeft: "1em" }}
        >
          {usuario && usuario.PermitirCambioCompania && <SwitchCompanyButton />}
          <SupportButton />
          <SettingsButton />
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
