import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";
import { Col, FormLabel, Row } from "react-bootstrap";
import moment from "moment";
import "./DateTimePicker.styles.scss";

export function DateTimePicker({
  esRangoFechas = false,
  usarLabel = true,
  fechaInicioOriginal,
  fechaFinOriginal,
  labelFechaInicio = "Fecha",
  labelFechaFin = "Fecha Fin",
  onChange,
  isDisabled = false,
  soloFecha,
  esGrid = false,
  required = false,
}) {
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [validationMsg, setValidationMsg] = useState("");
  const [updateNeeded, setUpdateNeeded] = useState({});

  const isToday = (momentToCheck) => {
    const today = new Date();
    const dateToCheck = momentToCheck.toDate();
    return (
      dateToCheck.getDate() === today.getDate() &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear()
    );
  };

  const renderDay = (props, currentDate, selectedDate) => {
    if (isToday(currentDate)) {
      props.className += " today";
    }
    return <td {...props}>{currentDate.date()}</td>;
  };

  useEffect(() => {
    if (
      (fechaInicioOriginal && fechaInicio != fechaInicioOriginal) ||
      (esRangoFechas && fechaFin != fechaFinOriginal)
    ) {
      if (fechaInicioOriginal != null && fechaInicioOriginal != undefined) {
        if (typeof fechaInicioOriginal === "string") {
          let newInicio = new Date(fechaInicioOriginal);
          setFechaInicio(newInicio);
        } else {
          setFechaInicio(fechaInicioOriginal);
        }
      }

      if (fechaFinOriginal != null && fechaFinOriginal != undefined) {
        if (typeof fechaFinOriginal === "string") {
          let newFin = new Date(fechaFinOriginal);
          setFechaFin(newFin);
        } else {
          setFechaFin(fechaFinOriginal);
        }
      }
    }
  }, [fechaInicioOriginal, fechaFinOriginal]);

  useEffect(() => {
    handleChange();
  }, [updateNeeded]);

  const handleFechaInicioChange = (fecha) => {
    const dateIsNotValidMsg = `La fecha proporcionada en ${labelFechaInicio} no es válida.`;
    if (typeof fecha === "object") {
      if (fecha != undefined && fecha != null && fecha.isValid()) {
        setValidationMsg("");
        setFechaInicio(fecha.toDate());
      } else {
        setValidationMsg(dateIsNotValidMsg);
      }
    } else if (typeof fecha === "string") {
      let dateFromString = getDateFromString(fecha);
      if (Object.prototype.toString.call(dateFromString) === "[object Date]") {
        setValidationMsg("");
        setFechaInicio(dateFromString);
      } else {
        setValidationMsg(dateIsNotValidMsg);
      }
    }
    setUpdateNeeded({});
  };

  const handleFechaFinChange = (fecha) => {
    const dateIsNotValidMsg = `La fecha proporcionada en ${labelFechaFin} no es válida.`;
    if (typeof fecha === "object") {
      if (fecha != undefined && fecha != null && fecha.isValid()) {
        setValidationMsg("");
        setFechaFin(fecha.toDate());
      } else {
        setValidationMsg(dateIsNotValidMsg);
      }
    } else if (typeof fecha === "string") {
      let dateFromString = getDateFromString(fecha);
      if (Object.prototype.toString.call(dateFromString) === "[object Date]") {
        setValidationMsg("");
        setFechaFin(dateFromString);
      } else {
        setValidationMsg(dateIsNotValidMsg);
      }
    }
    setUpdateNeeded({});
  };

  function handleChange() {
    if (
      esRangoFechas &&
      fechaFin &&
      fechaInicio &&
      fechaInicio.toDateString() == fechaFin.toDateString() &&
      fechaFin.getHours() < fechaInicio.getHours()
    ) {
      setValidationMsg(
        `La hora ${labelFechaInicio} no puede ser mayor que la hora ${labelFechaFin}`
      );
    } else setValidationMsg("");
    if (validationMsg === "") onChange({ fechaInicio, fechaFin });
  }

  function getDateFromString(value) {
    let dateParts = value.split("/");
    let date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
    return date;
  }

  return (
    <>
      <Row className="text-center">
        <div
          className={esRangoFechas ? "col-xl-6" : "col-xl-12"}
          style={esGrid && esRangoFechas ? { paddingRight: "1px" } : {}}
        >
          <ReactDatetime
            renderDay={renderDay}
            value={
              !fechaInicio || fechaInicio == "Invalid Date" ? "" : fechaInicio
            }
            inputProps={{
              className: `form-control ${
                required ? "required-datetimepicker" : ""
              } ${!isDisabled ? "dateTimePickerStyle" : ""}`,
              placeholder: !usarLabel ? labelFechaInicio : "",
              disabled: isDisabled,
              readOnly: true,
            }}
            onChange={(e) => handleFechaInicioChange(e)}
            timeFormat={!soloFecha}
            dateFormat="DD/MM/yyyy"
          />
        </div>

        {esRangoFechas ? (
          <div
            className="col-xl-6"
            style={esGrid && esRangoFechas ? { paddingLeft: "1px" } : {}}
          >
            <ReactDatetime
              renderDay={renderDay}
              value={!fechaFin || fechaFin == "Invalid Date" ? "" : fechaFin}
              inputProps={{
                className: `form-control ${
                  required ? "required-datetimepicker" : ""
                } ${!isDisabled ? "dateTimePickerStyle" : ""} `,
                placeholder: !usarLabel ? labelFechaFin : "",
                disabled: isDisabled,
                readOnly: true,
              }}
              onChange={(e) => handleFechaFinChange(e)}
              timeFormat={!soloFecha}
              dateFormat="DD/MM/yyyy"
              isValidDate={(current) => {
                var fechaInicioMoment = moment(
                  !fechaInicio || fechaInicio == "Invalid Date"
                    ? new Date()
                    : fechaInicio
                );
                return (
                  current.toDate() >=
                  fechaInicioMoment.subtract(1, "d").toDate()
                );
              }}
            />
          </div>
        ) : null}
      </Row>
      {validationMsg != "" ? (
        <Row>
          <Col md={12} className="text-center">
            <FormLabel className="text-danger mb-0">{validationMsg}</FormLabel>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
