import React, { useState, useRef } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import Select from "react-select";
import useMessage from "hooks/useMessage";
import ToolTip from "components/ToolTip/ToolTip";
import useExceptionHandler from "hooks/useExceptionHandler";
import { GridView } from "components";
import { Simplebar } from "components/Simplebar/Simplebar";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useAuthentication } from "hooks";
import { DEFAULT_PAGINATION_VALUES } from "utils";
import { AuthenticationManager } from "managers";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import IconButton from "components/IconButton/IconButton";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import { Compania } from "models/Compania";
import { CompaniaManager } from "managers";

export const SwitchCompanyButton = () => {
  const { cambiarCompania } = useAuthentication();
  const [showCanvas, setShowCanvas] = useState(false);

  const { Warning, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();
  const [list, setList] = useState([]);
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState({
    ...DEFAULT_PAGINATION_VALUES,
    OrderBy: "Id",
  });
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const simplebarRef = useRef();
  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    ...Compania.ESTATUS_LIST,
  ];

  useEffect(() => {
    if (showCanvas) {
      buscar();
    }
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where, showCanvas]);

  const showLoading = () => {
    try {
      simplebarRef?.current?.showLoading(true);
    } catch (error) {
      console.dev(error);
    }
  };

  const hideLoading = () => {
    try {
      simplebarRef?.current?.showLoading(false);
    } catch (error) {
      console.dev(error);
    }
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (id) => {
    if (id) {
      showLoading();
      cambiarCompania({ Id: id })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => {
          hideLoading();
        });
    } else {
      Warning({ message: "Compañía inválida." });
    }
  };

  const buscar = () => {
    const whereWithPaginationValues = { ...where, ...pagination };
    CompaniaManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setList(res.Data || []);
      })
      .catch((error) => {
        handleException(error);
      });
  };

  return (
    <>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle
          as={Nav.Link}
          id="dropdown-company-switch"
          className="buttonHover"
          onClick={() => {
            setShowCanvas(true);
          }}
        >
          <ToolTip placement="left" message="Cambiar Compañía">
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#77777A"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 2c5.519 0 10 4.481 10 10s-4.481 10-10 10-10-4.481-10-10 4.481-10 10-10zm2 12v-3l5 4-5 4v-3h-9v-2h9zm-4-6v-3l-5 4 5 4v-3h9v-2h-9z" />
            </svg>
          </ToolTip>
        </Dropdown.Toggle>
      </Dropdown>

      {createPortal(
        <Simplebar
          ref={simplebarRef}
          size="lg"
          useLoading={true}
          backdrop={true}
          placement={"end"}
          portal={false}
          visible={showCanvas}
          setShow={(e) => {
            setShowCanvas(e);
            cerrarAlertas();
          }}
          title={"Cambiar Compañía"}
          btnLeft={{
            show: false,
            func: () => {},
          }}
          btnRight={{
            show: false,
            func: () => {},
          }}
        >
          <>
            <GridView
              pagination={pagination}
              setPagination={setPagination}
              allowPaging={pagination.EsPaginable}
              pageSize={pagination.CantidadPorPagina}
              pageIndex={pagination.IndiceDePagina}
              lastPageNumber={pagination.PaginasTotal}
              dataSource={list}
              onPageIndexChanging={onPageIndexChanging}
              where={where}
              setWhere={setWhere}
              height="70vh"
            >
              <GridView.Column
                isSticky
                bodyRenderer={(row) => {
                  return (
                    <>
                      <div className="float-right">
                        <IconButton onClick={() => handleClick(row.Id)}>
                          <ToolTip message="Ingresar">
                            <div>
                              <SvgComponent
                                iconName="user"
                                height="25"
                                width="25"
                              />
                            </div>
                          </ToolTip>
                        </IconButton>
                      </div>
                    </>
                  );
                }}
              />

              {list &&
                list.length > 0 &&
                list.some((l) => l.Id !== null && l.Id !== undefined) && (
                  <GridView.Column
                    headerText="Id"
                    dataField="Id"
                    sortExpression="Id"
                    width="100px"
                  />
                )}

              <GridView.Column
                headerText="Nombre"
                dataField="Nombre"
                sortExpression="Nombre"
              />
              <GridView.Column
                headerText="Código"
                dataField="Codigo"
                sortExpression="Codigo"
              />
              <GridView.Column
                headerText="Estatus"
                dataField="Estatus"
                sortExpression="Estatus"
                filterRenderer={(onFilterChange) => {
                  return (
                    <Select
                      className="react-select primary"
                      options={ESTATUS_LIST}
                      value={ESTATUS_LIST.filter(function (option) {
                        return (
                          option.value ===
                          (where.Estatus
                            ? where.Estatus
                            : ESTATUS_LIST[0].value)
                        );
                      })}
                      name="Estatus"
                      onChange={(e) =>
                        onFilterChange({
                          target: { name: "Estatus", value: e.value },
                        })
                      }
                    ></Select>
                  );
                }}
              />
            </GridView>
          </>
        </Simplebar>,
        document.querySelector("#sidebarContainer")
      )}
    </>
  );
};
