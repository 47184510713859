import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";

import useMessage from "hooks/useMessage";
import useExceptionHandler from "hooks/useExceptionHandler";
import { useTheme } from "hooks";
import { Simplebar } from "components/Simplebar/Simplebar";

const ReporteViewer = ({ reporte, setShowCanvas, showCanvas }) => {
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();
  const { theme } = useTheme();

  useEffect(() => {
    return () => {
      cerrarAlertas();
    };
  }, []);

  return (
    <Simplebar
      size="xxxl"
      backdrop={true}
      placement={"end"}
      portal={false}
      visible={showCanvas}
      setShow={(e) => {
        setShowCanvas(e);
      }}
      useLoading={true}
      title="Reporte"
      btnLeft={{
        show: true,
        message: "Abrir en nueva pestaña",
        isComponent: true,
        size: "45px",
        height: "24px",
        with: "24px",
        iconName: "link",
        func: () => {
          if (reporte?.Url) {
            window.open(reporte.Url, "_blank");
          }
        },
      }}
      btnRight={{
        show: false,
        label: "Grabar",
        func: () => {},
      }}
    >
      <Row
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          padding: 0,
          margin: 0,
          width: "100%",
        }}
      >
        <Col
          md={12}
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          {reporte?.Url && (
            <iframe src={reporte.Url} width="100%" height="100%" />
          )}
        </Col>
      </Row>
    </Simplebar>
  );
};
export default ReporteViewer;
