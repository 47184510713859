import { CRM_API, RequestHelper } from "utils";

const RUTA = "autorizacion";
export const AuthorizationService = (() => {
  const refreshAuthorization = (permiso) => {
    return RequestHelper(CRM_API).post(`${RUTA}/refreshAuthorization`, {});
  };
  const tienePermiso = (permiso) => {
    return RequestHelper(CRM_API).get(`${RUTA}/validarPermiso`, {
      permiso: permiso,
    });
  };
  return {
    tienePermiso,
    refreshAuthorization,
  };
})();
