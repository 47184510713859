import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import uuid from "react-uuid";

function ToolTip({ children, message, placement }) {
  const renderTooltip = (props) => (
    <Tooltip id={uuid()} {...props}>
      <div>{message}</div>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={placement ? placement : "top"}
      overlay={renderTooltip || function () {}}
      onToggle={() => {}}
      delay={{ show: 700 }}
    >
      {children}
    </OverlayTrigger>
  );
}

export default ToolTip;
