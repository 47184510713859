import { SvgComponent } from "components";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";
import { useTheme } from "hooks";
import React, { useState } from "react";
export const Example = () => {
  const { theme } = useTheme();
  return (
    <div style={{ margin: "200px" }}>
      <button
        type="button"
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 24 24"
          fill={theme.color1}
        >
          <path d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z" />
        </svg>
      </button>
      <button
        type="button"
        style={{
          backgroundColor: "transparent",
          border: "none",
          paddingLeft: "80px",
          paddingRight: "80px",
          outline: "none",
        }}
      >
        <div
          className="d-flex"
          style={{
            height: "50px",
            width: "50px",
            backgroundColor: theme.color1,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "500%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
          >
            <path d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z" />
          </svg>
        </div>
      </button>
      <button
        type="button"
        style={{
          backgroundColor: "transparent",
          border: "none",
          paddingLeft: "80px",
          paddingRight: "80px",
          outline: "none",
        }}
      >
        <div
          className="d-flex"
          style={{
            height: "50px",
            width: "50px",
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "500%",
            border: `3px solid ${theme.color1}`,
            WebkitBorderRadius: "50%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={theme.color1}
          >
            <path d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z" />
          </svg>
        </div>
      </button>

      <AnimatedIconButton
        size="50px"
        height="24px"
        width="24px"
        iconName="send"
        message="Enviar"
      />
    </div>
  );
};
