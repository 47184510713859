import React, { useState } from "react";
import useMessage from "hooks/useMessage";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import { Button, Form, Row, Col } from "react-bootstrap";
import { ConfiguracionEmailHelper } from "utils";
import { ConfiguracionCorreoElectronicoManager } from "managers";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { useApp } from "hooks";

function CuentaGoogle({
  showLoading,
  hideLoading,
  configuracionEmail,
  setConfiguracionEmail,
  setTipoActual,
  tipoActual,
  fuente,
  referencia,
  esAutomatizacion = false,
  codeResponse,
  setCodeResponse,
}) {
  const { Message } = useMessage();
  const { handleException } = useExceptionHandler();
  const [correoVinculado, setCorreoVinculado] = useState(false);

  const autorizarCodigo = (codeResponse) => {
    if (esAutomatizacion) {
      setCodeResponse(codeResponse);
      setCorreoVinculado(true);
    } else {
      showLoading();
      ConfiguracionCorreoElectronicoManager.vincularCuentaGmail(codeResponse)
        .then((data) => {
          Message({ message: `Cuenta Gmail Vinculada Exitosamente.` });
          setConfiguracionEmail(data);
          setTipoActual(data.Tipo);
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => {
          hideLoading();
        });
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponseData) => {
      autorizarCodigo({ ...codeResponseData, fuente, referencia });
    },
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.profile",
  });

  const handleClickVincularCuentaGmail = () => {
    try {
      showLoading();
      login();
    } catch (e) {
      handleException;
    } finally {
      hideLoading();
    }
  };

  const handleClickDesvincularCuentaGmail = () => {
    if (esAutomatizacion) {
      setConfiguracionEmail({
        ...configuracionEmail,
        EmailRegistrado: null,
        RutaCredenciales: null,
        IdSec: null,
      });
    } else {
      showLoading();
      ConfiguracionCorreoElectronicoManager.desvincularCuentaGmail({
        fuente,
        referencia,
      })
        .then((data) => {
          Message({ message: `Cuenta Gmail Desvinculada Exitosamente.` });
          setConfiguracionEmail(data);
          setTipoActual(data.Tipo);
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => {
          hideLoading();
        });
    }
  };

  return (
    <EditSection>
      {configuracionEmail.EmailRegistrado &&
      tipoActual === ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.GMAIL ? (
        <Row>
          <Form.Label column sm={esAutomatizacion ? "4" : "2"}>
            Cuenta Vinculada
          </Form.Label>
          <Col sm={esAutomatizacion ? "12" : "4"}>
            <Form.Control
              disabled={true}
              name="EmailRegistrado"
              type="text"
              placeholder="EmailRegistrado"
              value={configuracionEmail.EmailRegistrado}
            />

            <Button
              style={{
                padding: "0px",
                backgroundColor: "transparent",
                border: "none",
                color: "#4491f9",
                cursor: "pointer",
                fontSize: "0.9rem",
                padding: "0",
                textAlign: "left",
                textDecoration: "underline",
              }}
              className="mt-3"
              onClick={handleClickDesvincularCuentaGmail}
              variant="link"
            >
              Desvincular Cuenta de Gmail
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Form.Label column sm="2"></Form.Label>
          <Col sm={esAutomatizacion ? "6" : "4"}>
            <Button
              style={{
                padding: "0px",
                backgroundColor: "transparent",
                border: "none",
                color: "#4491f9",
                cursor: "pointer",
                fontSize: "0.9rem",
                padding: "0",
                textAlign: "left",
                textDecoration: correoVinculado ? "none" : "underline",
              }}
              onClick={
                correoVinculado ? () => {} : handleClickVincularCuentaGmail
              }
              variant="link"
            >
              {correoVinculado
                ? "Correo Vinculado"
                : "Vincular Cuenta de Gmail"}
            </Button>
          </Col>
        </Row>
      )}
    </EditSection>
  );
}

const VincularCuentaGoogle = ({
  showLoading,
  hideLoading,
  configuracionEmail,
  setConfiguracionEmail,
  setDefaultTipo,
  setTipoActual,
  tipoActual,
  fuente,
  referencia,
  esAutomatizacion = false,
  setCodeResponse,
}) => {
  const { configuration } = useApp();
  return (
    <GoogleOAuthProvider clientId={configuration.GMAIL_CLIENT_ID}>
      <CuentaGoogle
        showLoading={showLoading}
        hideLoading={hideLoading}
        configuracionEmail={configuracionEmail}
        tipoActual={tipoActual}
        setTipoActual={setTipoActual}
        setConfiguracionEmail={setConfiguracionEmail}
        setDefaultTipo={setDefaultTipo}
        fuente={fuente}
        referencia={referencia}
        esAutomatizacion={esAutomatizacion}
        setCodeResponse={setCodeResponse}
      />
    </GoogleOAuthProvider>
  );
};

export default VincularCuentaGoogle;
