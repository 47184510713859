import React, { useState, useEffect, useReducer } from "react";
import { ConfirmContext } from "contexts";
import { createPortal } from "react-dom";
import Confirm from "components/ConfirmAlert/Confirm";
import { useContext } from "react";
import uuid from "react-uuid";
import { ADD, REMOVE, REMOVE_ALL } from "./message.provider";

const initialState = [];

export const confirmReducer = (state, action) => {
  switch (action.type) {
    case ADD:
      return [
        ...state,
        {
          id: uuid(),
          content: action.payload.content,
          type: action.payload.type,
        },
      ];
    case REMOVE:
      return state.filter((t) => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};
export function ConfirmProvider({ children }) {
  const [confirm, confirmDispatch] = useReducer(confirmReducer, initialState);
  const confirmData = { confirm, confirmDispatch };

  return (
    <ConfirmContext.Provider value={confirmData}>
      {children}
      {createPortal(<Confirm data={confirm} />, document.body)}
    </ConfirmContext.Provider>
  );
}

export const useConfirmContext = () => {
  return useContext(ConfirmContext);
};
