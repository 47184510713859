import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import usePaginationValuesWhere from "../hooks/usePaginationValuesWhere";
import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";
import { useEffect } from "react";
import { PerfilManager } from "managers";
import { GridView } from "components";
import * as BS from "react-bootstrap";
import Select from "react-select";
import IconButton from "components/IconButton/IconButton";
import ToolTip from "components/ToolTip/ToolTip";
import ListHeader from "components/PantallaList/Header/ListHeader";
import { Perfil } from "models/Perfil";
import ListContainer from "components/PantallaList/ListContainer/ListContainer";
import { SvgComponent } from "components";
import { DEFAULT_PAGINATION_VALUES } from "utils";
import { useConfigurationPage } from "hooks";

export default function PerfilList({
  showLoading,
  hideLoading,
  hideTitle,
  isActive,
}) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const history = useHistory();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const ESTATUS_LIST = [{ value: "", label: "Todos" }, ...Perfil.ESTATUS_LIST];
  const { setConfigurationState } = useConfigurationPage();

  useEffect(() => {
    if (isActive) {
      buscar();
    }
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where, isActive]);

  const buscar = () => {
    const whereWithPaginationValues = { ...where, ...pagination };
    PerfilManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setList(res.Data);
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (params) => {
    const { Id, Clonar } = params;
    let urlBase = `/PerfilEdit?`;

    if (Id) {
      urlBase += `Id=${Id}`;
    }
    if (Clonar) {
      urlBase += `&Clonar=${Clonar}`;
    }
    if (isActive) {
      setConfigurationState(false);
    }
    history.push(urlBase);
  };

  return (
    <div>
      <div style={{ paddingRight: "6px" }}>
        <ListHeader
          titulo={hideTitle ? "" : "Perfil"}
          crearNuevo={handleClick}
        ></ListHeader>
      </div>
      <>
        <GridView
          pagination={pagination}
          setPagination={setPagination}
          allowPaging={pagination.EsPaginable}
          pageSize={pagination.CantidadPorPagina}
          pageIndex={pagination.IndiceDePagina}
          lastPageNumber={pagination.PaginasTotal}
          dataSource={list}
          onPageIndexChanging={onPageIndexChanging}
          where={where}
          setWhere={setWhere}
          height="70vh"
        >
          <GridView.Column
            bodyRenderer={(row) => {
              return (
                <>
                  <div className="float-right">
                    <IconButton
                      onClick={() =>
                        handleClick({ Id: row.IdSec, Clonar: false })
                      }
                    >
                      <ToolTip message="Editar">
                        <div>
                          <SvgComponent
                            iconName="pencil"
                            height="25px"
                            width="25px"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleClick({ Id: row.IdSec, Clonar: true })
                      }
                    >
                      <ToolTip message="Clonar">
                        <div>
                          <SvgComponent
                            iconName="copy"
                            height="25px"
                            width="25px"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                  </div>
                </>
              );
            }}
          />
          <GridView.Column
            headerText="Id"
            dataField="IdSec"
            sortExpression="IdSec"
          />
          <GridView.Column
            headerText="Nombre"
            dataField="Nombre"
            sortExpression="Nombre"
          />
          <GridView.Column
            headerText="Estatus"
            dataField="Estatus"
            sortExpression="Estatus"
            filterRenderer={() => {
              return (
                <Select
                  className="react-select primary"
                  options={ESTATUS_LIST}
                  value={ESTATUS_LIST.filter(function (option) {
                    return (
                      option.value ===
                      (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                    );
                  })}
                  name="Estatus"
                  onChange={(e) =>
                    setWhere({
                      ...where,
                      Estatus: e.value,
                    })
                  }
                ></Select>
              );
            }}
          />
        </GridView>
      </>
    </div>
  );
}
