import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTheme } from "hooks";

export const IsLoadingHOC = (WrappedComponent) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false);
    const { theme } = useTheme();

    const showLoading = () => {
      setLoading(true);
    };

    const hideLoading = () => {
      setLoading(false);
    };
    return (
      <>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: "9999999",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {}
            <Spinner
              style={{
                width: "4rem",
                height: "4rem",
                color: theme.color1,
              }}
              animation="border"
            />
          </div>
        )}
        <div
          style={
            isLoading === true
              ? {
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  WebkitFilter: "blur(2px)",
                  filter: "blur(2px)",
                }
              : {
                  minHeight: "inherit",
                }
          }
        >
          <WrappedComponent
            {...props}
            showLoading={showLoading}
            hideLoading={hideLoading}
          ></WrappedComponent>
        </div>
      </>
    );
  }

  return HOC;
};

export default IsLoadingHOC;
