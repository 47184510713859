import { CRM_API, RequestHelper } from "utils";

const RUTA = "token";
export const TokenService = (() => {
  const generarToken = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).post(`${RUTA}`, params);
    });
  };

  const buscarToken = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/${Id}`);
    });
  };

  const inactivarToken = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/Inactivar/${Id}`);
    });
  };

  const listarToken = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}`, params);
    });
  };

  return {
    generarToken,
    buscarToken,
    listarToken,
    inactivarToken,
  };
})();
