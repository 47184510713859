export class Transacciones {
  static get PROPIEDADES_EXPORTAR() {
    return [
      {
        value: "IdSec",
        label: "Id",
      },
      {
        value: "TipoeCF",
        label: "Tipo eCF",
      },
      {
        value: "eNCF",
        label: "NCF",
      },
      {
        value: "FechaVencimientoSecuencia",
        label: "Fecha Vencimiento Secuencia",
      },
      {
        value: "IndicadorNotaCredito",
        label: "Indicador Nota Credito",
      },
      {
        value: "IndicadorEnvioDiferido",
        label: "Indicador Envio Diferido",
      },
      {
        value: "IndicadorMontoGravado",
        label: "Indicador Monto Gravado",
      },
      {
        value: "TipoIngresos",
        label: "Tipo de Ingresos",
      },
      {
        value: "TipoPago",
        label: "Tipo de Pago",
      },
      {
        value: "FechaLimitePago",
        label: "FechaLimitePago",
      },
      {
        value: "TerminoPago",
        label: "Termino de Pago",
      },
      {
        value: "TipoCuentaPago",
        label: "Tipo Cuenta de Pago",
      },
      {
        value: "NumeroCuentaPago",
        label: "Numero Cuenta de Pago",
      },
      {
        value: "BancoPago",
        label: "Banco de Pago",
      },
      {
        value: "FechaDesde",
        label: "Fecha Desde",
      },
      {
        value: "FechaHasta",
        label: "Fecha Hasta",
      },
      {
        value: "TotalPaginas",
        label: "Total de Paginas",
      },
      {
        value: "RNCEmisor",
        label: "RNC Emisor",
      },
      {
        value: "RazonSocialEmisor",
        label: "Razon Social Emisor",
      },
      {
        value: "NombreComercial",
        label: "Nombre Comercial",
      },
      {
        value: "Sucursal",
        label: "Sucursal",
      },
      {
        value: "DireccionEmisor",
        label: "Direccion Emisor",
      },
      {
        value: "Municipio",
        label: "Municipio",
      },
      {
        value: "Provincia",
        label: "Provincia",
      },
      {
        value: "CorreoEmisor",
        label: "Correo Emisor",
      },
      {
        value: "WebSite",
        label: "WebSite",
      },
      {
        value: "ActividadEconomica",
        label: "Actividad Economica",
      },
      {
        value: "CodigoVendedor",
        label: "Codigo Vendedor",
      },
      {
        value: "NumeroFacturaInterna",
        label: "Número FacturaInterna",
      },
      {
        value: "NumeroPedidoInterno",
        label: "Número Pedido Interno",
      },
      {
        value: "ZonaVenta",
        label: "Zona de Venta",
      },
      {
        value: "RutaVenta",
        label: "Ruta de Venta",
      },
      {
        value: "InformacionAdicionalEmisor",
        label: "Informacion Adicional Emisor",
      },
      {
        value: "FechaEmision",
        label: "Fecha Emisión",
      },
      {
        value: "RNCComprador",
        label: "RNC Comprador",
      },
      {
        value: "IdentificadorExtranjero",
        label: "Identificador Extranjero",
      },
      {
        value: "RazonSocialComprador",
        label: "Razon Social Comprador",
      },
      {
        value: "ContactoComprador",
        label: "Contacto Comprador",
      },
      {
        value: "CorreoComprador",
        label: "Correo Comprador",
      },
      {
        value: "DireccionComprador",
        label: "Dirección Comprador",
      },
      {
        value: "MunicipioComprador",
        label: "Municipio Comprador",
      },
      {
        value: "ProvinciaComprador",
        label: "Provincia Comprador",
      },
      {
        value: "PaisComprador",
        label: "Pais Comprador",
      },
      {
        value: "FechaEntrega",
        label: "Fecha Entrega",
      },
      {
        value: "ContactoEntrega",
        label: "Contacto Entrega",
      },
      {
        value: "DireccionEntrega",
        label: "Direccion Entrega",
      },
      {
        value: "TelefonoAdicional",
        label: "Telefono Adicional",
      },
      {
        value: "FechaOrdenCompra",
        label: "Fecha Orden Compra",
      },
      {
        value: "NumeroOrdenCompra",
        label: "Numero Orden Compra",
      },
      {
        value: "CodigoInternoComprador",
        label: "Codigo Interno Comprador",
      },
      {
        value: "ResponsablePago",
        label: "Responsable Pago",
      },
      {
        value: "Informacionadicionalcomprador",
        label: "Informacion Adicional Comprador",
      },
      {
        value: "FechaEmbarque",
        label: "Fecha Embarque",
      },
      {
        value: "NumeroEmbarque",
        label: "Número Embarque",
      },
      {
        value: "NumeroContenedor",
        label: "Número Contenedor",
      },
      {
        value: "NumeroReferencia",
        label: "Número Referencia",
      },
      {
        value: "NombrePuertoEmbarque",
        label: "Número Puerto Embarque",
      },
      {
        value: "CondicionesEntrega",
        label: "Condiciones Entrega",
      },
      {
        value: "TotalFob",
        label: "Total Fob",
      },
      {
        value: "Seguro",
        label: "Seguro",
      },
      {
        value: "Flete",
        label: "Flete",
      },
      {
        value: "OtrosGastos",
        label: "Otros Gastos",
      },
      {
        value: "TotalCif",
        label: "Total Cif",
      },
      {
        value: "RegimenAduanero",
        label: "Régimen Aduanero",
      },
      {
        value: "NombrePuertoSalida",
        label: "Nombre Puerto Salida",
      },
      {
        value: "NombrePuertoDesembarque",
        label: "Nombre Puerto Desembarque",
      },
      {
        value: "PesoBruto",
        label: "Peso Bruto",
      },
      {
        value: "PesoNeto",
        label: "Peso Neto",
      },
      {
        value: "UnidadPesoBruto",
        label: "Unidad Peso Bruto",
      },
      {
        value: "UnidadPesoNeto",
        label: "Unidad Peso Neto",
      },
      {
        value: "CantidadBulto",
        label: "Cantidad Bulto",
      },
      {
        value: "VolumenBulto",
        label: "Volumen Bulto",
      },
      {
        value: "UnidadVolumen",
        label: "Unidad Volumen",
      },
      {
        value: "ViaTransporte",
        label: "Via Transporte",
      },
      {
        value: "PaisOrigen",
        label: "Pais Origen",
      },
      {
        value: "DireccionDestino",
        label: "Dirección Destino",
      },
      {
        value: "PaisDestino",
        label: "Pais Destino",
      },
      {
        value: "RNCIdentificacionCompaniaTransportista",
        label: "RNC Identificacion Compañia Transportista",
      },
      {
        value: "NombreCompaniaTransportista",
        label: "Nombre Compañia Transportista",
      },
      {
        value: "NumeroViaje",
        label: "Numero Viaje",
      },
      {
        value: "Conductor",
        label: "Conductor",
      },
      {
        value: "DocumentoTransporte",
        label: "Documento Transporte",
      },
      {
        value: "Ficha",
        label: "Ficha",
      },
      {
        value: "Placa",
        label: "Placa",
      },
      {
        value: "RutaTransporte",
        label: "Ruta Transporte",
      },
      {
        value: "ZonaTransporte",
        label: "Zona Transporte",
      },
      {
        value: "NumeroAlbaran",
        label: "Número Albaran",
      },
      {
        value: "MontoGravadoTotal",
        label: "Monto Gravado Total",
      },
      {
        value: "MontoGravadoI1",
        label: "Monto Gravado I1",
      },
      {
        value: "MontoGravadoI2",
        label: "Monto Gravado I2",
      },
      {
        value: "MontoGravadoI3",
        label: "Monto Gravado I3",
      },
      {
        value: "MontoExento",
        label: "Monto Exento",
      },
      {
        value: "ITBIS1",
        label: "ITBIS 1",
      },
      {
        value: "ITBIS2",
        label: "ITBIS 2",
      },
      {
        value: "ITBIS3",
        label: "ITBIS 3",
      },
      {
        value: "TotalITBIS",
        label: "Total ITBIS",
      },
      {
        value: "TotalITBIS1",
        label: "Total ITBIS 1",
      },
      {
        value: "TotalITBIS2",
        label: "Total ITBIS 2",
      },
      {
        value: "TotalITBIS3",
        label: "Total ITBIS 3",
      },
      {
        value: "MontoImpuestoAdicional",
        label: "Monto Impuesto Adicional",
      },
      {
        value: "MontoTotal",
        label: "Monto Total",
      },
      {
        value: "MontoNoFacturable",
        label: "Monto No Facturable",
      },
      {
        value: "MontoPeriodo",
        label: "Monto Periodo",
      },
      {
        value: "SaldoAnterior",
        label: "Saldo Anterior",
      },
      {
        value: "MontoAvancePago",
        label: "Monto Avance Pago",
      },
      {
        value: "ValorPagar",
        label: "Valor a Pagar",
      },
      {
        value: "TotalITBISRetenido",
        label: "Total ITBIS Retenido",
      },
      {
        value: "TotalISRRetencion",
        label: "Total ISR Retencion",
      },
      {
        value: "TotalITBISPercepcion",
        label: "Total ITBIS Percepcion",
      },
      {
        value: "TotalISRPercepcion",
        label: "Total ISR Percepcion",
      },
      {
        value: "TipoMoneda",
        label: "Tipo Moneda",
      },
      {
        value: "TipoCambio",
        label: "Tipo Cambio",
      },
      {
        value: "MontoGravadoTotalOtraMoneda",
        label: "Monto Gravado Total Otra Moneda",
      },
      {
        value: "MontoGravado1OtraMoneda",
        label: "Monto Gravado 1 Otra Moneda",
      },
      {
        value: "MontoGravado2OtraMoneda",
        label: "Monto Gravado 2 Otra Moneda",
      },
      {
        value: "MontoGravado3OtraMoneda",
        label: "Monto Gravado 3 Otra Moneda",
      },
      {
        value: "MontoExentoOtraMoneda",
        label: "Monto Exento Otra Moneda",
      },
      {
        value: "TotalITBISOtraMoneda",
        label: "Total ITBIS Otra Moneda",
      },
      {
        value: "TotalITBIS1OtraMoneda",
        label: "Total ITBIS 1 Otra Moneda",
      },
      {
        value: "TotalITBIS2OtraMoneda",
        label: "Total ITBIS 2 Otra Moneda",
      },
      {
        value: "TotalITBIS3OtraMoneda",
        label: "Total ITBIS 3 Otra Moneda",
      },
      {
        value: "MontoImpuestoAdicionalOtraMoneda",
        label: "Monto Impuesto Adicional Otra Moneda",
      },
      {
        value: "MontoTotalOtraMoneda",
        label: "Monto Total Otra Moneda",
      },
      {
        value: "NCFModificado",
        label: "NCF Modificado",
      },
      {
        value: "RNCOtroContribuyente",
        label: "RNC Otro Contribuyente",
      },
      {
        value: "FechaNCFModificado",
        label: "Fecha NCF Modificado",
      },
      {
        value: "CodigoModificacion",
        label: "Codigo Modificación",
      },
      {
        value: "RazonModificacion",
        label: "Razon Modificación",
      },
      {
        value: "FechaHoraFirma",
        label: "Fecha Hora Firma",
      },
      {
        value: "Estatus",
        label: "Estatus",
      },
      {
        value: "EstadoECF",
        label: "EstadoECF",
      },
      {
        value: "MensajeDGII",
        label: "MensajeDGII",
      },
      {
        value: "TrackId",
        label: "TrackId",
      },
      {
        value: "Ambiente",
        label: "Ambiente",
      },
      {
        value: "CodigoSeguridadEncf",
        label: "CodigoSeguridadEncf",
      },
    ];
  }
}
