import { useEffect, useState, useRef } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";

import ToolTip from "components/ToolTip/ToolTip";
import { GridView } from "components/GridView/GridView";
import IconButton from "components/IconButton/IconButton";
import useExceptionHandler from "hooks/useExceptionHandler";
import { AutoComplete, Simplebar, SvgComponent } from "components";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";
import {
  buttonStyle,
  controlsProportion,
  initialWhere,
  initialWhereModal,
} from "..";
import { DEFAULT_PAGINATION_VALUES } from "utils";
import "../Lookup.style.scss";
import { ComprobanteElectronicoManager } from "managers";

export const ComprobanteRecibidoLookup = ({
  initialValue = { label: "", value: "" },
  onChange,
  isDisabled,
  isInput = false,
}) => {
  const ref = useRef(null);
  const [showCanvas, setShowCanvas] = useState(false);
  const [modalList, setModalList] = useState([]);
  const [whereModal, setWhereModal] = useState({
    ...initialWhereModal,
    Estatus: "",
  });
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [selectedValue, setSelectedValue] = useState({
    label: "",
    value: "",
    rncEmisor: "",
    eNCF: "",
    fechaEmision: "",
    nombreComercial: "",
    monto: 0,
    tipoeCF: "",
  });

  const { handleException } = useExceptionHandler();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();

  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    { value: "N", label: "Nuevo" },
    { value: "A", label: "Aprobado por la DGII" },
    { value: "R", label: "Rechazado por la DGII" },
    { value: "D", label: "Documento Generado" },
  ];
  const LabelsHolders = {
    placeholder: "Comprobantes Recibidos",
    message: "Seleccionar Comprobantes Recibidos",
  };

  useEffect(() => {
    if (whereModal != initialWhereModal) {
      buscar();
    }
  }, [whereModal]);

  useEffect(() => {
    if (
      selectedValue != undefined &&
      selectedValue.value !== null &&
      selectedValue.value != ""
    ) {
      onChange(selectedValue);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (
      initialValue.value &&
      initialValue.value != "" &&
      initialValue.value !== selectedValue.value &&
      initialValue.value !== -1
    ) {
      setValue();
    }
    if (initialValue.value === -1) {
      setSelectedValue({
        label: "",
        value: "",
        rncEmisor: "",
        eNCF: "",
        fechaEmision: "",
        nombreComercial: "",
        monto: 0,
        tipoeCF: "",
      });
    }
  }, [initialValue.value]);

  const setValue = () => {
    ComprobanteElectronicoManager.buscarPorId(initialValue.value, true).then(
      (data) => setSelectedValue(data)
    );
  };

  function buscar() {
    const whereWithPaginationValues = {
      ...whereModal,
      ...pagination,
    };
    ComprobanteElectronicoManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setModalList(res.Data);
      })
      .catch((err) => handleException(err));
  }

  const handleCerrar = () => {
    setShowCanvas(false);
    setWhereModal({ ...initialWhereModal, Estatus: "" });
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhereModal({ ...whereModal });
  };

  const getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const res = await ComprobanteElectronicoManager.listado({
      ...initialWhere,
      NCF: inputValue,
      Estatus: "",
    });
    return res.Data;
  };

  return (
    <>
      <InputGroup>
        <div style={{ width: controlsProportion.input }}>
          <AutoComplete
            placeholder={LabelsHolders.placeholder}
            getList={getSuggestions}
            parentValue={selectedValue}
            setParentValue={setSelectedValue}
            noBorderRadius={isInput}
            isDisabled={isDisabled}
          />
        </div>
        <InputGroup.Append style={{ width: controlsProportion.button }}>
          <AnimatedIconButton
            size="40px"
            height="18"
            width="18"
            message={LabelsHolders.message}
            style={buttonStyle}
            disabled={isDisabled}
            iconName="search"
            onClick={() => {
              setShowCanvas(true);
              setTimeout(() => {
                buscar();
              }, 200);
            }}
          />
        </InputGroup.Append>
      </InputGroup>
      {showCanvas && (
        <Simplebar
          ref={ref}
          backdrop={true}
          placement={"end"}
          portal={false}
          size="lg"
          useLoading={false}
          visible={showCanvas}
          setShow={(e) => setShowCanvas(e)}
          title={LabelsHolders.message}
          btnLeft={{
            show: false,
            func: () => {},
          }}
          btnRight={{
            show: false,
            func: () => {},
          }}
        >
          <GridView
            pagination={pagination}
            setPagination={setPagination}
            allowPaging={pagination.EsPaginable}
            pageSize={pagination.CantidadPorPagina}
            pageIndex={pagination.IndiceDePagina}
            lastPageNumber={pagination.PaginasTotal}
            dataSource={modalList}
            onPageIndexChanging={onPageIndexChanging}
            where={whereModal}
            setWhere={setWhereModal}
          >
            <GridView.Column
              bodyRenderer={(row) => {
                return (
                  <div className="float-right">
                    <IconButton
                      onClick={() => {
                        setSelectedValue({
                          value: row.IdSec,
                          label: row.NCF,
                          rncEmisor: row.RNCEmisor,
                          eNCF: row.NCF,
                          fechaEmision: row.FechaEmision,
                          nombreComercial: row.NombreComercial,
                          monto: row.Monto,
                          tipoeCF: row.TipoeCF,
                        });
                        handleCerrar();
                      }}
                    >
                      <ToolTip message="Seleccionar">
                        <div>
                          <SvgComponent
                            height="20"
                            width="20"
                            iconName="check"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                  </div>
                );
              }}
            />
            <GridView.Column
              headerText="Contribuyente"
              dataField="NombreComercial"
              sortExpression="NombreComercial"
            />
            <GridView.Column
              headerText="E-NCF"
              dataField="NCF"
              sortExpression="NCF"
            />
            <GridView.Column
              headerText="Monto"
              dataField="Monto"
              sortExpression="Monto"
            />
            <GridView.Column
              headerText="Estatus"
              dataField="EstatusDesc"
              sortExpression="Estatus"
              filterRenderer={() => {
                return (
                  <Select
                    className="react-select primary"
                    options={ESTATUS_LIST}
                    value={ESTATUS_LIST.filter(function (option) {
                      return (
                        option.value ===
                        (whereModal.Estatus
                          ? whereModal.Estatus
                          : ESTATUS_LIST[0].value)
                      );
                    })}
                    name="Estatus"
                    onChange={(e) =>
                      setWhereModal({
                        ...whereModal,
                        Estatus: e.value,
                      })
                    }
                  />
                );
              }}
            />
          </GridView>
        </Simplebar>
      )}
    </>
  );
};
