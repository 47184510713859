import React from "react";
import { Form, Col, Row } from "react-bootstrap";

function InputContainer({
  children,
  label,
  colSize,
  style,
  labelSize,
  onLabelClick = null,
  labelClassName,
}) {
  return (
    <div style={{ ...style }}>
      <Form.Group
        as={Row}
        style={{ alignItems: "center", marginBottom: "0.5rem" }}
      >
        {label && (
          <Form.Label
            className={`${labelClassName ?? "pl-4 d-flex"}`}
            column
            md={labelSize || "2"}
            style={{
              paddingTop: "0px",
              paddingRight: "0px",
              paddingBottom: "0px",
              wordBreak: "break-word",
              cursor: `${onLabelClick ? "pointer" : "default"}`,
            }}
            onClick={onLabelClick}
          >
            {label}
          </Form.Label>
        )}
        <Col md={colSize || "8"} className="pr-10">
          {children}
        </Col>
      </Form.Group>
    </div>
  );
}

export default InputContainer;
