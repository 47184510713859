import { CRM_API, RequestHelper } from "utils";

const RUTA = "unidad-medida";
export const UnidadesMedidaService = (() => {
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}`, params);
    });
  };

  const descargarExcel = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/excel`, params, true);
    });
  };

  return {
    listado,
    descargarExcel,
  };
})();
