export const DateHelper = (() => {
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date = new Date()) {
    if (isValidDate(date))
      return (
        [
          date.getFullYear(),
          padTo2Digits(date.getMonth() + 1),
          padTo2Digits(date.getDate()),
        ].join("-") +
        " " +
        [
          padTo2Digits(date.getHours()),
          padTo2Digits(date.getMinutes()),
          padTo2Digits(date.getSeconds()),
        ].join(":")
      );
    else return null;
  }

  function formatStringDate(date = "", showTime = true, justTime = false) {
    var d = new Date(date);
    if (date !== null && isValidDate(d)) {
      var dformat =
        [d.getDate(), d.getMonth() + 1, d.getFullYear()].join("/") + " ";
      if (showTime) {
        dformat = dformat + formatAMPM(d);
      }
      if (justTime) {
        return formatAMPM(d);
      }
      return dformat;
    } else return undefined;
  }

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = padTo2Digits(minutes);
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  function getMaxDate(arr = [], propName, propName2) {
    const date1 = new Date(
      Math.max(
        ...arr.map((element) => {
          return new Date(element[propName]);
        })
      )
    );

    const date2 = new Date(
      Math.max(
        ...arr.map((element) => {
          return new Date(element[propName2]);
        })
      )
    );
    if (date1 > date2) return date1;
    else return date2;
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  return {
    formatDate,
    formatStringDate,
    formatAMPM,
    getMaxDate,
    isValidDate,
  };
})();
