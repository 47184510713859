import { DirectorioServiciosService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const DirectorioServiciosManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listado = (where) => {
    return service.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    listado,
  };
})();
