import { ThemeContext } from "contexts";
import { useApp, useAuthentication } from "hooks";
import React, { useEffect, useState } from "react";

export function ThemeProvider({ children }) {
  const MAX_HEIGHT_HEADER = "50px";
  const defaultVendorTheme = "generico";
  const defaultThemes = {
    generico: {
      title: "ECF",
      color1: "black",
      color2: "black",
      color3: "black",
      logo: "",
      favicon: "",
      MAX_HEIGHT_HEADER,
    },
    claro: {
      title: "GN ECF",
      color1: "#D52B1E",
      color2: "#1097AF",
      color3: "#FFFFFF",
      logo: require("../assets/img/logo-claro.png"),
      favicon: require("../assets/img/claro_favicon.ico"),
      MAX_HEIGHT_HEADER,
      skeletonColor: "#D5D8DC",
    },
    citrus: {
      title: "Citrus ECF",
      color1: "#F16101",
      color2: "#8AA8B0",
      color3: "#FFFFFF",
      logo: require("../assets/img/citrus.jpg"),
      favicon: require("../assets/img/citrus_favicon.ico"),
      MAX_HEIGHT_HEADER,
      skeletonColor: "#D5D8DC",
    },
  };

  const { configuration } = useApp();
  const { usuario } = useAuthentication();
  const [theme, setTheme] = useState(
    defaultThemes[configuration?.Vendor || defaultVendorTheme]
  );
  const [vendorTheme, setVendorTheme] = useState(
    defaultThemes[defaultVendorTheme]
  );

  useEffect(() => {
    let newVendorTheme =
      defaultThemes[configuration?.Vendor || defaultVendorTheme];
    let newTheme = !usuario
      ? newVendorTheme
      : {
          ...newVendorTheme,
          color1: "#" + usuario.Compania.Color1,
          color2: "#" + usuario.Compania.Color2,
          color3: "#" + usuario.Compania.Color3,
          MAX_HEIGHT_HEADER,
        };
    setVendorTheme(newTheme);
    setTheme(newTheme);
    updateTitle(theme.title);
    updateFavicon(theme.favicon);
  }, [usuario, configuration]);

  const updateTitle = (title) => {
    document.title = title;
  };

  const updateFavicon = (path) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = path;
  };

  return (
    <ThemeContext.Provider value={{ theme, vendorTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
