import React, { useState, useEffect, useCallback } from "react";
import { AppManager } from "managers";
import { AppContext } from "contexts";

export function AppProvider({ children }) {
  const [configuration, setConfiguration] = useState(undefined);
  useEffect(() => {
    let onGetConfigError = (error) => {
      setCurrentConfiguration(null);
    };

    setTimeout(() => {
      AppManager.refreshAppConfiguration()
        .then((res) => {
          if (!res || !res.Success) {
            onGetConfigError(
              new Error("No se pudo obtener la configuración de la aplicación.")
            );
            return;
          } else {
            setCurrentConfiguration(res.Data);
          }
        })
        .catch((err) => {
          onGetConfigError(err);
        });
    }, 100);
  }, []);

  const setCurrentConfiguration = useCallback(
    (data) => {
      setConfiguration(data || null);
    },
    [configuration]
  );

  return (
    <AppContext.Provider value={{ configuration }}>
      {children}
      <div id="sidebarContainer">{/* sidebars container */}</div>
    </AppContext.Provider>
  );
}
