import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import { ConfiguracionCorreoElectronicoManager } from "managers";
import { useAuthentication } from "hooks";
import useMessage from "hooks/useMessage";
import useExceptionHandler from "hooks/useExceptionHandler";
import Select from "react-select";
import { ConfiguracionEmailHelper } from "utils";
import VincularCuentaGoogle from "./VincularCuentaGoogle";
import VincularCuentaMicrosoft from "./VincularCuentaMicrosoft";

function ConfigurarEmailCompania({
  showLoading,
  hideLoading,
  isActive = true,
}) {
  const { usuario } = useAuthentication();
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();
  const [configuracionEmail, setConfiguracionEmail] = useState({});
  const [tipoActual, setTipoActual] = useState();
  const fuente = ConfiguracionEmailHelper.getMicrosoftFuente();
  const referencia = ConfiguracionEmailHelper.getMicrosoftReferencia();

  useEffect(() => {
    if (isActive) {
      showLoading();
      cargarConfiguracionEmail();
    }
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [isActive]);

  const setDefaultTipo = () => {
    setTipoActual(ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DEFAULT);
    setConfiguracionEmail({
      ...configuracionEmail,
      Tipo: ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DEFAULT,
    });
  };

  const cargarConfiguracionEmail = () => {
    showLoading();
    const code = ConfiguracionEmailHelper.obtenerCodigoAutorizacion();
    ConfiguracionCorreoElectronicoManager.buscarMailConfig({
      fuente: ConfiguracionEmailHelper.FUENTE_ENTIDAD.COMPANIA,
      referencia: usuario.CompaniaId,
    })
      .then((data) => {
        if (code) {
          data = {
            ...data,
            Tipo: ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.OUTLOOK,
          };
        } else if (!data.Tipo) {
          data = {
            ...data,
            Tipo: ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO_DEFAULT,
          };
        }
        setConfiguracionEmail(data);
        setTipoActual(data.Tipo);
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const grabar = () => {
    showLoading();
    ConfiguracionCorreoElectronicoManager.actualizarMailConfig(
      configuracionEmail
    )
      .then(() => {
        Message({ message: `Actualizado Exitosamente` });
        cargarConfiguracionEmail();
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        hideLoading();
      });
  };

  return (
    <>
      <div>
        <Col md="12" className="p-0">
          <Form
            action=""
            className="form-horizontal"
            id="TypeValidation"
            method=""
          >
            <EditHeader
              showLoading={showLoading}
              hideLoading={hideLoading}
              titulo="Servidor de Correos"
            />
            <EditContainer>
              <EditSection>
                <Row>
                  <Form.Label column sm="2">
                    Tipo
                  </Form.Label>
                  <Col sm="4">
                    <Form.Group>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Tipo"
                        onChange={(e) => {
                          setConfiguracionEmail({
                            ...configuracionEmail,
                            Tipo: e.value,
                          });
                        }}
                        options={
                          ConfiguracionEmailHelper.PROVEEDOR_SERVIDOR_CORREO_DDL
                        }
                        value={ConfiguracionEmailHelper.PROVEEDOR_SERVIDOR_CORREO_DDL.filter(
                          function (option) {
                            return (
                              option.value ===
                              (configuracionEmail.Tipo ||
                                ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO
                                  .OUTLOOK)
                            );
                          }
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </EditSection>
              {configuracionEmail.Tipo ===
                ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.GMAIL &&
                usuario.CompaniaId && (
                  <VincularCuentaGoogle
                    showLoading={showLoading}
                    hideLoading={hideLoading}
                    configuracionEmail={configuracionEmail || {}}
                    setConfiguracionEmail={setConfiguracionEmail}
                    setDefaultTipo={setDefaultTipo}
                    tipoActual={tipoActual}
                    setTipoActual={setTipoActual}
                    fuente={ConfiguracionEmailHelper.FUENTE_ENTIDAD.COMPANIA}
                    referencia={usuario.CompaniaId}
                  ></VincularCuentaGoogle>
                )}
              {configuracionEmail.Tipo ===
                ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.OUTLOOK && (
                <VincularCuentaMicrosoft
                  showLoading={showLoading}
                  hideLoading={hideLoading}
                  configuracionEmail={configuracionEmail || {}}
                  setConfiguracionEmail={setConfiguracionEmail}
                  setDefaultTipo={setDefaultTipo}
                  tipoActual={tipoActual}
                  setTipoActual={setTipoActual}
                  fuente={fuente}
                  referencia={referencia}
                ></VincularCuentaMicrosoft>
              )}
            </EditContainer>
          </Form>
        </Col>
      </div>
    </>
  );
}

export default ConfigurarEmailCompania;
