import { useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import XMLViewer from "react-xml-viewer";
import Select from "react-select";

import useMessage from "hooks/useMessage";
import ToolTip from "components/ToolTip/ToolTip";
import IconButton from "components/IconButton/IconButton";
import { TransaccionesManager as manager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import { DateHelper, DEFAULT_PAGINATION_VALUES } from "utils";
import ListHeader from "components/PantallaList/Header/ListHeader";
import ModalContainer from "components/ModalContainer/ModalContainer";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import { GraficosManager } from "managers";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { Transacciones } from "models/ECF/Transacciones";
import { useAuthentication } from "hooks";
import { Permisos } from "utils/permisos";

import uuid from "react-uuid";

import {
  PERIODO_TABLA_LIST,
  PERIODICIDAD_TABLA_ACTUAL_LIST,
  PERIODICIDAD_TABLA_PASADO_LIST,
  PERIODICIDAD_TABLA_OTRO_LIST,
  PERIODO_TABLA_ACTUAL,
  PERIODO_TABLA_PASADO,
  TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL,
  TIPO_COMPROBANTE_FACTURA_CONSUMO,
  TIPO_COMPROBANTE_NOTA_DEBITO,
  TIPO_COMPROBANTE_NOTA_CREDITO,
  TIPO_COMPROBANTE_REGIMEN_ESPECIAL,
  TIPO_COMPROBANTE_GUBERNAMENTAL,
  PERIODICIDAD_TABLA_OTRO_RANGO,
  PERIODO_TABLA_OTRO,
  TIPO_COMPROBANTE_LIST,
  TIPO_COMPROBANTE_COMPRAS,
  TIPO_COMPROBANTE_GASTO_MENOR,
  TIPO_COMPROBANTE_PAGOS_EXTERIOR,
  TIPO_NOTAS_CANCELACION,
} from "utils";

import {
  GridView,
  InputSidebarContainer,
  SvgComponent,
  Simplebar,
} from "components";
import ReporteViewer from "components/Reportes/ReporteViewer";
import Exportar from "components/Exportar/Exportar";
import { TransaccionesManager } from "managers";
import { DetallesItems } from "models/ECF/DetallesItem";
import { TRANSACCION_ESTATUS_EN_PROCESO_LABEL } from "utils";
import { TRANSACCION_ESTATUS_EN_PROCESO } from "utils";
import { TRANSACCION_ESTATUS_ERROR_INTERNO } from "utils";
import { ESTADO_ECF_ACEPTADO } from "utils";
import { ESTADO_ECF_ACEPTADO_CONDICIONAL } from "utils";
import { ESTADO_ECF_ACEPTADO_LABEL } from "utils";
import { ESTADO_ECF_ACEPTADO_CONDICIONAL_LABEL } from "utils";

export default function TransaccionesList({ showLoading, hideLoading }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const [currentTransaccion, setCurrentTransaccion] = useState({});
  const [showCanvas, setShowCanvas] = useState(false);
  const [showCanvasReporte, setShowCanvasReporte] = useState(false);
  const [showExportar, setShowExportar] = useState(false);
  const [showExportarDetalles, setShowExportarDetalles] = useState(false);
  const [dateFilterkey, setDateFilterKey] = useState(uuid());
  const [fechaCreacionFilterkey, setFechaCreacionFilterKey] = useState(uuid());
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCancelarModal, setShowCancelarModal] = useState(false);
  const [correoElectronicoModal, setCorreoElectronicoModal] = useState("");
  const { usuario, tienePermiso } = useAuthentication();

  const [reporteWhere, setReporteWhere] = useState({
    TipoEcfList: [
      TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL,
      TIPO_COMPROBANTE_FACTURA_CONSUMO,
      TIPO_COMPROBANTE_NOTA_DEBITO,
      TIPO_COMPROBANTE_NOTA_CREDITO,
      TIPO_COMPROBANTE_REGIMEN_ESPECIAL,
      TIPO_COMPROBANTE_GUBERNAMENTAL,
      TIPO_COMPROBANTE_COMPRAS,
      TIPO_COMPROBANTE_GASTO_MENOR,
      TIPO_COMPROBANTE_PAGOS_EXTERIOR,
    ],
  });
  const [showCanvasFiltrosReporte, setShowCanvasFiltrosReporte] =
    useState(false);
  const [reporte, setReporte] = useState(undefined);
  const [sucursales, setSucursales] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [tipoComprobantes, setTipoComprobantes] = useState([]);
  const [codigosVendedores, setCodigosVendedores] = useState([]);

  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas, Message, Warning } = useMessage();
  const ref = useRef(null);

  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    { value: "N", label: "Pendiente Envio" },
    { value: "P", label: "En Proceso" },
    { value: "I", label: "Error Interno" },
    { value: "T", label: "Terminada" },
  ];

  const ESTADO_LIST = [
    { value: "", label: "Todos" },
    { value: "A", label: "Aceptado" },
    { value: "C", label: "Aceptado Condicional" },
    { value: "R", label: "Rechazado" },
    { value: "P", label: "En Proceso" },
  ];

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  function buscar() {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
      FechaInicio: where.RangoFechas?.fechaInicio,
      FechaFin: where.RangoFechas?.fechaFin,
      FechaInicioCreacion: where.RangoFechasCreacion?.fechaInicio,
      FechaFinCreacion: where.RangoFechasCreacion?.fechaFin,
    };
    manager
      .listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        try {
          for (let transaccion of res.Data) {
            transaccion["Fecha"] = DateHelper.formatStringDate(
              transaccion["Fecha"],
              false
            );
            transaccion["FechaCreacion"] = DateHelper.formatStringDate(
              transaccion["FechaCreacion"],
              true
            );

            for (let transaccionDetalles of transaccion.Detalles) {
              transaccionDetalles["Fecha"] = DateHelper.formatStringDate(
                transaccionDetalles["Fecha"],
                false
              );
            }
          }
        } catch (err) {}
        setList(res.Data);
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());

    setTipoComprobantes([
      {
        value: "-1",
        label: "Todos",
      },
      ...TIPO_COMPROBANTE_LIST,
    ]);

    GraficosManager.obtenerDatosFiltros()
      .then((res) => {
        const sucursales = res.Data?.Sucursales?.map((sucursal) => {
          return {
            label: sucursal,
            value: sucursal,
          };
        });
        sucursales.unshift({
          label: "Todas",
          value: "",
        });
        setSucursales(sucursales);

        const monedas = res.Data?.Monedas?.map((moneda) => {
          return {
            label: moneda,
            value: moneda,
          };
        });
        monedas.unshift({
          label: "Todas",
          value: "",
        });
        setMonedas(monedas);

        const codigosVendedores = res.Data?.CodigosVendedores?.map(
          (vendedor) => {
            return {
              label: vendedor,
              value: vendedor,
            };
          }
        );
        codigosVendedores.unshift({
          label: "Todos",
          value: "",
        });
        setCodigosVendedores(codigosVendedores);
      })
      .catch((err) => handleException(err));
  }

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (params) => {
    const { Id } = params;
    const transaccion = list.find((t) => t.IdSec == Id);
    if (transaccion !== undefined) {
      setCurrentTransaccion(transaccion);
      setShowCanvas(true);
    }
  };
  const handleEmailClick = (params) => {
    const { Id } = params;
    const transaccion = list.find((t) => t.IdSec == Id);
    if (transaccion !== undefined) {
      setCurrentTransaccion(transaccion);
      setShowEmailModal(true);
    }
  };

  const handleCancelarClick = (params) => {
    const { Id } = params;
    const transaccion = list.find((t) => t.IdSec == Id);
    if (transaccion !== undefined) {
      if (transaccion.eCFCancelado) {
        Warning({
          message: `Ya fue generada una Nota de Cancelación para este comprobante.`,
        });
        return;
      }
      setCurrentTransaccion({
        ...transaccion,

        TipoNotaCancelacion: transaccion.ENCF.startsWith("E34")
          ? TIPO_COMPROBANTE_NOTA_DEBITO
          : TIPO_COMPROBANTE_NOTA_CREDITO,
        fechaEmisionCancelacion: new Date(),
        fechaVencimientoSecuenciaCancelacion: new Date(
          new Date().getFullYear(),
          11,
          31,
          23,
          59,
          59,
          999
        ),
      });
      if (
        transaccion.EstadoECF == ESTADO_ECF_ACEPTADO_LABEL ||
        transaccion.EstadoECF == ESTADO_ECF_ACEPTADO_CONDICIONAL_LABEL
      ) {
        setShowCancelarModal(true);
      } else {
        Warning({
          message: `Para generar Nota de Cancelación el comprobante debe ser ${ESTADO_ECF_ACEPTADO_LABEL}.`,
        });
      }
    }
  };

  const openReporteSideBar = () => {
    setShowCanvasFiltrosReporte(true);
  };

  const openExportar = () => {
    setShowExportar(true);
  };
  const openExportarDetalles = () => {
    setShowExportarDetalles(true);
  };

  const imprimirReporte = () => {
    showLoading();
    manager
      .reporte(reporteWhere)
      .then((res) => {
        setReporte(res.Data);
        setShowCanvasReporte(true);
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());
  };

  const representacionImpresa = (IdSec) => {
    showLoading();
    manager
      .representacionImpresa(IdSec)
      .then((res) => {
        setReporte(res.Data);
        setShowCanvasReporte(true);
      })
      .catch((err) => {
        handleException(err);
      })
      .finally(() => hideLoading());
  };

  const cancelarECF = () => {
    showLoading();

    manager
      .cancelarECF({
        TransaccionId: currentTransaccion.IdSec,
        fechaEmisionCancelacion: currentTransaccion.fechaEmisionCancelacion,
        TipoNota: currentTransaccion.TipoNotaCancelacion,
        NCFCancelacion: currentTransaccion.NCFCancelacion,
        FechaVencimientoSecuenciaCancelacion:
          currentTransaccion.fechaVencimientoSecuenciaCancelacion,
      })
      .then((res) => {
        if (
          res.Data.EstadoeCF != ESTADO_ECF_ACEPTADO_LABEL &&
          res.Data.EstadoeCF != ESTADO_ECF_ACEPTADO_CONDICIONAL
        ) {
          Warning({ message: res.Data.Mensaje });
          return;
        }
        Message({ message: "Nota de Cancelación generada Exitosamente." });
      })
      .catch((err) => {
        handleException(err);
      })
      .finally(() => hideLoading());
  };

  const descargarArchivoXML = (IdSec, nombreArchivo) => {
    showLoading();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = nombreArchivo;
    manager
      .descargarArchivoXML(IdSec)
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => hideLoading());
  };

  const descargarArchivoRespuestaXML = (IdSec, nombreArchivo) => {
    showLoading();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = nombreArchivo;
    manager
      .descargarArchivoRespuestaXML(IdSec)
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => hideLoading());
  };
  const FilterSimplebar = () => {
    return (
      <Simplebar
        ref={ref}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvasFiltrosReporte}
        setShow={(e) => {
          setShowCanvasFiltrosReporte(e);
        }}
        useLoading={true}
        title="Filtros Reporte Transacciones"
        btnLeft={{
          show: false,
        }}
        btnRight={{
          show: true,
          message: "Imprimir",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "check",
          func: () => {
            imprimirReporte();
            setShowCanvasFiltrosReporte(false);
          },
        }}
      >
        <InputSidebarContainer label="Periodo">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Periodo"
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                Periodo: e.value,
              });
            }}
            options={PERIODO_TABLA_LIST}
            value={PERIODO_TABLA_LIST.filter(function (option) {
              return (
                option.value ===
                (reporteWhere.Periodo
                  ? reporteWhere.Periodo
                  : PERIODO_TABLA_LIST[0].value)
              );
            })}
          />

          {reporteWhere.Periodo === PERIODO_TABLA_ACTUAL && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_ACTUAL_LIST}
              value={PERIODICIDAD_TABLA_ACTUAL_LIST.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.Periodicidad
                    ? reporteWhere.Periodicidad
                    : PERIODICIDAD_TABLA_ACTUAL_LIST[0].value)
                );
              })}
            />
          )}

          {reporteWhere.Periodo === PERIODO_TABLA_PASADO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_PASADO_LIST}
              value={PERIODICIDAD_TABLA_PASADO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.Periodicidad
                    ? reporteWhere.Periodicidad
                    : PERIODICIDAD_TABLA_PASADO_LIST[0].value)
                );
              })}
            />
          )}

          {reporteWhere.Periodo === PERIODO_TABLA_OTRO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_OTRO_LIST}
              value={PERIODICIDAD_TABLA_OTRO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.Periodicidad
                    ? reporteWhere.Periodicidad
                    : PERIODICIDAD_TABLA_OTRO_LIST[0].value)
                );
              })}
            />
          )}

          {reporteWhere.Periodo === PERIODO_TABLA_OTRO &&
            reporteWhere.Periodicidad === PERIODICIDAD_TABLA_OTRO_RANGO && (
              <InputSidebarContainer label="Rango de Fechas">
                <DateTimePicker
                  usarLabel={false}
                  esRangoFechas={true}
                  labelFechaInicio="Desde"
                  labelFechaFin="Hasta"
                  fechaInicioOriginal={reporteWhere.FechaInicio}
                  fechaFinOriginal={reporteWhere.FechaFin}
                  onChange={(e) => {
                    if (
                      (reporteWhere.FechaInicio !==
                        DateHelper.formatDate(e.fechaInicio) ||
                        reporteWhere.FechaFin !==
                          DateHelper.formatDate(e.fechaFin)) &&
                      (e.fechaFin || e.fechaInicio)
                    ) {
                      setReporteWhere({
                        ...reporteWhere,
                        FechaInicio: DateHelper.formatDate(e.fechaInicio),
                        FechaFin: DateHelper.formatDate(e.fechaFin),
                      });
                    }
                  }}
                />
              </InputSidebarContainer>
            )}
        </InputSidebarContainer>

        <InputSidebarContainer label="Tipo Comprobante">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="TipoEcfList"
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                TipoEcfList: e.value,
              });
            }}
            options={tipoComprobantes}
            value={tipoComprobantes.filter(function (option) {
              return (
                option.value ===
                (reporteWhere.TipoEcfList
                  ? reporteWhere.TipoEcfList
                  : tipoComprobantes[0].value)
              );
            })}
          />
        </InputSidebarContainer>

        <InputSidebarContainer label="Sucursal">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Sucursal"
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                Sucursal: e.value,
              });
            }}
            options={sucursales}
            value={sucursales.filter(function (option) {
              return (
                option.value ===
                (reporteWhere.Sucursal
                  ? reporteWhere.Sucursal
                  : sucursales[0].value)
              );
            })}
          />
        </InputSidebarContainer>
        <InputSidebarContainer label="Moneda">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Sucursal"
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                Moneda: e.value,
              });
            }}
            options={monedas}
            value={monedas.filter(function (option) {
              return (
                option.value ===
                (reporteWhere.Moneda ? reporteWhere.Moneda : monedas[0].value)
              );
            })}
          />
        </InputSidebarContainer>
        <InputSidebarContainer label="Codigo Vendedor">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="CodigoVendedor"
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                CodigoVendedor: e.value,
              });
            }}
            options={codigosVendedores}
            value={codigosVendedores.filter(function (option) {
              return (
                option.value ===
                (reporteWhere.CodigoVendedor
                  ? reporteWhere.CodigoVendedor
                  : codigosVendedores[0].value)
              );
            })}
          />
        </InputSidebarContainer>
      </Simplebar>
    );
  };

  const onReset = () => {
    setDateFilterKey(uuid());
    setFechaCreacionFilterKey(uuid());
  };

  const enviarRepresentacionEmail = (IdSec) => {
    showLoading();
    manager
      .enviarEmail(IdSec, currentTransaccion.CorreoComprador)
      .then((res) => {
        setShowEmailModal(false);
        Message({ message: "Email enviado Exitosamente." });
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => hideLoading());
  };

  const emailModal = (
    <ModalContainer
      showModal={showEmailModal}
      size="lg"
      title="Enviar Representación Impresa Por Email"
      btnGrabar={{
        label: "Enviar Email",
        show: true,
      }}
      cerrar={() => {
        setShowEmailModal(false);
      }}
      grabar={() => {
        enviarRepresentacionEmail(currentTransaccion.IdSec);
      }}
    >
      <EditSection>
        <Row colSpan={1}>
          <InputSidebarContainer label="Email">
            <Form.Control
              disabled={false}
              value={currentTransaccion.CorreoComprador}
              onChange={(e) =>
                setCurrentTransaccion({
                  ...currentTransaccion,
                  CorreoComprador: e.target.value,
                })
              }
            ></Form.Control>
          </InputSidebarContainer>
        </Row>
      </EditSection>
    </ModalContainer>
  );

  const cancelarModal = (
    <ModalContainer
      showModal={showCancelarModal}
      size="lg"
      title={`Nota de Cancelación Transacción: ${currentTransaccion.IdSec}`}
      subtitle={`NCF: ${currentTransaccion.ENCF}`}
      btnGrabar={{
        label: "Generar Nota de Cancelación",
        show: true,
      }}
      cerrar={() => {
        setShowCancelarModal(false);
      }}
      grabar={() => {
        cancelarECF();
      }}
    >
      <EditSection>
        <Row>
          <InputSidebarContainer label="Fecha de Emisión" colSize={5}>
            <DateTimePicker
              soloFecha={true}
              usarLabel={false}
              fechaInicioOriginal={currentTransaccion.fechaEmisionCancelacion}
              onChange={(e) => {
                if (e.fechaInicio != null)
                  setCurrentTransaccion({
                    ...currentTransaccion,
                    fechaEmisionCancelacion: DateHelper.formatDate(
                      e.fechaInicio
                    ),
                  });
              }}
            />
          </InputSidebarContainer>

          <InputSidebarContainer label="Tipo Nota de Cancelación" colSize={5}>
            <Select
              className="react-select primary mb-2"
              classNamePrefix="react-select"
              name="TipoNotaCancelacion"
              isDisabled={true}
              onChange={(e) =>
                setCurrentTransaccion({
                  ...currentTransaccion,
                  TipoNotaCancelacion: e.value,
                })
              }
              options={TIPO_NOTAS_CANCELACION}
              value={TIPO_NOTAS_CANCELACION.filter(function (option) {
                return (
                  option.value ===
                  (currentTransaccion.TipoNotaCancelacion
                    ? currentTransaccion.TipoNotaCancelacion
                    : TIPO_NOTAS_CANCELACION[0].value)
                );
              })}
            />
          </InputSidebarContainer>
          <InputSidebarContainer label="NCF Nota de Cancelación" colSize={5}>
            <Form.Control
              disabled={false}
              value={currentTransaccion.NCFCancelacion}
              onChange={(e) =>
                setCurrentTransaccion({
                  ...currentTransaccion,
                  NCFCancelacion: e.target.value,
                })
              }
            ></Form.Control>
          </InputSidebarContainer>

          <InputSidebarContainer
            label="Fecha de Vencimiento de Secuencia"
            colSize={5}
          >
            <DateTimePicker
              soloFecha={true}
              usarLabel={false}
              fechaInicioOriginal={
                currentTransaccion.fechaVencimientoSecuenciaCancelacion
              }
              onChange={(e) => {
                if (e.fechaInicio != null)
                  setCurrentTransaccion({
                    ...currentTransaccion,
                    fechaVencimientoSecuenciaCancelacion: DateHelper.formatDate(
                      e.fechaInicio
                    ),
                  });
              }}
            />
          </InputSidebarContainer>
        </Row>
      </EditSection>
    </ModalContainer>
  );

  return (
    <>
      <ViewTransaccion
        setShowCanvas={setShowCanvas}
        showCanvas={showCanvas}
        transaccion={currentTransaccion}
        showLoading={showLoading}
        hideLoading={hideLoading}
        handleException={handleException}
        setReporte={setReporte}
        reporte={reporte}
        setShowCanvasReporte={setShowCanvasReporte}
      />
      {showEmailModal && emailModal}
      {showCancelarModal && cancelarModal}
      <Exportar
        PROPIEDADES_EXPORTAR={Transacciones.PROPIEDADES_EXPORTAR}
        showExportar={showExportar}
        setShowExportar={setShowExportar}
        showLoading={showLoading}
        hideLoading={hideLoading}
        where={where}
        exportarCallBack={TransaccionesManager.exportar}
      ></Exportar>

      <Exportar
        PROPIEDADES_EXPORTAR={DetallesItems.PROPIEDADES_EXPORTAR}
        showExportar={showExportarDetalles}
        setShowExportar={setShowExportarDetalles}
        showLoading={showLoading}
        hideLoading={hideLoading}
        where={where}
        exportarCallBack={TransaccionesManager.exportarDetalles}
      ></Exportar>

      <FilterSimplebar />

      <ListHeader
        titulo="Transacciones E-CF"
        reporte={openReporteSideBar}
        exportar={openExportar}
        openExportarDetalles={openExportarDetalles}
      />
      <ReporteViewer
        showCanvas={showCanvasReporte}
        setShowCanvas={setShowCanvasReporte}
        reporte={reporte}
      ></ReporteViewer>

      <GridView
        pagination={pagination}
        setPagination={setPagination}
        allowPaging={pagination.EsPaginable}
        pageSize={pagination.CantidadPorPagina}
        pageIndex={pagination.IndiceDePagina}
        lastPageNumber={pagination.PaginasTotal}
        dataSource={list}
        onPageIndexChanging={onPageIndexChanging}
        where={where}
        setWhere={setWhere}
        height="78vh"
        onReset={onReset}
      >
        <GridView.Column
          isField
          width="250px"
          showClearFilter={true}
          bodyRenderer={(row) => {
            return (
              <>
                {tienePermiso(Permisos.Transacciones.CANCELAR) && (
                  <div className="float-right">
                    <IconButton
                      onClick={() =>
                        handleCancelarClick({
                          Id: row.IdSec,
                        })
                      }
                    >
                      <ToolTip message={`Cancelar eCF `}>
                        <div>
                          <SvgComponent
                            iconName="cancel"
                            height="25px"
                            width="25px"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                  </div>
                )}

                <div className="float-right">
                  <IconButton
                    onClick={() => {
                      descargarArchivoRespuestaXML(
                        row.IdSec,
                        row.RutaRespuestaXML
                      );
                    }}
                  >
                    <ToolTip message="Archivo Respuesta XML">
                      <div>
                        <SvgComponent
                          iconName="download"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
                <div className="float-right">
                  <IconButton
                    onClick={() => {
                      descargarArchivoXML(row.IdSec, row.RutaXML);
                    }}
                  >
                    <ToolTip message="Archivo XML">
                      <div>
                        <SvgComponent
                          iconName="download"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>

                <div className="float-right">
                  <IconButton
                    onClick={() => {
                      representacionImpresa(row.IdSec);
                    }}
                  >
                    <ToolTip message="Representación Impresa">
                      <div>
                        <SvgComponent
                          iconName="print"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
                <div className="float-right">
                  <IconButton
                    onClick={() =>
                      handleEmailClick({
                        Id: row.IdSec,
                      })
                    }
                  >
                    <ToolTip message="Enviar Email">
                      <div>
                        <SvgComponent
                          iconName="email"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>

                <div className="float-right">
                  <IconButton
                    onClick={() =>
                      handleClick({
                        Id: row.IdSec,
                      })
                    }
                  >
                    <ToolTip message="Ver">
                      <div>
                        <SvgComponent
                          iconName="view"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
              </>
            );
          }}
        />
        <GridView.Column
          headerText="Id"
          dataField="IdSec"
          sortExpression="IdSec"
          width="75px"
        />
        <GridView.Column
          date
          hideFilter
          headerText="Fecha"
          dataField="Fecha"
          sortExpression="FechaEmision"
          width="250px"
          filterRenderer={(onInputChange) => {
            return (
              <DateTimePicker
                key={dateFilterkey}
                soloFecha
                esRangoFechas
                esGrid
                usarLabel={false}
                fechaInicioOriginal={where?.RangoFechas?.fechaInicio || ""}
                fechaFinOriginal={where?.RangoFechas?.fechaFin || ""}
                onChange={(e) =>
                  onInputChange({
                    target: { name: "RangoFechas", value: e },
                  })
                }
              />
            );
          }}
        />
        <GridView.Column
          headerText="E-CF"
          dataField="ENCF"
          sortExpression="ENCF"
          width="180px"
        />
        <GridView.Column
          headerText="Id Interno"
          dataField="NumeroFacturaInterna"
          sortExpression="NumeroFacturaInterna"
          width="250px"
        />
        <GridView.Column
          headerText="Estatus"
          dataField="Estatus"
          sortExpression="Estatus"
          width="180px"
          filterRenderer={() => {
            return (
              <Select
                className="react-select primary"
                options={ESTATUS_LIST}
                value={ESTATUS_LIST.filter(function (option) {
                  return (
                    option.value ===
                    (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                  );
                })}
                name="Estatus"
                onChange={(e) =>
                  setWhere({
                    ...where,
                    Estatus: e.value,
                  })
                }
              />
            );
          }}
        />
        <GridView.Column
          headerText="Estado e-CF"
          dataField="EstadoECF"
          sortExpression="EstadoECF"
          width="180px"
          filterRenderer={() => {
            return (
              <Select
                className="react-select primary"
                options={ESTADO_LIST}
                value={ESTADO_LIST.filter(function (option) {
                  return (
                    option.value ===
                    (where.Estatus ? where.Estatus : ESTADO_LIST[0].value)
                  );
                })}
                name="EstadoECF"
                onChange={(e) =>
                  setWhere({
                    ...where,
                    EstadoECF: e.value,
                  })
                }
              />
            );
          }}
        />
        RNCComprador
        <GridView.Column
          headerText="Mensaje Respuesta"
          dataField="MensajeRespuesta"
          sortExpression="MensajeRespuesta"
          width="400px"
          isDisabled
        />
        <GridView.Column
          headerText="RNC Comprador"
          dataField="RNCComprador"
          sortExpression="RNCComprador"
          width="400px"
        />
        <GridView.Column
          headerText="Razon Social Comprador"
          dataField="RazonSocialComprador"
          sortExpression="RazonSocialComprador"
          width="400px"
        />
        <GridView.Column
          headerText="Monto Gravado"
          dataField="MontoGravadoTotal"
          sortExpression="MontoGravadoTotal"
          width="180px"
          number={true}
        />
        <GridView.Column
          headerText="Monto Exento"
          dataField="MontoExento"
          sortExpression="MontoExento"
          width="180px"
          number={true}
        />
        <GridView.Column
          headerText="Total ITBIS"
          dataField="TotalITBIS"
          sortExpression="TotalITBIS"
          width="180px"
          number={true}
        />
        <GridView.Column
          headerText="Monto Total"
          dataField="MontoTotal"
          sortExpression="MontoTotal"
          width="180px"
          number={true}
        />
        <GridView.Column
          date
          hideFilter
          headerText="Fecha Creacion"
          dataField="FechaCreacion"
          sortExpression="FechaCreacion"
          width="250px"
          filterRenderer={(onInputChange) => {
            return (
              <DateTimePicker
                key={fechaCreacionFilterkey}
                soloFecha
                esRangoFechas
                esGrid
                usarLabel={false}
                fechaInicioOriginal={
                  where?.RangoFechasCreacion?.fechaInicio || ""
                }
                fechaFinOriginal={where?.RangoFechasCreacion?.fechaFin || ""}
                onChange={(e) =>
                  onInputChange({
                    target: { name: "RangoFechasCreacion", value: e },
                  })
                }
              />
            );
          }}
        />
      </GridView>
    </>
  );
}

function ViewTransaccion({
  showCanvas,
  setShowCanvas,
  transaccion,
  showLoading,
  hideLoading,
  handleException,
  setReporte,
  setShowCanvasReporte,
}) {
  const [showModal, setShowModal] = useState(false);
  const [currentDetalle, setCurrentDetalle] = useState({});
  const { Message } = useMessage();
  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    { value: "N", label: "Pendiente Envio" },
    { value: "P", label: "En Proceso" },
    { value: "I", label: "Error Interno" },
    { value: "T", label: "Terminada" },
  ];

  const modal = (
    <ModalContainer
      showModal={showModal}
      size="lg"
      title="Detalles Transacción"
      btnGrabar={{
        show: true,
      }}
      cerrar={() => {
        setShowModal(false);
        setCurrentDetalle({});
      }}
    >
      <EditSection>
        <Row colSpan={1}>
          <InputSidebarContainer label="Proceso">
            <Form.Control
              value={currentDetalle.Proceso}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
        </Row>
        <Row>
          <InputSidebarContainer label="Request">
            <Form.Control
              value={currentDetalle.Request}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
        </Row>
        <Row>
          <InputSidebarContainer label="Response">
            <div className="col-sm-12">
              <XMLViewer
                xml={currentDetalle.Response}
                theme={{
                  overflowBreak: true,
                }}
              />
            </div>
          </InputSidebarContainer>
        </Row>
        <Row>
          <InputSidebarContainer label="Content">
            <div className="col-sm-12">
              <XMLViewer
                xml={currentDetalle.Content}
                theme={{
                  overflowBreak: true,
                }}
              />
            </div>
          </InputSidebarContainer>
        </Row>
      </EditSection>
    </ModalContainer>
  );

  const handleClick = (params) => {
    const { Id } = params;
    const detalle = transaccion.Detalles?.find((t) => t.IdSec == Id);
    if (detalle !== undefined) {
      setCurrentDetalle(detalle);
      setShowModal(true);
    }
  };

  const representacionImpresa = (IdSec) => {
    showLoading();
    manager
      .representacionImpresa(IdSec)
      .then((res) => {
        setReporte(res.Data);
        setShowCanvasReporte(true);
      })
      .catch((err) => {
        handleException(err);
      })
      .finally(() => hideLoading());
  };

  const descargarArchivoXML = (IdSec, nombreArchivo) => {
    showLoading();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = nombreArchivo;
    manager
      .descargarArchivoXML(IdSec)
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => hideLoading());
  };

  const descargarArchivoRespuestaXML = (IdSec, nombreArchivo) => {
    showLoading();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = nombreArchivo;
    manager
      .descargarArchivoRespuestaXML(IdSec)
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => hideLoading());
  };

  const consultarEstado = (IdSec) => {
    showLoading();
    manager
      .consultarEstado(IdSec)
      .then((res) => {
        Message({
          message: `Estado consultado Exitosamente. Estado Transacción: ${res.Data.Estatus}  Estado eCF: ${res.Data.EstadoeCF}`,
        });
      })
      .catch((err) => {
        handleException(err);
      })
      .finally(() => hideLoading());
  };

  return (
    <Simplebar
      backdrop={true}
      size="lg"
      placement={"end"}
      portal={false}
      visible={showCanvas}
      setShow={(e) => {
        setShowCanvas(e);
      }}
      useLoading={false}
      title="Transacción Comprobante Fiscal Electrónico"
      btnRight={{
        show: false,
        label: "Grabar",
        func: () => {},
      }}
      btnLeft={{
        show: false,
        label: "Cerrar",
      }}
    >
      {showModal && modal}
      <EditSection title="General">
        <SectionRow>
          <InputSidebarContainer label="Id">
            <Form.Control
              value={transaccion.IdSec}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
          <InputSidebarContainer label="NCF">
            <Form.Control
              value={transaccion.ENCF}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
        </SectionRow>
        <SectionRow>
          <InputSidebarContainer label="Fecha">
            <Form.Control
              value={transaccion.Fecha}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
          <InputSidebarContainer label="Estatus">
            <Form.Control
              value={transaccion.Estatus}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
        </SectionRow>
        <SectionRow>
          <InputSidebarContainer label="Estado e-CF">
            <Form.Control
              value={transaccion.EstadoECF}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
        </SectionRow>

        <InputSidebarContainer label="Mensaje Respuesta">
          <Form.Control
            as={"textarea"}
            value={transaccion.MensajeRespuesta}
            rows={transaccion.MensajeRespuesta ? 4 : 1}
            disabled={true}
          ></Form.Control>
        </InputSidebarContainer>

        <SectionRow>
          <div></div>
          <Row>
            <Col></Col>

            <Col className="d-flex justify-content-end">
              {transaccion.Estatus != TRANSACCION_ESTATUS_ERROR_INTERNO &&
                (transaccion.EstadoECF == "" ||
                  transaccion.EstadoECF ===
                    TRANSACCION_ESTATUS_EN_PROCESO_LABEL) && (
                  <IconButton
                    onClick={() => {
                      consultarEstado(transaccion.IdSec);
                    }}
                  >
                    <ToolTip message="Consultar Estado Transacción">
                      <div>
                        <SvgComponent iconName="search" />
                      </div>
                    </ToolTip>
                  </IconButton>
                )}
              <IconButton
                onClick={() => {
                  representacionImpresa(transaccion.IdSec);
                }}
              >
                <ToolTip message="Representación Impresa">
                  <div>
                    <SvgComponent iconName="print" />
                  </div>
                </ToolTip>
              </IconButton>

              <IconButton
                onClick={() => {
                  descargarArchivoXML(transaccion.IdSec, transaccion.RutaXML);
                }}
              >
                <ToolTip message="Descargar XML">
                  <div>
                    <SvgComponent iconName="download" />
                  </div>
                </ToolTip>
              </IconButton>
              <IconButton
                onClick={() => {
                  descargarArchivoRespuestaXML(
                    transaccion.IdSec,
                    transaccion.RutaRespuestaXML
                  );
                }}
              >
                <ToolTip message="Descargar Respuesta XML">
                  <div>
                    <SvgComponent iconName="download" />
                  </div>
                </ToolTip>
              </IconButton>
            </Col>
          </Row>
        </SectionRow>
      </EditSection>
      <EditSection title="Detalles">
        <GridView
          dataSource={transaccion.Detalles}
          allowPaging={false}
          hideFilters={true}
        >
          <GridView.Column
            width="80px"
            bodyRenderer={(row) => {
              return (
                <>
                  <div className="float-right">
                    <IconButton
                      onClick={() =>
                        handleClick({
                          Id: row.IdSec,
                        })
                      }
                    >
                      <ToolTip message="Ver">
                        <div>
                          <SvgComponent
                            iconName="view"
                            height="25px"
                            width="25px"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                  </div>
                </>
              );
            }}
          />
          <GridView.Column
            headerText="Fecha"
            dataField="Fecha"
            sortExpression="Fecha"
            width="auto"
          />

          <GridView.Column
            headerText="Proceso"
            dataField="Proceso"
            sortExpression="Proceso"
            width="auto"
          />
        </GridView>
      </EditSection>
    </Simplebar>
  );
}
