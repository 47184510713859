import { ImpuestosAdicionalesService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const ImpuestosAdicionalesManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listadoImpuestosAdicionales = (where) => {
    return ImpuestosAdicionalesService.listadoImpuestosAdicionales(where).then(
      (res) => {
        if (!res.Success) {
          handleException(res);
        }
        return res;
      }
    );
  };

  const descargarExcel = () => {
    return ImpuestosAdicionalesService.descargarExcel().then((res) => {
      return res;
    });
  };
  return {
    listadoImpuestosAdicionales,
    descargarExcel,
  };
})();
