import React, { useState, useEffect } from "react";
import { Link, useLocation, Route, useHistory } from "react-router-dom";
import { Collapse, Nav, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTheme } from "hooks";
import { useAuthentication } from "hooks";
import "./Sidebar.styles.scss";
import CambiarContraseniaModal from "components/Model/CambiarContraseniaModal";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import { useConfigurationPage } from "hooks/useConfigurationPage";

function Sidebar({ routes, logo, isFixed = true, codigo = "" }) {
  const { theme } = useTheme();
  const [userCollapseState, setUserCollapseState] = useState(false);
  const [showCambiarPwd, setShowCambiarPwd] = useState(false);
  const { setConfigurationState } = useConfigurationPage();
  const [logoCompania, setLogoCompania] = useState(null);

  const history = useHistory();
  const { usuario } = useAuthentication();
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = useState({});

  useEffect(() => {
    setState(getCollapseStates(routes));
  }, []);

  useEffect(() => {
    setLogoCompania(logo);
  }, [logo]);

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes prop
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.Submenu) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.Submenu),
          ...getCollapseStates(prop.Submenu),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].Submenu)) {
        return true;
      } else if (location.pathname === routes[i].URL) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    if (usuario && usuario.CompaniaId !== 10) {
      routes = routes.filter((ruta) => ruta.URL != "/CompaniaList");
    }
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      // if (!prop.component) {
      //   return null;
      // }
      if (isFixed) {
        return (
          <Nav.Item className={"specialItem"} key={key} as="li">
            {prop.isURL ? (
              <Nav.Link
                to={{ pathname: prop.URL }}
                target="_blank"
                as={Link}
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
                onClick={(e) => setConfigurationState(false)}
              >
                <div
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    maxHeight: "35px",
                  }}
                >
                  {prop.Icono}
                </div>
                <p
                  style={{
                    marginLeft: "15px",
                    fontSize: "15px",
                    marginRight: "15px",
                  }}
                  className="menuName"
                >
                  {prop.Nombre}
                </p>
              </Nav.Link>
            ) : (
              <Nav.Link
                to={prop.URL}
                as={Link}
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
                onClick={(e) => setConfigurationState(false)}
              >
                <div
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    maxHeight: "35px",
                  }}
                >
                  {prop.Icono}
                </div>
                <p
                  style={{
                    marginLeft: "15px",
                    fontSize: "15px",
                    marginRight: "15px",
                  }}
                  className="menuName"
                >
                  {prop.Nombre}
                </p>
              </Nav.Link>
            )}
          </Nav.Item>
        );
      }
      if (prop.Submenu) {
        var st = {};
        for (const key in state) {
          if (key.includes("_state")) {
            st[key] = false;
          }
        }
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.Submenu) ? "active" : ""}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
                setConfigurationState(false);
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.Icono ? prop.Icono : "fa-solid fa-star"}></i>
              <p>
                {prop.Nombre} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.Submenu)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }

      return (
        <Nav.Item className={activeRoute(prop.URL)} key={key} as="li">
          <Nav.Link
            to={prop.URL}
            as={Link}
            onClick={(e) => setConfigurationState(false)}
          >
            {prop.Icono ? (
              <>
                <i className={prop.Icono} />
                <p>{prop.Nombre}</p>
              </>
            ) : (
              <>
                <i className={"fa-solid fa-star"} />
                <p>{prop.Nombre}</p>
              </>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const cambiarContrasena = () => {
    setShowCambiarPwd(true);
  };

  const getGradient = (number) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(theme.color1);
    let rgbRes1 = `rgba(${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16
    )}, ${parseInt(result[3], 16)}, 1)`;
    let rgbRes2 = `rgba(${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16
    )}, ${parseInt(result[3], 16)}, 0.5)`;

    if (number === 1) {
      return rgbRes1;
    }
    if (number === 2) {
      return rgbRes2;
    }
  };

  return (
    <>
      {showCambiarPwd && (
        <CambiarContraseniaModal
          setMostrarModalCambiarContrasenia={setShowCambiarPwd}
          mostrarModalCambiarContrasenia={showCambiarPwd}
          user={usuario}
        />
      )}
      <div
        className="sidebar"
        style={{
          backgroundImage: `linear-gradient(${theme.color1}, ${theme.color2} 150%)`,
        }}
      >
        <div
          className="sidebar-wrapper"
          style={{
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {logo.length > 25 ? (
            <div
              style={{
                backgroundColor: "#fefefe",
                width: "250px",
                zIndex: "1000",
                padding: "0px",
                margin: "0px",
              }}
            >
              <div
                className="logo changingImg"
                style={{
                  paddingTop: "0px",
                  height: "50px",
                  width: "50px",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/dashboard")}
              >
                <a className="simple-text" style={{ paddingTop: "0px" }}>
                  <div
                    className="logo-normal"
                    style={{ backgroundColor: "#fefefe" }}
                  >
                    {<img src={logoCompania} height="50px" width="80px" />}
                  </div>
                </a>
              </div>
            </div>
          ) : (
            <a
              className="simple-text"
              href="/dashboard"
              style={{ paddingTop: "0px" }}
            >
              <div
                className="d-flex align-content-center align-items-center justify-content-center "
                style={{ marginTop: "15px", padding: "2px" }}
              >
                <h5
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    wordBreak: "break-all",
                  }}
                >
                  {codigo.trim().toUpperCase()}
                </h5>
              </div>
            </a>
          )}
          <div className="user" onClick={() => setConfigurationState(false)}>
            {" "}
            <div className="d-flex photo align-content-center">
              {usuario.img64 ? (
                <img alt="Imgen Usuario" src={usuario.img64} />
              ) : (
                <SvgComponent iconName="user" height="35" width="35" />
              )}
            </div>
            <div className="info-user">
              <a
                className={userCollapseState ? "collapsed" : "font-weight-bold"}
                data-toggle="collapse"
                aria-expanded={userCollapseState}
              >
                <span style={{ marginLeft: "65px", cursor: "pointer" }}>
                  {usuario.descripcion.split(" ")[0]}
                </span>
              </a>
            </div>
          </div>
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
      </div>
    </>
  );
}

let linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

Sidebar.defaultProps = {
  routes: [],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...linkPropTypes,
        icon: PropTypes.string,
      }),
      PropTypes.shape({
        collapse: true,
        path: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        icon: PropTypes.string,
        views: PropTypes.shape({
          ...linkPropTypes,
          mini: PropTypes.string,
        }),
      }),
    ])
  ),
};

export const MemoizedSideBar = React.memo(Sidebar);
