import { CompaniaService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const CompaniaManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const buscarPorId = (Id) => {
    return CompaniaService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };
  const actualizar = (compania) => {
    const filesArr = [];
    if (compania.Logo) filesArr.push(compania.Logo[0]);
    if (compania.Certificado) filesArr.push(compania.Certificado[0]);

    return CompaniaService.actualizar({
      ...compania,
      Files: filesArr,
    }).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    return CompaniaService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const crear = (compania) => {
    const filesArr = [];
    if (compania.Logo) filesArr.push(compania.Logo[0]);
    return CompaniaService.crear({
      ...compania,
      Files: filesArr,
    }).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const editar = (compania) => {
    const filesArr = [];
    if (compania.Logo) filesArr.push(compania.Logo[0]);
    return CompaniaService.editar({
      ...compania,
      Files: filesArr,
    }).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listadoDatosCertificado = () => {
    return CompaniaService.listarDatosCertificado().then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    buscarPorId,
    actualizar,
    listado,
    crear,
    editar,
    listadoDatosCertificado
  };
})();
