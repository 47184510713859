import { GraficosService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const GraficosManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const obtenerDatosGraficoComprobantesVenta = (params) => {
    return service.obtenerDatosGraficoComprobantesVenta(params).then((res) => {
      if (!res.Success) handleException(res);
      return res;
    });
  };

  const obtenerDatosGraficoTransacciones = (params) => {
    return service.obtenerDatosGraficoTransacciones(params).then((res) => {
      if (!res.Success) handleException(res);
      return res;
    });
  };

  const obtenerDatosGraficoComprobantesCompras = (params) => {
    return service
      .obtenerDatosGraficoComprobantesCompras(params)
      .then((res) => {
        if (!res.Success) handleException(res);
        return res;
      });
  };

  const obtenerDatosTablaComprobantesCompras = (params) => {
    return service.obtenerDatosTablaComprobantesCompras(params).then((res) => {
      if (!res.Success) handleException(res);
      return res;
    });
  };

  const obtenerDatosTablaComprobantesVentas = (params) => {
    return service.obtenerDatosTablaComprobantesVentas(params).then((res) => {
      if (!res.Success) handleException(res);
      return res;
    });
  };

  const obtenerDatosTablaTop = (params) => {
    return service.obtenerDatosTablaTop(params).then((res) => {
      if (!res.Success) handleException(res);
      return res;
    });
  };
  const obtenerDatosTablaBottom = (params) => {
    return service.obtenerDatosTablaBottom(params).then((res) => {
      if (!res.Success) handleException(res);
      return res;
    });
  };
  const obtenerDatosFiltros = () => {
    return service.obtenerDatosFiltros().then((res) => {
      if (!res.Success) handleException(res);
      return res;
    });
  };
  return {
    obtenerDatosGraficoComprobantesVenta,
    obtenerDatosGraficoComprobantesCompras,
    obtenerDatosGraficoTransacciones,
    obtenerDatosTablaComprobantesCompras,
    obtenerDatosTablaComprobantesVentas,
    obtenerDatosTablaTop,
    obtenerDatosTablaBottom,
    obtenerDatosFiltros,
  };
})();
