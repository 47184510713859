import { AppService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const AppManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const refreshAppConfiguration = () => {
    return AppService.refreshAppConfiguration();
  };

  const reportarError = (reporte) => {
    return AppService.reportarError(reporte);
  };

  return {
    refreshAppConfiguration,
    reportarError,
  };
})();
