import { CRM_API, RequestHelper } from "utils";

const RUTA = "aprobacion-comercial";
export const AprobacionComercialService = (() => {
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}`, params);
    });
  };

  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/${Id}`);
    });
  };

  const crear = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(
        `${RUTA}/generarAprobacionComercial`,
        data
      );
    });
  };

  return {
    listado,
    buscarPorId,
    crear,
  };
})();
