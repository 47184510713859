import { useContext } from "react";
import { ThemeContext } from "contexts";

export function useTheme() {
  const { theme, vendorTheme } = useContext(ThemeContext);
  return {
    theme,
    vendorTheme,
  };
}
