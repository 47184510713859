import { useContext } from "react";
import { AuthorizationContext } from "contexts";
import useMessage from "hooks/useMessage";
import { MENSAJE_ERROR_PERMISO } from "utils";

export function useAuthorization() {
  const { authorization, setAuthorization } = useContext(AuthorizationContext);

  const handleUnauthorizedAccess = (unathorizedModel) => {
    setAuthorization(unathorizedModel);
  };

  return { 
    authorization,
    handleUnauthorizedAccess
  };
}