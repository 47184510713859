import { MenuDetalleService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const MenuDetalleManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const buscarPorId = (Id) => {
    return MenuDetalleService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };
  const listado = (where) => {
    return MenuDetalleService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };
  const crear = (menuDetalle) => {
    return MenuDetalleService.crear(menuDetalle).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };
  const actualizar = (menuDetalle) => {
    return MenuDetalleService.actualizar(menuDetalle).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    buscarPorId,
    listado,
    crear,
    actualizar,
  };
})();
