import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import Select from "react-select";
import { useLocation } from "react-router-dom";

import { FormHelper } from "utils";
import { GridView } from "components";
import { Perfil } from "models/Perfil";
import useMessage from "hooks/useMessage";
import useQueryParams from "hooks/useQueryParams";
import { PerfilManager, FuncionManager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";

export default function PerfilEdit({ showLoading, hideLoading }) {
  const [perfil, setPerfil] = useState({});
  const [esNuevo, setEsNuevo] = useState(false);
  const [tituloHeader, setTituloHeader] = useState("Crear Perfil");
  const [funcionesPorAgregarList, setFuncionesPorAgregarList] = useState([]);

  const URLParameters = useQueryParams();
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();

  const Id = URLParameters.get("Id");
  const esClonar = URLParameters.get("clonar") == "true";
  const ESTATUS_LIST = Perfil.ESTATUS_LIST;
  const location = useLocation();

  useEffect(() => {
    setEsNuevo(!Id || esClonar);
    if (Id) {
      showLoading();
      cargarPerfil(Id);
      esClonar
        ? setTituloHeader("Clonar Perfil")
        : setTituloHeader("Editar Perfil");
    } else {
      setDefaultValues();
    }

    return () => {
      hideLoading();
      cerrarAlertas();
    };
  }, [location.search]);

  const setDefaultValues = () => {
    setPerfil({
      ...perfil,
      Estatus: Perfil.DEFAULT_ESTATUS_VALUE,
      LlamadaCondicion: Perfil.CONDICION_LLAMADA_EN_PROCESO,
    });
  };

  const cargarPerfil = (Id) => {
    PerfilManager.buscarPorId(Id)
      .then((data) => {
        setPerfil(data);
        cargarFuncionesList(data.IdSec, data.Funciones);
      })
      .catch((e) => handleException(e))
      .finally(() => hideLoading());
  };

  const grabar = () => {
    showLoading();
    if (esNuevo) {
      PerfilManager.crear(perfil)
        .then((res) => {
          setEsNuevo(false);
          Message({ message: `Grabado Exitosamente` });
          cargarPerfil(res.IdSec);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    } else {
      setPerfil({ ...perfil, IdSec: Id });
      PerfilManager.actualizar(perfil)
        .then(() => {
          Message({ message: `Actualizado Exitosamente` });
          cargarPerfil(res.IdSec);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    }
  };

  function cargarFuncionesList(Id, funcionesList) {
    FuncionManager.buscarNoEnPerfilId(Id)
      .then((res) => {
        let list = res.concat(funcionesList);
        funcionesList.map((el) => {
          let index = list.findIndex((funcion) => funcion.IdSec === el.IdSec);
          if (index !== -1) {
            list[index].EsAnadirPerfil = true;
          } else {
            list[index].EsAnadirPerfil = false;
          }
        });
        sortPermisosList(list);
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        hideLoading();
      });
  }
  function sortPermisosList(list) {
    if (!list) {
      list = [...funcionesPorAgregarList];
    }
    list.sort((a, b) => {
      return Number(b.EsAnadirPerfil) - Number(a.EsAnadirPerfil);
    });
    setFuncionesPorAgregarList(list);
  }

  function addToList(Id) {
    const index = funcionesPorAgregarList.findIndex((funcion) => {
      return funcion.IdSec === Id;
    });
    perfil.Funciones.push(funcionesPorAgregarList[index]);
  }

  function removeFromList(Id) {
    const index = perfil.Funciones.findIndex((perfil) => {
      return perfil.IdSec === Id;
    });
    setFuncionesPorAgregarList([
      ...funcionesPorAgregarList,
      perfil.Funciones[index],
    ]);
    perfil.Funciones.splice(index, 1);
  }

  function handleFuncionSwitch(params) {
    const { Id } = params;
    const index = funcionesPorAgregarList.findIndex((funcion) => {
      return funcion.IdSec === Id;
    });
    let list = [...funcionesPorAgregarList];
    if (list[index].EsAnadirPerfil) {
      removeFromList(Id);
    } else {
      addToList(Id);
    }
    list[index].EsAnadirPerfil = !funcionesPorAgregarList[index].EsAnadirPerfil;
    setFuncionesPorAgregarList(list);
  }

  const FuncionesGrid = (
    <div>
      <GridView
        allowPaging={false}
        dataSource={funcionesPorAgregarList}
        hideFilters={true}
      >
        <GridView.Column
          headerText="Id"
          dataField="IdSec"
          sortExpression="IdSec"
        />
        <GridView.Column
          headerText="Nombre"
          dataField="Nombre"
          sortExpression="Nombre"
        />
        <GridView.Column
          headerText="Descripción"
          dataField="Descripcion"
          sortExpression="Descripcion"
        />
        <GridView.Column
          isField
          bodyRenderer={(row) => {
            return (
              <>
                <Form.Check
                  type="switch"
                  name="AgregarPerfil"
                  onChange={(e) => {
                    handleFuncionSwitch({ Id: row.IdSec });
                  }}
                  checked={row.EsAnadirPerfil}
                  id={`switch-${row.IdSec}`}
                />
              </>
            );
          }}
        />
      </GridView>
    </div>
  );

  return (
    <Form action="" className="form-horizontal" id="TypeValidation" method="">
      <Card>
        <EditHeader titulo={`${tituloHeader}`} volver={true} grabar={grabar} />
        <EditContainer>
          <EditSection title="Información General">
            <SectionRow>
              <InputContainer label="Nombre">
                <Form.Control
                  name="Nombre"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setPerfil)}
                  value={perfil.Nombre}
                />
              </InputContainer>
              <InputContainer label="Descripción">
                <Form.Control
                  onChange={(e) => FormHelper.handleChange(e, setPerfil)}
                  name="Descripcion"
                  type="text"
                  value={perfil.Descripcion}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Estatus">
                <Select
                  isDisabled={esNuevo}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="Estatus"
                  onChange={(e) =>
                    setPerfil({
                      ...perfil,
                      Estatus: e.value,
                    })
                  }
                  options={ESTATUS_LIST}
                  value={
                    esNuevo
                      ? ESTATUS_LIST.filter(function (option) {
                          return option.value === Perfil.DEFAULT_ESTATUS_VALUE;
                        })
                      : ESTATUS_LIST.filter(function (option) {
                          return option.value === perfil.Estatus;
                        })
                  }
                />
              </InputContainer>
            </SectionRow>
          </EditSection>
          {!esNuevo && (
            <>
              <Card.Header>
                <Card.Title as="h4">Permisos </Card.Title>
              </Card.Header>
              {FuncionesGrid}
            </>
          )}
        </EditContainer>
      </Card>
    </Form>
  );
}
