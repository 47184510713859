import { UnidadesMedidaService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const UnidadesMedidaManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listado = (where) => {
    return UnidadesMedidaService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const descargarExcel = () => {
    return UnidadesMedidaService.descargarExcel().then((res) => {
      return res;
    });
  };

  return {
    listado,
    descargarExcel,
  };
})();
