import { ReferenciasService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const ReferenciasManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listadoMunicipios = (where) => {
    return ReferenciasService.listadoMunicipios(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const descargarMunicipiosExcel = () => {
    return ReferenciasService.descargarMunicipiosExcel().then((res) => {
      return res;
    });
  };

  const descargarProvinciasExcel = () => {
    return ReferenciasService.descargarProvinciasExcel().then((res) => {
      return res;
    });
  };

  return {
    listadoMunicipios,
    descargarMunicipiosExcel,
    descargarProvinciasExcel,
  };
})();
