import { MessageReferenceContext } from "contexts";
import React from "react";

export function MessageReferenceProvider({ children }) {
  return (
    <MessageReferenceContext.Provider value={[]}>
      {children}
    </MessageReferenceContext.Provider>
  );
}

export default MessageReferenceContext.provider;
