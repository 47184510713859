import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Card } from "react-bootstrap";

import { useTheme } from "hooks";
import ToolTip from "components/ToolTip/ToolTip";
import IconButton from "components/IconButton/IconButton";
import { AnimatedIconButton } from "components/AnimatedIconButton/AnimatedIconButton";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import useQueryParams from "hooks/useQueryParams";

function EditHeader(props) {
  const { theme } = useTheme();
  const history = useHistory();
  const URLParameters = useQueryParams();
  const Id = URLParameters.get("Id");

  useEffect(() => {
    window.addEventListener("keydown", onEvent);
    return () => {
      window.removeEventListener("keydown", onEvent);
    };
  });

  function onEvent(e) {
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyS") {
      if (props.grabar) {
        e.preventDefault();
        props.grabar();
      }
    }
  }

  function back() {
    history.goBack();
  }

  return (
    <div
      style={{
        position: `sticky`,
        top: !props.isInSimpleBar ? "48px" : "0px",
        zIndex: 200,
        display: props.esModal ? "none" : "",
        maxHeight: "50px",
      }}
    >
      <Card.Header
        style={{
          background: "white",
          padding: "0.3em 1em 0.3em 1em",
          borderBottom: "1px solid  #e3e3e3",
        }}
      >
        <div className="d-flex justify-content-between">
          <div
            className=" d-flex w-50 align-content-center"
            style={{
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            {props.volver && !props.esView && (
              <IconButton onClick={back}>
                <ToolTip message="Volver">
                  <div>
                    <SvgComponent width="30" height="30" iconName="back" />
                  </div>
                </ToolTip>
              </IconButton>
            )}
            <Card.Title as="h4" className="d-inline font-weight-bold">
              {props.titulo + (Id && !props.hideIdSec ? ` (Id: ${Id})` : "")}
            </Card.Title>
          </div>
          <div className="d-flex w-25 justify-content-end">
            {props.generarCuenta && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="id-card"
                message="Generar Cuenta"
                onClick={props.generarCuenta}
              />
            )}
            {props.nuevoDetalle && (
              <IconButton onClick={props.nuevoDetalle}>
                <ToolTip message="Nuevo Detalle">
                  <i
                    style={{
                      fontSize: "30px",
                      color: `${theme.color1}`,
                    }}
                    className="fa-solid fa-file-circle-plus"
                  ></i>
                </ToolTip>
              </IconButton>
            )}
            {props.descargarPlantillas && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="download"
                message="Descargar Plantillas"
                onClick={props.descargarPlantillas}
              />
            )}
            {props.grabar && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="Save"
                message="Guardar (ctrl + S)"
                onClick={props.grabar}
              />
            )}
            {props.inactivar && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="cancel"
                message="Inactivar"
                onClick={props.inactivar}
              />
            )}
            {props.cambioPassword && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="lock"
                message="Cambiar Contraseña"
                onClick={props.cambioPassword}
              />
            )}

            {props.iniciarLlamada && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="call"
                message="Iniciar Llamada"
                onClick={props.iniciarLlamada}
              />
            )}
            {props.concluirLlamada && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="close-call"
                message="Concluir Llamada"
                onClick={props.concluirLlamada}
              />
            )}
            {props.copiarToken && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="copy"
                message="Copiar Token"
                onClick={props.copiarToken}
              />
            )}
            {props.generarToken && (
              <AnimatedIconButton
                size="38px"
                height="20px"
                width="20px"
                iconName="create"
                message="Generar Nuevo Token"
                onClick={props.generarToken}
              />
            )}
          </div>
        </div>
      </Card.Header>
    </div>
  );
}

export default EditHeader;
