import { useHistory } from "react-router";
import { MENSAJE_ERROR_PERMISO, ERROR_DEFAULT_MESSAGE } from "utils";
import { useAuthorization } from "hooks";
import useMessage from "hooks/useMessage";

export default function useExceptionHandler(e) {
  const { handleUnauthorizedAccess } = useAuthorization();
  const { Warning, Error, NoConnection } = useMessage();

  const handleException = (error) => {
    console.log(error);
    if (error.unauthorized) {
      handleUnauthorizedAccess(error.unauthorizedModel);
    } else {
      let message =
        error.warning || error.noConnection
          ? error.message
          : ERROR_DEFAULT_MESSAGE;
      if (error.mostrarErrores) {
        message = `${message} ${error.exceptionType ?? ""} ${
          error.exceptionMessage ?? ""
        }  ${error.stackTrace ?? ""}`;
      }
      error.warning
        ? Warning({ message })
        : error.noConnection
        ? NoConnection({ message })
        : Error({ message });
    }
  };

  return { handleException };
}
