import { createContext } from "react";
export const AuthenticationContext = createContext();
export const AuthorizationContext = createContext();
export const ThemeContext = createContext();
export const GridViewContext = createContext();
export const GridViewHeaderContext = createContext();
export const GridViewRowContext = createContext();
export const MessageContext = createContext();
export const MessageReferenceContext = createContext();
export const ConfirmContext = createContext();
export const LoadingContext = createContext();
export const CountContext = createContext();
export const ActividadesContext = createContext();
export const ChatContext = createContext();
export const AppContext = createContext();
export const GlobalChatContext = createContext();
export const PanelHorizontalContext = createContext();
