import { TransaccionesService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const TransaccionesManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const listado = (where) => {
    return service.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const buscarPorId = (id) => {
    return service.buscarPorId(id).then((res) => {
      return res;
    });
  };

  const reporte = (where) => {
    return service.reporte(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const representacionImpresa = (IdSec) => {
    return service.representacionImpresa(IdSec).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const cancelarECF = (notaCancelacionDTO) => {
    return service.cancelarECF(notaCancelacionDTO).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const consultarEstado = (IdSec) => {
    return service.consultarEstado(IdSec).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const descargarArchivoXML = (IdSec) => {
    return service.descargarArchivoXML(IdSec).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.blob;
    });
  };

  const enviarEmail = (IdSec, correoElectronico) => {
    return service.enviarEmail(IdSec, correoElectronico).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const descargarArchivoRespuestaXML = (IdSec) => {
    return service.descargarArchivoRespuestaXML(IdSec).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.blob;
    });
  };

  const exportar = (where) => {
    return service.exportar(where).then((res) => {
      return res;
    });
  };

  const exportarDetalles = (where) => {
    return service.exportarDetalles(where).then((res) => {
      return res;
    });
  };

  const GenerarReportesExcel = (where) => {
    return service.GenerarReportesExcel(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const GenerarReportesCSV = (where) => {
    return service.GenerarReportesCSV(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    listado,
    reporte,
    representacionImpresa,
    exportar,
    exportarDetalles,
    descargarArchivoXML,
    descargarArchivoRespuestaXML,
    enviarEmail,
    GenerarReportesExcel,
    GenerarReportesCSV,
    consultarEstado,
    buscarPorId,
    cancelarECF,
  };
})();
