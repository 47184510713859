import { CRM_API, RequestHelper } from "utils";

const RUTA = "autenticacion";
export const AuthService = (() => {
  const login = function (arg) {
    return new Promise((res) => res(arg))
      .then((data) => {
        return {
          NombreUsuario: data.nombreUsuario,
          Contrasena: data.contrasena,
          CodigoCompania: data.codigoCompania,
        };
      })
      .then((data) => {
        return RequestHelper(CRM_API).post(`${RUTA}/login`, data);
      });
  };

  const refreshUsuarioLogin = () => {
    return RequestHelper(CRM_API).post(`${RUTA}/refreshUsuarioLogin`, {});
  };

  const logout = () => {
    return RequestHelper(CRM_API).post(`${RUTA}/logout`, {});
  };

  const sendRecoveryEmail = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}/sendRecoveryEmail`, data);
    });
  };

  const resetPassword = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}/resetPassword`, data);
    });
  };

  const cambiarCompania = (args) => {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post(`${RUTA}/cambiarCompania`, data, {
        "Clear-Site-Data": "cache",
      });
    });
  };
  const listarCompaniasPermitidas = (args) => {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/listarCompaniasPermitidas`,
        data
      );
    });
  };

  return {
    login,
    logout,
    refreshUsuarioLogin,
    sendRecoveryEmail,
    resetPassword,
    cambiarCompania,
    listarCompaniasPermitidas,
  };
})();
