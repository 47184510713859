import { CRM_API, RequestHelper } from "utils";

export const PerfilService = (() => {
  const buscarPorId = function (args, esLookup) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`perfil/${Id}`, {
        esLookup: esLookup,
      });
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get("perfil", params);
    });
  };

  const crear = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post("perfil", data);
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).put("perfil", data);
    });
  };

  return {
    listado,
    crear,
    actualizar,
    buscarPorId,
  };
})();
