import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import usePaginationValuesWhere from "../hooks/usePaginationValuesWhere";
import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";
import { useEffect } from "react";
import { GridView } from "components";
import IconButton from "components/IconButton/IconButton";
import ToolTip from "components/ToolTip/ToolTip";
import ListHeader from "components/PantallaList/Header/ListHeader";
import { Perfil } from "models/Perfil";
import { SvgComponent } from "components";
import { DateHelper, DEFAULT_PAGINATION_VALUES } from "utils";
import { SerieFiscalManager } from "managers";
import { TIPO_SERIE_FISCAL_FILTRO } from "utils";
import Select from "react-select";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import uuid from "react-uuid";

function SerieFiscalList({ showLoading, hideLoading, hideTitle, isActive }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const history = useHistory();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const ESTATUS_LIST = [{ value: "", label: "Todos" }, ...Perfil.ESTATUS_LIST];
  const [dateFilterkey, setDateFilterKey] = useState(uuid());
  const [fechaCreacionFilterkey, setFechaCreacionFilterKey] = useState(uuid());

  useEffect(() => {
    buscar();

    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where, isActive]);

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (params) => {
    const { Id, Clonar } = params;
    let urlBase = `/SerieFiscalEdit?`;

    if (Id) {
      urlBase += `Id=${Id}`;
    }
    if (Clonar) {
      urlBase += `&Clonar=${Clonar}`;
    }
    history.push(urlBase);
  };

  const buscar = () => {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
      FechaExpiracionInicio: where.RangoFechas?.fechaInicio,
      FechaExpiracionFin: where.RangoFechas?.fechaFin,
      FechaCreacionInicio: where.RangoFechasCreacion?.fechaInicio,
      FechaCreacionFin: where.RangoFechasCreacion?.fechaFin,
    };
    SerieFiscalManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);

        for (let serieFiscal of res.Data) {
          serieFiscal["FechaExpiracion"] = DateHelper.formatStringDate(
            serieFiscal["FechaExpiracion"],
            false
          );
          serieFiscal["FechaCreacion"] = DateHelper.formatStringDate(
            serieFiscal["FechaCreacion"],
            true
          );
        }
        setList(res.Data);
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {
        hideLoading();
      });
  };

  return (
    <div>
      <div style={{ paddingRight: "6px" }}>
        <ListHeader
          titulo={hideTitle ? "" : "Serie Fiscal"}
          crearNuevo={handleClick}
        ></ListHeader>
      </div>
      <>
        <GridView
          pagination={pagination}
          setPagination={setPagination}
          allowPaging={pagination.EsPaginable}
          pageSize={pagination.CantidadPorPagina}
          pageIndex={pagination.IndiceDePagina}
          lastPageNumber={pagination.PaginasTotal}
          dataSource={list}
          onPageIndexChanging={onPageIndexChanging}
          where={where}
          setWhere={setWhere}
          height="70vh"
          onReset={() => {
            setDateFilterKey(uuid());
            setFechaCreacionFilterKey(uuid());
          }}
        >
          <GridView.Column
            bodyRenderer={(row) => {
              return (
                <>
                  <div className="float-right">
                    <IconButton
                      onClick={() =>
                        handleClick({ Id: row.IdSec, Clonar: false })
                      }
                    >
                      <ToolTip message="Editar">
                        <div>
                          <SvgComponent
                            iconName="pencil"
                            height="25px"
                            width="25px"
                          />
                        </div>
                      </ToolTip>
                    </IconButton>
                  </div>
                </>
              );
            }}
          />
          <GridView.Column
            headerText="Id"
            dataField="IdSec"
            sortExpression="IdSec"
          />
          <GridView.Column
            width={350}
            headerText="Tipo"
            dataField="Tipo"
            sortExpression="Tipo"
            filterRenderer={() => {
              return (
                <Select
                  className="react-select primary"
                  options={TIPO_SERIE_FISCAL_FILTRO}
                  value={TIPO_SERIE_FISCAL_FILTRO.filter(function (option) {
                    return (
                      option.value ===
                      (where.Tipo
                        ? where.Tipo
                        : TIPO_SERIE_FISCAL_FILTRO[0].value)
                    );
                  })}
                  name="Tipo"
                  onChange={(e) =>
                    setWhere({
                      ...where,
                      Tipo: e.value,
                    })
                  }
                />
              );
            }}
          />

          <GridView.Column
            headerText="Fijo"
            dataField="SerieFija"
            sortExpression="SerieFija"
            isDisabled={true}
          />
          <GridView.Column
            headerText="Inicio"
            dataField="RangoDesde"
            sortExpression="RangoDesde"
          />
          <GridView.Column
            headerText="Fin"
            dataField="RangoHasta"
            sortExpression="RangoHasta"
          />
          <GridView.Column
            headerText="Ultima Secuencia Utilizada"
            dataField="UltimaSerieUsada"
            sortExpression="UltimaSerieUsada"
            isDisabled={true}
            width="250px"
          />
          <GridView.Column
            date
            hideFilter
            headerText="Fecha Expiración"
            dataField="FechaExpiracion"
            sortExpression="FechaExpiracion"
            width="250px"
            filterRenderer={(onInputChange) => {
              return (
                <DateTimePicker
                  key={dateFilterkey}
                  soloFecha
                  esRangoFechas
                  esGrid
                  usarLabel={false}
                  fechaInicioOriginal={where?.RangoFechas?.fechaInicio || ""}
                  fechaFinOriginal={where?.RangoFechas?.fechaFin || ""}
                  onChange={(e) =>
                    onInputChange({
                      target: { name: "RangoFechas", value: e },
                    })
                  }
                />
              );
            }}
          />
          <GridView.Column
            date
            hideFilter
            headerText="Fecha Creacion"
            dataField="FechaCreacion"
            sortExpression="FechaCreacion"
            width="250px"
            filterRenderer={(onInputChange) => {
              return (
                <DateTimePicker
                  key={fechaCreacionFilterkey}
                  soloFecha
                  esRangoFechas
                  esGrid
                  usarLabel={false}
                  fechaInicioOriginal={
                    where?.RangoFechasCreacion?.fechaInicio || ""
                  }
                  fechaFinOriginal={where?.RangoFechasCreacion?.fechaFin || ""}
                  onChange={(e) =>
                    onInputChange({
                      target: { name: "RangoFechasCreacion", value: e },
                    })
                  }
                />
              );
            }}
          />
          <GridView.Column
            headerText="Estatus"
            dataField="Estatus"
            sortExpression="Estatus"
          />
        </GridView>
      </>
    </div>
  );
}

export default SerieFiscalList;
