import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Select from "react-select";

import useMessage from "hooks/useMessage";
import { AnulacionManager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import { ComprobanteElectronico } from "models/ECF/ComprobanteElectronico";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";

export default function AnulacionEdit({ showLoading, hideLoading }) {
  const [anulacion, setAnulacion] = useState({});
  const [saved, setSaved] = useState(false);

  const { Message, cerrarAlertas, Types } = useMessage();
  const { handleException } = useExceptionHandler();

  useEffect(() => {
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, []);

  function grabar() {
    if (!saved) {
      showLoading();
      AnulacionManager.crear(anulacion)
        .then((_res) => {
          Message({ message: `Grabado Exitosamente` });
          setSaved(true);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    } else {
      Message({
        message: `La entidad ya fue grabada.`,
        type: Types.TYPE_WARNING,
      });
    }
  }

  const onSecuenciaChange = (propName, value) => {
    let numValue = Number.parseInt(value);

    let cantidad = 0;
    let desde = 0;
    let hasta = 0;
    if (!isNaN(anulacion.SecuenciaDesde) && !isNaN(anulacion.SecuenciaHasta)) {
      desde = Number.parseInt(anulacion.SecuenciaDesde);
      hasta = Number.parseInt(anulacion.SecuenciaHasta);
    } else if (
      !isNaN(anulacion.SecuenciaDesde) &&
      propName === "SecuenciaHasta"
    ) {
      desde = Number.parseInt(anulacion.SecuenciaDesde);
      hasta = numValue;
    } else if (
      propName === "SecuenciaDesde" &&
      !isNaN(anulacion.SecuenciaHasta)
    ) {
      desde = numValue;
      hasta = Number.parseInt(anulacion.SecuenciaHasta);
    }

    cantidad = hasta - desde + 1;
    setAnulacion({
      ...anulacion,
      [propName]: numValue,
      CantidadeNCFAnulados: cantidad,
    });
  };

  return (
    <>
      <Form action="" className="form-horizontal" method="">
        <Card>
          <EditHeader
            titulo="Anulación de e-NCF"
            volver={true}
            grabar={grabar}
          />
          <EditContainer>
            <SectionRow>
              <InputContainer label="Tipo de Comprobante">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="selectTipoComprobante"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  placeholder="Tipo Comprobante"
                  options={ComprobanteElectronico.tipoComprobanteList}
                  value={ComprobanteElectronico.tipoComprobanteList.filter(
                    (opt) => opt.value === anulacion.TipoComprobante
                  )}
                  onChange={(e) => {
                    setAnulacion({
                      ...anulacion,
                      TipoComprobante: e.value,
                    });
                  }}
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Desde">
                <Form.Control
                  type="number"
                  name="SecuenciaDesde"
                  value={anulacion.SecuenciaDesde}
                  onChange={(e) =>
                    onSecuenciaChange(e.target.name, e.target.value)
                  }
                />
              </InputContainer>
              <InputContainer label="Hasta">
                <Form.Control
                  type="number"
                  name="SecuenciaHasta"
                  value={anulacion.SecuenciaHasta}
                  onChange={(e) =>
                    onSecuenciaChange(e.target.name, e.target.value)
                  }
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Cantidad de NCF anulados">
                <Form.Control
                  type="number"
                  name="CantidadeNCFAnulados"
                  value={anulacion.CantidadeNCFAnulados}
                  readOnly
                />
              </InputContainer>
            </SectionRow>
          </EditContainer>
        </Card>
      </Form>
    </>
  );
}
