import React from "react";
import "./IconButton.styles.scss";

function IconButton({ children, onClick, styles, isDisabled = false }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`icon-button ${isDisabled && "disabled"}`}
      style={styles}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}

export default IconButton;
