import { CRM_API, RequestHelper } from "utils";

const RUTA = "division-territorial";
export const ReferenciasService = (() => {
  const listadoMunicipios = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/municipios`, params);
    });
  };

  const descargarMunicipiosExcel = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/municipios/excel`,
        params,
        true
      );
    });
  };

  const descargarProvinciasExcel = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(
        `${RUTA}/provincias/excel`,
        params,
        true
      );
    });
  };

  return {
    listadoMunicipios,
    descargarMunicipiosExcel,
    descargarProvinciasExcel,
  };
})();
