import { Route } from "react-router";
import { Dashboard } from "views";
import uuid from "react-uuid";
import { getComponentWithLoading, CRM_API } from "utils";

//Compania Config y Permisos
import ConfigurarCompania from "views/ConfigurarCompania";
import PerfilEdit from "views/PerfilEdit";
import PerfilList from "views/PerfilList";

//Usuarios y Equipos
import UsuarioList from "views/UsuarioList";
import UsuarioEdit from "views/UsuarioEdit";
import UsuariosEquipos from "views/UsuariosEquipos";

//Entidades
import { Example } from "views";
import ArchivoList from "views/Archivos/ArchivoList";
import TransaccionesList from "views/Transacciones/TransaccionesList";
import AnulacionesList from "views/Anulacion/AnulacionesList";
import AnulacionEdit from "views/Anulacion/AnulacionEdit";
import ComprobantesRecibidosList from "views/ComprobantesRecibidos/ComprobantesRecibidosList";
import FirmarArchivoXML from "views/FirmarArchivo/FirmarArchivoXML";
import AprobacionComercialList from "views/AprobacionComercial/AprobacionComercialList";
import AprobacionComercialEdit from "views/AprobacionComercial/AprobacionComercialEdit";
import DirectorioServiciosList from "views/DirectorioServicios/DirectoriosServicios";
import CompaniaList from "views/Admin/CompaniaList";
import CompaniaEdit from "views/Admin/CompaniaEdit";
import Reporte from "views/Reporte/Reporte";
import CargaArchivos from "views/Archivos/CargaArchivos";

import ConfigurarEmailCompania from "./components/Email/ConfigurarEmailCompania";
import SerieFiscalList from "views/SerieFiscalList";
import SerieFiscalEdit from "views/SerieFiscalEdit";

export const routes = [
  <Route
    exact
    path={"/Reporte"}
    key={uuid()}
    component={getComponentWithLoading(Reporte)}
  />,
  <Route
    exact
    path={"/ArchivoList"}
    key={uuid()}
    component={getComponentWithLoading(ArchivoList)}
  />,
  <Route
    exact
    path={"/CargaArchivo"}
    key={uuid()}
    component={getComponentWithLoading(CargaArchivos)}
  />,
  <Route
    exact
    path={"/TransaccionesList"}
    key={uuid()}
    component={getComponentWithLoading(TransaccionesList)}
  />,
  <Route
    exact
    path={"/AnulacionesList"}
    key={uuid()}
    component={getComponentWithLoading(AnulacionesList)}
  />,
  <Route
    exact
    path={"/AnulacionesEdit"}
    key={uuid()}
    component={getComponentWithLoading(AnulacionEdit)}
  />,
  <Route
    exact
    path={"/ConsultaDirectorioList"}
    key={uuid()}
    component={getComponentWithLoading(DirectorioServiciosList)}
  />,
  <Route
    exact
    path={"/AprobacionComercialList"}
    key={uuid()}
    component={getComponentWithLoading(AprobacionComercialList)}
  />,
  <Route
    exact
    path={"/AprobacionComercialEdit"}
    key={uuid()}
    component={getComponentWithLoading(AprobacionComercialEdit)}
  />,
  <Route
    exact
    path={"/ComprobantesRecibidosList"}
    key={uuid()}
    component={getComponentWithLoading(ComprobantesRecibidosList)}
  />,
  <Route
    exact
    path={"/FirmarArchivoXML"}
    key={uuid()}
    component={getComponentWithLoading(FirmarArchivoXML)}
  />,
  <Route
    exact
    path={"/ConfigurarCompania"}
    key={uuid()}
    component={getComponentWithLoading(ConfigurarCompania)}
  />,
  <Route
    exact
    path={"/UsuariosEquipos"}
    key={uuid()}
    component={getComponentWithLoading(UsuariosEquipos)}
  />,
  <Route
    exact
    path={"/UsuarioList"}
    key={uuid()}
    component={getComponentWithLoading(UsuarioList)}
  />,
  <Route
    exact
    path={"/UsuarioEdit"}
    key={uuid()}
    component={getComponentWithLoading(UsuarioEdit)}
  />,
  <Route
    exact
    path={"/PerfilEdit"}
    key={uuid()}
    component={getComponentWithLoading(PerfilEdit)}
  />,
  <Route
    exact
    path={"/PerfilList"}
    key={uuid()}
    component={getComponentWithLoading(PerfilList)}
  />,
  <Route
    exact
    path={"/CompaniaList"}
    key={uuid()}
    component={getComponentWithLoading(CompaniaList)}
  />,
  <Route
    exact
    path={"/CompaniaEdit"}
    key={uuid()}
    component={getComponentWithLoading(CompaniaEdit)}
  />,
  <Route
    exact
    path={"/SerieFiscalList"}
    key={uuid()}
    component={getComponentWithLoading(SerieFiscalList)}
  />,

  <Route
    exact
    path={"/SerieFiscalEdit"}
    key={uuid()}
    component={getComponentWithLoading(SerieFiscalEdit)}
  />,

  <Route
    path={"/ConfiguracionEmail"}
    key={"/ConfiguracionEmail"}
    component={getComponentWithLoading(ConfigurarEmailCompania)}
  ></Route>,

  <Route
    exact
    path={"/Example"}
    key={uuid()}
    component={getComponentWithLoading(Example)}
  />,

  <Route
    path="/Public/Temp/*"
    component={(props) => {
      var rootUrl = "";
      var urlParts = (CRM_API || "").split("/api");
      if (urlParts.length > 1) {
        rootUrl = urlParts[0];
      } else {
        rootUrl = window.location.origin;
      }
      var reportUrl = `${rootUrl}${props.history.location.pathname}`;
      window.location.href = reportUrl;
      return null;
    }}
  />,

  /////////////////////////////////////////////////////////
  <Route exact path={"/Dashboard"} key={uuid()} component={Dashboard} />,

  //////////////////Mantener siempre al final/////////////////////
  <Route
    path={"/"}
    key={uuid()}
    component={getComponentWithLoading(Dashboard)}
  ></Route>,
  /////////////////////////////////////////////////////////
];
