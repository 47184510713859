/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";
import App from "App";
import {
  AuthenticationProvider,
  AuthorizationProvider,
  ThemeProvider,
  MessageProvider,
  MessageReferenceProvider,
  ConfirmProvider,
  AppProvider,
} from "providers";

const Root = () => {
  return (
    <AppProvider>
      <AuthenticationProvider>
        <ThemeProvider>
          <MessageProvider>
            <MessageReferenceProvider>
              <ConfirmProvider>
                <AuthorizationProvider>
                  <App />
                </AuthorizationProvider>
              </ConfirmProvider>
            </MessageReferenceProvider>
          </MessageProvider>
        </ThemeProvider>
      </AuthenticationProvider>
    </AppProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
