import { CRM_API, RequestHelper } from "utils";

export const SerieFiscalService = (() => {
  const buscarPorId = function (args, esLookup) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`serieFiscal/${Id}`, {
        esLookup: esLookup,
      });
    });
  };
  const inactivar = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`serieFiscal/${Id}/inactivar`);
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get("serieFiscal", params);
    });
  };

  const crear = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post("serieFiscal", data);
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).put("serieFiscal", data);
    });
  };

  return {
    listado,
    crear,
    actualizar,
    buscarPorId,
    inactivar,
  };
})();
