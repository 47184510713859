import { EntidadService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const EntidadManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const listado = (criterio) => {
    return EntidadService.listado(criterio).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };
  return {
    listado,
  };
})();
