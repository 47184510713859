import { useEffect, useState, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { GraficosManager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import { TRANSACCION_ESTATUS_TERMINADA } from "utils";
import { PERIODICIDAD_TABLA_ACTUAL_MES } from "utils";
import { FormHelper } from "utils";
import { useLocalStorage } from "hooks";

export const ComprobantesGaugeChart = ({ showCanvas, setShowCanvas }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const ref = useRef(null);
  const MAX_GRAFICO = 10;
  const DEFAULT_VALUES = {
    Estatus: TRANSACCION_ESTATUS_TERMINADA,
    Periodicidad: PERIODICIDAD_TABLA_ACTUAL_MES,
    CantidadPeriodos: 1,
    EsGraficoTransacciones: true,
  };

  const [storedWhere, setStoredWhere] = useLocalStorage(
    "Comprobantes-gauge-chart",
    DEFAULT_VALUES
  );
  const [where, setWhere] = useState(
    storedWhere ? storedWhere : DEFAULT_VALUES
  );

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setWhere);
    FormHelper.handleChange(e, setStoredWhere);
  };

  const [options, setOptions] = useState({
    legend: {
      show: true,
    },
    series: [
      {
        type: "gauge",
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: "#464646",
          },
        },
        axisLine: {
          lineStyle: {
            width: "60",
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        title: {
          fontSize: "0.7rem",
        },
        detail: {
          width: 22,
          height: 9,
          fontSize: "0.6rem",
          color: "auto",
          borderColor: "auto",
          borderRadius: 10,
          borderWidth: 1,
          formatter: "{value}%",
        },
      },
    ],
  });
  const { handleException } = useExceptionHandler();

  useEffect(() => {
    if (data) {
    }
  }, [data]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setIsLoading(true);
    GraficosManager.obtenerDatosGraficoTransacciones(where)
      .then((res) => {
        let cont = -110;
        let gaugeOptions = res.Data.DataGraficoComprobantes.map((d) => {
          cont += 35;
          let cantidad =
            (d.Cantidad * 100) / res.Data.CantidadTransacccionesCompania;

          cantidad = cantidad.toFixed(2);
          return {
            name: d.TipoEcf,
            value: cantidad,

            title: {
              offsetCenter: [`-160%`, `${cont - 45}%`],
            },
            detail: {
              valueAnimation: true,
              offsetCenter: [`-160%`, `${cont - 31}%`],
            },
          };
        });

        const initialValue = 0;
        let sumWithInitial = gaugeOptions.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.value),
          initialValue
        );
        sumWithInitial = sumWithInitial.toFixed(2);

        let planOption = {
          name: "Porcentaje\nConsumido",
          value: sumWithInitial,

          title: {
            offsetCenter: [`0%`, `-15%`],
            fontSize: "0.9rem",
          },
          detail: {
            valueAnimation: true,
            offsetCenter: [`0%`, `15%`],
            width: "40rem",
            height: "24rem",
            fontSize: "0.9rem",
            color: "auto",
            borderColor: "auto",
            borderRadius: 30,
            borderWidth: 1,
            formatter: "{value}%",
          },
        };

        gaugeOptions.unshift(planOption);

        setOptions({
          ...options,
          series: [
            {
              ...options.series[0],
              data: gaugeOptions,
            },
          ],
        });
        setData(res.Data);
        setIsLoading(false);
      })
      .catch((err) => handleException(err));
  }

  return (
    <>
      <ReactECharts
        option={options}
        showLoading={isLoading}
        loadingOption={{ text: "Cargando Datos" }}
        style={{ height: "100%", paddingBottom: "2%" }}
      />
    </>
  );
};
