import { AuthService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const AuthenticationManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const login = (usuario) => {
    return AuthService.login(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const logout = () => {
    return AuthService.logout().then((res) => {
      if (!res.Success) {
        handleException(res);
      }
    });
  };

  const refreshUsuarioLogin = () => {
    return AuthService.refreshUsuarioLogin().then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const sendRecoveryEmail = (params) => {
    return AuthService.sendRecoveryEmail(params).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const resetPassword = (params) => {
    return AuthService.resetPassword(params).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listarCompaniasPermitidas = (params) => {
    return AuthService.listarCompaniasPermitidas(params).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const cambiarCompania = (params) => {
    return AuthService.cambiarCompania(params).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    login,
    logout,
    refreshUsuarioLogin,
    sendRecoveryEmail,
    resetPassword,
    cambiarCompania,
    listarCompaniasPermitidas,
  };
})();
