import { CRM_API, RequestHelper } from "utils";

export const MenuDetalleService = (() => {
  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get("menu-detalle/buscarPorId", {
        Id: Id,
      });
    });
  };
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get("menu-detalle/listar", params);
    });
  };
  const crear = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post("menu-detalle/crear", data);
    });
  };
  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper(CRM_API).post("menu-detalle/actualizar", data);
    });
  };

  return {
    listado,
    crear,
    actualizar,
    buscarPorId,
  };
})();
