import React, { useState, useEffect } from "react";
import useMessage from "hooks/useMessage";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import { Button, Form, Row, Col } from "react-bootstrap";
import { ConfiguracionEmailHelper } from "utils";
import { ConfiguracionCorreoElectronicoManager } from "managers";
import pkceChallenge from "pkce-challenge";
import { useHistory } from "react-router";

function VincularCuentaMicrosoft({
  showLoading,
  hideLoading,
  configuracionEmail,
  setConfiguracionEmail,
  setTipoActual,
  tipoActual,
  fuente,
  referencia,
  esAutomatizacion = false,
  authResponse,
  setAuthResponse,
}) {
  const history = useHistory();
  const { Message } = useMessage();
  const { handleException } = useExceptionHandler();
  const [outlookAuthCode, setOutlookAuthCode] = useState();
  const [correoVinculado, setCorreoVinculado] = useState(false);

  useEffect(() => {
    if (fuente) {
      ConfiguracionEmailHelper.setMicrosoftFuente(fuente);
    }
    if (referencia) {
      ConfiguracionEmailHelper.setMicrosoftReferencia(referencia);
    }
  }, []);

  useEffect(() => {
    if (fuente) {
      ConfiguracionEmailHelper.setMicrosoftFuente(fuente);
    }
    if (referencia) {
      ConfiguracionEmailHelper.setMicrosoftReferencia(referencia);
    }
  }, [fuente, referencia]);

  useEffect(() => {
    if (outlookAuthCode && !esAutomatizacion) {
      showLoading();
      const pkceChallenge = JSON.parse(localStorage.getItem("pkceChallenge"));
      const codeVerifier = pkceChallenge.code_verifier;
      const codeChallenge = pkceChallenge.code_challenge;
      ConfiguracionCorreoElectronicoManager.vincularCuentaOutlook({
        Code: outlookAuthCode,
        ProofKey: {
          CodeVerifier: codeVerifier,
          CodeChallenge: codeChallenge,
        },
        fuente: ConfiguracionEmailHelper.getMicrosoftFuente(),
        referencia: ConfiguracionEmailHelper.getMicrosoftReferencia(),
      })
        .then((data) => {
          setConfiguracionEmail(data);
          setTipoActual(data.Tipo);
          Message({
            message: `Cuenta Outlook Vinculada Exitosamente.`,
          });
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => {
          setOutlookAuthCode("");
          hideLoading();
        });
    } else if (outlookAuthCode && esAutomatizacion) {
      const pkceChallenge = JSON.parse(localStorage.getItem("pkceChallenge"));
      const codeVerifier = pkceChallenge.code_verifier;
      const codeChallenge = pkceChallenge.code_challenge;
      setConfiguracionEmail({
        ...configuracionEmail,
        Tipo: ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.OUTLOOK,
      });
      setTipoActual(ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.OUTLOOK);
      setAuthResponse({
        Code: outlookAuthCode,
        ProofKey: {
          CodeVerifier: codeVerifier,
          CodeChallenge: codeChallenge,
        },
        fuente: ConfiguracionEmailHelper.getMicrosoftFuente(),
        referencia: ConfiguracionEmailHelper.getMicrosoftReferencia(),
      });
      setCorreoVinculado(true);
    }
  }, [outlookAuthCode]);

  useEffect(() => {
    const code = ConfiguracionEmailHelper.obtenerCodigoAutorizacion();
    if (code) {
      showLoading();
      setOutlookAuthCode(code);
      history.replace({
        search: "",
      });
      hideLoading();
    }
  }, []);

  const handleClickDesvincularCuentaOutlook = () => {
    if (esAutomatizacion) {
      setConfiguracionEmail({
        ...configuracionEmail,
        EmailRegistrado: null,
        RutaCredenciales: null,
        IdSec: null,
      });
    } else {
      showLoading();
      ConfiguracionCorreoElectronicoManager.desvincularCuentaOutlook()
        .then((data) => {
          Message({ message: `Cuenta Outlook Desvinculada Exitosamente.` });
          setConfiguracionEmail(data);
          setTipoActual(data.Tipo);
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => {
          hideLoading();
        });
    }
  };

  const SignInButton = () => {
    const handleLogin = () => {
      showLoading();
      const pkceChallengeResult = pkceChallenge();
      localStorage.setItem(
        "pkceChallenge",
        JSON.stringify(pkceChallengeResult)
      );
      const codeVerifier = pkceChallengeResult.code_verifier;
      const codeChallenge = pkceChallengeResult.code_challenge;

      const Fuente = ConfiguracionEmailHelper.getMicrosoftFuente();
      const Referencia = ConfiguracionEmailHelper.getMicrosoftReferencia();

      ConfiguracionCorreoElectronicoManager.obtenerUrlOutlook({
        codeVerifier: codeVerifier,
        codeChallenge: codeChallenge,
        fuente: Fuente,
        referencia: Referencia,
      })
        .then((data) => {
          window.location = data;
        })
        .catch((e) => {
          handleException(e);
        })
        .finally(() => {
          hideLoading();
        });
    };

    const handleLoginAutomatizacion = () => {
      if (esAutomatizacion) {
        showLoading();
        const pkceChallengeResult = pkceChallenge();
        localStorage.setItem(
          "pkceChallenge",
          JSON.stringify(pkceChallengeResult)
        );
        const codeVerifier = pkceChallengeResult.code_verifier;
        const codeChallenge = pkceChallengeResult.code_challenge;

        const Fuente = fuente;
        const Referencia = referencia;
        ConfiguracionCorreoElectronicoManager.obtenerUrlOutlookAutomatizacion({
          codeVerifier: codeVerifier,
          codeChallenge: codeChallenge,
          fuente: Fuente,
          referencia: Referencia,
        })
          .then((data) => {
            window.location = data;
          })
          .catch((e) => {
            handleException(e);
          })
          .finally(() => {
            hideLoading();
          });
      }
    };

    return (
      <Row>
        <Form.Label column sm="2"></Form.Label>
        <Col sm="6">
          <Button
            style={{
              padding: "0px",
              backgroundColor: "transparent",
              border: "none",
              color: "#4491f9",
              cursor: "pointer",
              fontSize: "0.9rem",
              padding: "0",
              textAlign: "left",
              textDecoration: correoVinculado ? "none" : "underline",
            }}
            className="ml-auto"
            onClick={() => {
              if (correoVinculado) {
                return;
              } else if (esAutomatizacion) {
                handleLoginAutomatizacion();
                return;
              } else {
                handleLogin();
              }
            }}
          >
            {correoVinculado ? "Correo Vinculado" : "Vincular Cuenta Outlook"}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <EditSection>
      {configuracionEmail.EmailRegistrado &&
      tipoActual === ConfiguracionEmailHelper.TIPO_SERVIDOR_CORREO.OUTLOOK ? (
        <Row>
          <Form.Label column sm={esAutomatizacion ? "4" : "2"}>
            Cuenta Vinculada
          </Form.Label>
          <Col sm={esAutomatizacion ? "12" : "4"}>
            <Form.Group>
              <Form.Control
                disabled={true}
                name="EmailRegistrado"
                type="text"
                placeholder="EmailRegistrado"
                value={configuracionEmail.EmailRegistrado}
              />
            </Form.Group>

            <Button
              style={{
                padding: "0px",
                backgroundColor: "transparent",
                border: "none",
                color: "#4491f9",
                cursor: "pointer",
                fontSize: "0.9rem",
                padding: "0",
                textAlign: "left",
                textDecoration: "underline",
              }}
              onClick={handleClickDesvincularCuentaOutlook}
            >
              Desvincular Cuenta de Outlook
            </Button>
          </Col>
        </Row>
      ) : (
        <SignInButton></SignInButton>
      )}
    </EditSection>
  );
}

export default VincularCuentaMicrosoft;
