import React, { useState, useRef } from "react";
import useExceptionHandler from "hooks/useExceptionHandler";
import useMessage from "hooks/useMessage";
import { useEffect } from "react";
import { GridView } from "components";
import { SvgComponent } from "components/SvgComponent/SvgComponent";
import IconButton from "components/IconButton/IconButton";
import { Card } from "react-bootstrap";
import { GraficosManager } from "managers";
import { Simplebar } from "components/Simplebar/Simplebar";
import { InputSidebarContainer } from "components/PantallasEdit/InputContainer/InputSideBarContainer";
import Select from "react-select";
import { useLocalStorage } from "hooks";
import { PERIODO_TABLA_LIST } from "utils";
import { PERIODICIDAD_TABLA_ACTUAL_LIST } from "utils";
import { PERIODICIDAD_TABLA_PASADO_LIST } from "utils";
import { PERIODO_TABLA_ACTUAL } from "utils";
import { PERIODO_TABLA_PASADO } from "utils";
import { TRANSACCION_ESTATUS_TERMINADA } from "utils";
import { TIPO_COMPROBANTE_COMPRAS } from "utils";
import { TIPO_COMPROBANTE_GASTO_MENOR } from "utils";
import { TIPO_COMPROBANTE_PAGOS_EXTERIOR } from "utils";
import { PERIODICIDAD_TABLA_ACTUAL_HOY } from "utils";
import Porcentaje from "components/Porcentaje/Porcentaje";
import {
  PERIODICIDAD_TABLA_OTRO_LIST,
  PERIODO_TABLA_OTRO,
  PERIODICIDAD_TABLA_OTRO_RANGO,
} from "utils";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { DateHelper } from "utils";

function TablaBottomProveedores({ showCanvas, setShowCanvas, title }) {
  const [list, setList] = useState([]);
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const ref = useRef(null);

  const DEFAULT_VALUES = {
    TipoEcfList: [
      TIPO_COMPROBANTE_COMPRAS,
      TIPO_COMPROBANTE_GASTO_MENOR,
      TIPO_COMPROBANTE_PAGOS_EXTERIOR,
    ],
    Estatus: TRANSACCION_ESTATUS_TERMINADA,
    Periodo: PERIODO_TABLA_ACTUAL,
    Periodicidad: PERIODICIDAD_TABLA_ACTUAL_HOY,
  };

  const [storedWhere, setStoredWhere] = useLocalStorage(
    "Comprobantes-Tabla-Proveedores-bottom-Ventas",
    DEFAULT_VALUES
  );

  const [where, setWhere] = useState(
    storedWhere ? storedWhere : DEFAULT_VALUES
  );
  useEffect(() => {
    buscar();
  }, []);

  function buscar() {
    GraficosManager.obtenerDatosTablaBottom(where)
      .then((res) => {
        setList(res.Data);
      })
      .catch((err) => handleException(err));
  }

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setWhere);
    FormHelper.handleChange(e, setStoredWhere);
  };

  const FilterSimplebar = () => {
    return (
      <Simplebar
        ref={ref}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvas}
        setShow={(e) => {
          setShowCanvas(e);
        }}
        useLoading={true}
        title="Filtros Tabla Comprobantes Ventas"
        btnLeft={{
          show: true,
          message: "Limpiar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "clean",
          func: () => {
            setWhere({});
            setStoredWhere({});
            setTimeout(() => {
              buscar();
            }, 100);
          },
        }}
        btnRight={{
          show: true,
          message: "Filtrar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "check",
          func: () => {
            buscar();
            setShowCanvas(false);
          },
        }}
      >
        <InputSidebarContainer label="Periodo">
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Periodo"
            onChange={(e) => {
              setWhere({
                ...where,
                Periodo: e.value,
              });
              setStoredWhere({
                ...storedWhere,
                Periodo: e.value,
              });
            }}
            options={PERIODO_TABLA_LIST}
            value={PERIODO_TABLA_LIST.filter(function (option) {
              return (
                option.value ===
                (where.Periodo ? where.Periodo : PERIODO_TABLA_LIST[0].value)
              );
            })}
          />

          {where.Periodo === PERIODO_TABLA_ACTUAL && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setWhere({
                  ...where,
                  Periodicidad: e.value,
                });
                setStoredWhere({
                  ...storedWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_ACTUAL_LIST}
              value={PERIODICIDAD_TABLA_ACTUAL_LIST.filter(function (option) {
                return (
                  option.value ===
                  (where.Periodicidad
                    ? where.Periodicidad
                    : PERIODICIDAD_TABLA_ACTUAL_LIST[0].value)
                );
              })}
            />
          )}

          {where.Periodo === PERIODO_TABLA_PASADO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setWhere({
                  ...where,
                  Periodicidad: e.value,
                });
                setStoredWhere({
                  ...storedWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_PASADO_LIST}
              value={PERIODICIDAD_TABLA_PASADO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (where.Periodicidad
                    ? where.Periodicidad
                    : PERIODICIDAD_TABLA_PASADO_LIST[0].value)
                );
              })}
            />
          )}

          {where.Periodo === PERIODO_TABLA_OTRO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setWhere({
                  ...where,
                  Periodicidad: e.value,
                });
                setStoredWhere({
                  ...storedWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_OTRO_LIST}
              value={PERIODICIDAD_TABLA_OTRO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (where.Periodicidad
                    ? where.Periodicidad
                    : PERIODICIDAD_TABLA_OTRO_LIST[0].value)
                );
              })}
            />
          )}

          {where.Periodo === PERIODO_TABLA_OTRO &&
            where.Periodicidad === PERIODICIDAD_TABLA_OTRO_RANGO && (
              <InputSidebarContainer label="Rango de Fechas">
                <DateTimePicker
                  usarLabel={false}
                  esRangoFechas={true}
                  labelFechaInicio="Desde"
                  labelFechaFin="Hasta"
                  fechaInicioOriginal={where.FechaInicio}
                  fechaFinOriginal={where.FechaFin}
                  onChange={(e) => {
                    if (
                      (where.FechaInicio !==
                        DateHelper.formatDate(e.fechaInicio) ||
                        where.FechaFin !== DateHelper.formatDate(e.fechaFin)) &&
                      (e.fechaFin || e.fechaInicio)
                    ) {
                      setWhere({
                        ...where,
                        FechaInicio: DateHelper.formatDate(e.fechaInicio),
                        FechaFin: DateHelper.formatDate(e.fechaFin),
                      });
                      setStoredWhere({
                        ...where,
                        FechaInicio: DateHelper.formatDate(e.fechaInicio),
                        FechaFin: DateHelper.formatDate(e.fechaFin),
                      });
                    }
                  }}
                />
              </InputSidebarContainer>
            )}
        </InputSidebarContainer>
      </Simplebar>
    );
  };

  return (
    <div>
      <FilterSimplebar />
      <Card
        style={{
          width: "40vw",
        }}
      >
        <Card.Header>
          <Card.Title
            className="d-flex"
            style={{ justifyContent: "space-between" }}
          >
            {title}
            <IconButton onClick={() => setShowCanvas(true)}>
              <SvgComponent iconName="filter" fill="#77777a" />
            </IconButton>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <GridView
            allowPaging={false}
            dataSource={list}
            where={where}
            setWhere={setWhere}
            height="60vh"
            width="100%"
            hideFilters={true}
            primaryKey="Nombre"
            borderless={true}
          >
            <GridView.Column
              headerText="Nombre"
              dataField="Nombre"
              sortExpression="Nombre"
            />
            <GridView.Column
              headerText="Total"
              dataField="Cantidad"
              sortExpression="Cantidad"
              number={true}
              extraComponentdataField={"Porcentaje"}
              extraComponent={Porcentaje}
            />
          </GridView>
        </Card.Body>
      </Card>
    </div>
  );
}
export default TablaBottomProveedores;
