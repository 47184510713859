import { SvgComponent } from "components";
import ToolTip from "components/ToolTip/ToolTip";
import { useTheme } from "hooks";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import "./AnimatedIconButton.styles.scss";

export const AnimatedIconButton = ({
  onClick,
  style,
  size,
  disabled = false,
  iconName = "Grabar",
  width = "24px",
  height = "24px",
  message,
  placement,
  isWhite = false,
  useVendorTheme = false
}) => {
  const { theme, vendorTheme } = useTheme();
  const [svgClass, setSvgClass] = useState("animated-button");
  const [divClass, setDivClass] = useState("animated-div");
  const [iconTheme] = useState(useVendorTheme ? vendorTheme : theme);

  return (
    <div>
      {message ? (
        <ToolTip message={message} placement={placement}>
          <button
            type="button"
            onClick={onClick}
            className={`animated-button ${disabled && "disabled"}`}
            style={style}
            disabled={disabled}
          >
            <div
              className={divClass}
              style={{
                ...style,
                height: size,
                width: size,
                backgroundColor:
                  divClass == "animated-div-hover"
                    ? iconTheme.color1
                    : isWhite
                    ? "white"
                    : "transparent",
                borderColor: iconTheme.color1,
              }}
              onMouseEnter={() => {
                setSvgClass("animated-button-hover");
                setDivClass("animated-div-hover");
              }}
              onMouseLeave={() => {
                setSvgClass("animated-button");
                setDivClass("animated-div");
              }}
            >
              <SvgComponent
                height={height}
                width={width}
                iconName={iconName}
                fill={iconTheme.color1}
                className={svgClass}
              />
            </div>
          </button>
        </ToolTip>
      ) : (
        <button
          type="button"
          onClick={onClick}
          className={`animated-button ${disabled && "disabled"}`}
          style={style}
          disabled={disabled}
        >
          <div
            className={divClass}
            style={{
              ...style,
              height: size,
              width: size,
              backgroundColor:
                divClass == "animated-div-hover"
                  ? iconTheme.color1
                  : isWhite
                  ? "white"
                  : "transparent",
              borderColor: iconTheme.color1,
            }}
            onMouseEnter={() => {
              setSvgClass("animated-button-hover");
              setDivClass("animated-div-hover");
            }}
            onMouseLeave={() => {
              setSvgClass("animated-button");
              setDivClass("animated-div");
            }}
          >
            <SvgComponent
              height={height}
              width={width}
              iconName={iconName}
              fill={iconTheme.color1}
              className={svgClass}
            />
          </div>
        </button>
      )}
    </div>
  );
};
