import { useState, useRef, useEffect } from "react";
import { Form, FormGroup } from "react-bootstrap";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import { Simplebar } from "components";
import useExceptionHandler from "hooks/useExceptionHandler";
import uuid from "react-uuid";

function Exportar({
  PROPIEDADES_EXPORTAR,
  showExportar,
  setShowExportar,
  where,
  showLoading,
  hideLoading,
  exportarCallBack,
}) {
  const ref = useRef(null);
  const [propiedadesSeleccionadas, setPropiedadesSeleccionadas] = useState([]);
  const { handleException } = useExceptionHandler();
  const [componentKey, setComponentKey] = useState();

  useEffect(() => {
    setComponentKey(uuid().substring(0, 4));
  }, []);

  const setCheckedProp = (e) => {
    const { name, checked } = e.target;
    const [property, descripcion] = name.split("*");
    let props = [...propiedadesSeleccionadas];
    if (checked) {
      props = [...props, { Descripcion: descripcion, Property: property }];
    } else {
      props = props.filter(function (element) {
        return element.Property !== property;
      });
    }
    setPropiedadesSeleccionadas(props);
  };

  const exportar = () => {
    showLoading();

    const whereExportar = {
      ...where,
      FechaInicio: where.RangoFechas?.fechaInicio,
      FechaFin: where.RangoFechas?.fechaFin,
    };

    exportarCallBack({
      PROPIEDADES_EXPORTAR: propiedadesSeleccionadas,
      Where: whereExportar,
    })
      .then(async (res) => {
        const link = document.createElement("a");
        const result = await res.blob;
        link.target = "_blank";
        link.download = `${uuid()}.xls`;
        link.href = URL.createObjectURL(result);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {
        hideLoading();
      });
  };

  function selectAll() {
    const checkBoxes = document.querySelectorAll(
      `.ck${componentKey} input[type='checkbox']`
    );

    let props = [];
    for (var i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i].type == "checkbox") {
        checkBoxes[i].checked = true;
        const [property, descripcion] = checkBoxes[i].name.split("*");

        if (checkBoxes[i].checked) {
          props = [...props, { Descripcion: descripcion, Property: property }];
        } else {
          props = props.filter(function (element) {
            return element.Property !== property;
          });
        }
      }
      setPropiedadesSeleccionadas(props);
    }
  }

  function deselectAll() {
    const checkBoxes = document.querySelectorAll(
      `.ck${componentKey} input[type='checkbox']`
    );
    let props = [];
    for (var i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i].type == "checkbox") {
        checkBoxes[i].checked = false;
        const [property, descripcion] = checkBoxes[i].name.split("*");

        if (checkBoxes[i].checked) {
          props = [...props, { Descripcion: descripcion, Property: property }];
        } else {
          props = props.filter(function (element) {
            return element.Property !== property;
          });
        }
      }
      setPropiedadesSeleccionadas(props);
    }
  }

  return (
    <Simplebar
      size="sm"
      ref={ref}
      backdrop={true}
      placement={"end"}
      portal={false}
      visible={showExportar}
      setShow={(e) => {
        setShowExportar(e);
      }}
      useLoading={true}
      title="Exportar a Excel"
      btnLeft={{
        show: false,
      }}
      btnRight={{
        show: true,
        message: "Imprimir",
        isComponent: true,
        size: "45px",
        height: "24px",
        with: "24px",
        iconName: "check",
        func: () => {
          exportar();
          setShowExportar(false);
        },
      }}
    >
      <FormGroup className={`ck${componentKey}`}>
        <div className="mb-3">
          <SectionRow colspan={3}>
            <button
              variant="primary"
              className="ml-5 "
              key={`selectALL`}
              name={`btnselectALL`}
              id={"btnselectALL"}
              onClick={selectAll}
              style={{
                background: `white`,
                border: `none`,
                padding: ` 0!important`,
                color: `#069`,
                textDecoration: `underline`,
                cursor: `pointer`,
              }}
            >
              Todos
            </button>
            <button
              variant="primary"
              className="ml-5 "
              key={`selectALL`}
              name={`btnselectALL`}
              id={"btnselectALL"}
              onClick={deselectAll}
              style={{
                background: `white`,
                border: `none`,
                padding: ` 0!important`,
                color: `#069`,
                textDecoration: `underline`,
                cursor: `pointer`,
              }}
            >
              Ninguno
            </button>
          </SectionRow>
        </div>

        {PROPIEDADES_EXPORTAR &&
          PROPIEDADES_EXPORTAR?.map((propiedad) => {
            return (
              <SectionRow colspan={1}>
                <Form.Check
                  className="ml-5 "
                  type="checkbox"
                  aria-label={propiedad.label}
                  key={`${propiedad.value}*${propiedad.label}`}
                  name={`${propiedad.value}*${propiedad.label}`}
                  onChange={setCheckedProp}
                />
                <label
                  htmlFor={`ck${propiedad.label}`}
                  style={{ marginRight: "15px", marginTop: "3px" }}
                >
                  {propiedad.label}
                </label>
              </SectionRow>
            );
          })}
      </FormGroup>
    </Simplebar>
  );
}

export default Exportar;
