import React, { useState, useEffect } from "react";
import { DEFAULT_PAGINATION_VALUES } from "utils";
import { GridView } from "components";
import useExceptionHandler from "hooks/useExceptionHandler";
import usePaginationValuesWhere from "../hooks/usePaginationValuesWhere";
import useMessage from "hooks/useMessage";
import { UnidadesMedidaManager } from "managers";
import useQueryParams from "hooks/useQueryParams";
import ListHeader from "components/PantallaList/Header/ListHeader";

function UnidadesMedida(showLoading, hideLoading) {
  const [pagination, setPagination] = useState({
    ...DEFAULT_PAGINATION_VALUES,
    OrderBy: "Id",
    EsAscendente: true,
    CantidadPorPagina: 20,
  });
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const [where, setWhere] = useState({});
  const [list, setList] = useState([]);
  const URLParameters = useQueryParams();

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };
  useEffect(() => {
    buscar();
  }, [where]);

  const buscar = () => {
    const whereWithPaginationValues = { ...where, ...pagination };
    UnidadesMedidaManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        setList(res.Data);
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => {});
  };

  const descargarExcel = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Unidades de Medida.xlsx";
    UnidadesMedidaManager.descargarExcel()
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      });
  };

  return (
    <div>
      <div style={{ paddingRight: "6px" }}>
        <ListHeader
          titulo={"Unidades de Medida"}
          descargar={() => {
            descargarExcel();
          }}
        ></ListHeader>
      </div>
      <>
        <GridView
          pagination={pagination}
          setPagination={setPagination}
          allowPaging={pagination.EsPaginable}
          pageSize={pagination.CantidadPorPagina}
          pageIndex={pagination.IndiceDePagina}
          lastPageNumber={pagination.PaginasTotal}
          dataSource={list}
          onPageIndexChanging={onPageIndexChanging}
          where={where}
          setWhere={setWhere}
          height="80vh"
        >
          <GridView.Column
            headerText="Código"
            dataField="Codigo"
            sortExpression="Codigo"
            isfield={true}
          />
          <GridView.Column
            headerText="Descripción"
            dataField="Descripcion"
            sortExpression="Descripcion"
            isfield={true}
          />
          <GridView.Column
            headerText="Abreviatura"
            dataField="Abreviatura"
            sortExpression="Abreviatura"
            isfield={true}
          />
        </GridView>
      </>
    </div>
  );
}

export default UnidadesMedida;
