export const ConfiguracionEmailHelper = (() => {
  const MICROSOFT_FUENTE_STORAGE_KEY = "_MSF_";
  const MICROSOFT_REFERENCIA_STORAGE_KEY = "_MSR_";

  const FUENTE_ENTIDAD = {
    COMPANIA: "C",
    USUARIO: "U",
    EQUIPO: "E",
    AUTOMATIZACION: "A",
  };

  const TIPO_SERVIDOR_CORREO = {
    SMTP: "SMTP",
    GMAIL: "GMAIL",
    OUTLOOK: "OUTLOOK",
  };

  const PROVEEDOR_SERVIDOR_CORREO_DDL = [
    { value: TIPO_SERVIDOR_CORREO.GMAIL, label: "Gmail" },
    { value: TIPO_SERVIDOR_CORREO.OUTLOOK, label: "Outlook" },
  ];

  const TIPO_SERVIDOR_CORREO_DDL = [
    { value: TIPO_SERVIDOR_CORREO.SMTP, label: "SMTP" },
    ...PROVEEDOR_SERVIDOR_CORREO_DDL,
  ];

  const TIPO_SERVIDOR_CORREO_DEFAULT = TIPO_SERVIDOR_CORREO.SMTP;

  const obtenerCodigoAutorizacion = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    return code;
  };

  const getMicrosoftFuente = () => {
    return localStorage.getItem(MICROSOFT_FUENTE_STORAGE_KEY);
  };
  const getMicrosoftReferencia = () => {
    return localStorage.getItem(MICROSOFT_REFERENCIA_STORAGE_KEY);
  };

  const obtenerFuenteReferenciaLocalStorage = () => {
    const parametros = {
      fuente: getMicrosoftFuente(),
      referencia: getMicrosoftReferencia(),
    };
    return parametros;
  };

  const setMicrosoftFuente = (fuente) => {
    localStorage.setItem(MICROSOFT_FUENTE_STORAGE_KEY, fuente);
  };

  const setMicrosoftReferencia = (referencia) => {
    localStorage.setItem(MICROSOFT_REFERENCIA_STORAGE_KEY, referencia);
  };

  return {
    TIPO_SERVIDOR_CORREO,
    TIPO_SERVIDOR_CORREO_DDL,
    TIPO_SERVIDOR_CORREO_DEFAULT,
    FUENTE_ENTIDAD,
    PROVEEDOR_SERVIDOR_CORREO_DDL,
    obtenerCodigoAutorizacion,
    obtenerFuenteReferenciaLocalStorage,
    setMicrosoftFuente,
    setMicrosoftReferencia,
    getMicrosoftFuente,
    getMicrosoftReferencia,
  };
})();
