import React, { useMemo, useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Row } from "react-bootstrap";

import IconButton from "components/IconButton/IconButton";
import useExceptionHandler from "hooks/useExceptionHandler";
import "./Dropzone.styles.scss";

export default function DropZone(props) {
  const [files, setFiles] = useState([]);
  const { handleException } = useExceptionHandler();
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [useVideo, setUseVideo] = useState(false);

  useEffect(() => {
    if (useVideo) getVideo();
  }, [videoRef]);

  useEffect(() => {
    if (!useVideo) releaseVideo();
  }, [useVideo]);

  useEffect(() => {
    callUpdateFilesCb(files);
  }, [files]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxFiles: props.maxFiles,
      multiple: props.multiple,
      accept: getAcceptedExtensions(props.type),
      onDrop: onDrop,
    });

  function getAcceptedExtensions(type) {
    switch (type) {
      case "image":
        return {
          "image/*": [".jpeg", ".png"],
        };

      case "p12":
        return {
          "application/x-pkcs12": [".p12"],
        };

      case "xml":
        return {
          "application/xml": [".xml"],
        };

      case "excel":
        return {
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
        };

      default:
        return "*";
    }
  }

  function onDrop(acceptedFiles) {
    if (props.multiple) {
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    } else {
      setFiles([
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    }
  }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        window.localStream = stream;
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        handleException(err);
      });
  };

  const releaseVideo = () => {
    if (window.localStream) window.localStream?.getTracks?.()[0].stop?.();
  };

  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");

    const width = 300;
    const height = 200;
    photo.width = width;
    photo.height = height;
    ctx.drawImage(video, 0, 0, width, height);
  };

  const uploadPhoto = () => {
    let photo = photoRef.current;
    const data = photo.toDataURL("image/jpeg");
    let blob = dataURLtoBlob(data);
    var file = new File([blob], `photo-${files.length + 1}.jpeg`, {
      type: "image/jpg",
    });
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    if (props.multiple) {
      setFiles([...files, file]);
    } else {
      setFiles([, file]);
    }
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const handleUseVideo = () => {
    if (useVideo) {
      setUseVideo(false);
    } else {
      setUseVideo(true);
      getVideo();
    }
  };

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  function isFileImage(file) {
    return file && file["type"].split("/")[0] === "image";
  }

  const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

  function callUpdateFilesCb(files) {
    props.updateFilesCb(convertNestedObjectToArray(files));
  }

  const thumbs = files.map((file) => {
    if (file) {
      return (
        <div key={file.name}>
          <Row>
            {isFileImage(file) ? (
              <div>
                <img
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                  src={file.preview}
                />
              </div>
            ) : (
              <span>{file.name}</span>
            )}

            <IconButton>
              <i className="fa fa-trash" onClick={() => removeFile(file)}></i>
            </IconButton>
          </Row>
        </div>
      );
    }
  });

  return (
    <div className="container" style={props.styles}>
      <Row>
        <div {...getRootProps({ style })}>
          <input name="FileUpload" {...getInputProps()} />
          <p>{props.label}</p>
        </div>
        {props.type === "image" && (
          <div>
            <button type="button" onClick={handleUseVideo}>
              <i className="fas fa-camera"></i>
            </button>
          </div>
        )}
      </Row>
      {useVideo === true && (
        <div className="container-fluid photoContainer">
          <Row>
            <div className="webcam-video">
              <video ref={videoRef} />
            </div>
            <canvas ref={photoRef} />
          </Row>
          <Row>
            <button
              className="TakePictureButton"
              type="button"
              onClick={() => paintToCanvas()}
            >
              <i className="fas fa-image"></i>
            </button>
            <button
              className="TakePictureButton"
              type="button"
              onClick={uploadPhoto}
            >
              <i className="fas fa-upload"></i>
            </button>
          </Row>
        </div>
      )}
      {thumbs.length > 0 && (
        <aside>
          <h4>Archivo a cargar</h4>
          {thumbs}
        </aside>
      )}
    </div>
  );
}
