import { CRM_API, RequestHelper } from "utils";

const RUTA = "funcion";
export const FuncionService = (() => {
  const buscarPorPerfilId = function (args, enPerfil) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/${Id}`, {
        NoEnPerfil: enPerfil,
      });
    });
  };

  const buscarNoEnPerfilId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/buscarNoEnPerfilId/${Id}`, {
        Id: Id,
      });
    });
  };

  const buscarPorNombreUsuario = function (args) {
    return new Promise((res) => res(args)).then((NombreUsuario) => {
      return RequestHelper(CRM_API).get(`${RUTA}/buscarPorNombreUsuario`, {
        NombreUsuario: NombreUsuario,
      });
    });
  };

  return {
    buscarPorPerfilId,
    buscarPorNombreUsuario,
    buscarNoEnPerfilId,
  };
})();
