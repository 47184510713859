import { useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import XMLViewer from "react-xml-viewer";
import Select from "react-select";
import IconButton from "components/IconButton/IconButton";
import ToolTip from "components/ToolTip/ToolTip";
import useMessage from "hooks/useMessage";
import { ArchivosManager } from "managers/ECF/archivos.manager";
import useExceptionHandler from "hooks/useExceptionHandler";
import { DateHelper, DEFAULT_PAGINATION_VALUES } from "utils";
import ListHeader from "components/PantallaList/Header/ListHeader";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";

import uuid from "react-uuid";

import {
  GridView,
  InputSidebarContainer,
  SvgComponent,
  Simplebar,
} from "components";
import ReporteViewer from "components/Reportes/ReporteViewer";
import Exportar from "components/Exportar/Exportar";
import { archivos } from "models/ECF/Archivos";

export default function ArchivoList({ showLoading, hideLoading }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const [showCanvasReporte, setShowCanvasReporte] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [currentArchivo, setCurrentArchivo] = useState({});
  const [showExportar, setShowExportar] = useState(false);
  const [showExportarDetalles, setShowExportarDetalles] = useState(false);
  const [dateFilterkey, setDateFilterKey] = useState(uuid());
  const [reporte, setReporte] = useState(undefined);
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const ref = useRef(null);
  const [fechaCreacionFilterkey, setFechaCreacionFilterKey] = useState(uuid());

  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    { value: "L", label: "Leido" },
    { value: "I", label: "Invalido" },
    { value: "P", label: "Por Procesar" },
    { value: "T", label: "Terminada" },
  ];

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  function buscar() {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
      FechaInicio: where.RangoFechas?.fechaInicio,
      FechaFin: where.RangoFechas?.fechaFin,
    };

    ArchivosManager.listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        try {
          for (let archivo of res.Data) {
            archivo["FechaCreacion"] = DateHelper.formatStringDate(
              archivo["FechaCreacion"],
              true
            );
          }
        } catch (err) {}
        setList(res.Data);
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());
  }

  const handleClick = (params) => {
    const { Id } = params;
    const archivo = list.find((t) => t.IdSec == Id);
    if (archivo !== undefined) {
      setCurrentArchivo(archivo);
      setShowCanvas(true);
    }
  };

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const openExportar = () => {
    setShowExportar(true);
  };

  const onReset = () => {
    setFechaCreacionFilterKey(uuid());
  };

  const descargarArchivo = (IdSec) => {
    showLoading();
    const link = document.createElement("a");
    link.target = "_blank";
    ArchivosManager.descargarArchivo(IdSec)
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => hideLoading());
  };

  return (
    <>
      <ViewArchivo
        setShowCanvas={setShowCanvas}
        showCanvas={showCanvas}
        archivo={currentArchivo}
        showLoading={showLoading}
        hideLoading={hideLoading}
        handleException={handleException}
        setShowCanvasReporte={setShowCanvasReporte}
      />
      <Exportar
        PROPIEDADES_EXPORTAR={archivos.PROPIEDADES_EXPORTAR}
        showExportar={showExportar}
        setShowExportar={setShowExportar}
        showLoading={showLoading}
        hideLoading={hideLoading}
        where={where}
        exportarCallBack={ArchivosManager.exportar}
      ></Exportar>

      <ListHeader titulo="Archivo" exportar={openExportar} />
      <ReporteViewer
        showCanvas={showCanvasReporte}
        setShowCanvas={setShowCanvasReporte}
        reporte={reporte}
      ></ReporteViewer>

      <GridView
        pagination={pagination}
        setPagination={setPagination}
        allowPaging={pagination.EsPaginable}
        pageSize={pagination.CantidadPorPagina}
        pageIndex={pagination.IndiceDePagina}
        lastPageNumber={pagination.PaginasTotal}
        dataSource={list}
        onPageIndexChanging={onPageIndexChanging}
        where={where}
        setWhere={setWhere}
        height="78vh"
        onReset={onReset}
      >
        <GridView.Column
          width="80px"
          showClearFilter={true}
          bodyRenderer={(row) => {
            return (
              <>
                <div className="float-right">
                  <IconButton
                    onClick={() => {
                      descargarArchivo(row.IdSec);
                    }}
                  >
                    <ToolTip message="Archivo XML">
                      <div>
                        <SvgComponent
                          iconName="download"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
                <div className="float-right">
                  <IconButton
                    onClick={() =>
                      handleClick({
                        Id: row.IdSec,
                      })
                    }
                  >
                    <ToolTip message="Ver">
                      <div>
                        <SvgComponent
                          iconName="view"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
              </>
            );
          }}
        />

        <GridView.Column
          headerText="Id"
          dataField="IdSec"
          sortExpression="IdSec"
          width="75px"
        />
        <GridView.Column
          date
          hideFilter
          headerText="Fecha"
          dataField="FechaCreacion"
          sortExpression="FechaCreacion"
          width="250px"
          filterRenderer={(onInputChange) => {
            return (
              <DateTimePicker
                key={fechaCreacionFilterkey}
                soloFecha
                esRangoFechas
                esGrid
                usarLabel={false}
                fechaInicioOriginal={where?.RangoFechas?.fechaInicio || ""}
                fechaFinOriginal={where?.RangoFechas?.fechaFin || ""}
                onChange={(e) =>
                  onInputChange({
                    target: { name: "RangoFechas", value: e },
                  })
                }
              />
            );
          }}
        />
        <GridView.Column
          headerText="Nombre"
          dataField="Nombre"
          sortExpression="Nombre"
          width="350px"
        />
        <GridView.Column
          headerText="Payload"
          dataField="Payload"
          sortExpression="Payload"
          width="350px"
        />

        <GridView.Column
          headerText="Estatus"
          dataField="Estatus"
          sortExpression="Estatus"
          width="180px"
          filterRenderer={() => {
            return (
              <Select
                className="react-select primary"
                options={ESTATUS_LIST}
                value={ESTATUS_LIST.filter(function (option) {
                  return (
                    option.value ===
                    (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                  );
                })}
                name="Estatus"
                onChange={(e) =>
                  setWhere({
                    ...where,
                    Estatus: e.value,
                  })
                }
              />
            );
          }}
        />

        <GridView.Column
          headerText="Error"
          dataField="Error"
          sortExpression="Error"
          width="400px"
          isDisabled
        />
      </GridView>
    </>
  );
}

function ViewArchivo({ showCanvas, setShowCanvas, archivo }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Simplebar
      backdrop={true}
      size="lg"
      placement={"end"}
      portal={false}
      visible={showCanvas}
      setShow={(e) => {
        setShowCanvas(e);
      }}
      useLoading={false}
      title="Detalles del Archivo"
      btnRight={{
        show: false,
        label: "Grabar",
        func: () => {},
      }}
      btnLeft={{
        show: false,
        label: "Cerrar",
      }}
    >
      {showModal && modal}
      <EditSection title="General">
        <SectionRow>
          <InputSidebarContainer label="Id">
            <Form.Control value={archivo.IdSec} disabled={true}></Form.Control>
          </InputSidebarContainer>
          <InputSidebarContainer label="Fecha">
            <Form.Control
              value={archivo.FechaCreacion}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
        </SectionRow>
        <SectionRow>
          <InputSidebarContainer label="Nombre">
            <Form.Control value={archivo.Nombre} disabled={true}></Form.Control>
          </InputSidebarContainer>
          <InputSidebarContainer label="Estatus">
            <Form.Control
              value={archivo.Estatus}
              disabled={true}
            ></Form.Control>
          </InputSidebarContainer>
        </SectionRow>

        <InputSidebarContainer label="Payload">
          <Form.Control
            as={"textarea"}
            rows={10}
            value={archivo.Payload}
            disabled={true}
          ></Form.Control>
        </InputSidebarContainer>

        <InputSidebarContainer label="Error">
          <Form.Control
            as={"textarea"}
            rows={archivo.Error ? 15 : 1}
            value={archivo.Error}
            disabled={true}
          ></Form.Control>
        </InputSidebarContainer>
      </EditSection>
    </Simplebar>
  );
}
