import { useEffect, useState, useRef } from "react";
import useExceptionHandler from "hooks/useExceptionHandler";
import { DateHelper, DEFAULT_PAGINATION_VALUES } from "utils";
import ListHeader from "components/PantallaList/Header/ListHeader";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import { TransaccionesManager as manager } from "managers";
import { GraficosManager } from "managers";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { Transacciones } from "models/ECF/Transacciones";
import Select from "react-select";
import uuid from "react-uuid";
import useMessage from "hooks/useMessage";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import {
  GridView,
  InputSidebarContainer,
  SvgComponent,
  Simplebar,
} from "components";
import ReporteViewer from "components/Reportes/ReporteViewer";
import Exportar from "components/Exportar/Exportar";
import { DetallesItems } from "models/ECF/DetallesItem";

import {
  PERIODO_TABLA_LIST,
  PERIODICIDAD_TABLA_ACTUAL_LIST,
  PERIODICIDAD_TABLA_PASADO_LIST,
  PERIODICIDAD_TABLA_OTRO_LIST,
  PERIODO_TABLA_ACTUAL,
  PERIODO_TABLA_PASADO,
  TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL,
  TIPO_COMPROBANTE_FACTURA_CONSUMO,
  TIPO_COMPROBANTE_NOTA_DEBITO,
  TIPO_COMPROBANTE_NOTA_CREDITO,
  TIPO_COMPROBANTE_REGIMEN_ESPECIAL,
  TIPO_COMPROBANTE_GUBERNAMENTAL,
  PERIODICIDAD_TABLA_OTRO_RANGO,
  PERIODO_TABLA_OTRO,
  TIPO_COMPROBANTE_LIST,
  TIPO_COMPROBANTE_COMPRAS,
  TIPO_COMPROBANTE_GASTO_MENOR,
  TIPO_COMPROBANTE_PAGOS_EXTERIOR,
  REPORTE_CANTIDAD_TRANSACCIONES_POR_RANGO_FECHA,
  REPORTE_TRANSACCIONES_POR_RANGO_FECHA,
} from "utils";

export default function Reporte({ showLoading, hideLoading }) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const [currentTransaccion, setCurrentTransaccion] = useState({});
  const [showCanvas, setShowCanvas] = useState(false);
  const [showCanvasReporte, setShowCanvasReporte] = useState(false);
  const [showExportar, setShowExportar] = useState(false);
  const [showExportarDetalles, setShowExportarDetalles] = useState(false);
  const [dateFilterkey, setDateFilterKey] = useState(uuid());

  const [reporteWhere, setReporteWhere] = useState({
    EstadoECFList: ["A", "C"],
    FormatoReporte: "PDF",
    TipoReporte: "T",
    RangoFecha: "S",
    Periodo: PERIODICIDAD_TABLA_ACTUAL_LIST,
  });
  const [showCanvasFiltrosReporte, setShowCanvasFiltrosReporte] =
    useState(false);
  const [reporte, setReporte] = useState(undefined);
  const [sucursales, setSucursales] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [tipoComprobantes, setTipoComprobantes] = useState([]);
  const [estadoComprobante, setEstadoComprobante] = useState([]);

  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const { handleException } = useExceptionHandler();
  const { cerrarAlertas } = useMessage();
  const ref = useRef(null);

  const ESTADO_LIST = [
    { value: "A", label: "Aceptado" },
    { value: "C", label: "Aceptado Condicional" },
    { value: "R", label: "Rechazado" },
    { value: "P", label: "En Proceso" },
  ];

  const TIPO_REPORTE = [
    { value: "T", label: "Transacciones" },
    { value: "V", label: "Ventas por tipo de comprobante" },
    { value: "C", label: "Cantidad de transacciones por rango de fecha" },
    { value: "TF", label: "Transacciones por rango de fecha" },
  ];

  const RANGO_FECHA = [
    { value: "S", label: "Semanal" },
    { value: "M", label: "Mensual" },
  ];

  const FORMATO_REPORTE = [
    { value: "PDF", label: "PDF" },
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  function buscar() {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
    };

    manager
      .listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        try {
          for (let transaccion of res.Data) {
            transaccion["Fecha"] = DateHelper.formatStringDate(
              transaccion["Fecha"],
              false
            );
            transaccion["FechaCreacion"] = DateHelper.formatStringDate(
              transaccion["FechaCreacion"],
              true
            );
          }
        } catch (err) {}
        setList(res.Data);
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());

    setTipoComprobantes([
      {
        value: "-1",
        label: "Todos",
      },
      ...TIPO_COMPROBANTE_LIST,
    ]);

    setEstadoComprobante([
      {
        value: "-1",
        label: "Todos",
      },
      ...ESTADO_LIST,
    ]);
  }

  const imprimirReporte = () => {
    if (reporteWhere.FormatoReporte == "Excel") {
      showLoading();
      manager
        .GenerarReportesExcel(reporteWhere)
        .then(async (res) => {
          const link = document.createElement("a");
          const result = await res.blob;
          link.target = "_blank";
          link.download = `${uuid()}.xls`;
          link.href = URL.createObjectURL(result);
          link.click();
        })
        .catch((err) => handleException(err))
        .finally(() => hideLoading());
    } else if (reporteWhere.FormatoReporte == "CSV") {
      showLoading();
      manager
        .GenerarReportesCSV(reporteWhere)
        .then(async (res) => {
          const link = document.createElement("a");
          const result = await res.blob;
          link.target = "_blank";
          link.download = `${uuid()}.csv`;
          link.href = URL.createObjectURL(result);
          link.click();
        })
        .catch((err) => handleException(err))
        .finally(() => hideLoading());
    } else {
      showLoading();
      manager
        .reporte(reporteWhere)
        .then((res) => {
          setReporte(res.Data);
          setShowCanvasReporte(true);
        })
        .catch((err) => handleException(err))
        .finally(() => hideLoading());
    }
  };

  return (
    <>
      <ListHeader
        titulo="Filtros de Reporte Transacciones"
        reporte={imprimirReporte}
      />

      <SectionRow>
        <InputSidebarContainer label="Tipo de Reporte" colSize={"12"}>
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="TipoReportes"
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                TipoReporte: e.value,
              });
            }}
            options={TIPO_REPORTE}
            value={TIPO_REPORTE.filter(function (option) {
              return (
                option.value ===
                (reporteWhere.TipoReporte
                  ? reporteWhere.TipoReporte
                  : TIPO_REPORTE[0].value)
              );
            })}
          />

          {reporteWhere.TipoReporte ===
            REPORTE_CANTIDAD_TRANSACCIONES_POR_RANGO_FECHA && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="RangoFecha"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  RangoFecha: e.value ?? RANGO_FECHA[0],
                });
              }}
              options={RANGO_FECHA}
              value={RANGO_FECHA.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.RangoFecha
                    ? reporteWhere.RangoFecha
                    : RANGO_FECHA[0].value)
                );
              })}
            />
          )}

          {reporteWhere.TipoReporte ===
            REPORTE_TRANSACCIONES_POR_RANGO_FECHA && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="RangoFecha"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  RangoFecha: e.value ?? RANGO_FECHA[0],
                });
              }}
              options={RANGO_FECHA}
              value={RANGO_FECHA.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.RangoFecha
                    ? reporteWhere.RangoFecha
                    : RANGO_FECHA[0].value)
                );
              })}
            />
          )}
        </InputSidebarContainer>

        <InputSidebarContainer label="Tipo de Comprobante" colSize={"12"}>
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="TipoEcfList"
            isMulti
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                TipoEcfList: e ? e.map((option) => option.value) : null,
              });
            }}
            options={tipoComprobantes}
            value={tipoComprobantes.filter(function (option) {
              return reporteWhere?.TipoEcfList?.some((v) => v === option.value);
            })}
          />
        </InputSidebarContainer>
      </SectionRow>

      <SectionRow>
        <InputSidebarContainer label="Periodo" colSize={"12"}>
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="Periodo"
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                Periodo: e.value,
              });
            }}
            options={PERIODO_TABLA_LIST}
            value={PERIODO_TABLA_LIST.filter(function (option) {
              return (
                option.value ===
                (reporteWhere.Periodo
                  ? reporteWhere.Periodo
                  : PERIODO_TABLA_LIST[0].value)
              );
            })}
          />

          {reporteWhere.Periodo === PERIODO_TABLA_ACTUAL && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_ACTUAL_LIST}
              value={PERIODICIDAD_TABLA_ACTUAL_LIST.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.Periodicidad
                    ? reporteWhere.Periodicidad
                    : PERIODICIDAD_TABLA_ACTUAL_LIST[0].value)
                );
              })}
            />
          )}

          {reporteWhere.Periodo === PERIODO_TABLA_PASADO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_PASADO_LIST}
              value={PERIODICIDAD_TABLA_PASADO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.Periodicidad
                    ? reporteWhere.Periodicidad
                    : PERIODICIDAD_TABLA_PASADO_LIST[0].value)
                );
              })}
            />
          )}

          {reporteWhere.Periodo === PERIODO_TABLA_OTRO && (
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="Periodicidad"
              onChange={(e) => {
                setReporteWhere({
                  ...reporteWhere,
                  Periodicidad: e.value,
                });
              }}
              options={PERIODICIDAD_TABLA_OTRO_LIST}
              value={PERIODICIDAD_TABLA_OTRO_LIST.filter(function (option) {
                return (
                  option.value ===
                  (reporteWhere.Periodicidad
                    ? reporteWhere.Periodicidad
                    : PERIODICIDAD_TABLA_OTRO_LIST[0].value)
                );
              })}
            />
          )}

          {reporteWhere.Periodo === PERIODO_TABLA_OTRO &&
            reporteWhere.Periodicidad === PERIODICIDAD_TABLA_OTRO_RANGO && (
              <InputSidebarContainer label="Rango de Fechas" colSize={"12"}>
                <DateTimePicker
                  usarLabel={false}
                  esRangoFechas={true}
                  labelFechaInicio="Desde"
                  labelFechaFin="Hasta"
                  fechaInicioOriginal={reporteWhere.FechaInicio}
                  fechaFinOriginal={reporteWhere.FechaFin}
                  onChange={(e) => {
                    if (
                      (reporteWhere.FechaInicio !==
                        DateHelper.formatDate(e.fechaInicio) ||
                        reporteWhere.FechaFin !==
                          DateHelper.formatDate(e.fechaFin)) &&
                      (e.fechaFin || e.fechaInicio)
                    ) {
                      setReporteWhere({
                        ...reporteWhere,
                        FechaInicio: DateHelper.formatDate(e.fechaInicio),
                        FechaFin: DateHelper.formatDate(e.fechaFin),
                      });
                    }
                  }}
                />
              </InputSidebarContainer>
            )}
        </InputSidebarContainer>

        <InputSidebarContainer label="Estatus de la Transacción" colSize={"12"}>
          <Select
            className="react-select primary mb-2"
            classNamePrefix="react-select"
            name="EstadoECF"
            isMulti
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                EstadoECFList: e ? e.map((option) => option.value) : null,
              });
            }}
            options={ESTADO_LIST}
            value={ESTADO_LIST.filter(function (option) {
              return reporteWhere?.EstadoECFList?.some(
                (v) => v === option.value
              );
            })}
          />
        </InputSidebarContainer>
      </SectionRow>

      <SectionRow>
        <InputSidebarContainer
          label="Formato del Reporte"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <RadioGroup
            options={FORMATO_REPORTE}
            selectedOption={reporteWhere.FormatoReporte}
            onChange={(e) => {
              setReporteWhere({
                ...reporteWhere,
                FormatoReporte: e.target.value,
              });
            }}
          />
        </InputSidebarContainer>
      </SectionRow>

      <ReporteViewer
        showCanvas={showCanvasReporte}
        setShowCanvas={setShowCanvasReporte}
        reporte={reporte}
      ></ReporteViewer>
    </>
  );
}

const RadioGroup = ({ options, selectedOption, onChange }) => {
  return (
    <div>
      {options.map((option) => (
        <label key={option.value} style={{ fontWeight: "none !important" }}>
          <input
            style={{ marginLeft: "5px" }}
            type="radio"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};
