import React, { useState, useEffect, useCallback } from "react";
import { AuthorizationContext } from "contexts";
import { useAuthentication } from "hooks";
import { AuthorizationManager } from "managers";

export function AuthorizationProvider({ children }) {
  const [authorization, setAuthorization] = useState(undefined);
  const {usuario} = useAuthentication();

  useEffect(() => {
    setTimeout(() => {
      AuthorizationManager.refreshAuthorization()
      .then((authorizationModel) => {
        setCurrentAuthorizationModel(authorizationModel);
      })
      .catch((error) => {
        setCurrentAuthorizationModel((error && error.unauthorized && error.unauthorizedModel) ? error.unauthorizedModel : null);
      });
    }, 250);
  }, [usuario]);

  const setCurrentAuthorizationModel = useCallback((data) => {
    setAuthorization(data || null);
  }, [authorization]);

  return (
    <AuthorizationContext.Provider value={{ authorization, setAuthorization }}>
      {children}
    </AuthorizationContext.Provider>
  );
}
