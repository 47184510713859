import {
  HEADER_WHERE_INDICE_PAGINA,
  HEADER_WHERE_CANTIDAD_PAGINA,
  HEADER_WHERE_RECORDS_TOTAL,
  HEADER_SESSION_TOKEN,
} from "utils";

export const ResponseHeadersHelper = (headers) =>
  (() => {
    const getSessionToken = () => {
      let token = null;
      if (headers.has(HEADER_SESSION_TOKEN)) {
        let tempToken = headers.get(HEADER_SESSION_TOKEN);
        if (tempToken && tempToken !== "null" && tempToken !== "undefined") {
          token = tempToken;
        }
      }
      return token;
    };
    const getPaginationHeaders = () => {
      const paginationHeaders = [
        HEADER_WHERE_INDICE_PAGINA,
        HEADER_WHERE_CANTIDAD_PAGINA,
        HEADER_WHERE_RECORDS_TOTAL,
      ];
      return get(paginationHeaders);
    };
    const get = (keys) => {
      const result = {};
      for (let k of keys) {
        if (headers.has(k)) {
          result[k] = headers.get(k);
        }
      }
      return result;
    };
    return {
      getSessionToken,
      getPaginationHeaders,
    };
  })();
