import React, { useState, useEffect, useRef } from "react";

import { Card, Form } from "react-bootstrap";

import useMessage from "hooks/useMessage";
import { InputSidebarContainer } from "components";
import DropZone from "components/DropZone/Dropzone";
import { ArchivosManager as manager } from "managers/ECF/archivos.manager";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import Select from "react-select";
import { Simplebar } from "components";
import uuid from "react-uuid";

export default function CargaArchivos({ showLoading, hideLoading }) {
  const [archivo, setArchivo] = useState({ TipoPlantilla: "F" });
  const [plantilla, setPlantilla] = useState({ TipoPlantilla: "F" });
  const { Message, cerrarAlertas, Types } = useMessage();
  const { handleException } = useExceptionHandler();
  const [showCanvas, setShowCanvas] = useState(false);
  const ref = useRef(null);
  const [archivoFilterkey, setArchivoFilterKey] = useState(uuid());

  const PLANTILLA_LIST = [
    { value: "F", label: "Factura (E31, E32, E44, E45, E46)" },
    { value: "NDE", label: "Nota de Debito (E33)" },
    { value: "NCR", label: "Nota de Crédito (E34)" },
    { value: "IN", label: "Proveedor Informal (E41)" },
    { value: "GM", label: "Gasto Menor (E43)" },
    { value: "PE", label: "Pagos al Exterior (E47)" },
  ];

  useEffect(() => {
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, []);

  const onFileChange = (propFile, propName) => {
    setArchivo({ ...archivo, [propName]: propFile });
  };

  function grabar() {
    showLoading();
    if (
      archivo.File === null ||
      archivo.File === undefined ||
      archivo.File.length === 0
    ) {
      Message({
        message: `Debe subir un archivo .xls.`,
        type: Types.TYPE_WARNING,
      });
      hideLoading();
      return;
    }

    manager
      .cargarArchivo(archivo)
      .then((res) => {
        if (res.Ok) {
          Message({ message: `Archivo Cargado Exitosamente` });
          setArchivoFilterKey(uuid());
        } else {
          Message({
            message: `El archivo no pudo ser cargado correctamente, intente de nuevo.`,
            type: Types.TYPE_WARNING,
          });
        }
      })
      .catch((e) => handleException(e))
      .finally(() => hideLoading());
  }

  const FilterSimplebar = () => {
    return (
      <Simplebar
        ref={ref}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvas}
        setShow={(e) => {
          setShowCanvas(e);
        }}
        useLoading={true}
        title="Descargar Plantilla"
        btnLeft={{
          show: false,
        }}
        btnRight={{
          show: true,
          message: "Descargar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "check",
          func: () => {
            descargarArchivo(plantilla.TipoPlantilla);
          },
        }}
      >
        <InputSidebarContainer label="Tipo Plantilla">
          <SectionRow colSpan={4}>
            <Select
              name="TamanoRepresentacionImpresa"
              className="react-select primary"
              options={PLANTILLA_LIST}
              value={PLANTILLA_LIST.filter(function (option) {
                return (
                  option.value ===
                  (plantilla.TipoPlantilla
                    ? plantilla.TipoPlantilla
                    : PLANTILLA_LIST[0].value)
                );
              })}
              onChange={(e) =>
                setPlantilla({
                  TipoPlantilla: e.value,
                })
              }
            />
          </SectionRow>
        </InputSidebarContainer>
      </Simplebar>
    );
  };

  const descargarArchivo = (tipoPlantilla) => {
    showLoading();
    const link = document.createElement("a");
    link.target = "_blank";
    manager
      .descargarPlantilla(tipoPlantilla)
      .then((res) => {
        link.href = URL.createObjectURL(res);
        link.click();
      })
      .catch((error) => {
        handleException(error);
      })
      .finally(() => hideLoading());
  };

  function descargarPlantillas() {
    setShowCanvas(true);
  }

  return (
    <>
      <FilterSimplebar />
      <Form action="" className="form-horizontal" method="">
        <Card>
          <EditHeader
            titulo="Cargar Archivo"
            volver={true}
            grabar={grabar}
            descargarPlantillas={descargarPlantillas}
          />

          <EditSection title="" mt={"mt-2"}>
            <InputContainer labelSize={3} label="Tipo Plantilla">
              <SectionRow>
                <Select
                  name="TamanoRepresentacionImpresa"
                  className="react-select primary"
                  options={PLANTILLA_LIST}
                  value={PLANTILLA_LIST.filter(function (option) {
                    return (
                      option.value ===
                      (archivo.TipoPlantilla
                        ? archivo.TipoPlantilla
                        : PLANTILLA_LIST[0].value)
                    );
                  })}
                  onChange={(e) =>
                    setArchivo({
                      ...archivo,
                      TipoPlantilla: e.value,
                    })
                  }
                />
              </SectionRow>
            </InputContainer>

            <InputContainer labelSize={3} label="Archivo">
              <SectionRow>
                <DropZone
                  key={archivoFilterkey}
                  label="Dé click o arrastre un archivo .xls o .xlsx para continuar"
                  updateFilesCb={(f) => onFileChange(f, "File")}
                  multiple={false}
                  type="excel"
                />
              </SectionRow>
            </InputContainer>
          </EditSection>
        </Card>
      </Form>
    </>
  );
}
