export class Permisos {
  static Compania = {
    LISTAR: "ECF.Admin.Compania.LISTAR",
    EDITAR: "ECF.Admin.Compania.EDITAR",
    CREAR: "ECF.Admin.Compania.CREAR",
  };

  static Perfil = {
    LISTAR: "ECF.Perfil.LISTAR",
    EDITAR: "ECF.Perfil.EDITAR",
    CREAR: "ECF.Perfil.CREAR",
  };

  static Usuario = {
    LISTAR: "ECF.Usuario.LISTAR",
    EDITAR: "ECF.Usuario.EDITAR",
    CREAR: "ECF.Usuario.CREAR",
    ACTUALIZAR_PASSWORD: "ECF.Seguridad.ActualizarUsuarioPassword",
  };

  static Seguridad = {
    SACAR_USUARIO_SESION: "ECF.Seguridad.Login.SacarUsuarioSesion",
    CREAR_TOKEN: "ECF.Seguridad.CrearToken",
    INACTIVAR_TOKEN: "ECF.Seguridad.InactivarToken",
    CONFIGURACION_LISTAR: "ECF.Seguridad.Configuracion.Ver",
  };

  static Documentacion = {
    DOCUMENTACION_LISTAR: "ECF.Documentacion.Ver",
  };

  static ComprobanteElectronico = {
    LISTAR: "ECF.ComprobanteElectronico.LISTAR",
    FIRMAR: "ECF.ComprobanteElectronico.FIRMAR",
    CARGAR: "ECF.ComprobanteElectronico.CARGAR",
  };

  static AnulacionSecuencia = {
    LISTAR: "ECF.AnulacionSecuencia.LISTAR",
    CREAR: "ECF.AnulacionSecuencia.CREAR",
  };

  static ConsultarDirectorios = {
    LISTAR: "ECF.ConsultarDirectorios.LISTAR",
  };

  static AprobacionComercial = {
    LISTAR: "ECF.AprobacionComercial.LISTAR",
    CREAR: "ECF.AprobacionComercial.CREAR",
  };

  static ComprobantesRecibidos = {
    LISTAR: "ECF.ComprobantesRecibidos.LISTAR",
  };

  static Archivo = {
    LISTAR: "ECF.Archivo.LISTAR",
  };

  static Reportes = {
    IMPRIMIR: "ECF.Reporte.Imprimir",
  };

  static Compras = {
    IMPRIMIR: "ECF.Transaccion.Compras.Imprimir",
  };

  static Exportaciones = {
    IMPRIMIR: "ECF.Transaccion.Exportaciones.Imprimir",
  };

  static FacturasConsumo = {
    IMPRIMIR: "ECF.Transaccion.FacturasConsumo.Imprimir",
  };

  static Facturas = {
    IMPRIMIR: "ECF.Transaccion.Facturas.Imprimir",
  };

  static GastoMenor = {
    IMPRIMIR: "ECF.Transaccion.GastoMenor.Imprimir",
  };

  static NotaCredito = {
    IMPRIMIR: "ECF.Transaccion.NotaCredito.Imprimir",
  };

  static NotaDebito = {
    IMPRIMIR: "ECF.Transaccion.NotaDebito.Imprimir",
  };

  static PagosExterior = {
    IMPRIMIR: "ECF.Transaccion.PagosExterior.Imprimir",
  };

  static Transacciones = {
    IMPRIMIR: "ECF.Transaccion.Transacciones.Imprimir",
    CANCELAR: "ECF.Transacciones.Transacciones.CANCELAR",
  };

  static Graficos = {
    VER_GRAFICOS: "ECF.Graficos.Ver",
    CARD_VENTAS_MES: "ECF.Graficos.Card.VentasMes.Ver",
    CARD_TOTAL_VENTAS_MES: "ECF.Graficos.Card.TotalVentasMes.Ver",
    CARD_COMPRAS_MES: "ECF.Graficos.Card.ComprasMes.Ver",
    CARD_TOTAL_COMPRAS_MES: "ECF.Graficos.Card.TotalComprasMes.Ver",
    CARD_INFORMACION_CERTIFICADO:
      "ECF.Graficos.Card.InformacionCertificado.Ver",
    TABLA_VENTAS: "ECF.Graficos.TablaVentas.Ver",
    TABLA_COMPRAS: "ECF.Graficos.TablaCompras.Ver",
    GRAFICO_PIE_VENTAS: "ECF.Graficos.PieComprobantesVentas.Ver",
    GRAFICO_PIE_COMPRAS: "ECF.Graficos.PieComprobantesCompras.Ver",
    GRAFICO_VENTAS: "ECF.Graficos.ComprobantesVentas.Ver",
    GRAFICO_COMPRAS: "ECF.Graficos.ComprobantesCompras.Ver",
    GRAFICO_TRANSACCIONES_REALIZADAS:
      "ECF.Graficos.TransaccionesRealizadas.Ver",
    GRAFICO_TOP_10_CLIENTES: "ECF.Graficos.Top10Clientes.Ver",
    GRAFICO_BOTTOM_10_CLIENTES: "ECF.Graficos.Bottom10Clientes.Ver",
    GRAFICO_TOP_10_PROVEDORES: "ECF.Graficos.Top10Proveedores.Ver",
    GRAFICO_BOTTOM_10_PROVEDORES: "ECF.Graficos.Bottom10Proveedores.Ver",
  };
}
