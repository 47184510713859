import React from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { MemoizedAdminLayout } from "layouts";
import { LoginPage, LoadingPage, Unauthorized } from "views";
import { useAuthentication, useAuthorization } from "hooks";
import { getComponentWithLoading } from "utils";
import useMessage from "hooks/useMessage";
import { MENSAJE_ERROR_PERMISO } from "utils";
import Referencias from "views/Referencias";
import ImpuestosAdicionales from "views/ImpuestosAdicionales";
import UnidadesMedida from "views/UnidadesMedida";
import NuevaCompania from "views/NuevaCompania";

export default function App() {
  const { usuario } = useAuthentication();
  const { authorization } = useAuthorization();
  const { Warning } = useMessage();

  const sinLicenciaServidor = () =>
    authorization && !authorization.TieneLicenciaServidor;
  const sinLicenciaCompania = () =>
    authorization && !authorization.TieneLicenciaCompania;
  const sinSessionUsuario = () =>
    authorization && !authorization.TieneSessionUsuario;
  const sinPermisoUsuario = () => authorization && !authorization.TienePermiso;

  if (usuario === undefined || authorization === undefined) {
    return getComponentWithLoading(LoadingPage)();
  } else if (false /**sinLicenciaServidor() */) {
    return (
      <Unauthorized
        message={`Servidor no autorizado. Serial: ${authorization.SerialServidor}`}
      />
    );
  } else if (false /**!sinSessionUsuario() && sinLicenciaCompania() */) {
    return (
      <Unauthorized
        message={`Compañía sin licencia. Contacte al administrador de su cuenta.`}
      />
    );
  } else if (sinSessionUsuario() || !usuario) {
    return getComponentWithLoading(LoginPage)();
  } else {
    if (sinPermisoUsuario()) {
      Warning({ message: MENSAJE_ERROR_PERMISO });
    }
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path={"/referencias"}
            key={"referencias"}
            component={getComponentWithLoading(Referencias)}
          />
          <Route
            path={"/impuestosAdicionales"}
            key={"impuestosAdicionales"}
            component={getComponentWithLoading(ImpuestosAdicionales)}
          />
          <Route
            path={"/unidadesMedida"}
            key={"unidadesMedida"}
            component={getComponentWithLoading(UnidadesMedida)}
          />
          <Route
            path={"/nuevaCompania"}
            key={"nuevaCompania"}
            component={getComponentWithLoading(NuevaCompania)}
          />
          <Route
            render={() => (
              <MemoizedAdminLayout
                logo={usuario.LogoBase64}
                rutasMenu={usuario.Menus}
                codigo={usuario.Compania.Codigo}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}
