export class archivos {
    static get PROPIEDADES_EXPORTAR() {
      return [
        {
          value: "IdSec",
          label: "Id",
        },
        {
          value: "Nombre",
          label: "Nombre",
        },
        {
          value: "Payload",
          label: "Payload",
        },
        {
            value: "FechaCreacion",
            label: "Fecha",
        },
        {
          value: "Estatus",
          label: "Estatus",
        },
        {
          value: "Tipo",
          label: "Tipo",
        },
        {
          value: "Error",
          label: "Error",
        }
      ];
    }
  }
  