import { useEffect, useState, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { GraficosManager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import { TRANSACCION_ESTATUS_TERMINADA } from "utils";
import { GRAFICO_PERIODICIDAD_MENSUAL } from "utils";

import { TIPO_COMPROBANTE_COMPRAS } from "utils";
import { TIPO_COMPROBANTE_GASTO_MENOR } from "utils";
import { TIPO_COMPROBANTE_PAGOS_EXTERIOR } from "utils";
import { Simplebar } from "components/Simplebar/Simplebar";
import { InputSidebarContainer } from "components/PantallasEdit/InputContainer/InputSideBarContainer";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { PERIOCIDAD_LIST } from "utils";
import { FormHelper } from "utils";
import { useLocalStorage } from "hooks";

export const ComprobantesComprasBarsChart = ({ showCanvas, setShowCanvas }) => {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const ref = useRef(null);

  const DEFAULT_VALUES = {
    TipoEcfList: [
      TIPO_COMPROBANTE_COMPRAS,
      TIPO_COMPROBANTE_GASTO_MENOR,
      TIPO_COMPROBANTE_PAGOS_EXTERIOR,
    ],
    Estatus: TRANSACCION_ESTATUS_TERMINADA,
    Periodicidad: GRAFICO_PERIODICIDAD_MENSUAL,
    CantidadPeriodos: 4,
  };

  const [storedWhere, setStoredWhere] = useLocalStorage(
    "Comprobantes-Compras-Bars-chart",
    DEFAULT_VALUES
  );

  const [where, setWhere] = useState(
    storedWhere ? storedWhere : DEFAULT_VALUES
  );

  const [options, setOptions] = useState({
    dataZoom: [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
      },
    ],
    legend: {
      type: "scroll",
      orient: "horizontal",
    },
    tooltip: {},
    dataset: {
      source: [],
    },
    xAxis: [
      { type: "category", gridIndex: 0 },
      { type: "category", gridIndex: 1 },
    ],
    yAxis: [{ gridIndex: 0 }, { gridIndex: 1 }],
    grid: [{ bottom: "55%" }, { top: "55%" }],
    series: [],
  });
  const { handleException } = useExceptionHandler();

  useEffect(() => {
    if (data) {
      const lines = data.DataHistoricoGraficoComprobantes?.map((d) => {
        if (d.label != "Periodo") {
          return {
            type: "bar",
            seriesLayoutBy: "row",
          };
        }
      });

      const linesFiltered = lines?.filter((element) => {
        return element !== undefined;
      });
      const linesBottom = [];
      if (data?.DataHistoricoGraficoComprobantes) {
        const columns = data.DataHistoricoGraficoComprobantes[0].value.length;
        for (let i = 0; i < columns; i++) {
          linesBottom.push({
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
          });
        }
      }
      setOptions({
        ...options,
        series: linesFiltered?.concat(linesBottom),
      });
    }
  }, [data]);

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setWhere);
    FormHelper.handleChange(e, setStoredWhere);
  };

  const FilterSimplebar = () => {
    return (
      <Simplebar
        ref={ref}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvas}
        setShow={(e) => {
          setShowCanvas(e);
        }}
        useLoading={true}
        title="Filtros Comprobantes Compras"
        btnLeft={{
          show: true,
          message: "Limpiar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "clean",
          func: () => {
            setWhere({});
            setStoredWhere({});
            setTimeout(() => {
              getData();
            }, 100);
          },
        }}
        btnRight={{
          show: true,
          message: "Filtrar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "check",
          func: () => {
            getData();
            setShowCanvas(false);
          },
        }}
      >
        <InputSidebarContainer label="Periodicidad">
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="Periodicidad"
            onChange={(e) => {
              setWhere({
                ...where,
                Periodicidad: e.value,
              });
              setStoredWhere({
                ...storedWhere,
                Periodicidad: e.value,
              });
            }}
            options={PERIOCIDAD_LIST}
            value={PERIOCIDAD_LIST.filter(function (option) {
              return (
                option.value ===
                (where.Periodicidad
                  ? where.Periodicidad
                  : PERIOCIDAD_LIST[0].value)
              );
            })}
          />

          <label>Cantidad de Periodos</label>
          <Form.Control
            name="CantidadPeriodos"
            type="number"
            value={where.CantidadPeriodos}
            onChange={onInputChange}
          />
        </InputSidebarContainer>
      </Simplebar>
    );
  };

  useEffect(() => {
    if (storedWhere) {
      setWhere(storedWhere);
      setTimeout(() => {
        getData();
      }, 100);
    } else {
      setWhere({});
      setTimeout(() => {
        getData();
      }, 100);
    }
  }, []);

  function getData() {
    setIsLoading(true);
    GraficosManager.obtenerDatosGraficoComprobantesCompras(where)
      .then((res) => {
        setOptions({
          ...options,
          dataset: {
            source: res.Data.DataHistoricoGraficoComprobantes.map((d) => {
              return [d.label, ...d.value];
            }),
          },
        });
        setIsLoading(false);
        setData(res.Data);
      })
      .catch((err) => handleException(err))
      .finally();
  }

  return (
    <>
      <FilterSimplebar />
      <ReactECharts
        option={options}
        showLoading={isLoading}
        loadingOption={{ text: "Cargando Datos" }}
        style={{ height: "95%", width: "95%" }}
      />
    </>
  );
};
