import { CRM_API, RequestHelper } from "utils";

const RUTA = "directorio";
export const DirectorioServiciosService = (() => {
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/true`);
    });
  };

  return {
    listado,
  };
})();
