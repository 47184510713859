import React from "react";
import { Form, Col } from "react-bootstrap";
export const InputSidebarContainer = ({ children, label, colSize, style }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "left",
        width: `100%`,
      }}
    >
      <Col sm={colSize || "12"} style={{ ...style }}>
        <Form.Group>
          {label && (
            <Form.Label column sm="12" className="pl-0">
              {label}
            </Form.Label>
          )}
          {children}
        </Form.Group>
      </Col>
    </div>
  );
};
