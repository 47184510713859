import React from "react";
import Porcentaje from "components/Porcentaje/Porcentaje";
import { Row, Col } from "react-bootstrap";
function GridFooterTotalCard({ totalColumnaGrid, totalCompararColumnaGrid }) {
  const componentSize = {
    width: "100%",
  };

  const calcularPorcentaje = (montoAnterior, monto) => {
    var diferencia = monto - montoAnterior;
    if (diferencia == 0) {
      return 0;
    } else if (montoAnterior == 0) {
      return monto;
    }
    return parseFloat((diferencia / montoAnterior) * 100, 2).toFixed(2);
  };

  const dollarUSLocale = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Row>
      <Col
        className="d-flex justify-content-end "
        style={{ padding: "0px", marginLeft: "6px" }}
      >
        <span
          style={{
            wordBreak: "break-all",
            float: `right`,

            fontWeight: "bold",
            fontSize: "0.7rem",
          }}
        >
          {dollarUSLocale.format(totalColumnaGrid)}
        </span>
      </Col>
      <Col
        md={5}
        className="d-flex justify-content-end"
        style={{ padding: "0px", paddingRight: "15px" }}
      >
        <Porcentaje
          amount={calcularPorcentaje(
            totalCompararColumnaGrid,
            totalColumnaGrid
          )}
        ></Porcentaje>
      </Col>
    </Row>
  );
}

export default GridFooterTotalCard;
