import React, { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
// core components
import { MemoizedSideBar } from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { routes } from "routes";
import { getMenuArray } from "utils";
import { useConfigurationPage } from "hooks/useConfigurationPage";
import { useAuthentication } from "hooks";
import { useApp } from "hooks";

function AdminLayout({ rutasMenu, logo, codigo = "" }) {
  const [menus, setMenus] = useState([]);
  const { setConfigurationState } = useConfigurationPage();
  const { usuario } = useAuthentication();
  const { configuration } = useApp();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname != "/EntidadesList") {
      setConfigurationState(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setRouteComponentForMenu();
  }, []);

  function setRouteComponentForMenu() {
    let rutasMenu = getMenuArray();

    var menuDocumentacion = rutasMenu.find(
      (objeto) => objeto.Nombre == "Documentación"
    );
    menuDocumentacion.URL = configuration.URL_DOCUMENTACION;

    const Permisos = usuario?.Funciones;
    if (rutasMenu?.length > 0) {
      const menusGeneralesPermitidos = rutasMenu.filter((menu) => {
        if (menu.PermisoNombre) {
          const someFunction = Permisos.some((p) =>
            p.includes(`${menu.PermisoNombre}`)
          );

          if (someFunction) {
            return true;
          } else {
            return false;
          }
        }

        return true;
      });

      rutasMenu = menusGeneralesPermitidos;
    } else {
      rutasMenu = [];
    }
    setMenus(rutasMenu);
  }

  return (
    <>
      <div className="wrapper">
        <MemoizedSideBar
          routes={menus}
          isFixed={true}
          logo={logo}
          codigo={codigo}
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Switch>{routes}</Switch>
          </div>
        </div>
      </div>
    </>
  );
}
export const MemoizedAdminLayout = React.memo(AdminLayout);
