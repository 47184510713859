import { AuthorizationService } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const AuthorizationManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const refreshAuthorization = (permiso) => {
    return AuthorizationService.refreshAuthorization().then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const tienePermiso = (permiso) => {
    return AuthorizationService.tienePermiso(permiso).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    tienePermiso,
    refreshAuthorization
  };
})();
