import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Select from "react-select";

import { FormHelper } from "utils";
import { Perfil } from "models/Perfil";
import { Usuario } from "models/usuario";
import useMessage from "hooks/useMessage";
import useQueryParams from "hooks/useQueryParams";
import { GridView } from "components";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import { PerfilManager, UsuarioManager } from "managers";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import CambiarContraseniaModal from "components/Model/CambiarContraseniaModal";
import { useAuthentication } from "hooks";
import { Permisos } from "utils/permisos";

export default function UsuarioEdit({ showLoading, hideLoading }) {
  const [tituloHeader, setTituloHeader] = useState("Crear Usuario");
  const [usuario, setUsuario] = useState({});
  const URLParameters = useQueryParams();
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();
  const [esNuevo, setEsNuevo] = useState(false);
  const [perfilesList, setPerfilesList] = useState([]);
  const [perfilesGrid, setPerfilesGrid] = useState([]);
  const [mostrarModalCambiarContrasenia, setMostrarModalCambiarContrasenia] =
    useState(false);
  const { tienePermiso } = useAuthentication();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState({
    EsPaginable: false,
  });
  const location = useLocation();

  const Id = URLParameters.get("Id");
  const esClonar = URLParameters.get("clonar") == "true";
  const ESTATUS_LIST = Usuario.ESTATUS_LIST;

  useEffect(() => {
    setEsNuevo(!Id || esClonar);
    if (Id) {
      showLoading();
      cargarUsuario();
      esClonar
        ? setTituloHeader("Clonar Usuario")
        : setTituloHeader("Editar Usuario");
    }
    return () => {
      hideLoading();
      cerrarAlertas();
    };
  }, [location.search]);

  useEffect(() => {
    if (where != {}) {
      setPerfilesGrid(filterList());
    }
  }, [where]);

  const grabar = () => {
    showLoading();
    if (esNuevo) {
      UsuarioManager.crear(usuario)
        .then((res) => {
          setEsNuevo(false);
          Message({ message: `Grabado Exitosamente` });
          cargarUsuario(res.IdSec);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    } else {
      UsuarioManager.actualizar(usuario)
        .then(() => {
          Message({ message: `Actualizado Exitosamente` });
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    }
  };

  const cargarUsuario = (IdSec) => {
    UsuarioManager.buscarPorId(IdSec ? IdSec : Id)
      .then((data) => {
        setUsuario(data);
        cargarPerfilesList(data.PerfilesUsuario);
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        hideLoading();
      });
  };

  function filterList() {
    let list = [...perfilesList];
    let filteredList = list.filter((el) => {
      return (
        (where.IdSec
          ? el.IdSec.toString().includes(where.IdSec.toString())
          : true) &&
        (where.Nombre
          ? el.Nombre?.toLowerCase().includes(where.Nombre?.toLowerCase())
          : true) &&
        (where.Descripcion
          ? el.Descripcion?.toLowerCase().includes(
              where.Descripcion?.toLowerCase()
            )
          : true)
      );
    });
    return filteredList;
  }

  function cargarPerfilesList(perfilesList) {
    let list = [];
    PerfilManager.listado({
      Estatus: Perfil.DEFAULT_ESTATUS_VALUE,
      EsPaginable: false,
    })
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        list = res.Data;
        perfilesList.map((el) => {
          let index = list.findIndex((perfil) => perfil.IdSec === el.IdSec);
          if (index !== -1) {
            list[index].EsAnadirUsuario = true;
          } else {
            list[index].EsAnadirUsuario = false;
          }
        });
        sortPerfilesList(list);
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        hideLoading();
      });
  }

  function sortPerfilesList(list) {
    if (!list) {
      list = [...perfilesList];
    }
    list.sort((a, b) => {
      return Number(b.EsAnadirUsuario) - Number(a.EsAnadirUsuario);
    });
    setPerfilesList(list);
    setPerfilesGrid(list);
  }

  function addToListClick(Id) {
    const index = perfilesList.findIndex((perfil) => {
      return perfil.IdSec === Id;
    });
    usuario.PerfilesUsuario.push(perfilesList[index]);
  }

  function removeFromList(Id) {
    const index = usuario.PerfilesUsuario.findIndex((perfil) => {
      return perfil.IdSec === Id;
    });
    setPerfilesList([...perfilesList, usuario.PerfilesUsuario[index]]);
    usuario.PerfilesUsuario.splice(index, 1);
  }

  const handlePerfilSwitch = (params) => {
    const { Id } = params;
    const index = perfilesList.findIndex((perfil) => {
      return perfil.IdSec === Id;
    });
    let list = [...perfilesList];
    if (list[index].EsAnadirUsuario) {
      removeFromList(Id);
    } else {
      addToListClick(Id);
    }
    list[index].EsAnadirUsuario = !perfilesList[index].EsAnadirUsuario;
    setPerfilesList(list);
  };

  const PerfilesGrid = (
    <GridView
      pagination={pagination}
      setPagination={setPagination}
      allowPaging={pagination.EsPaginable}
      pageSize={pagination.CantidadPorPagina}
      pageIndex={pagination.IndiceDePagina}
      lastPageNumber={pagination.PaginasTotal}
      dataSource={perfilesGrid}
      where={where}
      setWhere={setWhere}
      height="20rem"
    >
      <GridView.Column
        headerText="Id"
        dataField="IdSec"
        sortExpression="IdSec"
      />
      <GridView.Column
        headerText="Nombre"
        dataField="Nombre"
        sortExpression="Nombre"
      />
      <GridView.Column
        headerText="Descripción"
        dataField="Descripcion"
        sortExpression="Descripcion"
      />
      <GridView.Column
        isField
        bodyRenderer={(row) => {
          return (
            <>
              <div className="float-right mr-4">
                <Form.Check
                  type="switch"
                  name="AgregarUsuario"
                  onChange={() => {
                    handlePerfilSwitch({ Id: row.IdSec });
                  }}
                  checked={row.EsAnadirUsuario}
                  id={`switch-${row.IdSec}`}
                />
              </div>
            </>
          );
        }}
      />
    </GridView>
  );

  const cambioPassword = () => {
    setMostrarModalCambiarContrasenia(true);
  };

  return (
    <>
      {mostrarModalCambiarContrasenia && (
        <CambiarContraseniaModal
          setMostrarModalCambiarContrasenia={setMostrarModalCambiarContrasenia}
          mostrarModalCambiarContrasenia={mostrarModalCambiarContrasenia}
          user={usuario}
          noPedirContraseniaActual={true}
        ></CambiarContraseniaModal>
      )}
      <Form action="" className="form-horizontal" id="TypeValidation" method="">
        <Card>
          <EditHeader
            titulo={`${tituloHeader}`}
            volver={true}
            grabar={grabar}
            cambioPassword={
              tienePermiso(Permisos.Usuario.ACTUALIZAR_PASSWORD)
                ? cambioPassword
                : null
            }
          />
          <EditContainer>
            <SectionRow>
              <InputContainer label="Nombre">
                <Form.Control
                  onChange={(e) => FormHelper.handleChange(e, setUsuario)}
                  name="Descripcion"
                  type="text"
                  placeholder="Nombre Completo"
                  value={usuario.Descripcion}
                />
              </InputContainer>
              <InputContainer label="Usuario">
                <Form.Control
                  name="NombreUsuario"
                  type="text"
                  onChange={(e) => FormHelper.handleChange(e, setUsuario)}
                  value={usuario.NombreUsuario}
                  placeholder="Nombre de Usuario"
                />
              </InputContainer>
            </SectionRow>
            <SectionRow>
              <InputContainer label="Correo">
                <Form.Control
                  onChange={(e) => FormHelper.handleChange(e, setUsuario)}
                  name="Email"
                  type="email"
                  placeholder="Correo Electrónico"
                  value={usuario.Email}
                />
              </InputContainer>
              <InputContainer label="Estatus">
                <Select
                  isDisabled={esNuevo}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="Estatus"
                  onChange={(e) =>
                    setUsuario({
                      ...usuario,
                      Estatus: e.value,
                    })
                  }
                  options={ESTATUS_LIST}
                  value={
                    esNuevo
                      ? ESTATUS_LIST.filter(function (option) {
                          return option.value === Usuario.DEFAULT_ESTATUS_VALUE;
                        })
                      : ESTATUS_LIST.filter(function (option) {
                          return option.value === usuario.Estatus;
                        })
                  }
                />
              </InputContainer>
            </SectionRow>
            {esNuevo && (
              <EditSection title="Seguridad">
                <SectionRow>
                  <InputContainer label="Contraseña">
                    <Form.Control
                      name="nuevoPassword"
                      type="password"
                      onChange={(e) => FormHelper.handleChange(e, setUsuario)}
                      value={usuario.nuevoPassword}
                    ></Form.Control>
                  </InputContainer>
                  <InputContainer label="Confirmar">
                    <Form.Control
                      name="passwordConfirm"
                      type="password"
                      onChange={(e) => FormHelper.handleChange(e, setUsuario)}
                      value={usuario.passwordConfirm}
                      placeholder="Confirmar Contraseña"
                    ></Form.Control>
                  </InputContainer>
                </SectionRow>
              </EditSection>
            )}

            {!esNuevo ? (
              <EditSection title="Perfiles">{PerfilesGrid}</EditSection>
            ) : null}
          </EditContainer>
        </Card>
      </Form>
    </>
  );
}
