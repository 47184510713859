import { useEffect, useState, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { GraficosManager } from "managers";
import useExceptionHandler from "hooks/useExceptionHandler";
import { TRANSACCION_ESTATUS_TERMINADA } from "utils";
import { GRAFICO_PERIODICIDAD_MENSUAL } from "utils";
import { GRAFICO_PERIODICIDAD_DIARIO } from "utils";
import { GRAFICO_PERIODICIDAD_ANUAL } from "utils";
import { TIPO_COMPROBANTE_COMPRAS } from "utils";
import { TIPO_COMPROBANTE_GASTO_MENOR } from "utils";
import { TIPO_COMPROBANTE_PAGOS_EXTERIOR } from "utils";
import { Simplebar } from "components/Simplebar/Simplebar";
import { FormHelper } from "utils";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { PERIOCIDAD_LIST } from "utils";
import { InputSidebarContainer } from "components/PantallasEdit/InputContainer/InputSideBarContainer";
import { useLocalStorage } from "hooks";

export const ComprobantesComprasChart = ({ showCanvas, setShowCanvas }) => {
  const [data, setData] = useState([]);
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const DEFAULT_VALUES = {
    TipoEcfList: [
      TIPO_COMPROBANTE_COMPRAS,
      TIPO_COMPROBANTE_GASTO_MENOR,
      TIPO_COMPROBANTE_PAGOS_EXTERIOR,
    ],
    Estatus: TRANSACCION_ESTATUS_TERMINADA,
    Periodicidad: GRAFICO_PERIODICIDAD_MENSUAL,
    CantidadPeriodos: 4,
  };

  const [storedWhere, setStoredWhere] = useLocalStorage(
    "Comprobantes-Compras-chart",
    DEFAULT_VALUES
  );
  const [where, setWhere] = useState(
    storedWhere ? storedWhere : DEFAULT_VALUES
  );

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setWhere);
    FormHelper.handleChange(e, setStoredWhere);
  };

  const [options, setOptions] = useState({
    legend: {
      type: "scroll",
      orient: "horizontal",
    },
    dataZoom: [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
      },
    ],
    tooltip: {
      trigger: "axis",
      showContent: true,
    },
    dataset: {
      source: [],
    },
    xAxis: { type: "category" },
    yAxis: { gridIndex: 0 },
    grid: { top: "55%", height: "35%" },
    colorBy: "series",
    series: [],
  });
  const { handleException } = useExceptionHandler();

  useEffect(() => {
    if (data) {
      const lines = data.DataGraficoComprobantes?.map((d) => {
        return {
          type: "line",
          smooth: true,
          seriesLayoutBy: "row",
          emphasis: { focus: "series" },
        };
      });

      const pieOptions = data.DataGraficoComprobantes?.map((d) => {
        return {
          name: d.TipoEcf,
          value: d.Cantidad,
        };
      });

      if (lines) {
        setOptions({
          ...options,
          series: lines.concat([
            {
              type: "pie",
              id: "pie",
              radius: "50%",
              center: ["50%", "35%"],
              bottom: "40%",
              colorBy: "data",
              top: "15%",
              data: pieOptions,
              emphasis: {
                focus: "self",
              },
              label: {
                formatter: "{b}: {@2012} ({d}%)",
              },
              encode: {
                itemName: data.DataHistoricoGraficoComprobantes[0].label,
                value: data.DataHistoricoGraficoComprobantes[0].value[0],
                tooltip: data.DataHistoricoGraficoComprobantes[0].value[0],
              },
            },
          ]),
        });
      }
    }
  }, [data]);

  const FilterSimplebar = () => {
    return (
      <Simplebar
        ref={ref}
        backdrop={true}
        placement={"end"}
        portal={false}
        visible={showCanvas}
        setShow={(e) => {
          setShowCanvas(e);
        }}
        useLoading={true}
        title="Filtros Comprobantes Compras"
        btnLeft={{
          show: true,
          message: "Limpiar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "clean",
          func: () => {
            setWhere({});
            setStoredWhere({});
            setTimeout(() => {
              getData();
            }, 100);
          },
        }}
        btnRight={{
          show: true,
          message: "Filtrar",
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "check",
          func: () => {
            getData();
            setShowCanvas(false);
          },
        }}
      >
        <InputSidebarContainer label="Periodicidad">
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="Periodicidad"
            onChange={(e) => {
              setWhere({
                ...where,
                Periodicidad: e.value,
              });
              setStoredWhere({
                ...storedWhere,
                Periodicidad: e.value,
              });
            }}
            options={PERIOCIDAD_LIST}
            value={PERIOCIDAD_LIST.filter(function (option) {
              return (
                option.value ===
                (where.Periodicidad
                  ? where.Periodicidad
                  : PERIOCIDAD_LIST[0].value)
              );
            })}
          />

          <label>Cantidad de Periodos</label>
          <Form.Control
            name="CantidadPeriodos"
            type="number"
            value={where.CantidadPeriodos}
            onChange={onInputChange}
          />
        </InputSidebarContainer>
      </Simplebar>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setIsLoading(true);
    GraficosManager.obtenerDatosGraficoComprobantesCompras(where)
      .then((res) => {
        setOptions({
          ...options,
          dataset: {
            source: res.Data.DataHistoricoGraficoComprobantes.map((d) => {
              return [d.label, ...d.value];
            }),
          },
        });
        setData(res.Data);
        setIsLoading(false);
      })
      .catch((err) => handleException(err));
  }

  return (
    <>
      <FilterSimplebar />
      <ReactECharts
        option={options}
        showLoading={isLoading}
        loadingOption={{ text: "Cargando Datos" }}
        style={{ height: "95%" }}
      />
    </>
  );
};
