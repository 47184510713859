import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

import useMessage from "hooks/useMessage";
import { UsuarioManager } from "managers";
import { getComponentWithLoading } from "utils";
import useExceptionHandler from "hooks/useExceptionHandler";
import { Simplebar, InputSidebarContainer } from "components";

const CambiarContraseniaModal = getComponentWithLoading(
  ({
    setMostrarModalCambiarContrasenia,
    mostrarModalCambiarContrasenia,
    user,
    noPedirContraseniaActual,
  }) => {
    const [usuario, setUsuario] = useState({
      ...user,
      noValidarPasswordActual: noPedirContraseniaActual,
    });

    const ref = useRef();
    const { Message, cerrarAlertas } = useMessage();
    const { handleException } = useExceptionHandler();

    useEffect(() => {
      return () => {
        cerrarAlertas();
      };
    });

    const onModalCambiarContraseniaAceptar = () => {
      ref.current.showLoading(true);

      if (noPedirContraseniaActual) {
        UsuarioManager.actualizarContrasenia(usuario)
          .then(() => {
            Message({ message: `Contraseña Actualizada Correctamente.` });
            setMostrarModalCambiarContrasenia(false);
          })
          .catch((e) => handleException(e))
          .finally(() => ref.current.showLoading(false));
      } else {
        UsuarioManager.cambiarContrasenia(usuario)
          .then(() => {
            Message({ message: `Contraseña Actualizada Correctamente.` });
            setMostrarModalCambiarContrasenia(false);
          })
          .catch((e) => handleException(e))
          .finally(() => ref.current.showLoading(false));
      }
    };

    return (
      <Simplebar
        ref={ref}
        size="sm"
        placement="end"
        portal={false}
        backdrop={true}
        useLoading={true}
        title="Cambiar Contraseña"
        visible={mostrarModalCambiarContrasenia}
        setShow={(e) => setMostrarModalCambiarContrasenia(e)}
        btnLeft={{
          show: true,
          isCenter: true,
          isComponent: true,
          size: "45px",
          height: "24px",
          with: "24px",
          iconName: "save",
          message: "Aceptar",
          func: onModalCambiarContraseniaAceptar,
        }}
        btnRight={{ show: false }}
      >
        <Form
          action=""
          className="form-horizontal"
          id="TypeValidation"
          method=""
        >
          {!noPedirContraseniaActual && (
            <InputSidebarContainer label="Contraseña Actual">
              <Form.Control
                name="Nombre"
                type="password"
                value={usuario?.passwordActual}
                onChange={(e) =>
                  setUsuario({
                    ...usuario,
                    passwordActual: e.target.value,
                  })
                }
              />
            </InputSidebarContainer>
          )}

          <InputSidebarContainer label="Nueva Contraseña">
            <Form.Control
              name="Nombre"
              type="password"
              value={usuario?.nuevoPassword}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  nuevoPassword: e.target.value,
                })
              }
            />
          </InputSidebarContainer>
          <InputSidebarContainer label="Confirmar Contraseña">
            <Form.Control
              name="Nombre"
              type="password"
              value={usuario?.passwordConfirm}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  passwordConfirm: e.target.value,
                })
              }
            />
          </InputSidebarContainer>
        </Form>
      </Simplebar>
    );
  }
);

export default CambiarContraseniaModal;
